
export enum IDeviationEventSupervision {
    DailyInspection,
    BService,
    CService,
    EService,
    DService,
    IndividualService,
    ROVInspection,
    Checklist,
    Wash,
    Inspection
}
