import { useTranslation } from 'react-i18next'
import { ShowDialogProps } from '../../../show-dialog'
import { invalidateGlobalDocument, useUpdateGlobalDocumentRule } from '../../../api/global-documents'
import { GlobalDocumentRuleForm } from './GlobalDocumentRuleForm'
import { IGlobalDocumentRule } from '../../../generated-types/global-documents'
import {
  INTERNAL_SERVER_ERROR,
  RULES_MERGED,
  RULES_RESTORED,
  RULE_RELEASED,
  SAME_RULE_ERROR,
  SUCCESSFULLY_UPDATED,
} from '../../../constant'
import { toast } from 'react-toastify'
import { invalidateDocumentCategoryCache } from '../../../api/document-category'
import { invalidateDocument } from '../../../api/document'

export interface GlobalDocumentRuleUpdateDialogProps extends ShowDialogProps {
  documentRuleId: EntityId
  documentId: EntityId
  initialValues: IGlobalDocumentRule
  currentCustomerId?: EntityId
}

export const GlobalDocumentRuleUpdateDialog: React.FC<GlobalDocumentRuleUpdateDialogProps> = ({
  pop,
  documentRuleId,
  documentId,
  initialValues,
  currentCustomerId,
}) => {
  const { t: translate } = useTranslation(['global-documents', 'common'])

  const mutation = useUpdateGlobalDocumentRule(documentRuleId, documentId)

  const onSubmit = async form => {
    const mainComponentTypeId = form.mainComponentType?.id
    const customerId = currentCustomerId ?? form.customer?.id
    const { mainComponentType: _, customer: __, ...rest } = form
    await mutation.mutateAsync({ ...rest, mainComponentTypeId, customerId }).then(
      ({ data }) => {
        if (data === SUCCESSFULLY_UPDATED) {
          toast.success(translate('successfullyUpdated', { ns: 'common-api-message' }))
        }
        if (data === RULES_RESTORED) {
          toast.warning(translate('rulesRestored', { ns: 'global-document-rules' }))
        }
        if (data === RULE_RELEASED) {
          toast.warning(translate('ruleReleased', { ns: 'global-document-rules' }))
        }
        if (data === RULES_MERGED) {
          toast.warning(translate('ruleMerged', { ns: 'global-document-rules' }))
        }
        invalidateGlobalDocument.getRules(documentId)
        invalidateGlobalDocument.forApproval()
        invalidateGlobalDocument.approved()
        invalidateGlobalDocument.getCategories()
        invalidateDocumentCategoryCache.getDocumentCategoriesWithDocumentCountByKey()
        invalidateDocument.getDocuments(customerId)
        pop()
      },
      ({ data }) => {
        if (data === SAME_RULE_ERROR) {
          toast.error(translate('sameRuleError', { ns: 'global-document-rules' }))
        }
        if (data === INTERNAL_SERVER_ERROR) {
          toast.error(translate('unExpectedError', { ns: 'common-api-message' }))
        }
      },
    )
  }

  return (
    <GlobalDocumentRuleForm
      customerId={currentCustomerId}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={initialValues}
    />
  )
}
