import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import i18n from '../../../../i18n'
import { styled } from '@mui/material'

const localizer = momentLocalizer(moment)

const TaskCalendar = ({ onClick, events, onMonthChange }) => {

    return (
        <StyledCalendar
            culture={i18n.language}
            startAccessor={(event) => { return new Date(event.startDate) }}
            endAccessor={(event) => { return event.endDate ? new Date(event.endDate) : new Date(event.startDate) }}
            localizer={localizer}
            events={events}
            onSelectEvent={onClick}
            onNavigate={date => onMonthChange(date)}
            views={{ week: false, month: true, day: true }}
            popup
        />
    )
}

const StyledCalendar = styled(Calendar)({
    height: 500
})

export default TaskCalendar