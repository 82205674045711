import Grid from '@mui/material/Grid'
import React, { useEffect } from 'react'
import { languages } from '../constant'
import { FormTextField } from './FormCore'

interface LanguageSpecificTextFieldProps {
  data?: any
  label?: string
  form: any
}

export const LanguageSpecificTextField: React.FC<LanguageSpecificTextFieldProps> = ({ data, label, form }) => {
  useEffect(() => {
    const outputJson = JSON.parse(data)
    languages.forEach(label => {
      form.setValue(`label_${label}`, outputJson[label])
    })
  }, [data])

  return (
    <Grid container spacing={2}>
      {languages.map(language => {
        return (
          <Grid key={language} item xs={6}>
            <FormTextField className="w-full" name={`label_${language}`} label={`${label}-${language}`} />
          </Grid>
        )
      })}
    </Grid>
  )
}
