import { ICustomProperty } from '../generated-types/customProperty'
import { IMainComponentType } from '../generated-types/main-component-type'
import { IMainComponentTypeWithMainComponentCount } from '../generated-types/main-component-type-with-main-component-count'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/main-component-type`
const baseUrlAdmin = 'admin/main-component-type'

export const customerMainComponentTypeEndpoints = {
  getComponentTypes(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getComponentTypeList(customerId: EntityId) {
    return `customers/${customerId}/main-component-type-list`
  },
  getComponentType(id: EntityId, customerId: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  getComponentCustomProperties(id: EntityId, customerId: EntityId) {
    return `${baseUrl(customerId)}/${id}/get-custom-properties`
  },
  getMainComponentTypeWithMainComponentCount(customerId: EntityId) {
    return `${baseUrl(customerId)}/mainComponentTypesWithMainComponentCount`
  },
  createMainComponentType(customerId:EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateMainComponentType(id: EntityId, customerId: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  deleteMainComponentType(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
}

export const adminMainComponentTypeEndpoints = {
  getComponentTypes() {
    return `${baseUrlAdmin}/`
  },
  getComponentType(id: EntityId) {
    return `${baseUrlAdmin}/${id}`
  },
  getComponentCustomProperties(id: EntityId) {
    return `${baseUrlAdmin}/${id}/get-custom-properties`
  },
  createMainComponentType() {
    return `${baseUrlAdmin}`
  },
  updateMainComponentType(id: EntityId) {
    return `${baseUrlAdmin}/${id}`
  },
  deleteMainComponentType() {
    return `${baseUrlAdmin}`
  },
}

export const invalidateMainComponentTypeCache = {
  useGetMainComponentTypes(customerId: EntityId) {
    queryClient.invalidateQueries(customerMainComponentTypeEndpoints.getComponentTypes(customerId))
  },
  useGetMainComponentTypeWithMainComponentCount(customerId: EntityId) {
    queryClient.invalidateQueries(
      customerMainComponentTypeEndpoints.getMainComponentTypeWithMainComponentCount(customerId),
    )
  },
  useGetMainComponentType(id: EntityId, customerId: EntityId) {
    queryClient.invalidateQueries(customerMainComponentTypeEndpoints.getComponentType(id, customerId))
  },
  useGetMainComponentCustomProperties(id: EntityId, customerId: EntityId) {
    queryClient.invalidateQueries(customerMainComponentTypeEndpoints.getComponentCustomProperties(id, customerId))
  },
  useGetMainComponentTypesAdmin() {
    queryClient.invalidateQueries(adminMainComponentTypeEndpoints.getComponentTypes())
  },
  useGetMainComponentTypeAdmin(id: EntityId) {
    queryClient.invalidateQueries(adminMainComponentTypeEndpoints.getComponentType(id))
  },
  useGetMainComponentCustomPropertiesAdmin(id: EntityId) {
    queryClient.invalidateQueries(adminMainComponentTypeEndpoints.getComponentCustomProperties(id))
  },
}

export const useGetMainComponentTypes = (customerId:EntityId) =>
  useGet<IPaginatedResult<IMainComponentType>>(customerMainComponentTypeEndpoints.getComponentTypes(customerId))

export const useGetMainComponentTypeList = (customerId:EntityId) =>
  useGet<Array<IMainComponentType>>(customerMainComponentTypeEndpoints.getComponentTypeList(customerId))

export const useGetMainComponentTypesAdmin = () =>
  useGet<IPaginatedResult<IMainComponentType>>(adminMainComponentTypeEndpoints.getComponentTypes())

export const useGetMainComponentType = (id: EntityId, customerId: EntityId) => useGet<IMainComponentType>(customerMainComponentTypeEndpoints.getComponentType(id, customerId))
export const useGetMainComponentTypeAdmin = (id: EntityId) => useGet<IMainComponentType>(adminMainComponentTypeEndpoints.getComponentType(id))

export const useGetMainComponentCustomProperties = (id: EntityId, customerId: EntityId) =>
  useGet<Array<ICustomProperty>>(customerMainComponentTypeEndpoints.getComponentCustomProperties(id, customerId), undefined, { enabled: id !== undefined && id !== 0 && id !== null })

export const useGetMainComponentCustomPropertiesAdmin = (id: EntityId) =>
  useGet<Array<ICustomProperty>>(adminMainComponentTypeEndpoints.getComponentCustomProperties(id))
  
export const useGetMainComponentTypeWithMainComponentCount = (customerId: EntityId) => useGet<Array<IMainComponentTypeWithMainComponentCount>>(customerMainComponentTypeEndpoints.getMainComponentTypeWithMainComponentCount(customerId))

export const useAddMainComponentType = (customerId: EntityId) => usePost<IMainComponentType, IMainComponentType>(customerMainComponentTypeEndpoints.createMainComponentType(customerId))

export const useAddMainComponentTypeAdmin = () => usePost<IMainComponentType, IMainComponentType>(adminMainComponentTypeEndpoints.createMainComponentType())

export const useUpdateMainComponentType = (id: EntityId, customerId: EntityId) => usePut<IMainComponentType, IMainComponentType>(customerMainComponentTypeEndpoints.updateMainComponentType(id, customerId))

export const useUpdateMainComponentTypeAdmin = (id: EntityId) => usePut<IMainComponentType, IMainComponentType>(adminMainComponentTypeEndpoints.updateMainComponentType(id))

export const useDeleteMainComponentType = (customerId: EntityId) => useDelete<IMainComponentType>(customerMainComponentTypeEndpoints.deleteMainComponentType(customerId))

export const useDeleteMainComponentTypeAdmin = () => useDelete<IMainComponentType>(adminMainComponentTypeEndpoints.deleteMainComponentType())
