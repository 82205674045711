import { Box, BoxProps, Grid, styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import { FormField, FormLayout } from '../../generated-types/form-field'
import FieldSelector from './FieldSelector'
import { AddIcon } from '../../screens/customer/form/Icons'
import { DeleteOutline } from '@mui/icons-material'
import { UseFormReturn } from 'react-hook-form'

interface ILayoutProps {
    layout: FormLayout
    layoutIndex: number
    size: number
    onDrop: (index: number, fieldIndex: number) => void
    onRearrange?: (layoutIndex: number, colIndex: number, fieldIndex: number) => void
    onCreateLayout: (index: number) => void
    deleteLayout: () => void
    setSelectedSettingField: (field: FormField, row: number, col: number, fieldIndex: number) => void
    disabled: boolean
    indexCol: number
    form?: UseFormReturn
    withColorLayout?: boolean
}

const LayoutBox: React.FC<ILayoutProps> = ({ layout, onDrop, form, layoutIndex, size, onRearrange, onCreateLayout, deleteLayout, setSelectedSettingField, disabled, indexCol, withColorLayout = false }) => {
    const { fields } = layout
    const theme = useTheme()
    return (
      <StyledGrid
        md={size}
        marginTop={1}
        padding={0.1}
        bgcolor={withColorLayout ? '#edfbff8f' : ''}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          height={'100%'}
          borderRadius={1}
          border={withColorLayout ? '1px solid lightBlue' : 0}
        >
          <OuterContainer display={'flex'} flexDirection={'column'}>
            {fields.map((field: FormField, index: number) => {
              return (
                <DroppableBox
                  key={index}
                  isReArrangeable={Boolean(onRearrange)}
                  onDragEnter={
                    onRearrange
                      ? event => {
                          event.currentTarget.classList.add('drag-enter')
                        }
                      : undefined
                  }
                  onDragLeave={
                    onRearrange
                      ? event => {
                          event.currentTarget.classList.remove('drag-enter')
                        }
                      : undefined
                  }
                  onDragOver={
                    onRearrange
                      ? event => {
                          event.preventDefault()
                        }
                      : undefined
                  }
                  onDrop={
                    onRearrange
                      ? event => {
                          event.currentTarget.classList.remove('drag-enter')
                          onDrop(layoutIndex, index)
                        }
                      : undefined
                  }
                >
                  <FieldSelector
                    form={form}
                    fieldDisabled={field?.disabled}
                    disabled={disabled}
                    onChange={value => {
                      if (form) {
                        form.setValue(field.name, value)
                      }
                    }}
                    layoutIndex={layoutIndex}
                    onDrag={() => {
                      if (onRearrange) onRearrange(layoutIndex, indexCol, index)
                    }}
                    index={index}
                    field={field}
                    setSelectedSettingField={() => {
                      if (setSelectedSettingField) {
                        setSelectedSettingField(field, layoutIndex, indexCol, index)
                      }
                    }}
                  />
                  <Box marginLeft={1}>
                    <Typography variant="caption" color={'red'}>
                      {form?.formState.errors[field.name]?.message}
                    </Typography>
                  </Box>
                </DroppableBox>
              )
            })}
            {onRearrange && (
              <DroppableBox
                isReArrangeable
                onDragEnter={event => {
                  event.currentTarget.classList.add('drag-enter')
                }}
                onDragLeave={event => {
                  event.currentTarget.classList.remove('drag-enter')
                }}
                onDrop={event => {
                  event.currentTarget.classList.remove('drag-enter')
                  onDrop(layoutIndex, fields.length)
                }}
              >
                <StyledContainer
                  onDragOver={event => {
                    event.preventDefault()
                  }}
                  onDragEnter={event => {
                    event.preventDefault()
                  }}
                ></StyledContainer>
              </DroppableBox>
            )}
          </OuterContainer>
          <Box width={30} height={'100%'}>
            {disabled && (
              <>
                <AddIcon
                  onClick={() => {
                    onCreateLayout(indexCol + 1)
                  }}
                />
                <StyledDeleteButton onClick={() => deleteLayout()} />
              </>
            )}
          </Box>
        </Box>
      </StyledGrid>
    )
}

export const StyledGrid = styled(Grid)({
    minHeight: 50
})

export const StyledContainer = styled(Box)({
    height: '100%',
    minHeight: 50
})

export const StyledDeleteButton = styled(DeleteOutline)({
 color: '#88c2f3',
        cursor: 'pointer'
})

export const OuterContainer = styled(Box)({
      width: 'calc(100% - 30px)'
})

interface DroppableBoxProps extends BoxProps{
    isReArrangeable: boolean
}

const DroppableBox = styled(Box)<DroppableBoxProps>(({ isReArrangeable }) =>
  isReArrangeable
    ? {
        width: 'calc(100% - 30px)',
        minHeight: 50,
      }
    : undefined,
)

export default LayoutBox