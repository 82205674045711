import { IResource } from '../generated-types/resource'
import { ITask } from '../generated-types/task'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const taskCustomerAdminBaseURL = (customerId: EntityId) => `customers/${customerId}`
const taskBaseURL = (localityId: EntityId) => `localities/${localityId}/task`

export const taskEndpoints = {
  getTaskResources(localityId: EntityId, taskId: EntityId) {
    return `${taskBaseURL(localityId)}/${taskId}/taskWithTaskResources`
  },
  filterTask(customerId) {
    return `${taskCustomerAdminBaseURL(customerId)}/filteredTasks`
  },
  baseURL(localityId) {
    return `${taskCustomerAdminBaseURL(localityId)}/tasks`
  },
  singleTask(localityId, taskId) {
    return `${taskBaseURL(localityId)}/${taskId}`
  },
  localityTaskBaseURL(localityId: EntityId) {
    return `${taskBaseURL(localityId)}`
  },
  unAssignResource(localityId: EntityId, taskId: EntityId) {
    return `${taskBaseURL(localityId)}/${taskId}/unAssignTaskResource`
  },
  assignTaskResources(localityId: EntityId, taskId: EntityId) {
    return `${taskBaseURL(localityId)}/${taskId}/assign-resource`
  },
}

export const invalidateTaskCache = {
  useGetTaskResources(localityId: EntityId, taskId: EntityId) {
    queryClient.invalidateQueries(taskEndpoints.getTaskResources(localityId, taskId))
  },
  getCustomerAdminTaskByFilter(customerId) {
    queryClient.invalidateQueries(taskEndpoints.filterTask(customerId))
  },
  getTaskResources(localityId, taskId) {
    queryClient.invalidateQueries(taskEndpoints.getTaskResources(localityId, taskId))
  },
  useGetTask(localityId: EntityId, taskId: EntityId) {
    queryClient.invalidateQueries(taskEndpoints.singleTask(localityId, taskId))
  },
}

export const useCreateTask = (localityId: EntityId) => usePost(taskEndpoints.baseURL(localityId))

export const useUpdateTask = (localityId: EntityId, taskId: EntityId) =>
  usePut(taskEndpoints.singleTask(localityId, taskId))

export const useGetFilterTask = (
  customerId: number,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
  queries,
) => useGet<IPaginatedResult<ITask>>(taskEndpoints.filterTask(customerId), { page, pageSize, ...queries })

export const useGetTask = (localityId: EntityId, taskId: EntityId) =>
  useGet<ITask>(taskEndpoints.singleTask(localityId, taskId), undefined, { enabled: !!taskId || !!localityId })

export const useDeleteTask = (localityId: EntityId) =>
  useDelete<IPaginatedResult<ITask>>(taskEndpoints.localityTaskBaseURL(localityId))

export const useGetTaskResources = (localityId: EntityId, taskId: EntityId, scheduleTaskId?: EntityId) =>
  useGet<Array<IResource>>(taskEndpoints.getTaskResources(localityId, taskId), { scheduleTaskId })

export const useUnAssignTaskResource = (localityId: EntityId, taskId: EntityId) =>
  useDelete<any>(taskEndpoints.unAssignResource(localityId, taskId))

export const useAssignTaskResource = (localityId: EntityId, taskId: EntityId) =>
  usePost(taskEndpoints.assignTaskResources(localityId, taskId))
