import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IGetDocumentsParams } from '../../../../api/document'
import { DocumentsTableByDocumentCategory } from './DocumentsTableByDocumentCategory'
import { useGetDocumentCategoriesWithDocuments } from '../../../../api/users/document-category'
import { CenteredSpinner } from 'src/components/Spinner'
import { Context } from 'react'
import { MooringDropdownDto } from 'src/generated-types/mooring-drop-down-dto'

export interface IAccordionDocumentsTableProps {
  customerId: EntityId
  localityId: EntityId
  documentTypeId: EntityId
  documentsParams: IGetDocumentsParams
  height?: number
  context?: React.Context<any>
  moorings?: MooringDropdownDto[]
}

export const AccordionDocumentsTable: React.FC<IAccordionDocumentsTableProps> = ({
  customerId,
  localityId,
  documentsParams,
  height,
  context,
  moorings,
}) => {
  const { t } = useTranslation(['Document', 'common'])
  const { data: documentTypes, isLoading } = useGetDocumentCategoriesWithDocuments(customerId, { ...documentsParams })

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <>
      <Stack spacing={1} className="scrollbar" height={height} sx={{ overflowY: 'auto' }}>
        {documentTypes && documentTypes.length > 0 ? (
          documentTypes.map(documentCategory => (
            <DocumentsTableByDocumentCategory
              key={documentCategory?.id}
              customerId={customerId}
              localityId={localityId}
              documentsParams={{ documentCategoryId: documentCategory?.id as EntityId, ...documentsParams }}
              showEditDocumentButton={true}
              showDocumentsHeader={false}
              documentCategoryInfo={documentCategory}
              context={context as Context<any>}
              moorings={moorings}
            />
          ))
        ) : (
          <Box py={2} className="text-center">
            {t('noResultFound', { ns: 'common' })}
          </Box>
        )}
      </Stack>
    </>
  )
}
