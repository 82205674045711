import { ArrowDropUpTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import {
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
} from '@mui/x-data-grid-pro'
import React, { FC } from 'react'

interface IGenericToggleProps extends Pick<GridRenderCellParams, 'id' | 'value'> {
  expandTitle: string
}

export const GenericToggle: FC<IGenericToggleProps> = ({ id, value: isExpanded, expandTitle }) => {
  const apiRef = useGridApiContext()

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

  const hasDetail = React.isValidElement(contentCache[id])

  return (
    <Tooltip title={expandTitle}>
      <IconButton
        size="medium"
        tabIndex={-1}
        disabled={!hasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: theme =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      >
        <ArrowDropUpTwoTone fontSize="inherit" />
      </IconButton>
    </Tooltip>
  )
}
