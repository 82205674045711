import { Box, IconButton, Stack, styled, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose, MdKeyboardReturn } from 'react-icons/md'
import { MUIThemeColors } from 'src/mui-theme'
import { getThemeColor } from 'src/utils'

export const DropdownOptionsField = ({ dropdownOptions, setDropdownOptions, isFirstTime }) => {
  const [dropdownOptionText, setDropdownOptionText] = useState<string>('')
  const { t } = useTranslation(['custom-property'])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      dropdownOptionText.trim().length != 0 && setDropdownOptions(prevOptions => [...prevOptions, dropdownOptionText.trim()])
      setDropdownOptionText('')
    }
  }

  const removeDropdownOption = (optionToRemove: string) => {
    const updatedOptions = dropdownOptions.filter(item => item !== optionToRemove)
    setDropdownOptions(updatedOptions)
  }

  return (
    <>
      <OptionsField>
        <Stack spacing={2}>
          <Typography>{t('dropdownOptions')}</Typography>
          {dropdownOptions.length !== 0 && (
            <OptionItem direction={'column'} borderRadius={2}>
              {dropdownOptions.map(option => {
                return (
                  <>
                    <OptionItemContainer
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography>{option}</Typography>
                      <IconButton onClick={() => removeDropdownOption(option)}>
                        <MdClose />
                      </IconButton>
                    </OptionItemContainer>
                  </>
                )
              })}
            </OptionItem>
          )}
          <TextField
            fullWidth={true}
            id="standard-basic"
            label={t('addDropdownOption')}
            variant="standard"
            InputProps={{ endAdornment: <MdKeyboardReturn /> }}
            onChange={event => setDropdownOptionText(event.target.value)}
            onKeyDown={handleKeyDown}
            value={dropdownOptionText}
            error={!isFirstTime && dropdownOptions.length === 0}
            helperText={!isFirstTime && dropdownOptions.length === 0 ? t('dropdownOptionsAreEmpty') : ''}
          />
        </Stack>
      </OptionsField>
    </>
  )
}

const OptionsField = styled(Box)(({theme})=>({
  backgroundColor: getThemeColor(theme, MUIThemeColors.backgroundMain),
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
}))

const OptionItemContainer = styled(Stack)(({theme})=>({
  paddingLeft: theme.spacing(2),
  backgroundColor: getThemeColor(theme, MUIThemeColors.backgroundMain),
  '&:hover': {
    backgroundColor: getThemeColor(theme, MUIThemeColors.secondaryLight),
    opacity: [0.9, 0.8, 0.7],
  },
  borderRadius: theme.spacing(1),
}))

const OptionItem = styled(Stack)(({theme})=>({
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[4],
}))
