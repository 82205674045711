import { LocalityGroupForm } from '../../../forms/LocalityGroupFrom'
import { invalidateLocalityGroupsQueries, useAddLocalityGroup } from '../../../api/locality-groups'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { FC, useCallback } from 'react'
import { ShowDialogProps } from '../../../show-dialog'

interface CreateLocalityGroupDialogProps extends ShowDialogProps {
  customerId: string
}
export const CreateLocalityGroupDialog: FC<CreateLocalityGroupDialogProps> = ({ customerId, pop }) => {
  const { mutateAsync } = useAddLocalityGroup(customerId)
  const { t } = useTranslation(['common-api-message'])

  const onSubmit = useCallback(
    async form => {
      await mutateAsync(form).then(
        async () => {
          toast(t('successfullyAdded'), { type: 'success' })
          invalidateLocalityGroupsQueries.invalidateGetLocalityGroups(customerId)
          pop()
        },
        error => {
          toast.error(error.response.data ?? error.response.data.message)
        },
      )
    },
    [customerId],
  )

  return <LocalityGroupForm onSubmit={onSubmit} customerId={customerId} />
}
