import { useTranslation } from 'react-i18next'
import { Stack, useTheme } from '@mui/material'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useCallback, useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import { ShowDialogProps, showConfirmDialog } from '../../../show-dialog'
import { invalidateServiceCache, useDeleteService, useGetServices, useUpdateService } from '../../../api/service'
import { ServiceForm } from './ServiceForm'
import { FrequenciesContainer } from './frequencies/FrequenciesContainer'
import { IService } from '../../../generated-types/service'
import { ServiceMooringContainer } from './localities/ServiceMooringContainer'
import { toast } from 'react-toastify'
import GenericTable from 'src/components/table/GenericTable'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface UpdateServiceProps extends ShowDialogProps {
  customerId: EntityId
  service: IService
}
const UpdateServiceDialog: React.FC<UpdateServiceProps> = ({ pop, customerId, service }) => {
  const { t } = useTranslation(['supplier', 'common'])

  const { mutateAsync, isLoading } = useUpdateService(customerId as EntityId, service.id)

  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        invalidateServiceCache.getServices(customerId as EntityId)
        toast.success(t('serviceUpdatedSuccessfully', { ns: 'service' }))
        pop()
      },
      onError: _ => {
        toast.error(t('updatingError', { ns: 'common' }))
      },
    })
  }

  return (
    <ServiceForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={service}
      title={t('updateService', { ns: 'service' })}
    />
  )
}

const serviceDetailPanelContent = (row, theme) => {
  return (
    <Stack p={2} bgcolor={getThemeColor(theme, MUIThemeColors.backgroundMain)} spacing={1}>
      <FrequenciesContainer serviceId={row.id} />
      <ServiceMooringContainer serviceId={row.id} />
    </Stack>
  )
}

interface ServiceTableProps {
  customerId: EntityId
}
export const ServiceTable: React.FC<ServiceTableProps> = ({ customerId }) => {
  const { t: translate } = useTranslation(['service', 'common'])
  const apiRef = useGridApiRef()
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const { data: services } = useGetServices(customerId as EntityId)

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: translate('name', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
      },
      {
        field: 'updatedAt',
        headerName: translate('lastChanged', { ns: 'service' }),
        flex: 1,
        valueGetter: ({ value }) => {
          return typeof value === 'string' ? new Date(value).toLocaleDateString() : '/'
        },
      },
      {
        field: 'createdByUser',
        headerName: translate('createdBy', { ns: 'service' }),
        flex: 1,
        valueGetter: ({ value }) => {
          return value && value.firstName + ' ' + value.lastName
        },
      },
      { field: 'usedBy', headerName: translate('usedBy', { ns: 'service' }), flex: 1 },
    ],
    [translate],
  )

  const { mutate, isLoading: isDeleting } = useDeleteService(customerId as EntityId)

  const onDelete = useCallback(
    (id: EntityId) => {
      showConfirmDialog(
        translate('deleteService', { ns: 'service' }),
        translate('areYouSureDeleteService', { ns: 'service' }),
      ).then(e => {
        if (e) {
          mutate(id, {
            onSuccess: () => {
              apiRef.current.updateRows([{ id, _action: 'delete' }])
              toast.success(translate('serviceDeletedSuccessfully', { ns: 'service' }))
            },
            onError: () => {
              toast.error(translate('deletingError', { ns: 'common' }))
            },
          })
        }
      })
    },
    [apiRef, mutate, translate],
  )

  const onUpdate = useCallback(
    async (service: IService) => {
      const added = await showDialog(
        UpdateServiceDialog,
        {
          componentProps: { customerId: customerId as EntityId, service },
          opts: {
            maxWidth: 'sm',
            fullWidth: true,
          }
        },
        undefined,
        true,
      )

      if (!added) return

      invalidateServiceCache.getServices(customerId as EntityId)
      invalidateServiceCache.getService(customerId as EntityId, service.id)
    },
    [customerId],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 60,
        getActions: params => [
          <GridActionsCellItem
            key={params.id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(params.row)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(params.id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [STATIC_COLUMNS, isDeleting, onDelete, onUpdate, translate],
  )

  return (
    <GenericTable
      id="service-table"
      columns={columns}
      data={services?.items || []}
      getDetailPanelContent={({ row }) => serviceDetailPanelContent(row, theme)}
      getDetailPanelHeight={() => 'auto'}
      getRowHeight={() => 50}
      loading={isDeleting}
      apiRef={apiRef}
      autoHeight={false}
      height={'81vh'}
    />
  )
}
