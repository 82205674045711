import { taskQueryKeys } from 'src/query-keys'
import { IScheduledTask } from '../generated-types/scheduledTask'
import { queryClient } from '../query-client'
import { useGet, usePatch } from './reactQuery'

const baseUrl = (localityId: EntityId) => `localities/${localityId}/scheduledTask`

export const scheduledTaskEndpoints = {
  getScheduledTasks(localityId: EntityId) {
    return `${baseUrl(localityId)}`
  },
  getAllScheduledTasks(localityId: EntityId) {
    return `${baseUrl(localityId)}/all-tasks`
  },
  singleScheduledTask(localityId: EntityId, id: EntityId) {
    return `${baseUrl(localityId)}/${id}`
  },
}

export const invalidateScheduledTaskCache = {
  invalidateAllScheduledTasks(localityId: EntityId, queryParams?: object) {
    queryClient.invalidateQueries(taskQueryKeys.GET_SCHEDULED_TASKS(localityId, queryParams))
  },
  useSingleScheduledTask(localityId?: EntityId, taskId?: EntityId, queryParams?: object) {
    queryClient.invalidateQueries(taskQueryKeys.GET_SCHEDULED_TASK(localityId, taskId, queryParams))
  },
  useScheduledTasks(localityId: EntityId) {
    queryClient.invalidateQueries(scheduledTaskEndpoints.getScheduledTasks(localityId))
  },
}

export const useGetScheduledTask = (localityId: EntityId, id: EntityId, isTaskAttachmentsRequired = false) =>
  useGet<IScheduledTask>(
    scheduledTaskEndpoints.singleScheduledTask(localityId, id),
    { isTaskAttachmentsRequired },
    { queryKey: taskQueryKeys.GET_SCHEDULED_TASK(localityId, id) },
  )

export const useSignInScheduleTask = (localityId: EntityId, id: EntityId) =>
  usePatch(scheduledTaskEndpoints.singleScheduledTask(localityId, id))

export const useSaveDraftTask = (localityId: EntityId, id: EntityId) =>
  usePatch(`${scheduledTaskEndpoints.singleScheduledTask(localityId, id)}?isSaveDraft=true`)
