export const permissionValue = {
    Locality: {
        Read: 1,
        Update: 2,
        Deviation: {
            Read: 4,
            Write: 8,
            Update: 16,
            Delete: 32
        },
        Documents: {
            Read: 64,
            Write: 128,
            Delete: 256
        },
        Service: {
            Read: 512,
            Write: 34359738368,
            Update: 68719476736,
        },
        Task: {
            Read: 1024,
            Write: 137438953472,
            Update: 274877906944,
        },
        Mooring: {
            Write: 2048,
            Delete: 4096,
            Deviation: {
                Read: 8192
            }
        },
        MainComponent: {
            Read: 16384,
            Deviation: {
                Read: 32768,
                Write: 65536,
                Delete: 131072
            },
            Document: {
                Read: 262144,
                Write: 524288,
                Delete: 1048576
            },
            Certificate: {
                Read: 2097152,
                Write: 4194304,
                Delete: 8388608
            },
            Report: {
                Read: 16777216,
                Write: 33554432,
                Delete: 67108864
            }
        },
        WorkAssignment: {
            Read: 134217728,
            Write: 268435456,
            Delete: 536870912
        }
    },
    ServiceStation: {
        Read: 1073741824,
        Write: 2147483648,
        Update: 4294967296,
        Delete: 8589934592
    },
    ClientAdminPermission: 17179869184,
    SystemAdminPermission: 549755813888
}