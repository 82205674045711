import { Box, Card, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { IDocument } from 'src/generated-types/document'
import { FileUrlLoader } from '../FileUploader'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from 'src/app-routes'
import { Edit } from '@mui/icons-material'
import { GridActionButton } from '../common/GridActionButton'

interface IDocumentTableMobileViewCard {
    row: IDocument
    onEdit: (document: IDocument) => void
    customerId: EntityId
    localityId: EntityId
}

const DocumentTableMobileViewCard: React.FC<IDocumentTableMobileViewCard> = ({ row, onEdit, customerId, localityId }) => {
    const { palette } = useTheme()
    const { t } = useTranslation(['common', 'file-upload'])
    const navigate = useNavigate()
    const theme = useTheme()
    return (
        <Paper component={Card} sx={{ padding: '10px' }} onClick={(e) => {
            navigate(AppRoutesNavigation.Localities.document(customerId, localityId, row.id))
        }}>
            <Stack direction={'row'} >
                <Typography width={'100%'} variant='h6' textAlign={'center'}>{row.title}</Typography>
                <GridActionButton onClick={(e) => {
                    e.stopPropagation()
                    onEdit(row)
                }}
                    key='add'
                    bgColor={theme.palette.white.main} height="44px" width="44px"
                    buttonIcon={<Edit style={{ fontSize: '30px' }} htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
                />
            </Stack>
            <Box >
                <Stack direction={'row'} justifyContent={'center'} height={'60px'}>
                    <FileUrlLoader className='h-[50px] w-[50px] max-w-60px' contentTypeValue={row?.fileContentType} fileUrl={row.fileUrl} openOnClick />
                </Stack>
                {['version', 'dueDate'].map(item =>
                    <Grid container key={item}>
                        <Grid item xs={6} >
                            <Typography fontWeight={600} variant='subtitle1' color={palette.blueGray[palette.mode]}>
                                {t(item)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight={'medium'} variant='subtitle1' >
                                {row[item]}
                            </Typography>
                        </Grid>
                    </Grid>)}
                <Grid container key={'fileContentType'}>
                    <Grid item xs={6} >
                        <Typography fontWeight={600} variant='subtitle1' color={palette.blueGray[palette.mode]}>
                            {t('fileContentType', { ns: 'file-upload' })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontWeight={'medium'} variant='subtitle1' >
                            {row['fileContentType']}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default DocumentTableMobileViewCard