import { Autocomplete, AutocompleteProps, Paper, useTheme } from '@mui/material'
import { AppTextField } from './AppTextField'
import { MdChevronLeft } from 'react-icons/md'

type MaybeBool = boolean | undefined

type CustomAutocompleteProps<T> = Omit<AutocompleteProps<T, MaybeBool, MaybeBool, MaybeBool>, 'options' | 'renderInput'>

export interface SelectFieldProps<T = any> extends CustomAutocompleteProps<T> {
  name?: string
  data: T[]
  label?: string
  error?: boolean
  helperText?: string
  variant?: 'standard' | 'outlined' | 'filled'
}

const CustomPaper = props => {
  return <Paper elevation={16} {...props} />
}
export function SelectField<T = any>({ data, label, error, helperText, variant, ...rest }: SelectFieldProps<T>) {
  const theme = useTheme()
  return (
    <Autocomplete
      popupIcon={<MdChevronLeft transform="rotate(-90)" />}
      options={data ?? []}
      PaperComponent={CustomPaper}
      ListboxProps={{
        style: {
          maxHeight: 200,
          overflow: 'auto',
          backgroundColor: theme.palette.white.main,
          boxShadow: `0px 2px 4px ${theme.palette.darkTransparentBlack.main}`,
          fontSize: '14px',
          fontWeight: '400',
        },
      }}
      {...rest}
      renderInput={params => (
        <AppTextField variant={variant} {...params} label={label} error={error} helperText={helperText} />
      )}
    />
  )
}
