import { useContext } from 'react'
import { AppFormContext } from '../context/AppFormContext'
import { CustomerCtx } from '../context/CustomerContext'
import { IdentityServerUserCtx } from '../context/IdentityServerUserContext'
import { CustomerServiceStationCtx } from '../context/ServiceStationContext'
import { MainComponentCtx } from '../context/MainComponentContext'
import { SidebarCtx } from '../context/SidebarContext'
import { CustomerLocationCtx } from '../context/FacilityContext'
import { UserCtx } from '../context/UserContext'
import { DialogContextCtx } from 'src/context/DialogContext'
import { MooringsCtx } from 'src/context/MooringsContext'

export function useAppFormCtx() {
  return useContext(AppFormContext)
}

export function useIdentityServerUserCtx() {
  return useContext(IdentityServerUserCtx)
}

export function useUserCtx() {
  return useContext(UserCtx)
}

export function useLocationCtx() {
  return useContext(CustomerLocationCtx)
}

export function useServiceStationCtx() {
  return useContext(CustomerServiceStationCtx)
}

export function useMainComponentCtx() {
  return useContext(MainComponentCtx)
}

export function useCustomerCtx() {
  return useContext(CustomerCtx)
}

export function useDialogCtx() {
  return useContext(DialogContextCtx)
}


export function useSidebarCtx() {
  return useContext(SidebarCtx)
}

export function useMooringsCtx() {
  return useContext(MooringsCtx)
}