import { APIResultCount } from '../../../constant'
import { useGetCustomerSuppliers } from '../../../api/supplier'
import { GlobalDocumentContainer } from './GlobalDocumentContainer'
import { CenteredSpinner } from '../../../components/Spinner'
import { useParams } from 'react-router-dom'

export const GlobalDocumentCustomerContainer: React.FC = () => {
  const { id: customerId } = useParams()
  const { data: customerSuppliers, isLoading: customerSuppliersLoading } = useGetCustomerSuppliers(
    customerId as EntityId,
    0,
    APIResultCount.Max,
  )
  if (customerSuppliersLoading) {
    return <CenteredSpinner />
  }
  return <GlobalDocumentContainer customerSuppliers={customerSuppliers} />
}
