import { Stack, IconButton, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { MdArrowBack, MdExpandMore } from 'react-icons/md'
import { invalidateMainComponentCache, mainEndpoints, useBringBackMainComponent } from '../../../api/main-component'
import GenericTable from '../../../components/table/GenericTable'
import { t } from 'i18next'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { showConfirmDialog } from '../../../show-dialog'
import { invalidateMainComponentTypeCache } from '../../../api/main-component-type'

export const DiscardedMainComponentList: React.FC<{
  customerId: EntityId
}> = ({ customerId }) => {
  const { t: translate } = useTranslation(['main-component', 'common'])
  const { mutateAsync } = useBringBackMainComponent(customerId as EntityId)

  const bringBack = (row) => {
    showConfirmDialog(translate('bringBackMainComponent'), translate('areYouSureBringBackMainComponent')).then(async e => {
      if (e) {
        await mutateAsync({id: row.id}).then(() => {
          toast(translate('mainComponentBringBack'), { type: 'success' })
          invalidateMainComponentCache.getDiscardedMainComponents(customerId)
          invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId)
          invalidateMainComponentCache.getMainComponentsByComponentType(customerId, row.typeId)
        })
      }
    })
  }

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'name',
      headerName: translate('name', { ns: 'common' }),
      flex: 1,
      cellClassName: 'font-weight-semi-bold',
    },
    {
      field: 'description',
      headerName: translate('description', { ns: 'common' }),
      flex: 2,
      renderCell: params => {
        return (
          <>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Tooltip title={params.value}>
                <IconButton
                  disabled
                  sx={{
                    padding: 0,
                    '&.Mui-disabled': {
                      pointerEvents: 'all',
                    },
                  }}
                >
                  <MdExpandMore />
                </IconButton>
              </Tooltip>
              {params.value}
            </Stack>
          </>
        )
      },
    },
    { field: 'serialNumber', headerName: translate('serialNumber', { ns: 'main-component' }), flex: 1 },
    { field: 'orderNumber', headerName: translate('orderNumber', { ns: 'main-component' }), flex: 1 },
    {
      field: 'dateProduced',
      headerName: translate('dateProduced', { ns: 'main-component' }),
      flex: 1,
      renderCell: params =>
        !params.value && typeof params.value !== 'string' ? (
          '-'
        ) : (
          <h5>{new Date(params.value).toLocaleDateString()}</h5>
        ),
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        headerName: t('actions', { ns: 'common' }),
        type: 'actions' as GridNativeColTypes,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label={t('bring-back', { ns: 'common' })}
            icon={<MdArrowBack size={24} />}
            onClick={() => bringBack(row)}
            color="primary"
            showInMenu
          />
        ],
      },
    ],
    [],
  )

  return (
    <>
      <GenericTable
        id={'discarded-main-components'}
        columns={columns}
        defaultColumnOption={columns.map(c => c.headerName as string)}
        enableColumnOptions={true}
        enablePagination={true}
        pageSize={10}
        queryUrl={mainEndpoints.getDiscardedMainComponents(customerId as EntityId)}
        pageOptions={[10, 15, 20]}
        enableServerPagination={true}
        rowColor={'#e1e1e1'}
      />
    </>
  )
}
