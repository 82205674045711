import { Switch, SwitchProps, styled } from '@mui/material'

interface IMaintenanceToggle {
  state: boolean
  setState: (r: boolean) => void
}

export const MaintenanceToggleButton = ({ state, setState }: IMaintenanceToggle) => {
  const handleChange = () => {
    setState(!state)
  }

  return <StyledSwitch checked={!state} onChange={handleChange} />
}

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: '200px',
  height: '44px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '3px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(95px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.inputSearch.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb:before': {
        color: theme.palette.primaryDark[theme.palette.mode],
        fontWeight: 600,
        fontSize: '13.5px',
        content: '"Month"',
        alignSelf: 'center',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '100px',
    height: '36px',
    borderRadius: '6px',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    '&::before': {
      color: theme.palette.primaryDark[theme.palette.mode],
      fontWeight: 600,
      fontSize: '13.5px',
      content: '"Week"',
      alignSelf: 'center',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '6px',
    backgroundColor: theme.palette.inputSearch.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
      fontSize: '13.5px',
      color: theme.palette.secondaryDark[theme.palette.mode],
    },
    '&::before': {
      content: '"Week"',
      left: '30px',
    },
    '&::after': {
      content: '"Month"',
      right: '50px',
    },
  },
}))
