export function getUserDetail(user: any, option: UserDetail = UserDetail.Any) {
  if (user == null || typeof user == 'string') return '-'
  switch (option) {
    case UserDetail.UserName:
      return user.username
    case UserDetail.FirstName:
      return user.firstName
    case UserDetail.LastName:
      return user.lastName
    case UserDetail.Email:
      return user.email
    case UserDetail.FullName:
      return user?.fullName ? user.fullName : `${user?.firstName ?? '-'} ${user?.lastName ?? '-'}`
    case UserDetail.NameAndEmail:
      return (
        `${user.firstName ?? '-'} ${user.lastName ?? '-'}` +
        ` (${user.email.split('@')[0].substring(0, 3)}*****@${user.email.split('@')[1]})`
      )
    default:
      if (user.fullName != null) {
        return user.fullName
      } else if (user.firstName != null && user.firstName != '' && user.lastName != null && user.lastName != '') {
        return `${user.firstName} ${user.lastName}`
      } else if (user.firstName != null && user.firstName != '') {
        return user.firstName
      } else if (user.lastName != null && user.lastName != '') {
        return user.lastName
      } else {
        return user.email
      }
  }
}

export enum UserDetail {
  UserName = 'username',
  FirstName = 'first name',
  LastName = 'last name',
  FullName = 'full name',
  Email = 'email',
  NameAndEmail = 'name and email',
  Any = 'any valid detail',
}
