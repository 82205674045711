import { ISelectType } from '@lovoldsystem/core.client'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCageSelectOptionsByMooring } from 'src/api/positions'
import BasicDatePicker from 'src/components/DateTimePicker'
import { AppForm, AppFormProps, FormSelectField, FormTextField } from 'src/components/FormCore'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { MainButton } from 'src/components/MainButton'
import { CustomActivityType } from 'src/enums'
import { IPositionDropDownSelectOptions } from 'src/generated-types'
import { EnumToSelectTypes } from 'src/generated-types/select-type'
import { useZodForm } from 'src/hooks/zod-form'
import { getCageName } from 'src/utils/function'
import { z } from 'zod'

interface CustomCageLogFormProps extends AppFormProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  positionId: EntityId
  title: string
}

export const CustomCageLogForm: React.FC<CustomCageLogFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  localityId,
  mooringId,
  positionId,
  title,
}) => {
  const { t: translate } = useTranslation(['activity-logs'])
  const customLogTypes = EnumToSelectTypes(CustomActivityType, 'customLogTypes')
  const { data: allCagesOfTheMooring } = useGetCageSelectOptionsByMooring(customerId, localityId, mooringId)

  const forCages = allCagesOfTheMooring?.filter(c => c.id !== positionId)

  const schema = z
    .object({
      activityType: z
        .object({
          id: z.number(),
        })
        .transform(value => value.id),
      forCageId: z
        .object({
          id: z.number(),
        })
        .nullable()
        .optional()
        .transform(value => value?.id ?? undefined),
      forCageName: z.string().or(z.null()).optional(),
      logDate: z.date().or(z.null()).optional(),
      effectiveDate: z.date(),
      activityDescription: z.string().or(z.null()).optional(),
    })
    .refine(
      form => {
        if (form.activityType === (CustomActivityType.MovingFish as number)) {
          return (form.forCageId ?? 0) > 0
        } else {
          return true
        }
      },
      {
        message: translate('for-cage-id-required', { ns: 'activity-logs' }),
        path: ['forCageId'],
      },
    )

  const form = useZodForm(schema)

  const [selectedActivityType, setSelectedActivityType] = useState<ISelectType<typeof CustomActivityType>>()

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      actions={[
        <MainButton
          key={'cage-custom-log-submit-btn'}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
        >
          {translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        <FormSelectField
          name="activityType"
          data={customLogTypes}
          label={translate('log-type', { ns: 'activity-logs' })}
          getOptionLabel={option => translate(option.value, { ns: 'activity-log-type' })}
          renderOption={(props: any, option) => (
            <Box {...props} key={option.id}>
              {translate(option.value, { ns: 'activity-log-type' })}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(e, value) => {
            form.setValue('activityType', value as any)
            setSelectedActivityType(value as any)
          }}
        />
        {selectedActivityType?.id == (CustomActivityType.MovingFish as any) && (
          <FormSelectField
            name="forCageId"
            data={forCages ?? []}
            label={translate('for-cages', { ns: 'activity-logs' })}
            getOptionLabel={option => {
              return getCageName(option.name)
            }}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {getCageName(option.name)}
              </Box>
            )}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(e, value) => {
              if (value as IPositionDropDownSelectOptions) {
                const { name } = value as IPositionDropDownSelectOptions
                form.setValue('forCageId', value as any)
                form.setValue('forCageName', name)
              }
            }}
          />
        )}
        <BasicDatePicker label={translate('effective-date', { ns: 'activity-logs' })} name="effectiveDate" />
        <FormTextField
          name="activityDescription"
          label={translate('activity-description', { ns: 'activity-logs' })}
          fullWidth
          multiline
          rows={4}
        />
      </AppForm>
    </GenericDialogLayout>
  )
}
