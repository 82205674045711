import { ITimeReport } from '../generated-types/time-report'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (scheduledTaskId: EntityId) => `scheduledTask/${scheduledTaskId}/timeReport`
const timeReportCustomerAdminBaseURL = (customerId: EntityId) => `customers/${customerId}`

export const timeReportsEndpoints = {
  getTimeReports(scheduledTaskId: EntityId) {
    return `${baseUrl(scheduledTaskId)}`
  },
  getTimeReport(scheduledTaskId: EntityId, id: EntityId) {
    return `${baseUrl(scheduledTaskId)}/${id}`
  },
  createTimeReport(scheduledTaskId: EntityId) {
    return `${baseUrl(scheduledTaskId)}`
  },
  updateTimeReport(scheduledTaskId: EntityId, id: EntityId) {
    return `${baseUrl(scheduledTaskId)}/${id}`
  },
  deleteTimeReport(scheduledTaskId: EntityId) {
    return `${baseUrl(scheduledTaskId)}`
  },
  filterTimeReport(customerId) {
    return `${timeReportCustomerAdminBaseURL(customerId)}/filteredTimeReports`
  },
}

export const invalidateTimeReportCache = {
  useGetTimeReports(scheduledTaskId: EntityId) {
    queryClient.invalidateQueries(timeReportsEndpoints.getTimeReports(scheduledTaskId))
  },
  getCustomerAdminTimeReportByFilter(customerId) {
    queryClient.invalidateQueries(timeReportsEndpoints.filterTimeReport(customerId))
  },
  getTimeReport(scheduledTaskId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(timeReportsEndpoints.getTimeReport(scheduledTaskId, id))
  },
}

export const useGetTimeReports = (
  scheduledTaskId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) => useGet<IPaginatedResult<ITimeReport>>(timeReportsEndpoints.getTimeReports(scheduledTaskId), { page, pageSize })

export const useGetFilterTimeReport = (
  customerId: number,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
  queries,
) =>
  useGet<IPaginatedResult<ITimeReport>>(timeReportsEndpoints.filterTimeReport(customerId), {
    page,
    pageSize,
    ...queries,
  })

export const useGetTimeReport = (scheduledTaskId: EntityId, id: EntityId) =>
  useGet<ITimeReport>(timeReportsEndpoints.getTimeReport(scheduledTaskId, id))

export const useAddTimeReport = (scheduledTaskId: EntityId) =>
  usePost<ITimeReport, ITimeReport>(timeReportsEndpoints.createTimeReport(scheduledTaskId))

export const useUpdateTimeReport = (scheduledTaskId: EntityId, id: EntityId) =>
  usePut<ITimeReport, ITimeReport>(timeReportsEndpoints.updateTimeReport(scheduledTaskId, id))

export const useDeleteTimeReport = (scheduledTaskId: EntityId) =>
  useDelete<ITimeReport>(timeReportsEndpoints.deleteTimeReport(scheduledTaskId))
