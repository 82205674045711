import { t } from 'i18next'
import { toast } from 'react-toastify'

export const handleLocalityErrorResponse = (message: string) => {
  if (message === 'CannotCreateLocality') {
    toast.error(t('cannotCreateLocality', { ns: 'locality' }))
    return
  }
  if (message === 'CannotUpdateLocality') {
    toast.error(t('cannotUpdateLocality', { ns: 'locality' }))
    return
  }
  toast.error(t('unExpectedError', { ns: 'common-api-message' }))
}
