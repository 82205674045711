import { GridColumns } from '@mui/x-data-grid-pro'
import { FC, useCallback, useMemo } from 'react'
import GenericTable from '../../../../components/table/GenericTable'
import { getUserDetail } from '../../../../utils/getUserDetails'
import { useTranslation } from 'react-i18next'
import { useDialogCtx } from '../../../../hooks/context-hooks'
import AssignResourceForm from './AssignResourceForm'
import {
  invalidateTaskCache,
  useAssignTaskResource,
  useGetTaskResources,
  useUnAssignTaskResource,
} from '../../../../api/tasks'
import { toast } from 'react-toastify'
import { getFormattedDate } from '../../../../utils'
import { showConfirmDialog, ShowDialogProps } from '../../../../show-dialog'
import { Box, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { TaskTypes } from '../../../../enums'
import { invalidateScheduledTaskCache } from 'src/api/scheduledTask'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { Flex } from 'src/styles/flexComponent'
import { MainButton } from 'src/components/MainButton'
import { ITask } from 'src/generated-types/task'

interface TaskResourcesProps extends ShowDialogProps {
  task: ITask
  customerId: EntityId
  localityId: EntityId
  taskId: EntityId
}
const TaskResources: FC<TaskResourcesProps> = ({ customerId, localityId, taskId, task, pop }) => {
  const { t: translate } = useTranslation(['task', 'common'])
  const { showDialog } = useDialogCtx()
  const { mutateAsync } = useAssignTaskResource(localityId, taskId)
  const { data: taskResources } = useGetTaskResources(localityId, taskId)
  const { mutateAsync: mutationUnAssignResource } = useUnAssignTaskResource(localityId, taskId)

  const onUnAssignResource = async (resourceId: EntityId) => {
    showConfirmDialog(translate('unassign-resource-title'), translate('unassign-resource-description')).then(
      async e => {
        if (e) {
          await mutationUnAssignResource(resourceId)
          invalidateTaskCache.getTaskResources(localityId, taskId)
        }
      },
    )
  }

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'resource.name',
        headerName: translate('name', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
        renderCell: row => row.row.resource.name,
      },
      {
        field: 'resource.description',
        headerName: translate('description', { ns: 'common' }),
        flex: 1,
        renderCell: row => row.row.description,
      },
      {
        field: 'startDate',
        headerName: translate('startDate', { ns: 'common' }),
        flex: 1,
        renderCell: row => (row.row.startDate ? getFormattedDate(row.row.startDate) : '-'),
      },
      {
        field: 'endDate',
        headerName: translate('endDate', { ns: 'common' }),
        flex: 1,
        renderCell: row => (row.row.endDate ? getFormattedDate(row.row.endDate) : '-'),
      },
      {
        field: 'user',
        flex: 1,
        headerName: translate('responsibleUser', { ns: 'common' }),
        renderCell: ({ row }) => (row?.resource?.user ? getUserDetail(row.resource?.user) : '-'),
      },
      {
        field: '',
        headerName: '',
        type: 'actions',
        cellClassName: 'w-80',
        renderCell: params => {
          return (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} width={180}>
              <IconButton
                onClick={() => {
                  onUnAssignResource(params.row.id)
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )
        },
      },
    ],
    [translate],
  )

  const onAssign = useCallback(() => {
    const parameters = {}
    if (task.type == TaskTypes.Repeatable) {
      parameters['startDate'] = task?.startDate
      parameters['endDate'] = task?.endDate
    }
    showDialog(AssignResourceForm, {
      title: translate('assign-resource', { ns: 'task' }),
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
      componentProps: {
        customerId: customerId,
        task: { ...task, ...parameters },
        onSubmit: async formValues => {
          if (task.type == TaskTypes.Once) {
            formValues.endDate = null
          }
          formValues.tasksId = task.id
          await mutateAsync(formValues, {
            onError: error => {
              if (error['status'] == 409) {
                toast.error(translate('conflict-date', { ns: 'task' }))
              }
            },
            onSuccess: () => {
              invalidateTaskCache.getTaskResources(localityId, taskId)
              invalidateScheduledTaskCache.invalidateAllScheduledTasks(localityId)
            },
          })
        },
      },
    })
  }, [])

  return (
    <GenericDialogLayout
      title={translate('resources')}
      pop={pop}
      topActions={[
        <Flex.Row key="resources-action" px={3}>
          <MainButton onClick={onAssign}>{translate('assign', { ns: 'common' })}</MainButton>
        </Flex.Row>,
      ]}
    >
      <GenericTable data={taskResources} columns={columns} id={'customer-task-resources'} autoHeight={false} height={'300px'}/>
    </GenericDialogLayout>
  )
}

export default TaskResources
