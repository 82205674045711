import { Box, Chip, Divider, Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import BasicDatePicker from '../components/DateTimePicker'
import {
  AppForm,
  AppFormProps,
  FormCheckbox,
  FormFileCoreUpload,
  FormImageFileUpload,
  FormSelectField,
  FormTextField,
} from '../components/FormCore'
import { IFile } from '../generated-types'
import { useZodForm } from '../hooks/zod-form'
import { GenericDialogLayout } from '../components/GenericDialogLayout'
import { useGetCustomerUsers } from '../api/customer'
import { UserDetail, getUserDetail } from '../utils/getUserDetails'
import { MainButton } from 'src/components/MainButton'
import { Flex } from 'src/styles/flexComponent'
import { Close, Delete, Edit, RestartAlt, Save } from '@mui/icons-material'
import { useEffect, useMemo, useState } from 'react'
import { ILocalitySolidityAndCircumference } from 'src/generated-types/locality-solidity-circumference'
import { generateUUID } from 'src/utils/function'

export interface LocalityFormProps extends AppFormProps {
  file?: IFile
  title?: string
  isLoading?: boolean
  customerId?: number
}

export const LocalityForm: React.FC<LocalityFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  file,
  title,
  isLoading,
  customerId,
}) => {
  const { t: translate } = useTranslation(['locality', 'common'])
  const isUpdateForm = initialValues?.id
  const properties = ['solidity', 'circumference']
  const [solidityAndCircumferences, setsolidityAndCircumferences] = useState<Array<ILocalitySolidityAndCircumference>>(
    [],
  )
  const [editingPropertyId, setEditingPropertyId] = useState<string | null>(null)

  const schema = z.object({
    name: z.string().nonempty(translate('nameIsRequired')),
    locationNr: z.string().nonempty(translate('locationNrIsRequired')),
    latitude: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? parseFloat(value) : value))
      .optional(),
    longitude: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? parseFloat(value) : value))
      .optional(),
    notificationActive: z.boolean().default(false),
    active: z.boolean().default(true),
    fileId: z.any().optional(),
    logoId: z.any(),
    certifiedDate: z.date().or(z.string()).optional(),
    certificateDueDate: z.date().or(z.string()).or(z.null()).optional(),
    responsibleUserId: z.string().or(z.null()).optional(),
    responsibleUser: z
      .object({
        id: z.string(),
        email: z.string(),
        firstName: z.string().nullable().optional(),
        lastName: z.string().nullable().optional(),
        phoneNumber: z.string().nullable().optional(),
      })
      .optional()
      .nullable()
      .transform(value => (value ? value.id : undefined)),
    hasFish: z.boolean().optional(),
    hs: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? (value == '' ? null : Number(value)) : value))
      .refine(value => value == null || value >= 0, { message: translate('mustBeZeroOrPositive', { ns: 'common' }) })
      .optional(),
    vC5M: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? (value == '' ? null : Number(value)) : value))
      .refine(value => value == null || value >= 0, { message: translate('mustBeZeroOrPositive', { ns: 'common' }) })
      .optional(),
    vC15M: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? (value == '' ? null : Number(value)) : value))
      .refine(value => value == null || value >= 0, { message: translate('mustBeZeroOrPositive', { ns: 'common' }) })
      .optional(),
    solidityAndCircumferences: z
      .array(
        z.object({
          localityId: z.number().or(z.null()).optional(),
          solidity: z.number().or(z.string()),
          circumference: z.number().or(z.string()),
        }),
      )
      .or(z.null())
      .optional(),
  })

  const handleChange = (e, value) => {
    form.setValue('responsibleUser', value)
    form.setValue('responsibleUserId', value?.id)
  }

  const form = useZodForm(schema, { defaultValues: initialValues })

  const { data: customerUsers } = useGetCustomerUsers(customerId as EntityId)

  useEffect(() => {
    if (initialValues?.solidityAndCircumferences) {
      initialValues?.solidityAndCircumferences.forEach(property => {
        property.uniqueId = generateUUID()
      })
      setsolidityAndCircumferences(initialValues?.solidityAndCircumferences)
    }
  }, [initialValues])

  useEffect(() => {
    form.setValue('solidityAndCircumferences', solidityAndCircumferences)
  }, [solidityAndCircumferences, form])

  const resetProperties = () => {
    properties.forEach(key => {
      form.clearErrors(key as any)
      form.setValue(key as any, null)
    })
  }

  const validateProperty = key => {
    const value = form.getValues(key)
    if (!value) {
      form.setError(key, { type: 'required', message: translate('required', { ns: 'common' }) })
      return false
    }

    const numberValue = Number(value)
    if (isNaN(numberValue) || numberValue <= 0) {
      form.setError(key, { type: 'invalid', message: translate('mustBePositive', { ns: 'common' }) })
      return false
    }

    form.clearErrors(key)
    return true
  }

  const validateProperties = () => {
    let isValid = true
    properties.forEach(key => {
      if (!validateProperty(key)) {
        isValid = false
      }
    })
    return isValid
  }

  const handleSave = () => {
    const solidity = (form as any).getValues('solidity')
    const circumference = (form as any).getValues('circumference')
    if (!validateProperties()) {
      return
    }
    setsolidityAndCircumferences(prev => {
      if (editingPropertyId) {
        return prev.map(p => {
          if (p.uniqueId === editingPropertyId) {
            return {
              solidity: solidity,
              circumference: circumference,
              uniqueId: editingPropertyId,
            } as ILocalitySolidityAndCircumference
          }
          return p
        })
      }
      return [
        ...prev,
        {
          solidity: solidity,
          circumference: circumference,
          uniqueId: generateUUID(),
        } as ILocalitySolidityAndCircumference,
      ]
    })
    setEditingPropertyId(null)
    resetProperties()
  }

  const showingProperties = useMemo(() => {
    return solidityAndCircumferences.filter(x => x.uniqueId != editingPropertyId)
  }, [editingPropertyId, solidityAndCircumferences])

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      actions={[
        <MainButton
          key="locality-form-button"
          variant="contained"
          disabled={isLoading}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
          data-cy="UpdateLocality_submit"
        >
          {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
        <FormTextField name="name" label="Name" defaultValue={initialValues?.Name} />
        <Grid container>
          <Grid item sm={8}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormTextField name="locationNr" label="Location number" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="latitude" label="Gps latitude" type="number" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormTextField name="longitude" label="Gps longitude" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BasicDatePicker
                  label={translate('certificateDueDate', { ns: 'locality' })}
                  name="certificateDueDate"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <FormImageFileUpload
              label={translate('logo-image', { ns: 'common' })}
              name="logoId"
              mapFileKey="id"
              initialFiles={initialValues?.logoUrl}
              required
              inactiveMsg={translate('chooseCertificate', { ns: 'locality' })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <BasicDatePicker label={translate('certifiedDate', { ns: 'locality' })} name="certifiedDate" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField type="number" name="hs" label={translate('hs', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField type="number" name="vC5M" label={translate('vc5m', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField type="number" name="vC15M" label={translate('vc15m', { ns: 'common' })} />
          </Grid>
        </Grid>
        <Flex.Column px={1} py={1} justifyContent={'center'} alignItems={'flex-start'}>
          <Typography variant="subtitle2">{translate('solidityAndCircumference', { ns: 'locality' })}</Typography>
          <Divider />
          {showingProperties.map((property, index) => (
            <Flex.Row py={1} justifyContent={'center'} alignItems={'center'} key={index} gap={1}>
              {`#${index + 1}`}
              <Chip label={`${translate('solidity', { ns: 'locality' })} : ${property.solidity}`} />
              <Chip label={`${translate('circumference', { ns: 'locality' })} : ${property.circumference}`} />
              <Flex.Row gap={1} justifyContent={'center'} alignItems={'center'}>
                <IconButton
                  onClick={() => {
                    const appForm = form as any
                    appForm.setValue('solidity', property.solidity)
                    appForm.setValue('circumference', property.circumference)
                    setEditingPropertyId(property.uniqueId)
                  }}
                  sx={{ width: '30px', height: '30px' }}
                  disabled={editingPropertyId !== null}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setsolidityAndCircumferences(prev => prev.filter(p => p.uniqueId !== property.uniqueId))
                  }}
                  sx={{ width: '30px', height: '30px' }}
                  disabled={editingPropertyId !== null}
                >
                  <Delete />
                </IconButton>
              </Flex.Row>
            </Flex.Row>
          ))}
          <Flex.Row py={1} justifyContent={'center'} alignItems={'center'}>
            <FormTextField
              type={'number'}
              name="solidity"
              label={translate('solidity', { ns: 'locality' })}
              focused={editingPropertyId && showingProperties.length == 0 ? true : undefined}
            />
            <FormTextField
              type={'number'}
              name="circumference"
              label={translate('circumference', { ns: 'locality' })}
              focused={editingPropertyId && showingProperties.length == 0 ? true : undefined}
            />
            <Flex.Row gap={1}>
              <IconButton onClick={handleSave}>
                <Save />
              </IconButton>
              {editingPropertyId ? (
                <IconButton
                  onClick={() => {
                    setEditingPropertyId(null)
                    resetProperties()
                  }}
                >
                  <Close />
                </IconButton>
              ) : (
                <IconButton onClick={resetProperties}>
                  <RestartAlt />
                </IconButton>
              )}
            </Flex.Row>
          </Flex.Row>
        </Flex.Column>
        <FormSelectField
          name="responsibleUser"
          data={customerUsers!}
          label={translate('responsibleUser', { ns: 'locality' })}
          getOptionLabel={option => getUserDetail(option, UserDetail.NameAndEmail)}
          renderOption={(props: any, option) => (
            <Box {...props} key={option}>
              {getUserDetail(option, UserDetail.NameAndEmail)}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={handleChange}
        />
        <FormFileCoreUpload
          label={translate('fileName', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          initialFiles={file}
          inactiveMsg={translate('chooseCertificate', { ns: 'locality' })}
          height="150px"
        />
        <Box py={1}>
          <FormCheckbox name="notificationActive" label={translate('notificationActive', { ns: 'locality' })} />
          <FormCheckbox name="active" label={translate('active', { ns: 'locality' })} />
          {initialValues?.netCount > 0 && (
            <FormCheckbox name="hasFish" label={translate('hasFish', { ns: 'locality' })} />
          )}
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
