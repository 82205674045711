import React, { useState } from 'react'
import { ICertificateExtension } from 'src/generated-types/CertificateExtension'
import { GenericDialogLayout } from '../GenericDialogLayout'
import { ShowDialogProps } from 'src/show-dialog'
import { StyledDetailCard } from 'src/styles/Index'
import { Box, Grid, useTheme } from '@mui/material'
import { TitleTypography, ContentTypography } from 'src/screens/localities/deviations/DeviationDetailSection'
import { Flex } from 'src/styles/flexComponent'
import { getFormattedDate } from 'src/utils'
import { useGetFile } from 'src/api/file'
import { Spinner } from '../Spinner'
import { MdOpenInNew } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

interface ViewCertificateExtensionDialogProps extends ShowDialogProps {
  extension: ICertificateExtension
}

const ViewCertificateExtensionDialog: React.FC<ViewCertificateExtensionDialogProps> = ({ extension, pop }) => {
  const [loading, setLoading] = useState(true)
  const theme = useTheme()
  const { t } = useTranslation(['certificateExtension'])
  const handleLoad = () => {
    setLoading(false)
  }
  const { data: certificateFile } = useGetFile(extension?.fileId)
  const isWordOrExcelFile =
    certificateFile?.url.endsWith('.doc') ||
    certificateFile?.url.endsWith('.docx') ||
    certificateFile?.url.endsWith('.xls') ||
    certificateFile?.url.endsWith('.xlsx')

  const viewerUrl = isWordOrExcelFile
    ? `https://docs.google.com/gview?url=${certificateFile?.url}&embedded=true`
    : certificateFile?.url

  return (
    <GenericDialogLayout title={extension.extensionNumber} pop={pop} sx={{ height: '70vh' }}>
      <StyledDetailCard elevation={0} sx={{ padding: '20px 20px', marginRight: '16px', marginLeft: '8px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Flex.Column>
              <TitleTypography variant="subtitle2">{t('numberOfDays', { ns: 'certificateExtension' })}</TitleTypography>
              <ContentTypography variant="subtitle1">{extension?.numberOfDays}</ContentTypography>
            </Flex.Column>
          </Grid>
          <Grid item xs={6} md={3}>
            <Flex.Column>
              <TitleTypography variant="subtitle2">{t('issueDate', { ns: 'certificateExtension' })}</TitleTypography>
              <ContentTypography variant="subtitle1">{getFormattedDate(extension?.issuedDate)}</ContentTypography>
            </Flex.Column>
          </Grid>
          <Grid item xs={6} md={3}>
            <Flex.Column>
              <TitleTypography variant="subtitle2">{t('issuedBy', { ns: 'certificateExtension' })}</TitleTypography>
              <ContentTypography variant="subtitle1">{extension?.issuedBy}</ContentTypography>
            </Flex.Column>
          </Grid>
          <Grid item xs={6} md={3}>
            <Flex.Column>
              <TitleTypography variant="subtitle2">{t('attachment', { ns: 'certificateExtension' })}</TitleTypography>
              <Box padding={'4px'}>
                <MdOpenInNew
                  size={24}
                  color={theme.palette.primaryMain[theme.palette.mode]}
                  cursor={'pointer'}
                  onClick={() => window.open(viewerUrl, '_blank')}
                />
              </Box>
            </Flex.Column>
          </Grid>
        </Grid>
      </StyledDetailCard>
      <Box padding={'2px'} marginTop={'8px'} height={'53vh'}>
        {loading && (
          <Flex.Column width={'100%'} height={'99%'} justifyContent={'center'} alignItems={'center'}>
            <Spinner />
          </Flex.Column>
        )}
        <iframe src={viewerUrl} width={'100%'} height={'99%'} style={{ border: 'none' }} onLoad={handleLoad} />
      </Box>
    </GenericDialogLayout>
  )
}

export default ViewCertificateExtensionDialog
