import React from 'react'
import { getCustomerUsers } from '../../../http'
import { permissionBinding, permissionLevel } from '../../../constant'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { FacilityTypes, RoleTypes } from '../../../enums'
import { ILocality } from '../../../generated-types'
import { invalidateAccessGroups, useGetAccessGroupForLocality } from '../../../api/access-group'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LocalityGroupsInLocality from './LocalityGroupsInLocality'
import { AuthorizationUsersMapping } from '@lovoldsystem/authorization.client'
import { IAuthUserInitialValues } from '@lovoldsystem/authorization.client/components/users/authorizationMapping.action'
import { FunctionElementsParentsIds } from '../../../enums/auth'

interface props {
  locality: ILocality
}

const LocalityDetailPanel: React.FC<props> = ({ locality }) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation('access-control')
  const { data } = useGetAccessGroupForLocality(customer.id, locality.id)
  const { showConfirmDialog, showDialog } = useDialogCtx()

  return (
    <Box>
      <Box padding={1} className="scroll-grid">
        <AuthorizationUsersMapping
          invalidateUsers={() => {
            invalidateAccessGroups.getLocalityUsers(customer.id, locality.id)
          }}
          permissionBinding={permissionBinding}
          title={t('locality-users')}
          functionElementParents={[FunctionElementsParentsIds.LocalityPortal]}
          permissionLevel={permissionLevel}
          initialValues={
            {
              facilityId: locality.id,
              facilityName: locality.name,
              type: FacilityTypes.locality,
              facilityTypeText: t('locality', { ns: 'locality' }),
            } as IAuthUserInitialValues
          }
          clientId={customer.id}
          roleTypes={[RoleTypes.Locality]}
          facilityUsers={data || []}
          useGetUsers={getCustomerUsers}
          showDialog={showDialog}
          showConfirmDialog={showConfirmDialog}
        />
      </Box>
      <Box padding={1}>
        <LocalityGroupsInLocality localityId={locality.id} />
      </Box>
    </Box>
  )
}

export default LocalityDetailPanel
