import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generateUUID } from '../utils/function'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { CancelButton } from './CancelButton'
import { Close } from '@mui/icons-material'

interface DialogContentProps {
  bgColor?: string
  minHeight?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  maxHeight?: string | number
}

interface IGenericDialogProps extends DialogContentProps {
  title: string
  open: boolean
  onClose?: () => void
  bgColor?: string
  disableAutoClose?: boolean
  topActions?: React.ReactNode[]
  topActionsProps?: DialogActionsProps
  actions?: React.ReactNode[]
  removedCloseButton?: boolean
  fullWidth?: boolean
  sx?: SxProps<Theme> | undefined
  isWithLayout?: boolean
  fullScreen?: boolean | undefined
}
export const GenericDialog: React.FC<IGenericDialogProps> = ({
  title,
  open,
  onClose,
  minHeight,
  minWidth,
  maxHeight,
  maxWidth,
  bgColor,
  disableAutoClose,
  topActions,
  topActionsProps,
  actions,
  removedCloseButton,
  fullWidth = false,
  children,
  sx,
  isWithLayout = false,
  fullScreen = false,
}) => {
  const { t } = useTranslation()
  const DefaultFormat = useMemo(() => {
    return (
      <>
        <CustomDialogTitle onClose={onClose} isTopActionAvailable={Boolean(topActions)}>
          {title}
        </CustomDialogTitle>
        <StyledDialogContent
          bgColor={bgColor}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          minHeight={minHeight}
          minWidth={minWidth}
          sx={{ paddingX: '28px', width: '100%', height: '100% ', ...sx }}
        >
          {children}
        </StyledDialogContent>
        {(!removedCloseButton || actions) && (
          <DialogActions
            {...topActionsProps}
            sx={{ paddingBottom: '16px', paddingTop: '0px', paddingRight: '36px', paddingLeft: '36px' }}
          >
            {[
              !removedCloseButton ? (
                <CancelButton key={generateUUID()} onClick={onClose} variant="outlined">
                  {t('cancel', { ns: 'common' })}
                </CancelButton>
              ) : (
                <></>
              ),
              ...(actions ? actions : []),
            ]}
          </DialogActions>
        )}
      </>
    )
  }, [actions, children, onClose, removedCloseButton, t, title])
  return (
    <>
      <StyledDialog
        open={open}
        onClose={(e, reason) => {
          if (disableAutoClose && reason === 'backdropClick') return
          if (onClose) onClose()
        }}
        disableEscapeKeyDown
        onBackdropClick={_ => {}}
        fullWidth={fullWidth}
        maxWidth={maxWidth as any}
        sx={sx}
        fullScreen={fullScreen}
        bgColor={bgColor}
      >
        {isWithLayout ? children : DefaultFormat}
      </StyledDialog>
    </>
  )
}

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose?: () => void
  isTopActionAvailable: boolean
}

export const CustomDialogTitle: React.FC<DialogTitleProps> = props => {
  const theme = useTheme()
  const { children, onClose, isTopActionAvailable, ...other } = props

  return (
    <DialogTitle
      sx={{
        fontWeight: '600',
        color: getThemeColor(theme, MUIThemeColors.primaryMain),
        paddingTop: '36px',
        paddingX: '36px',
        paddingBottom: !isTopActionAvailable ? '24px' : '0px',
      }}
      {...other}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={500} color={getThemeColor(theme, MUIThemeColors.black)}>
          {children}
        </Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <Close sx={{ color: getThemeColor(theme, MUIThemeColors.black) }} />
          </IconButton>
        ) : null}
      </Stack>
    </DialogTitle>
  )
}

interface StyledDialogProps extends DialogProps{
  bgColor?: string
 }

const StyledDialog = styled(Dialog)<StyledDialogProps>(({bgColor})=>({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      backgroundColor: bgColor ?? 'undefined',
      borderRadius: '8px',
    },
  },
}))

const StyledDialogContent = styled(DialogContent)<DialogContentProps>(
  ({ bgColor, minHeight, minWidth, maxHeight, maxWidth, theme }) => ({
    backgroundColor: bgColor ?? getThemeColor(theme, MUIThemeColors.white),
    maxWidth: maxWidth ?? '85vw',
    maxHeight: maxHeight ?? '95vh',
    minWidth: minWidth ?? '15vw',
    minHeight: minHeight ?? '5vh',
  }),
)
