import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TimeReportFilter from './TimeReportsFilter'
import { useCallback, useMemo, useState } from 'react'
import { GridColumns } from '@mui/x-data-grid-pro'
import moment from 'moment'
import GenericTable from '../../../../components/table/GenericTable'
import { timeReportsEndpoints } from '../../../../api/time-report'
import { useCustomerCtx } from '../../../../hooks/context-hooks'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

const TimeReports = () => {
  const { t } = useTranslation(['time-reports', 'common'])
  const [queryParams, setQueryParams] = useState({})
  const { customer } = useCustomerCtx()
  const theme = useTheme()

  const timeReportFilterChange = useCallback(params => {
    params.resourceIds = params.resource.map(re => re.id).join(',')
    params.localityIds = params.locality.map(re => re.id).join(',')
    params.timeReportTypeIds = params.timeReportType.map(re => re.id).join(',')
    delete params.locality
    delete params.resource
    delete params.timeReportType
    setQueryParams(params)
  }, [])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'description',
        headerName: t('description', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
        renderCell: row => row.row.description,
      },
      {
        field: 'fromDateTime',
        headerName: t('fromDateAndTime'),
        flex: 1,
        renderCell: ({ row }) => {
          if (!row) return '-'
          return moment(row.fromDateTime).format('MM/DD/YYYY | HH:MM')
        },
      },
      {
        field: 'toDateTime',
        headerName: t('toDateAndTime'),
        flex: 1,
        renderCell: ({ row }) => {
          if (!row) return '-'
          return moment(row.toDateTime).format('MM/DD/YYYY | HH:MM')
        },
      },
      {
        field: 'timeReportTypeName',
        headerName: t('timeReportType'),
        flex: 1,
        renderCell: row => row.row.timeReportTypeName,
      },
      {
        field: 'resourceName',
        headerName: t('resource', { ns: 'task' }),
        flex: 1,
        renderCell: row => row.row.resourceName,
      },
    ],
    [t],
  )

  return (
    <CommonPageLayout
      titleSection={{ title: t('timeReportManagement') }}
      topAction={[<TimeReportFilter key="filter-actions" onChange={timeReportFilterChange} />]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <GenericTable
          queryUrl={timeReportsEndpoints.filterTimeReport(customer.id)}
          queryParams={queryParams}
          columns={STATIC_COLUMNS}
          enableServerPagination
          id={'filtered-time-reports'}
          autoHeight={false}
          height={'77vh'}
        />
      </Box>
    </CommonPageLayout>
  )
}

export default TimeReports
