import { css } from '@mui/material'

export const flex = {
    row: css`
            display: flex;
            flex-direction: row;
        `,
    column: css`
         display: flex;
        flex-direction: column;
    `
}