import { Box } from '@mui/material'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'
import { muiTheme } from '../../mui-theme'

export const NextArrow = () => {
    return (
        <Box
            border={`1px solid ${muiTheme.palette.darkGray.main}`}
            borderRadius={'50%'}
            height={'20px'}
            width={'20px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}>
            <MdOutlineArrowForwardIos size={10} />
        </Box>
    )
}

export const PreviousArrow = () => {
    return (
        <Box
            border={`1px solid ${muiTheme.palette.darkGray.main}`}
            borderRadius={'50%'}
            height={'20px'}
            width={'20px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}>
            <MdOutlineArrowBackIosNew size={10} />
        </Box>
    )
}
