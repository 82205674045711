import { IServiceStationGroup } from '../generated-types'
import { queryClient } from '../query-client'
import { serviceStationGroupQueryKey } from '../query-keys'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from '@lovoldsystem/core.client'
import { IApiResult } from '../generated-types/api-result'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/service-station-groups`

export const serviceStationGroupsEndpoints = {
  getServiceStationGroups(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getServiceStationGroupsForAuthorization(customerId: EntityId) {
    return `${baseUrl(customerId)}/for-authorization`
  },
  getServiceStationGroup(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  createServiceStationGroup(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateServiceStationGroup(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  deleteServiceStationGroup(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getServiceStationsForGroup(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/service-stations`
  },
  removeServiceStationsFromGroup(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/service-stations`
  }
}

export const invalidateServiceStationGroupsQueries = {
  getServiceStationGroups(customerId: EntityId) {
    queryClient.invalidateQueries(serviceStationGroupQueryKey.GET_SERVICE_STATION_GROUPS(customerId))
  },
  getServiceStationsForGroup(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(serviceStationGroupQueryKey.GET_SERVICE_STATIONS_FOR_GROUP(customerId, id))
  }
}

export const useGetServiceStationGroups = (
  customerId: EntityId,
  queryParams: { page?: number; pageSize?: number, serviceStationId?: EntityId}
) =>
  useGet<IPaginatedResult<IServiceStationGroup>>(
    serviceStationGroupsEndpoints.getServiceStationGroups(customerId),
    {
      ...queryParams
    },
    { queryKey: serviceStationGroupQueryKey.GET_SERVICE_STATION_GROUPS(customerId) },
  )

export const useGetServiceStationGroupsForAuthorization = (queryParams: { clientId: EntityId }) =>
  useGet<IPaginatedResult<IServiceStationGroup>>(
    serviceStationGroupsEndpoints.getServiceStationGroupsForAuthorization(queryParams.clientId),
    undefined,
    { queryKey: serviceStationGroupQueryKey.GET_SERVICE_STATION_GROUPS_FOR_AUTH(queryParams.clientId) },
  )

export const useGetServiceStationGroup = (customerId: EntityId, id: EntityId) =>
  useGet<IServiceStationGroup>(serviceStationGroupsEndpoints.getServiceStationGroup(customerId, id))

export const useCreateServiceStationGroup = (customerId: EntityId) =>
  usePost<IApiResult, IServiceStationGroup>(
    serviceStationGroupsEndpoints.createServiceStationGroup(customerId),
  )

export const useUpdateServiceStationGroup = (customerId: EntityId) =>
  usePut<IApiResult, IServiceStationGroup>(
    serviceStationGroupsEndpoints.updateServiceStationGroup(customerId),
  )

export const useDeleteServiceStationGroup = (customerId: EntityId) =>
  useDelete<IApiResult>(serviceStationGroupsEndpoints.deleteServiceStationGroup(customerId))

export const useGetServiceStationForGroup = (customerId: EntityId, id: EntityId) =>
  useGet<IServiceStationGroup>(serviceStationGroupsEndpoints.getServiceStationsForGroup(customerId, id), undefined, {
    queryKey: serviceStationGroupQueryKey.GET_SERVICE_STATIONS_FOR_GROUP(customerId, id),
  })

export const useDeleteServiceStationFromGroup = (customerId: EntityId, id: EntityId) =>
  useDelete<IApiResult>(serviceStationGroupsEndpoints.removeServiceStationsFromGroup(customerId, id))
