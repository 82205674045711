import {
  GridActionsCellItem,
  GridColumns,
  GridNativeColTypes,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import { QueryKeyT } from '@lovoldsystem/core.client'
import GenericTable from '../../../components/table/GenericTable'
import { GenericToggle } from '../../../components/GenericToggle'

interface LocalityGroupsTableProps {
  getDetailPanelContent?: (params: any) => JSX.Element
  queryUrl: string
  generateQueryKey?: (page: number, pageSize: number) => QueryKeyT | undefined
  queryParams?: object
  onUpdate?: (id: EntityId) => void
  onDelete?: (id: EntityId) => void
}

export const LocalityGroupsTable: React.FC<LocalityGroupsTableProps> = ({
  onDelete,
  onUpdate,
  queryUrl,
  queryParams,
  generateQueryKey,
  getDetailPanelContent,
}) => {
  const { t } = useTranslation(['common'])

  const renderActions = useCallback(
    ({ row, id }) => {
      const actions: JSX.Element[] = []
      if (onUpdate) {
        actions.push(
          <GridActionsCellItem
            key={row.id}
            label={t('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate && onUpdate(row.id)}
            color="primary"
            showInMenu
          />,
        )
      }
      if (onDelete) {
        actions.push(
          <GridActionsCellItem
            key={id}
            label={t('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => {
              onDelete && onDelete(row.id)
            }}
            color="primary"
            showInMenu
          />,
        )
      }
      return actions
    },
    [onUpdate],
  )

  const STATIC_COLUMNS: GridColumns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      renderCell: params => (
        <GenericToggle id={params.id} value={params.value} expandTitle={t('view-locality', { ns: 'locality' })} />
      ),
    },
    { field: 'name', headerName: t('name', { ns: 'common' }), flex: 1 },
  ]

  const columns = [
    ...STATIC_COLUMNS,
    {
      field: 'actions',
      type: 'actions' as GridNativeColTypes,
      headerName: '',
      width:60,
      getActions: renderActions,
    },
  ]

  return (
    <GenericTable
      queryUrl={queryUrl}
      queryParams={queryParams}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
      columns={columns.filter(col => onUpdate || col.field !== 'actions')}
      id={'locality-groups-table'}
      enableToolbar={true}
      enableServerPagination
      generateQueryKey={generateQueryKey}
      autoHeight={false}
      height={'81vh'}
    />
  )
}
