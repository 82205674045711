import { FC, useEffect, useState } from 'react'
import { UserRegistrationForm } from '../forms/UserRegistrationForm'
import { useUserCtx } from '../hooks/context-hooks'
import { Box } from '@mui/material'
import { CenteredSpinner } from '../components/Spinner'
import { ScreenTitle } from '../components/ScreenTitle'
import { useTranslation } from 'react-i18next'
import { useUpdateUser } from '../api/user'
import { toast } from 'react-toastify'
import { UserStatus } from '../enums/user-status'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../app-routes'

export const UserProfileScreen: FC = () => {
  const { user, isLoadingUser,refetch } = useUserCtx()
  const { mutateAsync, isLoading } = useUpdateUser()
  const { state } = useLocation()
  const { t } = useTranslation('user-register')
  const [isUserUpdated, setIsUserUpdated] = useState(false)
  

  useEffect(() => { 
    if (!isLoading && user?.status == UserStatus.Complete && isUserUpdated) {
      setTimeout(() => { 
        setIsUserUpdated(false)
        navigate((state as any)?.prevPath ?? AppRoutesNavigation.Home)
      }, 1000)
      return
    }
  }, [isUserUpdated,user])
  
  const navigate = useNavigate()
  
  const handleSubmit = async form => {
    mutateAsync(form, {
      onSuccess: () => {
        toast.success(t('successfullyUpdated', { ns: 'common-api-message' }))
        refetch()
        setIsUserUpdated(true)
      },
      onError: () => {
        toast.error(t('unExpectedError', { ns: 'common-api-message' }))
      },
    })
  }
	return (
    <>
      <Box className="flex flex-col items-center justify-center pt-6">
        <ScreenTitle title={t('profile', { ns: 'user-register' })} />
        <Box className="w-6/12 pt-2">
          {isLoadingUser ? (
            <CenteredSpinner />
          ) : (
            <UserRegistrationForm
              onSubmit={handleSubmit}
              initialValues={{ ...user }}
              isRegisterForm={false}
              isLoading={isLoading}
              onCancel={() => {
                if(user?.status == UserStatus.InComplete) {
                  toast.error(t('profileUpdateRequired', { ns: 'common-api-message' }))
                  return
                }
                navigate((state as any)?.prevPath ?? AppRoutesNavigation.Home)
              }}
            />
          )}
        </Box>
      </Box>
    </>
  )
}
