import { styled } from '@mui/system'
import { Card, CardContent, CardHeader, CardProps, Stack, Typography, useTheme } from '@mui/material'
import { GridActionButton } from './common/GridActionButton'
import { ReactNode } from 'react'
import { Spinner } from './Spinner'
import { DynamicTextTypography, IDynamicTextTypographyProps } from './DynamicTextTypography'
import { AuthorizationType } from 'src/enums/auth'

export interface ICardAction {
  label: string
  onClick: () => void
  icon: ReactNode,
  permissionBinaryValues?: number[]
  context?: React.Context<any> | null
  permission?: number
  disabled?: boolean
}

interface IGenericCardMinimalVersion extends Omit<CardProps, 'actions' | 'sx'> {
  title?: string
  titleTypographyProps?: Omit<IDynamicTextTypographyProps, 'text'>
  minHeight?: string | number
  maxHeight?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  actions?: Array<ICardAction>
  backgroundImage?: string
  sx?: object
  isLoading?: boolean
}

export const GenericCardMinimalVersion = ({
  title,
  titleTypographyProps,
  minHeight,
  maxHeight,
  minWidth,
  maxWidth,
  actions,
  backgroundImage,
  children,
  isLoading,
  sx,
  ...rest
}: IGenericCardMinimalVersion) => {
  const theme = useTheme()
  return (
    <StyledCard
      sx={{
        minHeight,
        maxHeight,
        minWidth,
        maxWidth,
        backgroundImage: backgroundImage,
        ...sx,
      }}
      elevation={3}
      {...rest}
    >
      {(title || actions) && (
        <CardHeader
          action={
            <Stack direction={'row'} className="pr-8-core">
              {actions?.map((item, index) => (
                <GridActionButton
                  key={index}
                  onClick={item.onClick}
                  toolTipTitle={item.label}
                  bgColor={theme.palette.lightGray.main}
                  height="36px"
                  width="36px"
                  buttonIcon={item.icon}
                  permission={item.permission}
                  permissionBinaryValues={item.permissionBinaryValues}
                  context={item.context}
                  authorizationType={AuthorizationType.Hidden}
                  disabled={item.disabled}
                  shadowWeight={0}
                />
              ))}
            </Stack>
          }
          title={
            title && (
              <DynamicTextTypography
                variant="h3"
                text={title}
                color={theme.palette.primaryMain[theme.palette.mode]}
                {...titleTypographyProps}
              />
            )
          }
        />
      )}
      <CardContent>
        {isLoading ? (
          <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} minHeight={'100px'}>
            <Spinner />
          </Stack>
        ) : (
          children
        )}
      </CardContent>
    </StyledCard>
  )
}

export const StyledCard = styled(Card)(() => ({
  height: '100%',
  borderRadius: '4px',
  '& .MuiCardContent-root': {
    padding: '12px !important',
  },
  '& .MuiCardHeader-root': { padding: '12px', paddingBottom: '0px' },
}))

export const StyledCardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primaryDark[theme.palette.mode],
}))
