import { IFile } from "./file";
import { IFormSchemaReport } from "./form-schema";
import { IUser } from "./user";

export interface IDeviationDocument {
    id: number
    created: Date,
    file: IFile,
    formSchemaReport: IFormSchemaReport
    type: DeviationDocumentType
    createdByUser?: IUser
}

export enum DeviationDocumentType {
    Document = 0,
    SchemaReport = 1
}