import { Autocomplete, Box, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IBaseSupplier, ICustomerAdminSupplier, ISupplier } from '../../../generated-types'
import { GlobalDocumentsTable } from './GlobalDocumentsTable'
import { useParams } from 'react-router-dom'
import { IPaginatedResult } from '../../../api/paginated-result'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { AppTextField } from 'src/components/AppTextField'

export interface GlobalDocumentContainerProps {
  adminSuppliers?: IPaginatedResult<ISupplier> | undefined
  customerSuppliers?: IPaginatedResult<ICustomerAdminSupplier> | undefined
  isSuperAdmin?: boolean
}

export const GlobalDocumentContainer: React.FC<GlobalDocumentContainerProps> = ({
  adminSuppliers,
  customerSuppliers,
  isSuperAdmin = false,
}) => {
  const { t: translate } = useTranslation(['global-documents', 'common'])
  const theme = useTheme()

  const supplier = useMemo(() => {
    const supplierDataFromSession = sessionStorage.getItem(isSuperAdmin ? 'admin-supplier' : 'customer-supplier')
    if (supplierDataFromSession) {
      return JSON.parse(supplierDataFromSession) as IBaseSupplier
    }
  }, [isSuperAdmin])

  const { id: customerId } = useParams()

  const suppliers = customerId ? customerSuppliers : adminSuppliers

  const [selectedSupplier, setSelectedSupplier] = useState<IBaseSupplier | null>(supplier as IBaseSupplier)

  const handleSaveLocal = useCallback(() => {
    if (selectedSupplier != null) {
      sessionStorage.setItem(
        isSuperAdmin ? 'admin-supplier' : 'customer-supplier',
        JSON.stringify({ id: selectedSupplier.id, name: selectedSupplier.name }),
      )
    } else {
      sessionStorage.removeItem(isSuperAdmin ? 'admin-supplier' : 'customer-supplier')
    }
  }, [isSuperAdmin, selectedSupplier])

  window.onbeforeunload = useCallback(() => {
    handleSaveLocal()
  }, [handleSaveLocal]) //this use to save locally when refresh

  useEffect(() => {
    if (supplier) {
      setSelectedSupplier(supplier as IBaseSupplier)
    }
  }, [supplier])

  useEffect(() => {
    return () => {
      handleSaveLocal()
    }
  }, [handleSaveLocal]) //this use to save locally when unmount

  const handleSelect = (_, value) => {
    setSelectedSupplier(value)
  }

  return (
    <CommonPageLayout
      titleSection={{ title: translate('global-documents', { ns: 'global-documents' }) }}
      topAction={[
        <Autocomplete
          key='supplier'
        sx={{ width: '20vw', backgroundColor: 'white' }}
        value={selectedSupplier}
        clearOnEscape
        openOnFocus={true}
        onChange={handleSelect}
        disablePortal
        id="combo-box-demo"
        options={suppliers?.items || []}
        getOptionLabel={supplier => `${supplier.name}`}
        renderInput={params => (
          <AppTextField
            {...params}
            label={translate('supplier', { ns: 'global-documents' })}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <GlobalDocumentsTable
          customerId={customerId}
          supplierId={selectedSupplier ? (selectedSupplier?.id as number) : undefined}
          height={'72vh'}
        />
      </Box>
    </CommonPageLayout>
  )
}
