import { Box, Button, styled, Typography, useTheme } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { invalidateDocument, useGetDocument, useUpdateDocument } from 'src/api/document'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { ShowDialogProps } from 'src/show-dialog'
import { DocumentDetailsSection } from './DocumentDetailsSection'
import { DocumentHistoryTable } from '../DocumentHistoryTable'
import { Flex } from 'src/styles/flexComponent'
import { EditOutlined } from '@mui/icons-material'
import { DocumentEditSection } from './DocumentEditSection'
import { IDocument } from 'src/generated-types/document'
import { invalidateDocumentCategoryCache } from 'src/api/document-category'
import { invalidateLocalityCache } from 'src/api/localities'
import { useZodForm } from 'src/hooks/zod-form'
import { z } from 'zod'
import { useGetAllMooringsByLocalityId } from 'src/api/moorings'
import { AppForm } from 'src/components/FormCore'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { MainButton } from '../../../../components/MainButton'
import { CancelButton } from 'src/components/CancelButton'
interface DocumentDetailDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  id: EntityId
}

export const DocumentDetailDialog: FC<DocumentDetailDialogProps> = ({ pop, id, customerId, localityId }) => {
  const { t } = useTranslation(['common', 'document'])
  const theme = useTheme()
  const { data: document } = useGetDocument(customerId as EntityId, id as EntityId)
  const [editMode, seteditMode] = useState(false)
  const mutate = useUpdateDocument(customerId as EntityId, document?.id as EntityId)
  const { data: moorings } = useGetAllMooringsByLocalityId(customerId as EntityId, localityId as EntityId)

  const renderSectionHeader = (title: string) => (
    <Box>
      <Typography fontWeight="600" variant="h5" fontSize={21} color={theme.palette.primaryMain[theme.palette.mode]}>
        {title}
      </Typography>
    </Box>
  )

  const schema = z.object({
    title: z.string().min(1),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    mooringId: z
      .object({
        id: z.number(),
      })
      .or(z.null())
      .optional()
      .transform(value => (typeof value == 'object' ? value?.id : value)),
    dueDate: z.date().or(z.string().nullable()).optional(),
    notes: z.string().optional(),
    version: z.string().optional(),
    fileId: z.number().default(document?.fileId as number),
  })

  const form = useZodForm(schema, {
    defaultValues: (() => {
      const { documentCategory, mooringId, dueDate, ...rest } = document ?? {}
      return {
        ...rest,
        dueDate: dueDate,
        documentCategoryId: documentCategory,
        mooringId: moorings?.find(m => m.id == mooringId),
      }
    })(),
  })

  const onSubmit = useCallback(
    async form => {
      await mutate.mutateAsync(form)
      if (document?.documentCategory?.id !== form.documentCategoryId) {
        invalidateDocumentCategoryCache.getDocumentCategoriesWithDocuments()
        invalidateDocument.getDocuments(customerId as EntityId)
      } else {
        invalidateDocument.getDocuments(customerId as EntityId)
      }
      invalidateDocument.getDocumentsHistory(customerId as EntityId, document?.id as EntityId)
      invalidateDocument.getExpireCounts(customerId, localityId)
      invalidateLocalityCache.getLocalitiesWithInfoCount(customerId)
      seteditMode(false)
    },
    [mutate, document?.documentCategory?.id, document?.id, customerId, localityId],
  )

  const changeMode = () => {
    seteditMode(true)
  }

  const handleCancel = () => {
    seteditMode(false)
  }

  const handleSave = () => {
    form.handleSubmit(onSubmit)()
  }

  return (
    <GenericDialogLayout
      title={editMode ? '' : t('document-details', { ns: 'document' })}
      pop={pop}
      actions={
        editMode
          ? [
              <CancelButton onClick={handleCancel} key={'cancel-btn'} data-cy="Cancel" />,
              <MainButton onClick={handleSave} key={'add/update-btn'} data-cy="AddDocument">
                {t('save')}
              </MainButton>,
            ]
          : []
      }
    >
      <CommonPageLayout
        titleSection={{ subtitle: document ? document.title ?? '' : '' }}
        titleContainerProps={{ paddingLeft: '8px', paddingBottom: editMode ? '16px' : undefined }}
        topActionContainerProps={{ paddingBottom: '16px' }}
        topAction={[
          <Flex.Row
            width={'100%'}
            key={'actions'}
            justifyContent={'space-between'}
            alignItems={'center'}
            marginLeft={'16px'}
          >
            <Flex.Row alignContent={'center'} justifyContent={'center'}>
              {!editMode && (
                <MainButton
                  variant="outlined"
                  onClick={changeMode}
                  startIcon={<EditOutlined />}
                  sx={{ marginRight: '16px', textTransform: 'none' }}
                >
                  {t('edit')}
                </MainButton>
              )}
            </Flex.Row>
          </Flex.Row>,
        ]}
      >
        {!editMode && (
          <Box width="100%" paddingTop={0}>
            {document && <DocumentDetailsSection data={document} />}
          </Box>
        )}
        <AppForm form={form}>
          {editMode && (
            <DocumentEditSection customerId={customerId} localityId={localityId} document={document as IDocument} />
          )}
        </AppForm>
      </CommonPageLayout>
      {!editMode && (
        <CommonPageLayout
          titleSection={{ subtitle: t('document-update-history', { ns: 'document' }) }}
          titleContainerProps={{ paddingLeft: '8px' }}
          topActionContainerProps={{ paddingBottom: '16px' }}
          sx={{ paddingTop: '16px' }}
          childrenProps={{ paddingX: '8px' }}
        >
          <DocumentHistoryTable
            customerId={customerId as EntityId}
            localityId={localityId as EntityId}
            documentId={id as EntityId}
          />
        </CommonPageLayout>
      )}
    </GenericDialogLayout>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  height: '36px',
  width: '98px',
  fontSize: '14px',
}))
