import React, { ReactNode, useState } from 'react'
import { IconButton, Menu, MenuItem, Typography, styled } from '@mui/material'
import { MdMoreVert } from 'react-icons/md'
import { muiTheme } from '../../mui-theme'

interface ActionBarElement {
  label: string
  onClick: () => void
  Icon: ReactNode
}

interface KebabMenuProps {
  actions: Array<ActionBarElement>
  isSelected?: boolean
}

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  color: ${muiTheme.palette.primary.main};

  &:hover {
    background-color: ${muiTheme.palette.tableRow.main};
  }
`
const StyledMenuLabel = styled(Typography)({
  fontWeight: 'normal',
})

const KebabMenu: React.FC<KebabMenuProps> = ({ actions, isSelected }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <StyledIconButton aria-controls="kebab-menu" aria-haspopup="true" onClick={handleClick} size="small">
        <MdMoreVert color={isSelected ? muiTheme.palette.white.main : ''} />
      </StyledIconButton>
      <Menu id="kebab-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {actions.map((action, index) => (
          <MenuItem key={index} onClick={action.onClick}>
            <StyledMenuLabel variant="h6">{action.label}</StyledMenuLabel>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default KebabMenu
