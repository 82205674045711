import { useGetDeviation, useGetDeviationAdmin } from '../../../api/deviation'
import { CenteredSpinner } from '../../../components/Spinner'
import UpdateDeviationDialog from '../../../dialogs/deviation/UpdateDeviationDialog'
import { IDeviation } from '../../../generated-types/deviation'
import { CreateDeviationFollowUpDialog, CreateDeviationFollowUpDialogAdmin } from './CreateDeviationFollowUpDialog'
import UpdateDeviationAdminDialog from '../../../dialogs/deviation/UpdateDeviationAdminDialog'
import { GenericDeviationContainer } from './GenericDeviationContainer'
import { useParams } from 'react-router-dom'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { Context } from 'react'

export const DeviationContainer: React.FC<{ isAdminLayout?: boolean, context?: Context<any> | null }> = ({ isAdminLayout, context }) => {
  const { customerId: clinetID, id, deviationId, localityId } = useParams()
  const customerId = clinetID ?? id
  const { data, isLoading } = useGetDeviation(customerId as EntityId, deviationId as EntityId)
  const { showDialog } = useDialogCtx()

  if (isLoading) return <CenteredSpinner />

  const onAdd = async () => {
    const added = await showDialog(
      CreateDeviationFollowUpDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          deviationId: deviationId as EntityId,
          dueDate: data?.dueDate,
          priority: data?.priority,
          localityId: localityId as EntityId,
          positionId: data?.positionId as EntityId,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const updateDeviation = async (deviation: IDeviation) => {
    const updated = await showDialog(UpdateDeviationDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        deviation: deviation,
      },
    },undefined,true)
    if (!updated) return
  }
  return (
    <GenericDeviationContainer
      data={data}
      customerId={customerId}
      deviationId={deviationId as EntityId}
      onAdd={onAdd}
      updateDeviation={updateDeviation}
      isAdminLayout={isAdminLayout}
      localityId={localityId as EntityId}
      context={context}
    />
  )
}

export const DeviationContainerAdmin: React.FC = () => {
  const { deviationId, localityId } = useParams()
  const { data, isLoading } = useGetDeviationAdmin(deviationId as EntityId)
  const { showDialog } = useDialogCtx()

  if (isLoading) return <CenteredSpinner />

  const onAdd = async () => {
    const added = await showDialog(CreateDeviationFollowUpDialogAdmin, {
      componentProps: {
        deviationId: deviationId as EntityId,
        dueDate: data?.dueDate,
        priority: data?.priority,
      },
    }, undefined, true)
    if (!added) return
  }

  const updateDeviation = async (deviation: IDeviation) => {
    const updated = await showDialog(UpdateDeviationAdminDialog, {
      componentProps: {
        deviation: deviation,
      },
    }, undefined, true)
    if (!updated) return
  }
  return (
    <GenericDeviationContainer
      data={data}
      deviationId={deviationId as EntityId}
      onAdd={onAdd}
      updateDeviation={updateDeviation}
      isAdminLayout={true}
      localityId={localityId as EntityId}
    />
  )
}

export default DeviationContainer