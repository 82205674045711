import { FC } from 'react'
import { AppFormProps } from '../../../components/FormCore'
import ServiceStationFrom from '../../serviceStations/ServiceStationForm'
import {
  invalidateServiceStationCache,
  useCreateServiceStation,
  useUpdateServiceStation,
} from '../../../api/service-station'
import { invalidateCustomersServiceStationDetails } from '../../../api/customer'
import { ShowDialogProps } from '../../../show-dialog'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

interface ServiceStationDialogProps extends ShowDialogProps, AppFormProps {
  title: string
  customerId: EntityId
}

export const ServiceStationDialog: FC<ServiceStationDialogProps> = ({
  title,
  customerId,
  initialValues,
  pop,
  ...rest
}) => {
  const { mutateAsync: createServiceStation } = useCreateServiceStation(customerId)
  const { mutateAsync: updateServiceStation } = useUpdateServiceStation(customerId, initialValues?.id)
  const { t } = useTranslation('service-station')

  const onCreate = (form: any) => {
    createServiceStation(form, {
      onSuccess() {
        invalidateServiceStationCache.getAllTypeServiceStations(customerId)
        toast.success(t('service-station-added-successfully', { ns: 'service-station' }))
        pop()
      },
      onError() {
        toast.error(t('service-station-add-error', { ns: 'service-station' }))
      },
    })
  }

  const onEdit = (form: any) => {
    updateServiceStation(form, {
      onSuccess() {
        invalidateCustomersServiceStationDetails()
        invalidateServiceStationCache.getAllTypeServiceStations(customerId)
        invalidateServiceStationCache.getServiceStationById(customerId, initialValues?.id)
        toast.success(t('service-station-updated-successfully', { ns: 'service-station' }))
        pop()
      },
      onError() {
        toast.error(t('service-station-update-error', { ns: 'service-station' }))
      },
    })
  }

  return (
    <ServiceStationFrom
      {...rest}
      initialValues={initialValues}
      customerId={customerId}
      title={title}
      pop={pop}
      onSubmit={initialValues?.id ? onEdit : onCreate}
    />
  )
}
