import { Badge } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GlobalDocumentForApprovalContainer } from './GlobalDocumentForApprovalContainer'
import { GlobalDocumentApprovedContainer } from './GlobalDocumentApprovedContainer'
import { useParams } from 'react-router-dom'
import { useGetGlobalDocumentCounts } from '../../../api/global-documents'
import { useMemo } from 'react'
import TabView, { IArrayItem } from 'src/components/tab/TabView'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'


export const GlobalDocumentsForCustomer: React.FC = () => {
  const { id: customerId } = useParams()
  const { data } = useGetGlobalDocumentCounts(customerId as EntityId)
  const { t: translate } = useTranslation(['global-documents', 'common'])

  const tabData: IArrayItem[] = useMemo(
    () => [
      {
        id: 1,
        header: {
          title: translate('for-approval', { ns: 'global-documents' }),
          content: <Badge badgeContent={data?.forApprovalCount} color="primary" />,
        },
        content: <GlobalDocumentForApprovalContainer />,
      },
      {
        id: 2,
        header: {
          title: translate('approved', { ns: 'global-documents' }),
          content: <Badge badgeContent={data?.approvedCount} color="primary" />,
        },
        content: <GlobalDocumentApprovedContainer />,
      },
    ],
    [translate, data?.forApprovalCount, data?.approvedCount],
  )
  return (
    <CommonPageLayout titleSection={{ title: translate('global-documents', { ns: 'global-documents' }) }}>
      <TabView uniqueId="global-documents-tab" data={tabData} height={window.innerHeight - 500} />
    </CommonPageLayout>
  )
}
