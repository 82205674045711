import { Box, DialogTitle, DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAddDistributor, invalidateDistributorCache } from '../../../api/distributor'
import { ShowDialogProps } from '../../../show-dialog'
import { DistributorForm } from './DistributorForm'

export const CreateDistributorDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: translate } = useTranslation(['distributor', 'common'])
  const mutation = useAddDistributor()

  const onSubmit = async form => {
    await mutation.mutateAsync(form)
    invalidateDistributorCache.useGetDistributors()
    pop(true)
  }

  return (
    <Box>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
        }}
        className='text-primary'
      >
        {translate('addDistributor', { ns: 'distributor' })}
      </DialogTitle>
      <DialogContent>
        <DistributorForm onSubmit={onSubmit} onCancel={() => pop()} />
      </DialogContent>
    </Box>
  )
}
