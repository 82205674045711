import { AxiosResponse } from 'axios'
import moment from 'moment'

export const downloadFileLocal = (res: AxiosResponse, fileName?: string) => {
  if (res.status === 200) {
    const blob = new Blob([res.data], { type: res.headers['content-type'] })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      `${fileName ?? 'download-' + moment(new Date()).format('DD-MM-YYYY hh:mm:ss').toString()}.${getFileExtension(
        res.headers['content-type'],
      )}`,
    )
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}

const getFileExtension = (contentType: string) => {
  switch (contentType) {
    case 'application/vnd.ms-excel':
      return 'xlsx'
    case 'application/x-gzip':
      return 'gz'
    case 'application/pdf':
      return 'pdf'
    default:
      return null
  }
}
