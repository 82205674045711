import { DeleteOutline, DownloadOutlined } from '@mui/icons-material'
import { Button, Box, Stack, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { useMemo, useCallback } from 'react'
import { AuthorizationType } from 'src/enums/auth'
import { IFormSchema, IFormSchemaDto, IFormSchemaReport } from 'src/generated-types/form-schema'
import { Spinner } from '../Spinner'
import GenericTable from '../table/GenericTable'
import ReportViewer from './ReportViewer'
import { Padding } from 'src/styles/paddingComponent'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { ITaskFormSchema } from 'src/generated-types/taskFormSchema'
import { GridActionButton } from '../common/GridActionButton'
import { Flex } from 'src/styles/flexComponent'

interface IOverallReportTabContainer {
    onComplete: (row: any) => void
    disabled?: boolean
    bulkUploadLoading?: number
    createPermissions: number[]
    context?: React.Context<any> | null
    handleExport: (row: any) => void
    data: IFormSchemaDto[] | undefined
    requiredReports?: ITaskFormSchema[]
    deletePermissions: number[]
    handleDelete: (id: number) => void
    isLoading: boolean
    height?: number | string
}

const OverallReportTabContainer: React.FC<IOverallReportTabContainer> = ({ deletePermissions, handleDelete, isLoading, height, data, requiredReports, onComplete, disabled, handleExport, bulkUploadLoading, context, createPermissions }) => {
    const { showDialog } = useDialogCtx()

    const STATIC_COLUMNS: GridColumns = [
        { field: 'name', headerName: t('name', { ns: 'common' }), flex: 1 },
        {
            field: 'description',
            headerName: t('description', { ns: 'common' }),
            flex: 1,
        },
        {
            field: 'actions',
            headerName: '',
            width: 140,
            renderCell: ({ row }) => {
                return row?.formSchemaReports == undefined ?
                    <Padding.p8>
                        <Button variant='outlined' sx={{ height: '30px' }} onClick={() => onComplete(row)} disabled={disabled}>
                            {t('complete')}
                        </Button>
                    </Padding.p8>
                    : (
                        <Flex.Row justifyContent={'flex-end'} width={'100px'}>
                            {bulkUploadLoading == row.id ? (
                                <Spinner size={25} />
                            ) :
                                <GridActionButton
                                    onClick={async () => {
                                        handleExport(row)
                                    }}
                                    disabled={disabled}
                                    context={context}
                                    permissionBinaryValues={createPermissions}
                                    authorizationType={AuthorizationType.Disabled}
                                    buttonIcon={<DownloadOutlined sx={{ fontSize: '20px' }} />}
                                    height='32px'
                                    width='32px'
                                />
                            }
                        </Flex.Row>
                    )
            },
        },
    ]

    const rows = useMemo(() => {
        const returnArray = data?.filter(s => s?.formSchemaReports != null && s?.formSchemaReports?.length > 0) || []
        const requiredArray = requiredReports
            ?.map(d => ({ ...d.formSchema, id: d.formSchemaId } as IFormSchema))
            .filter(ra => !returnArray.find(r => r.name == ra.name))
        return requiredArray ? requiredArray?.concat(returnArray as IFormSchema[]) : returnArray
    }, [data])


    const renderExpandedRow = useCallback(
        ({ row: schema }) => {
            return schema?.formSchemaReports != undefined && schema?.formSchemaReports?.length > 0 ? (
                <Box marginLeft={2} marginTop={1} paddingBottom={2}>
                    {schema?.formSchemaReports?.map((report: IFormSchemaReport) => {
                        return (
                            <Stack key={report.id} direction={'row'} paddingX={'20px'} paddingY={'5px'}
                                style={{ background: 'white', cursor: 'pointer' }} mr={1}>
                                <Box
                                    width={'100%'}
                                    key={report?.id}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    marginTop={1}
                                    onClick={() => {
                                        showDialog(ReportViewer, {
                                            componentProps: {
                                                layouts: JSON.parse(report?.formSchemaVersion?.layout || ''),
                                                schema: schema,
                                                report: report,
                                                disabled: disabled,
                                                createdAt: report?.createdAt,
                                                createdByUser: report?.createdByUser,
                                            },
                                            opts: {
                                                maxWidth: 'lg',
                                                fullWidth: true
                                            }
                                        })
                                    }}
                                >
                                    <Box flex={1}>
                                        <Typography>{`${report?.createdByUser?.firstName} ${report?.createdByUser?.lastName}`}</Typography>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography>{`${new Date(report?.createdAt + 'Z' || '').toLocaleString()}`}</Typography>
                                    </Box>
                                </Box>
                                <GridActionButton
                                    onClick={async () => {
                                        handleDelete(report.id as number)
                                    }}
                                    disabled={disabled}
                                    context={context}
                                    permissionBinaryValues={deletePermissions}
                                    authorizationType={AuthorizationType.Disabled}
                                    buttonIcon={<DeleteOutline sx={{ fontSize: '20px' }} />}
                                    height='32px'
                                    width='32px'
                                />
                            </Stack>
                        )
                    })}
                </Box>
            ) : null
        },
        [disabled, handleDelete],
    )

    return <GenericTable
        id={'overall-report-table'}
        columns={STATIC_COLUMNS}
        data={rows}
        loading={isLoading}
        autoHeight={false}
        getDetailPanelHeight={() => 'auto'}
        height={height}
        getDetailPanelContent={params => renderExpandedRow(params)}
        getRowHeight={() => 40}
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
    />
}

export default OverallReportTabContainer