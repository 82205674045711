import React, { createContext, useState } from 'react'

interface SidebarContextProps {
  collapsibleSidebarOpen: boolean
  setCollapsibleSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SidebarCtx = createContext<SidebarContextProps>(null!)

export const SidebarContextProvider: React.FC = ({ children }) => {
  const [collapsibleSidebarOpen, setCollapsibleSidebarOpen] = useState(false)

  const contextValue: SidebarContextProps = {
    collapsibleSidebarOpen,
    setCollapsibleSidebarOpen,
  }

  return <SidebarCtx.Provider value={contextValue}>{children}</SidebarCtx.Provider>
}
