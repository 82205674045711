import { Box } from '@mui/system'
import { FC } from 'react'
import { ServiceStationsTable } from '../service-station/ServiceStationsTable'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { toast } from 'react-toastify'
import { serviceStationGroupsEndpoints, useDeleteServiceStationFromGroup } from '../../../api/service-station-group'
import { AlertDialog } from '../../../show-dialog'
import { permissionBinding, permissionLevel } from '../../../constant'
import { FacilityTypes, RoleTypes } from '../../../enums'
import { Paper, Stack } from '@mui/material'
import { t } from 'i18next'
import { getCustomerUsers } from '../../../http'
import { invalidateAccessGroups, useGetAccessGroupForServiceStationGroup } from '../../../api/access-group'
import { IAuthUserInitialValues } from '@lovoldsystem/authorization.client/components/users/authorizationMapping.action'
import { FunctionElementsParentsIds } from '../../../enums/auth'
import { invalidateServiceStationGroupsQueries } from '../../../api/service-station-group'
import { AuthorizationUsersMapping } from '@lovoldsystem/authorization.client'

export const ServiceStationGroupContainer: FC<{ serviceStationGroupId: EntityId; serviceStationName: string }> = ({
  serviceStationGroupId,
  serviceStationName,
}) => {
  const { customer } = useCustomerCtx()
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { data } = useGetAccessGroupForServiceStationGroup(customer.id, serviceStationGroupId)
  const { mutateAsync: removeServiceStationFromGroup } = useDeleteServiceStationFromGroup(
    customer.id,
    serviceStationGroupId,
  )

  const handleServiceStationRemove = async (id: EntityId) => {
    showDialog(
      AlertDialog,
      {
        componentProps: {
          title: t('remove-service-station', { ns: 'service-station-group' }),
          acceptText: t('remove', { ns: 'common' }),
          text: t('remove-confirmation-message', { ns: 'service-station-group' }),
          onConfirm: async pop => {
            await removeServiceStationFromGroup(id)
              .then(() => {
                invalidateServiceStationGroupsQueries.getServiceStationGroups(customer.id as EntityId)
                toast.success(t('service-station-removed-successfully', { ns: 'service-station-group' }))
              })
              .catch(() => {
                toast.error(t('service-station-removed-error', { ns: 'service-station-group' }))
              })
            pop()
          },
        },
      },
      undefined,
      true,
    )
  }

  return (
    <Box className="px-4 py-2 mt-2 mb-2 shadow-md">
      <Stack spacing={2}>
        <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', p: 2, borderRadius: '5px' }}>
          <ScreenTitle title={t('service-stations', { ns: 'service-station-group' })} />
          <ServiceStationsTable
            queryUrl={serviceStationGroupsEndpoints.getServiceStationsForGroup(
              customer.id,
              serviceStationGroupId as EntityId,
            )}
            handleRemove={handleServiceStationRemove}
          />
        </Paper>

        {customer.id && (
          <AuthorizationUsersMapping
            invalidateUsers={() => {
              invalidateAccessGroups.getServiceStationGroupUsers(customer.id, serviceStationGroupId)
            }}
            showDialog={showDialog}
            showConfirmDialog={showConfirmDialog}
            title={t('service-station-group-users', { ns: 'service-station-group' })}
            permissionLevel={permissionLevel}
            permissionBinding={permissionBinding}
            functionElementParents={[FunctionElementsParentsIds.ServiceStationPortal]}
            initialValues={{
              facilityId: serviceStationGroupId as number,
              facilityName: serviceStationName,
              type: FacilityTypes.serviceStationGroup,
              facilityTypeText: t('service-station-group', { ns: 'service-station-group' }),
            } as IAuthUserInitialValues}
            clientId={customer.id}
            roleTypes={[RoleTypes.ServiceStation]}
            facilityUsers={data || []}
            useGetUsers={getCustomerUsers}
          />
        )}
      </Stack>
    </Box>
  )
}
