import { t as translate } from 'i18next'
import { GridColumns } from '@mui/x-data-grid-pro'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useGetComponents } from '../../../api/components'
import { CenteredSpinner } from '../../../components/Spinner'
import { APIResultCount } from '../../../constant'
import { IComponent } from '../../../generated-types'
import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import GenericTable from 'src/components/table/GenericTable'
import { SearchBar } from 'src/screens/localities/moorings/PositionTableFilter'

interface ISelectComponentStepProps {
  customerId: EntityId
  setDisabled: Dispatch<SetStateAction<boolean>>
  handleNext: () => void
  lgModal?: boolean
}
const SelectComponentStep: FC<ISelectComponentStepProps> = ({ customerId, setDisabled, handleNext, lgModal }) => {
  const { getValues, reset } = useFormContext()

  useEffect(() => {
    if (getValues('component')?.id === undefined) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [getValues('component')])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      { field: 'productNumber', headerName: translate('productNo', { ns: 'component' }), flex: 1 },
      {
        field: 'productDescription',
        headerName: translate('description', { ns: 'component' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
      },
      {
        field: 'positionDrawingNumber',
        headerName: translate('drawingNo', { ns: 'component' }),
        flex: 1,
        align: 'center',
      },
      { field: 'quantity', headerName: translate('quantity', { ns: 'component' }), flex: 1 },
      {
        field: 'installationDate',
        headerName: translate('installationDate', { ns: 'component' }),
        flex: 1,
        renderCell: params =>
          !params.value && typeof params.value !== 'string' ? (
            translate('n/a', { ns: 'common' })
          ) : (
            <h5>{new Date(params.value).toLocaleDateString()}</h5>
          ),
      },
    ],
    [],
  )

  const { data: components, isLoading } = useGetComponents(
    customerId,
    getValues('position')?.id as EntityId,
    0,
    APIResultCount.Max,
  )

  const handleRowClick = useCallback(
    params => {
      reset({ ...getValues(), component: params.row as IComponent })
      setDisabled(false)
      handleNext()
    },
    [setDisabled, handleNext],
  )

  const [searchInput, setSearchInput] = useState('')
  const handleSelectInputChange = input => {
    setSearchInput(input)
  }

  const filteredPositions = useMemo(() => {
    return components?.items.filter(
      c =>
        c.productNumber?.includes(searchInput) ||
        c.productDescription.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()),
    )
  }, [components?.items, searchInput])

  if (isLoading) return <CenteredSpinner />

  return (
    <Box width={'100%'} paddingTop={'16px'} px={'12px'}>
      <GenericTable
        columns={STATIC_COLUMNS}
        data={(filteredPositions as IComponent[]) || []}
        enableToolbar={true}
        height={400}
        enableToolbarFilter={lgModal}
        toolbarFilter={
          <SearchBar
            searchInput={searchInput}
            onChange={handleSelectInputChange}
            placeholder={translate('search', { ns: 'common' })}
            width="50%"
          />
        }
        loading={isLoading}
        onRowClick={handleRowClick}
        selectionModel={getValues('position') ? [getValues('position').id] : []}
        id={'create-deviation-positions'}
        autoHeight={false}
      />
    </Box>
  )
}

export default SelectComponentStep
