import { useTranslation } from 'react-i18next'
import {
  invalidateProductCertificationByProductIdCache,
  useAddProductCertification,
} from 'src/api/product-certification'
import { ShowDialogProps } from 'src/show-dialog'
import { ProductCertificationForm } from './ProductCertificationForm'

interface CreateProductCertificateDialogProps extends ShowDialogProps {
  productId: EntityId
}
export const CreateProductCertificateDialog: React.FC<CreateProductCertificateDialogProps> = ({ pop, productId }) => {
  const { t } = useTranslation(['main-component', 'common', 'certificate'])

  const mutation = useAddProductCertification()

  const onSubmit = async form => {
    form.productId = productId
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateProductCertificationByProductIdCache.getProductCertificationByProductId(productId)
        pop()
      },
    })
  }

  return (
    <ProductCertificationForm
      title={t('create-product-certificate', { ns: 'product' })}
      onSubmit={onSubmit}
      onCancel={() => pop()}
    />
  )
}
