import { IDocumentCategory } from "./document-category"
import { IFile } from "./file"

export interface IGlobalDocument {
  id: number
  createdAt: Date
  title: string
  documentCategory?: IDocumentCategory
  documentCategoryId: number
  notes: string
  isActive: boolean
  versions: IGlobalDocumentVersion[]
  description: string,
  version: string,
  documentUrl:string
}

export interface ICreateGlobalDocument {
  supplierId: number
  title: string
  isActive: string
  documentCategoryId: number
  notes: string
  version: string
  fileId: number
}

export interface ICreateGlobalDocumentVersion {
  documentId: number
  description: string
  version: string
  fileId: number
}

export interface IUpdateGlobalDocument {
  title: string
  isActive: string
}

export interface IGlobalDocumentVersion {
  id: number
  title: string
  description: string
  revision: string
  fileId: number
  file?: IFile
  globalDocumentId: number
  createdAt: Date
}

export interface IGlobalDocumentRule {
  id: number
  documentId: number
  customerId?: number
  mainComponentStatus?: number
  producedFromYear?: string
  producedFromMonth?: number
  producedForYear?: string
  producedForMonth?: number
  mainComponentTypeId?: number
}

export interface ICreateGlobalDocumentRule {
  documentId: number
  customerId?: number
  mainComponentStatus?: number
  mainComponentTypeId?: number
}

export interface IGlobalDocumentQueryParams  {
    supplierId?: EntityId
    page?: number
    pageSize?: number
}

export interface IAdminGlobalDocumentQueryParams  {
  customerId: EntityId
  documentCategoryId?: EntityId
  page?: number
  pageSize?: number
}

export interface IForApprovalGlobalDocument extends IGlobalDocument {
  documentVersionId: number
  supplierName: string
  createdUserName: string
  changesNote: string
  file: IFile
  status: GlobalDocumentStatus
}

export interface IApprovedGlobalDocument extends IForApprovalGlobalDocument {
  approvedAt: Date
  approvedBy: string
}

export interface ISaveDraftGlobalDocument { 
  documentVersionId: number
  customerId: number
  fileId: number
  changeNote: string
}

export interface IWithdrawGlobalDocument { 
  documentVersionId: EntityId
  customerId: EntityId
}

export enum GlobalDocumentStatus
{
    Withdrawn=0,
    Approved=1,
    New=2,
    SaveDraft=3
}

export interface IGlobalDocumentCategoriesWithDocumentCount {
  documentCategoryId : EntityId
  documentCategoryName: string
  documentCount: number
}

export interface IGlobalDocumentCounts {
  forApprovalCount : number
  approvedCount: number
}