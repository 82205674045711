import { Box, Grid, styled, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  invalidateMainComponentCache,
  useGetMainComponentById,
  useReleaseMainComponent,
} from '../../../api/main-component'
import { CenteredSpinner } from '../../../components/Spinner'
import DeviationTab from './components/DeviationTab'
import { IDeviationFormWizardType } from '../../../generated-types/form-wizard'
import { CreateCertificateDialog } from '../Certificates/Components/CertificatesContainer'
import CertificateTab from './components/CertificateTab'
import { Context, FC, useCallback, useMemo } from 'react'
import { CreateDocumentDialog, ICreateDocumentsParams } from '../documents/components/CreateDocumentDialog'
import { IDeviationType } from '../../../generated-types/deviation-type'
import { invalidateLocalityCache, useGetLocality } from '../../../api/localities'
import { MainComponentSendModalComponent } from '../positions/components/MainComponentSendModal'
import OverallReport from '../../../components/overallReport/OverallReport'
import { formSchemaReportTypes } from '../../../generated-types/form-schema'
import { formFieldsDefaultValues } from '../../../generated-types/form-field'
import { IMainComponentType, MainComponentStatus } from '../../../enums'
import { CreatePositionDeviationDialog, ICreateDeviationDialogProps } from '../deviations/CreatePositionDeviationDialog'
import { UpdateMainComponentDialog } from '../../customer/mainComponents/UpdateMainComponentDialog'
import { AuthorizationProvider, hasPermission } from '../../../components/common/Authorization'
import { permissionValue } from '../../../enums/permissionValue'
import { AuthorizationType } from '../../../enums/auth'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { invalidateDocumentCategory } from 'src/api/users/document-category'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline, Edit } from '@mui/icons-material'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { MainComponentHistoriesTable } from './MainComponentHistoriesTable'
import DocumentsTableGeneric from 'src/components/documents/DocumentsTableGeneric'
import { APIResultCount } from 'src/constant'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { Flex } from 'src/styles/flexComponent'
import { toast } from 'react-toastify'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { invalidatePositionCache } from 'src/api/positions'
import { IMainComponent } from 'src/generated-types/main-component'
import { ReactComponent as ReleaseIcon } from 'src/assets/icons/releaseIcon.svg'
import ChangeLocationDialog from '../Equipment/ChangeLocationDialog'

export interface IEquipmentDetailModalProps {
  context?: Context<any> | null
  permission?: number
  customerId?: EntityId
  localityId?: EntityId
  mainComponentId?: any
  serviceStationId?: any
  adminCustomerId?: any
  pop?: any
}

export const EquipmentDetailModal: FC<IEquipmentDetailModalProps> = ({
  context,
  permission,
  customerId: cId,
  localityId,
  serviceStationId,
  adminCustomerId,
  mainComponentId,
  pop = () => {},
}) => {
  const { t } = useTranslation(['main-component', 'common', 'equipment', 'activity-logs', 'overall-reports'])
  const customerId = useMemo(() => cId ?? adminCustomerId, [cId, adminCustomerId])
  const { data, isLoading } = useGetMainComponentById(customerId as EntityId, mainComponentId as EntityId)
  const { mutateAsync } = useReleaseMainComponent(customerId as EntityId)
  const { data: locality } = useGetLocality(
    customerId as EntityId,
    data?.selectedLocalityId ? (data?.selectedLocalityId as EntityId) : (0 as EntityId),
    { mainComponentId },
  )

  const { showDialog, showConfirmDialog } = useDialogCtx()
  const theme = useTheme()
  const { localityId: localityIdFromURL } = useParams()

  const onUpdate = useCallback(
    async id => {
      const added = await showDialog(
        UpdateMainComponentDialog,
        {
          componentProps: {
            id: id as EntityId,
            mainComponentTypeName: data?.type.name as string,
            customerId: customerId as EntityId,
            localityId: localityId,
          },
        },
        undefined,
        true,
      )
      if (!added) return
    },
    [data?.mainComponentTypeName],
  )

  const onAddDeviationForComponent = useCallback(async () => {
    await showDialog(
      CreatePositionDeviationDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          localityId: locality?.id as EntityId,
          mooringId: data?.position?.mooringId as EntityId,
          position: data?.position,
          mainComponent: data,
          formWizard: IDeviationFormWizardType.Component,
          type:
            data?.type.componentType === IMainComponentType.Custom
              ? IDeviationType.Equipment
              : IDeviationType.MainComponent,
          disableSteps: true,
        } as ICreateDeviationDialogProps,
        isAutoCloseDisabled: true,
      },
      undefined,
      true,
    )
  }, [customerId, locality?.id, data])

  const onAddCertificateForComponent = async () => {
    const added = await showDialog(
      CreateCertificateDialog,
      {
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        componentProps: {
          mainComponentId: mainComponentId as EntityId,
          customerId: customerId as EntityId,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onAddDocumentForComponent = async () => {
    const documentParams = {
      mainComponentId: mainComponentId as EntityId,
      mooringId: data?.position?.mooringId as EntityId,
      positionId: data?.positionId,
      mooringName: data?.position?.mooringName as string,
    } as ICreateDocumentsParams
    const added = await showDialog(
      CreateDocumentDialog,
      {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          customerId: customerId as EntityId,
          localityId: locality?.id as EntityId,
          createDocumentsParams: documentParams,
        },
        pop: () => {
          invalidateDocumentCategory.getDocumentCategoriesWithDocuments(customerId as EntityId, { mainComponentId })
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const showChangeLocationDialog = useCallback(
    async (mainComponent: IMainComponent) => {
      await showDialog(
        ChangeLocationDialog,
        {
          componentProps: {
            customerId: customerId as EntityId,
            localityId: localityId as EntityId,
            mainComponent: mainComponent,
          },
          opts: {
            maxWidth: 'sm',
            fullWidth: true,
          },
        },
        undefined,
        true,
      )
    },
    [customerId, localityId],
  )

  const onSend = async () => {
    const updated = await showDialog(MainComponentSendModalComponent, {
      title: t('sendToServiceStation', { ns: 'main-component' }),
      componentProps: {
        customerId: customerId as EntityId,
        localityId: locality?.id as EntityId,
        mooringId: data?.position?.mooringId as EntityId,
        positionId: data?.positionId as EntityId,
        id: mainComponentId as EntityId,
        localityIdFromURL: localityIdFromURL,
      },
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
    })
    if (!updated) return
  }

  const onRelease = useCallback(async () => {
    if (!data) return
    showConfirmDialog(
      t('release-from-cage'),
      t('release-from-cage-description'),
      {
        acceptText: t('yes'),
        cancelText: t('no'),
      },
      async pop => {
        await mutateAsync(
          { id: data?.id as number, localityId: localityId as EntityId },
          {
            onSuccess: () => {
              toast.success(t('main-component-released-from-cage-toast', { ns: 'main-component' }))
              invalidateMainComponentCache.getMainComponents(
                customerId as EntityId,
                localityId as EntityId,
                data?.position?.mooringId as EntityId,
                data?.positionId as EntityId,
              )

              invalidationActivityLogCache.getCageActivityLogs()
              invalidatePositionCache.getCagePositionDeviations(
                customerId as EntityId,
                localityId as EntityId,
                data?.positionId as EntityId,
              )
              invalidateLocalityCache.getLocalityWithActivityCounts(customerId as EntityId, localityId as EntityId)
              invalidateLocalityCache.getLocality(customerId as EntityId, localityId as EntityId)
              invalidateMainComponentCache.getMainComponentById(customerId as EntityId, mainComponentId as EntityId)
              invalidateMainComponentCache.getEquipment(customerId)
              pop()
            },
          },
        )
      },
    )
  }, [data])

  if (isLoading) return <CenteredSpinner />

  if (data !== undefined)
    return (
      <CommonPageLayout
        titleSection={{
          title: `${data?.name} ${
            data.position ? ` / ${data.position.mooringName} - ${data.position.reference ?? data.position.name}` : ''
          }`,
        }}
        sx={{ backgroundColor: theme.palette.backgroundMain[theme.palette.mode], padding: '8px' }}
        topAction={[
          <Flex.Row justifyContent={'center'} alignItems={'center'} key="equipment-action" gap={1}>
            <>
              {data?.positionId != null && (
                <GridActionButton
                  buttonText={t('release')}
                  onClick={onRelease}
                  height="44px"
                  variant="outlined"
                  shadowWeight={0}
                  permissionBinaryValues={[
                    permissionValue.Locality.Mooring.Write,
                    permissionValue.ServiceStation.Write,
                    permissionValue.ClientAdminPermission,
                  ]}
                  context={context}
                  startIcon={<ReleaseIcon height={'20px'} width={'20px'} />}
                  authorizationType={AuthorizationType.Disabled}
                />
              )}
              {((data?.selectedLocalityId && data.status === MainComponentStatus.InStock) || (data?.selectedLocalityId && data.status === MainComponentStatus.Outgoing)  ||
              (data?.positionId && data.status === MainComponentStatus.Active)) && <GridActionButton
                permissionBinaryValues={[
                  permissionValue.Locality.Mooring.Write,
                  permissionValue.ServiceStation.Write,
                  permissionValue.ClientAdminPermission,
                ]}
                context={context}
                authorizationType={AuthorizationType.Disabled}
                onClick={() => {
                  showChangeLocationDialog(data as IMainComponent)
                }}
                startIcon={<img height={'28px'} width={'28px'} src="/icons/change.svg" />}
                buttonText={t('change-location', { ns: 'main-component' })}
                height="44px"
                sx={{ padding: '8px 16px' }}
              />}
            </>
            {((data?.selectedLocalityId && data.status === MainComponentStatus.InStock) ||
              (data?.positionId && data.status === MainComponentStatus.Active)) && (
              <AuthorizationProvider
                permissions={[
                  permissionValue.Locality.Mooring.Write,
                  permissionValue.ServiceStation.Write,
                  permissionValue.ClientAdminPermission,
                ]}
                Context={context}
                type={AuthorizationType.Disabled}
              >
                <GridActionButton
                  onClick={onSend}
                  startIcon={<img height={'28px'} width={'28px'} src="/icons/service_station_number.svg" />}
                  buttonText={t('move-send', { ns: 'main-component' })}
                  height="44px"
                  sx={{ padding: '8px 16px' }}
                />
              </AuthorizationProvider>
            )}
            <AuthorizationProvider
              permissions={[
                permissionValue.Locality.Mooring.Write,
                permissionValue.ServiceStation.Write,
                permissionValue.ClientAdminPermission,
              ]}
              Context={context}
              type={AuthorizationType.Disabled}
            >
              <GridActionButton
                buttonIcon={<Edit />}
                onClick={() => onUpdate(data.id)}
                toolTipTitle={t('edit', { ns: 'common' })}
                height="44px"
                width="44px"
              />
            </AuthorizationProvider>
          </Flex.Row>,
        ]}
      >
        <Flex.Row
          justifyContent={'space-between'}
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          paddingTop={'20px'}
          paddingBottom={'20px'}
        >
          <Grid md={6} className="pr-8-core">
            <CommonPageLayout
              titleSection={{
                subtitle: t('3d-view', { ns: 'equipment' }),
              }}
              titleContainerProps={{ paddingBottom: '12px', fontWeight: 600 }}
            >
              <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
                <Box height={window.innerHeight / 3} />
              </Box>
            </CommonPageLayout>
          </Grid>
          <Grid md={6} className="pl-8-core">
            <AuthorizationProvider
              type={AuthorizationType.Disabled}
              permissions={[
                permissionValue.ServiceStation.Read,
                permissionValue.Locality.MainComponent.Certificate.Read,
                permissionValue.Locality.MainComponent.Certificate.Write,
                permissionValue.Locality.MainComponent.Certificate.Delete,
                permissionValue.ClientAdminPermission,
              ]}
              Context={context}
            >
              <CertificateTab
                mainComponentId={data.id}
                mainComponentType={data.type}
                positionId={data.positionId}
                customerId={customerId as EntityId}
                onAddCertificateForComponent={onAddCertificateForComponent}
                Context={context}
              />
            </AuthorizationProvider>
          </Grid>
        </Flex.Row>
        <AuthorizationProvider
          type={AuthorizationType.Disabled}
          Context={context}
          permissions={[
            permissionValue.ServiceStation.Read,
            permissionValue.Locality.MainComponent.Deviation.Read,
            permissionValue.Locality.MainComponent.Deviation.Write,
            permissionValue.Locality.MainComponent.Deviation.Delete,
            permissionValue.ClientAdminPermission,
          ]}
        >
          <DeviationTab
            customerId={customerId!}
            localityId={localityId}
            mainComponentId={data.id}
            onAddDeviationForComponent={onAddDeviationForComponent}
            queryParams={{ mainComponentId }}
            context={context as Context<any>}
            pop={pop}
            enableDelete={hasPermission(
              [
                permissionValue.Locality.MainComponent.Deviation.Delete,
                permissionValue.ServiceStation.Delete,
                permissionValue.ClientAdminPermission,
              ],
              permission,
            )}
            addPermissions={[
              permissionValue.Locality.MainComponent.Deviation.Write,
              permissionValue.ServiceStation.Write,
              permissionValue.ClientAdminPermission,
            ]}
            updatePermissions={[
              permissionValue.Locality.MainComponent.Deviation.Write,
              permissionValue.ServiceStation.Write,
              permissionValue.ClientAdminPermission,
            ]}
            deletePermissions={[
              permissionValue.Locality.MainComponent.Deviation.Delete,
              permissionValue.ServiceStation.Delete,
              permissionValue.ClientAdminPermission,
            ]}
            permission={permission}
            enablePagination={false}
            autoHeight={false}
            height={window.innerHeight / 3}
          />
        </AuthorizationProvider>
        <Flex.Row
          justifyContent={'space-between'}
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          paddingTop={'20px'}
          paddingBottom={'20px'}
        >
          <Grid md={6} className="pr-8-core">
            <CommonPageLayout
              titleSection={{ subtitle: t('documents', { ns: 'document' }) }}
              titleContainerProps={{ paddingBottom: '12px' }}
              topAction={[
                {
                  buttonText: t('documents', { ns: 'document' }),
                  buttonIcon: <AddCircleOutline />,
                  onClick: onAddDocumentForComponent,
                  permissionBinaryValues: [
                    permissionValue.Locality.MainComponent.Document.Write,
                    permissionValue.ClientAdminPermission,
                    permissionValue.ServiceStation.Write,
                  ],
                  variant: 'outlined',
                  shadowWeight: null,
                  context: context,
                },
              ]}
            >
              <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
                <DocumentsTableGeneric
                  customerId={customerId as EntityId}
                  localityId={localityId as EntityId}
                  documentsParams={{ mainComponentId, customerId: customerId as EntityId }}
                  showAddDocumentButton={false}
                  showDocumentsHeader={false}
                  pageSize={APIResultCount.Max || 0}
                  autoHeight={false}
                  isViewAllRecords
                  height={window.innerHeight / 3}
                  context={context as Context<any>}
                  hideMooringColumn={true}
                />
              </Box>
            </CommonPageLayout>
          </Grid>
          <Grid md={6} className="pl-8-core">
            <AuthorizationProvider
              type={AuthorizationType.Disabled}
              Context={context}
              permissions={[
                permissionValue.ServiceStation.Read,
                permissionValue.Locality.MainComponent.Report.Read,
                permissionValue.ClientAdminPermission,
              ]}
            >
              <OverallReport
                type={formSchemaReportTypes.MainComponent}
                mainComponentId={mainComponentId}
                context={context}
                formInitialValues={{
                  [formFieldsDefaultValues.orderNumber]: data?.orderNumber || '',
                  [formFieldsDefaultValues.netType]:
                    Object.keys(IMainComponentType)[
                      Object.values(IMainComponentType).indexOf(
                        data?.type.componentType as unknown as IMainComponentType,
                      )
                    ] || '',
                  [formFieldsDefaultValues.siteLocationName]: locality?.name ? locality?.name : 'n/a',
                  [formFieldsDefaultValues.siteNumber]: locality?.locationNr ? locality?.locationNr : 'n/a',
                  [formFieldsDefaultValues.resourcesName]: data?.name || '',
                }}
                createPermissions={[
                  permissionValue.Locality.MainComponent.Report.Write,
                  permissionValue.ServiceStation.Write,
                  permissionValue.ClientAdminPermission,
                ]}
                deletePermissions={[
                  permissionValue.Locality.MainComponent.Report.Delete,
                  permissionValue.ServiceStation.Delete,
                  permissionValue.ClientAdminPermission,
                ]}
                height={window.innerHeight / 3}
                listView={true}
              />
            </AuthorizationProvider>
          </Grid>
        </Flex.Row>
        <CommonPageLayout
          titleSection={{ subtitle: t('history', { ns: 'activity-logs' }) }}
          topAction={[]}
          titleContainerProps={{ paddingBottom: '12px' }}
        >
          <Box
            padding={'8px'}
            bgcolor={getThemeColor(theme, MUIThemeColors.white)}
            borderRadius={'4px'}
            height={window.innerHeight / 3}
          >
            <MainComponentHistoriesTable
              mainComponentId={mainComponentId as EntityId}
              customerId={customerId as EntityId}
              localityId={localityId || (data?.selectedLocalityId as EntityId)}
              mooringId={data?.position?.mooringId as EntityId}
              enablePagination={false}
              height={window.innerHeight / 3}
              pop={pop}
            />
          </Box>
        </CommonPageLayout>
      </CommonPageLayout>
    )
  return <></>
}

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.black[theme.palette.mode],
  fontSize: '24px',
  fontWeight: 600,
}))
