import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { LocalitiesRoutes } from './LocalitiesRoutes'
import { AppRoutesRouter } from '../../app-routes'
import { UserContextProvider } from '../../context/UserContext'
import { MapScreen } from '../../screens/map/MapScreen'
import { BoatsScreen } from '../../screens/boats/BoatsScreen'
import { CustomerAdminRoutes } from './CustomerAdminRoutes'
import { ServiceStationRoutes } from './ServiceStationRoutes'
import { Box } from '@mui/material'
import LocalityMainPage from '../../screens/localities/LocalityMainPage'
import ServiceStationMainPage from '../../screens/serviceStations/ServiceStationMainPage'
import { useEffect } from 'react'
import { closeAllDialog } from '../../show-dialog'
import Layout from '../MainLayout'
import { AdminRoutes } from './AdminRoutes'
import { IdentityServerUserContextProvider } from 'src/context/IdentityServerUserContext'
import { UserProfileScreen } from 'src/screens/UserProfileScreen'
import { DialogContextProvider as DialogContextProviderCommon } from '@lovoldsystem/core.client'
import { DialogContextProvider } from 'src/context/DialogContext'

export const MainRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    closeAllDialog()
  }, [location])

  return (
    <IdentityServerUserContextProvider>
      <UserContextProvider>
        <DialogContextProviderCommon>
          <DialogContextProvider>
            <Layout>
              <Routes>
                <Route path={AppRoutesRouter.Profile} element={<UserProfileScreen />} />
                <Route path={AppRoutesRouter.Localities.main} element={MainAppBarHOC(LocalityMainPage)} />
                <Route path={AppRoutesRouter.serviceStation.main} element={MainAppBarHOC(ServiceStationMainPage)} />
                <Route path="*" element={<SubRoutes />} />
              </Routes>
            </Layout>
          </DialogContextProvider>
        </DialogContextProviderCommon>
      </UserContextProvider>
    </IdentityServerUserContextProvider>
  )
}

const MainAppBarHOC = (Content) => {
  return <Box className="flex w-full h-full">
    <Content />
  </Box >
}

const SubRoutes = () => {
  return <Box className="flex w-full">
    <Routes>
      <Route path={AppRoutesRouter.Localities.basePath} element={<LocalitiesRoutes />} />
      <Route path={AppRoutesRouter.Home} element={<LocalitiesRoutes />} />
      <Route path={AppRoutesRouter.Customer.basePath} element={<CustomerAdminRoutes />} />
      <Route path={AppRoutesRouter.serviceStation.basePath} element={<ServiceStationRoutes />} />
      <Route path={AppRoutesRouter.Boats} element={<BoatsScreen />} />
      <Route path={AppRoutesRouter.Map} element={<MapScreen />} />
      <Route path={AppRoutesRouter.Admin.basePath} element={<AdminRoutes />} />
      <Route path="*" element={<Navigate to={AppRoutesRouter.Home} />} />
    </Routes>
  </Box>

}
