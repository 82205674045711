import { IAuditLog } from '../generated-types/audit-logs'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { useGet } from './reactQuery'

const baseUrl = () => 'admin/audits'

export const auditEndpoints = {
  getAllAudits() {
    return `${baseUrl()}`
  },
  getOwnAudits() {
    return `${baseUrl()}/current-user`
  },
  deleteAudits() {
    return `${baseUrl()}`
  },
}

export const invalidateDocumentCategoryCache = {
  getAllAudits() {
    queryClient.invalidateQueries(auditEndpoints.getAllAudits())
  },
  getOwnAudits() {
    queryClient.invalidateQueries(auditEndpoints.getOwnAudits())
  }
}

export const useGetAllAudits = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IAuditLog[]>(auditEndpoints.getAllAudits(), { page, pageSize })
