import { ICreateProduct, IProduct } from '../generated-types'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/products'
const baseBySupplierIdUrl = (supplierId: EntityId) => `admin/products/GetBySupplierId/${supplierId}`

export const productsEndpoints = {
  getProducts(searchInput?: string) {
    return `${baseUrl()}?searchInput=${searchInput ?? ''}`
  },
  getProduct(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  getProductsBySupplierId(supplierId: EntityId) {
    return `${baseBySupplierIdUrl(supplierId)}`
  },
  createProduct() {
    return `${baseUrl()}`
  },
  updateProduct(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  deleteProduct() {
    return `${baseUrl()}`
  },
}

export const invalidateProductCache = {
  getProducts(searchInput?: string, page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) {
    queryClient.invalidateQueries(productsEndpoints.getProducts(searchInput))
  },
  getProduct(id: EntityId) {
    queryClient.invalidateQueries(productsEndpoints.getProduct(id))
  },
}

export const useGetProducts = (
  searchInput?: string,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) => useGet<IPaginatedResult<IProduct>>(productsEndpoints.getProducts(searchInput), { page, pageSize })

export const useGetProduct = (id: EntityId) => useGet<IProduct>(productsEndpoints.getProduct(id))

export const useAddProduct = () => usePost<IProduct, ICreateProduct>(productsEndpoints.createProduct())

export const useGetProductsBySupplierId = (
  supplierId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) => useGet<IPaginatedResult<IProduct>>(productsEndpoints.getProductsBySupplierId(supplierId), { page, pageSize })

export const useUpdateProduct = (id: EntityId) => usePut<IProduct, IProduct>(productsEndpoints.updateProduct(id))

export const useDeleteProduct = () => useDelete<IProduct>(productsEndpoints.deleteProduct())
