import {
  GridActionsCellItem,
  GridColumns,
  GridNativeColTypes,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdEdit } from 'react-icons/md'
import GenericTable from '../../../components/table/GenericTable'
import { GenericToggle } from '../../../components/GenericToggle'
import { QueryKeyT } from '@lovoldsystem/core.client'

interface LocalitiesTableProps {
  getDetailPanelContent?: (params: any) => JSX.Element
  queryUrl: string
  generateQueryKey?: (page: number, pageSize: number) => QueryKeyT | undefined
  queryParams?: object
  onUpdate?: (locality: any) => void
}

export const LocalitiesTable: React.FC<LocalitiesTableProps> = ({
  getDetailPanelContent,
  queryUrl,
  queryParams,
  generateQueryKey,
  onUpdate,
}) => {
  const renderActions = useCallback(
    ({ row }) => {
      const actions: JSX.Element[] = []
      if (onUpdate) {
        actions.push(
          <GridActionsCellItem
            key={row.id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate && onUpdate(row)}
            color="primary"
            showInMenu
          />,
        )
      }
      return actions
    },
    [onUpdate],
  )

  const { t: translate } = useTranslation(['admin-locality', 'common'])
  const STATIC_COLUMNS: GridColumns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      renderCell: params => (
        <GenericToggle
          id={params.id}
          value={params.value}
          expandTitle={translate('view-locality', { ns: 'locality' })}
        />
      ),
    },
    {
      field: 'name',
      headerName: translate('name', { ns: 'common' }),
      flex: 1,
      cellClassName: 'font-weight-semi-bold',
    },
    {
      field: 'locationNr',
      headerName: translate('locationNr', { ns: 'admin-locality' }),
      flex: 1,
    },
    {
      field: 'latitude',
      headerName: translate('latitude', { ns: 'common' }),
      flex: 1,
    },
    {
      field: 'longitude',
      headerName: translate('longitude', { ns: 'common' }),
      flex: 1,
    },
    {
      field: 'active',
      headerName: translate('active', { ns: 'common' }),
      flex: 1,
      renderCell: ({ value: active }) => {
        return active ? translate('yes', { ns: 'common' }) : translate('no', { ns: 'common' })
      },
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: '',
        width: 60,
        getActions: renderActions,
      },
    ],
    [],
  )

  return (
    <GenericTable
      queryUrl={queryUrl}
      queryParams={queryParams}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
      columns={columns.filter(col => onUpdate || col.field !== 'actions')}
      id={'localities-table'}
      enableToolbar={true}
      enableServerPagination
      generateQueryKey={generateQueryKey}
      autoHeight={false}
      getRowHeight={() => 50}
      height={'81vh'}
    />
  )
}
