import { useEffect } from 'react'
import { userManager } from '../user-manager'

export const LoginScreen = () => {
  useEffect(() => {
    userManager.signinRedirect()
  }, [])

  return null
}
