import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { z } from 'zod'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps, FormCheckbox } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { CancelButton } from '../../../components/CancelButton'

export const DistributorForm: React.FC<AppFormProps> = ({ onSubmit, onCancel, initialValues }) => {
  const { t: translate } = useTranslation(['distributor', 'common'])
  const isUpdateForm = initialValues?.id

  const schema = z.object({
    name: z.string().nonempty(translate('nameIsRequired', { ns: 'distributor' })),
    userName: z.string().nonempty(translate('userNameIsRequired', { ns: 'distributor' })),
    status: z.boolean(),
  })

  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  const handleSubmit = async form => {
    if (isUpdateForm) {
      form.id = initialValues.id
    }
    onSubmit(form)
  }

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={initialValues}>
      {isUpdateForm && <FormTextField name="id" type="hidden" />}
      <FormTextField name="name" label={translate('name', { ns: 'distributor' })} />
      <FormTextField name="userName" label={translate('userName', { ns: 'distributor' })} />
      <FormCheckbox name="status" label={translate('isEnabled', { ns: 'distributor' })} defaultValue={true} />

      <Box className="flex justify-end gap-4 pt-4">
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}
