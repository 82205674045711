import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CollectionFormFieldList, DefaultValueIncludedFieldList, FormField, IOption, NotFieldTypes, formFieldsDefaultValues } from '../../../generated-types/form-field'
import { Box, Checkbox, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select, styled, TextField, Typography } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface IFormValidation {
    selectedSetting: FormField
    changeSetting: (value: any) => void
    onDelete: () => void
}

const initialOption = { label: '', value: '' }

const FormValidation: React.FC<IFormValidation> = ({ selectedSetting, changeSetting, onDelete }) => {
    const [option, setOption] = useState<IOption>(initialOption)
    const { t: translate } = useTranslation(['form-schema', 'common'])
    const requiredRef = useRef(null)
    const disabledRef = useRef(null)
    const onChange = useCallback((value, fieldName) => {
        const fieldValues = selectedSetting
        fieldValues[fieldName] = value
        changeSetting(fieldValues)
    }, [selectedSetting])

    useEffect(() => {
        if (requiredRef?.current) {
            const element = requiredRef?.current as Element
            element.getElementsByClassName('PrivateSwitchBase-input')[0]['checked'] = selectedSetting?.isRequired
        }
    }, [selectedSetting?.identifier])

    useEffect(() => {
        if (disabledRef?.current) {
            const element = disabledRef?.current as Element
            element.getElementsByClassName('PrivateSwitchBase-input')[0]['checked'] = selectedSetting?.disabled
        }
    }, [selectedSetting?.identifier, disabledRef])

    return (selectedSetting ?
        <Box padding={1} >
            <Box marginTop={1} padding={1} >
                <Typography color={'gray'} variant='subtitle2' borderBottom={'1px solid gray'} paddingBottom={1}>
                    {translate('general-settings')}
                </Typography>
                <Box paddingTop={2}>
                    <TextField value={selectedSetting?.name} label={translate('name', { ns: 'common' })} variant='outlined' onChange={(evt) => onChange(evt.target.value, 'name')} />
                </Box>
                {DefaultValueIncludedFieldList.includes(selectedSetting.type) &&
                    <>
                        <Box marginTop={1}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{translate('default-values')}</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={translate('default-values')}
                                    value={selectedSetting?.defaultValue}
                                    onChange={(evt) => {
                                        onChange(evt.target.value, 'defaultValue')
                                    }}
                                >
                                    {/* <MenuItem value={''}>--{translate('none', { ns: 'form-schema' })}--</MenuItem>*/}
                                    {/* <ListSubheader ><Typography fontSize={16} borderBottom={'1px solid gray'} marginTop={1}>{translate('facility', { ns: 'form-schema' })}</Typography></ListSubheader>
                                    <MenuItem value={formFieldsDefaultValues.facilityName}><Typography fontSize={14}>{translate('name', { ns: 'common' })}</Typography></MenuItem> */}
                                    <ListSubheader><Typography fontSize={16} borderBottom={'1px solid gray'} marginTop={1}>{translate('site-location', { ns: 'form-schema' })}</Typography></ListSubheader>
                                    <MenuItem value={formFieldsDefaultValues.siteLocationName}><Typography fontSize={14}>{translate('name', { ns: 'common' })}</Typography></MenuItem>
                                    <MenuItem value={formFieldsDefaultValues.siteNumber}><Typography fontSize={14}>{translate('site-number', { ns: 'form-schema' })}</Typography></MenuItem>
                                    <ListSubheader><Typography fontSize={16} borderBottom={'1px solid gray'} marginTop={1}>{translate('resources', { ns: 'form-schema' })}</Typography></ListSubheader>
                                    {/* <MenuItem value={formFieldsDefaultValues.productionNumber}>{translate('productionNumber', { ns: 'form-schema' })}</MenuItem> */}
                                    <MenuItem value={formFieldsDefaultValues.orderNumber}><Typography fontSize={14}>{translate('orderNumber', { ns: 'form-schema' })}</Typography></MenuItem>
                                    <MenuItem value={formFieldsDefaultValues.netType}><Typography fontSize={14}>{translate('component-type')}</Typography></MenuItem>
                                    {/* <MenuItem value={formFieldsDefaultValues.netFunction}>{translate('net-function')}</MenuItem> */}
                                    {/* <MenuItem value={formFieldsDefaultValues.perimeter}>{translate('perimeter')}</MenuItem> */}
                                    {/* <MenuItem value={formFieldsDefaultValues.cagePlacement}>{translate('cage-placement')}</MenuItem> */}
                                    <MenuItem value={formFieldsDefaultValues.resourcesName}><Typography fontSize={14}>{translate('name', { ns: 'common' })}</Typography></MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} marginTop={1} >
                            <Checkbox
                                ref={disabledRef}
                                defaultChecked={selectedSetting?.disabled}
                                checked={selectedSetting?.disabled ?? false}
                                onChange={(evt) => {
                                    onChange(evt.target.checked, 'disabled')
                                }}
                            />
                            <Box marginTop={1} >
                                <Typography>{translate('write-protected')}</Typography>
                            </Box>
                        </Box>
                    </>
                }
            </Box>
            {
                !NotFieldTypes.includes(selectedSetting.type) &&
                <Box marginTop={1} padding={1} >
                    <Typography color={'gray'} variant='subtitle2' borderBottom={'1px solid gray'} paddingBottom={1}>{translate('validations')}</Typography>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Checkbox
                            ref={requiredRef}
                            defaultChecked={selectedSetting?.isRequired}
                            checked={selectedSetting?.isRequired ?? false}
                            onChange={(evt) => onChange(evt.target.checked, 'isRequired')}
                        />
                        <Box paddingTop={1}>
                            <Typography>{translate('is-required')}</Typography>
                        </Box>
                    </Box>
                </Box>
            }
            {CollectionFormFieldList.includes(selectedSetting.type) &&
                <Box marginTop={1}  >
                    <Typography color={'gray'} variant='subtitle2' borderBottom={'1px solid gray'} paddingBottom={1} marginBottom={1}>{translate('options')}</Typography>
                    {selectedSetting.options?.map((option: IOption, index: number) => {
                        return <Grid container key={index + option.value} md={12}>
                            <Grid item sm={5}>
                                <StyledTextField value={option.label} placeholder={translate('label')} onChange={(evt) => {
                                    const optionList = selectedSetting.options || []
                                    if (optionList[index].label == optionList[index].value) {
                                        optionList[index] = { label: evt.target.value, value: evt.target.value }
                                    } else {
                                        optionList[index] = { label: evt.target.value, value: optionList[index].value }
                                    }
                                    onChange(optionList, 'options')
                                }} />
                            </Grid>
                            <Grid item sm={5}>
                                <TextField value={option.value} placeholder={translate('value')} onChange={(evt) => {
                                    const optionList = selectedSetting.options || []
                                    optionList[index] = { value: evt.target.value, label: optionList[index].label }
                                    onChange(optionList, 'options')
                                }} />
                            </Grid>
                            <Grid item sm={2}>
                                <Box marginTop={2} marginLeft={1}>
                                    <Delete color='error' style={{ cursor: 'pointer' }} onClick={(evt) => {
                                        const optionList = selectedSetting.options || []
                                        optionList.splice(index, 1)
                                        onChange(optionList, 'options')
                                    }} />
                                </Box>
                            </Grid>
                        </Grid>
                    })}
                    <Grid container md={12}>
                        <Grid item sm={5}>
                            <TextField value={option.label} placeholder={translate('label')} onChange={(evt) => {
                                if (option.label == option.value) {
                                    setOption({ label: evt.target.value, value: evt.target.value })
                                } else {
                                    setOption({ ...option, ...{ label: evt.target.value } })
                                }
                            }} />
                        </Grid>
                        <Grid item sm={5}>
                            <TextField value={option.value} placeholder={translate('value')} onChange={(evt) => {
                                setOption({ ...option, ...{ value: evt.target.value } })
                            }} />
                        </Grid>
                        <Grid item sm={2}>
                            <Box marginTop={2} marginLeft={1}>
                                <Add onClick={() => {
                                    const optionList = selectedSetting.options || []
                                    if (option.label == '') {
                                        toast.error(translate('optionsAreEmpty'))
                                    } else if (optionList.findIndex(o => o.label == option.label) != -1) {
                                        toast.error(translate('duplicateOption'))
                                    } else {
                                        optionList?.push(option)
                                        setOption(initialOption)
                                        onChange(optionList, 'options')
                                    }
                                }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={1} marginTop={1} border={'1px solid red'} borderRadius={1} onClick={() => {
                onDelete()
            }} >
                <Delete color='warning' />
            </Box>
        </Box> : <></>
    )
}

const StyledTextField = styled(TextField)({
    '&.MuiOutlinedInput-input': {
      padding: '1px 1px',
      fontSize: '10px',
      backgroundColor: '',
    },
})

export default FormValidation