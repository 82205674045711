import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { padding } from './paddingCss'

export const Padding = {
  p1: styled(Box)`
        ${padding.p1}
    `,
  p2: styled(Box)`
        ${padding.p2}`,
  p4: styled(Box)`
        ${padding.p4}`,
  p6: styled(Box)`
         ${padding.p6}`,
  p8: styled(Box)`
      ${padding.p8}`,
  p10: styled(Box)`
        ${padding.p10}`,
  p12: styled(Box)`
        ${padding.p12}`,
  p14: styled(Box)`
        ${padding.p14}`,
  p16: styled(Box)`
      ${padding.p16}`,
  p22: styled(Box)`
      ${padding.p22}`,
  pt1: styled(Box)`
       ${padding.pt1}`,
  pt2: styled(Box)`
       ${padding.pt2}`,
  pt4: styled(Box)`
       ${padding.pt4}`,
  pt6: styled(Box)`
        ${padding.pt6}`,
  pt8: styled(Box)`
     ${padding.pt8}`,
  pt10: styled(Box)`
       ${padding.pt10}`,
  pt12: styled(Box)`
       ${padding.pt12}`,
  pt14: styled(Box)`
       ${padding.pt14}`,
  pt16: styled(Box)`
     ${padding.pt16}`,
  pb1: styled(Box)`
     ${padding.pb1}`,
  pb2: styled(Box)`
     ${padding.pb2}`,
  pb4: styled(Box)`
     ${padding.pb4}`,
  pb6: styled(Box)`
      ${padding.pb6}`,
  pb8: styled(Box)`
   ${padding.pb8}`,
  pb10: styled(Box)`
     ${padding.pb10}`,
  pb12: styled(Box)`
     ${padding.pb12}`,
  pb14: styled(Box)`
     ${padding.pb14}`,
  pb16: styled(Box)`
   ${padding.pb16}`,
  pb18: styled(Box)`
   ${padding.pb18}`,
  pl1: styled(Box)`
     ${padding.pl1}`,
  pl2: styled(Box)`
     ${padding.pl2}`,
  pl4: styled(Box)`
     ${padding.pl4}`,
  pl6: styled(Box)`
      ${padding.pl6}`,
  pl8: styled(Box)`
   ${padding.pl8}`,
  pl10: styled(Box)`
     ${padding.pl10}`,
  pl12: styled(Box)`
     ${padding.pl12}`,
  pl14: styled(Box)`
     ${padding.pl14}`,
  pl16: styled(Box)`
   ${padding.pl16}`,
  pr1: styled(Box)`
   ${padding.pr1}`,
  pr2: styled(Box)`
   ${padding.pr2}`,
  pr4: styled(Box)`
   ${padding.pr4}`,
  pr6: styled(Box)`
   ${padding.pr6}`,
  pr8: styled(Box)`
   ${padding.pr8}`,
  pr10: styled(Box)`
   ${padding.pr10}`,
  pr12: styled(Box)`
   ${padding.pr12}`,
  pr14: styled(Box)`
   ${padding.pr14}`,
  pr16: styled(Box)`
   ${padding.pr16}`,
  pv1: styled(Box)`
   ${padding.pt1}
   ${padding.pb1}`,
  pv2: styled(Box)`
   ${padding.pt2}
   ${padding.pb2}`,
  pv4: styled(Box)`
   ${padding.pt4}
   ${padding.pb4}`,
  pv6: styled(Box)`
   ${padding.pt6}
   ${padding.pb6}`,
  pv8: styled(Box)`
   ${padding.pt8}
   ${padding.pb8}`,
  pv10: styled(Box)`
   ${padding.pt10}
   ${padding.pb10}`,
  pv12: styled(Box)`
   ${padding.pt12}
   ${padding.pb12}`,
  pv14: styled(Box)`
   ${padding.pt14}
   ${padding.pb14}`,
  pv16: styled(Box)`
   ${padding.pt16}
   ${padding.pb16}`,
  ph1: styled(Box)`
   ${padding.pl1}
   ${padding.pr1}`,
  ph2: styled(Box)`
   ${padding.pl2}
   ${padding.pr2}`,
  ph4: styled(Box)`
   ${padding.pl4}
   ${padding.pr4}`,
  ph6: styled(Box)`
   ${padding.pl6}
   ${padding.pr6}`,
  ph8: styled(Box)`
   ${padding.pl8}
   ${padding.pr8}`,
  ph10: styled(Box)`
   ${padding.pl10}
   ${padding.pr10}`,
  ph12: styled(Box)`
   ${padding.pl12}
   ${padding.pr12}`,
  ph14: styled(Box)`
   ${padding.pl14}
   ${padding.pr14}`,
  ph16: styled(Box)`
   ${padding.pl16}
   ${padding.pr16}`,
}