import { ICertificate } from '../generated-types/Certificate'
import { ICertificateExtension, ICreateCertificateExtension } from '../generated-types/CertificateExtension'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useGet, usePost } from './reactQuery'

const baseUrl = (certificateId: EntityId) => `certificate/${certificateId}`

export const certificateEndpoints = {
    getCertificateExtensions(certificateId: EntityId) {
        return `${baseUrl(certificateId)}/getAllByMainComponent`
    },
    createCertificateExtension(certificateId: EntityId) {
        return `${baseUrl(certificateId)}`
    }
}
export const invalidateCertificateExtensionCache = {
    getCertificateExtensions(certificateId: EntityId) {
    queryClient.invalidateQueries(certificateEndpoints.getCertificateExtensions(certificateId))
  },
}
export interface IGetCertificateExtensionParams {
    certificateId?: EntityId,
}

export const useGetCertificates = (certificateId: EntityId, params: IGetCertificateExtensionParams,
    page = 0, pageSize = 1000) =>
    useGet<IPaginatedResult<ICertificate>>(certificateEndpoints.getCertificateExtensions(certificateId), {
        ...params,
        page,
        pageSize
    })

export const useCreateCertificateExtension = (certificateId: EntityId) =>
    usePost<ICertificateExtension, ICreateCertificateExtension>(certificateEndpoints.createCertificateExtension(certificateId))