import { CustomPropertyForm } from './CustomPropertyForm'
import {
  invalidateCustomPropertyCache,
  useAddCustomPropertyByAdmin,
  useAddCustomPropertyByCustomer,
} from '../../../api/custom-property'
import { useTranslation } from 'react-i18next'
import { ShowDialogProps } from '../../../show-dialog'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { toast } from 'react-toastify'

interface CreateCustomPropertyProps extends ShowDialogProps {
  isAdmin: boolean
  customerId: EntityId
}

interface CreateCustomPropertyCustomerProps extends ShowDialogProps {
  customerId: EntityId
}

export const CreateCustomPropertyDialogCustomer: React.FC<CreateCustomPropertyCustomerProps> = ({
  pop,
  customerId,
}) => {
  const { t: translate } = useTranslation(['custom-property', 'common', 'common-api-message'])
  const mutation = useAddCustomPropertyByCustomer(customerId)
  const onSubmit = async form => {
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateCustomPropertyCache.useGetCustomPropertiesCustomer(customerId)
        toast.success(translate('custom-property-added-successfully'))
        pop(true)
      },
      onError: () => {
        toast.error(translate('addingError', { ns: 'common' }))
      },
    })
  }

  return <GenericCustomPropertyFormDialog title={translate('addCustomProperty')} pop={pop} onSubmit={onSubmit} />
}

export const CreateCustomPropertyDialogAdmin: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: translate } = useTranslation(['custom-property', 'common', 'common-api-message'])
  const mutation = useAddCustomPropertyByAdmin()
  const onSubmit = async form => {
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateCustomPropertyCache.useGetCustomPropertiesAdmin()
        toast.success(translate('custom-property-added-successfully'))
        pop(true)
      },
      onError: () => {
        toast.error(translate('addingError', { ns: 'common' }))
      },
    })
  }

  return <GenericCustomPropertyFormDialog title={translate('addCustomProperty')} pop={pop} onSubmit={onSubmit} />
}

const GenericCustomPropertyFormDialog: React.FC<{ title: string; pop: () => void; onSubmit: (form) => void }> = ({
  title,
  pop,
  onSubmit,
}) => {
  return (
    <GenericDialogLayout title={title} pop={pop}>
      <CustomPropertyForm onSubmit={onSubmit} onCancel={() => pop()} />
    </GenericDialogLayout>
  )
}

export const CreateCustomPropertyDialog: React.FC<CreateCustomPropertyProps> = ({ pop, customerId, isAdmin }) => {
  if (isAdmin) {
    return <CreateCustomPropertyDialogAdmin pop={pop} />
  } else {
    return <CreateCustomPropertyDialogCustomer pop={pop} customerId={customerId} />
  }
}
