import { ICertificate, ICreateCertificate } from '../generated-types/Certificate'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost } from './reactQuery'

const baseUrl = (mainComponentId: EntityId) => `mainComponent/${mainComponentId}`
const baseUrlForNetComponentCertificate = (customerId: EntityId, positionId: EntityId) => `customers/${customerId}/positions/${positionId}/net-certificates`

export const certificateEndpoints = {
    getCertificates(mainComponentId: EntityId) {
        return `${baseUrl(mainComponentId)}/getAllByMainComponent`
    },
    createCertificate(mainComponentId: EntityId) {
        return `${baseUrl(mainComponentId)}`
    },
    deleteCertificate(mainComponentId: EntityId) {
        return `${baseUrl(mainComponentId)}`
    },
    getCertificatesByPosition(customerId: EntityId, positionId: EntityId) {
        return `${baseUrlForNetComponentCertificate(customerId, positionId)}`
    },
}
export const invalidateCertificateCache = {
    getCertificates(mainComponentId: EntityId) {
    queryClient.invalidateQueries(certificateEndpoints.getCertificates(mainComponentId))
  },
  getCertificatesByPosition(customerId: EntityId, positionId: EntityId) {
    queryClient.invalidateQueries(certificateEndpoints.getCertificatesByPosition(customerId, positionId))
  },
}
export interface IGetCertificatesParams {
    mainComponentId?: EntityId,
}

export const useGetCertificates = (mainComponentId: EntityId, params: IGetCertificatesParams,
    page = 0, pageSize = 1000) =>
    useGet<IPaginatedResult<ICertificate>>(certificateEndpoints.getCertificates(mainComponentId), {
        ...params,
        page,
        pageSize
    })

export const useGetCertificatesByPosition = (customerId: EntityId, positionId: EntityId, params: IGetCertificatesParams,
        page = 0, pageSize = 1000) =>
        useGet<IPaginatedResult<ICertificate>>(certificateEndpoints.getCertificatesByPosition(customerId, positionId), {
            ...params,
            page,
            pageSize
        })

export const useAddCertificate = (mainComponentId: EntityId) =>
    usePost<ICertificate, ICreateCertificate>(certificateEndpoints.createCertificate(mainComponentId))

export const useDeleteCertificate = (mainComponentId: EntityId) => 
    useDelete<ICertificate>(certificateEndpoints.deleteCertificate(mainComponentId))