import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { invalidateLocalityCache, useAddLocality } from '../../../api/localities'
import { LocalityForm } from '../../../forms/LocalityForm'
import { handleLocalityErrorResponse } from '../../localities/utils'
import { ILocality } from '../../../generated-types'
import { ShowDialogProps } from '../../../show-dialog'

interface CreateLocalitiesProps extends ShowDialogProps {
  customerId: EntityId
}

export const CreateLocalitiesDialog: React.FC<CreateLocalitiesProps> = ({ pop, customerId }) => {
  const { t: translate } = useTranslation(['admin-locality', 'common'])
  const { mutateAsync, isLoading } = useAddLocality(customerId)

  const onSubmit = async (form: ILocality) => {
    await mutateAsync(form, {
      onSuccess: () => {
        toast.success(translate('addedSuccessfully', { ns: 'common' }))
        invalidateLocalityCache.getLocalities(customerId)
        pop()
      },
      onError: (error:any) => {
        handleLocalityErrorResponse(error.data)
      },
    })
  }

  return (
    <LocalityForm
      title={translate('addLocality', { ns: 'admin-locality' })}
      isLoading={isLoading}
      onSubmit={onSubmit}
      customerId={customerId as number}
      onCancel={() => pop()}
    />
  )
}
