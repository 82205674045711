import { Box } from '@mui/material'
import { GlobalDocumentRulesTable } from './GlobalDocumentsRulesTable'
import { ShowDialogProps } from '../../../show-dialog'
import { useGetGlobalDocumentRules } from '../../../api/global-documents'
import { IGlobalDocumentRule } from '../../../generated-types/global-documents'

export interface GlobalDocumentRuleContainerDialogProps extends ShowDialogProps {
  globalDocumentId: EntityId
  customerId?: EntityId | undefined
  isViewOnly?: boolean
}

export const GlobalDocumentRuleContainerDialog: React.FC<GlobalDocumentRuleContainerDialogProps> = ({
  globalDocumentId,
  customerId,
  isViewOnly,
}) => {
  const { data: globalDocumentRules } = useGetGlobalDocumentRules(globalDocumentId as EntityId, { customerId })
  return (
    <Box>
      <GlobalDocumentRulesTable
        customerId={customerId as EntityId}
        globalDocumentId={globalDocumentId as EntityId}
        globalDocumentRules={globalDocumentRules as IGlobalDocumentRule[]}
        isViewOnly={isViewOnly}
      />
    </Box>
  )
}
