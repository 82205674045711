
import { IDocumentCategory, IDocumentCategoryInfoDto, IDocumentCategoryRequestDto } from '../generated-types'
import { queryClient } from '../query-client'
import { DocumentCategoryKeys } from '../query-keys'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/documentcategories'
const userBaseUrl = () => '/user/document-categories'

export const documentCategoryEndpoints = {
  getDocumentCategories() {
    return `${baseUrl()}`
  },
  getUserDocumentCategories() { 
    return `${userBaseUrl()}`
  },
  getUserDocumentCategoriesWithDocuments() {
    return `${userBaseUrl()}/with-documents`
  },
  getDocumentCategory(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  createDocumentCategory() {
    return `${baseUrl()}`
  },
  updateDocumentCategory(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  deleteDocumentCategory() {
    return `${baseUrl()}`
  },
}

export const invalidateDocumentCategoryCache = {
  useGetDocumentCategories() {
    queryClient.invalidateQueries(documentCategoryEndpoints.getDocumentCategories())
    queryClient.invalidateQueries(documentCategoryEndpoints.getUserDocumentCategories())
  },
  getDocumentCategory(id: EntityId) {
    queryClient.invalidateQueries(documentCategoryEndpoints.getDocumentCategory(id))
  },
  getDocumentCategoriesWithDocuments() {
    queryClient.invalidateQueries(documentCategoryEndpoints.getUserDocumentCategoriesWithDocuments())
  },
  getDocumentCategoriesWithDocumentCountByKey() {
    queryClient.invalidateQueries(DocumentCategoryKeys.GET_DOCUMENT_CATEGORIES_FOR_DOCUMENTS())
  },
}

export const useAdminGetDocumentCategories = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IDocumentCategory>>(documentCategoryEndpoints.getDocumentCategories(), { page, pageSize })

export const useUserGetDocumentCategories = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IDocumentCategory>>(documentCategoryEndpoints.getUserDocumentCategories(), { page, pageSize })

  export const useUserGetDocumentCategoriesWithDocuments = (queryParams: IDocumentCategoryRequestDto) =>
    useGet<Array<IDocumentCategoryInfoDto>>(documentCategoryEndpoints.getUserDocumentCategoriesWithDocuments(), queryParams, {
      queryKey: DocumentCategoryKeys.GET_DOCUMENT_CATEGORIES_FOR_DOCUMENTS(),
    })

export const useGetDocumentCategory = (id: EntityId) =>
  useGet<IDocumentCategory>(documentCategoryEndpoints.getDocumentCategory(id))

export const useAddDocumentCategory = () =>
  usePost<IDocumentCategory, IDocumentCategory>(documentCategoryEndpoints.createDocumentCategory())

export const useUpdateDocumentCategory = (id: EntityId) =>
  usePut<IDocumentCategory, IDocumentCategory>(documentCategoryEndpoints.updateDocumentCategory(id))

export const useDeleteDocumentCategory = () => useDelete<IDocumentCategory>(documentCategoryEndpoints.deleteDocumentCategory())
