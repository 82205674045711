import { Box, Card } from '@mui/material'
import React, { ReactElement } from 'react'
interface IDraggableField {
    name: string
    value: number
    onDrag: (value: number) => void
    icon: ReactElement
}

const DraggableField: React.FC<IDraggableField> = ({ name, value, onDrag, icon }) => {
    return (
        <Box width={190} style={{ cursor: 'pointer', border: '1px solid #d7eaf0' }} color='#0065bb'
            height={40} component={Card} padding={1} marginTop={1}
            display={'flex'} flexDirection={'row'}
            draggable={true} onDragStart={() => onDrag(value)}>
            <Box width={40}>
                {icon}
            </Box>
            {name}
        </Box>
    )
}

export default DraggableField