import { Grid, useMediaQuery, styled, useTheme, Box } from '@mui/material'
import React, { Suspense, useEffect, useMemo } from 'react'
import { ILocality, IMooring, IMooringType, IPosition } from '../../generated-types'
import { Flex } from 'src/styles/flexComponent'
import { useParams, useSearchParams } from 'react-router-dom'
import { VesselCardContainer } from 'src/screens/localities/dashboard/vessel/VesselCardContainer'
import { LocalityDetailsCard } from 'src/screens/localities/locality-dashboard/InformationCards/LocalityDetailsCard'
import WeatherMinimalCard from 'src/screens/localities/locality-dashboard/InformationCards/WeatherMinimalCard'
import { DeviationCountCard } from 'src/screens/localities/moorings/InformationCards/DeviationCountCard'
import { LatLngExpression } from 'leaflet'
import { MapComponent } from 'src/screens/localities/components/map/MapComponent'
import { CenteredSpinner, Spinner } from 'src/components/Spinner'
import { EmptyStatusCard } from 'src/components/mooring/EmptyStatusCard'
import { useTranslation } from 'react-i18next'
import NoCageAssigned2x from '../../assets/NoCageAssigned@2x.png'
import NoCageAssigned from '../../assets/NoCageAssigned.png'

export const PositionSmallHeader: React.FC<{
    locality: ILocality,
    position?: IPosition,
    mooringDetails: IMooring,
    loading?: boolean
}> = ({ locality, position, mooringDetails, loading }) => {
    const theme = useTheme()
    const { localityId, customerId, mooringId, mainComponentId } = useParams()
    const [searchParams] = useSearchParams()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { t } = useTranslation(['cage-status', 'common'])
    const NoCageAssignedImage = isMobile ? NoCageAssigned : NoCageAssigned2x

    useEffect(() => {
      if (!localityId && locality) {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('localityId', locality.id.toString())
        const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`
        window.history.replaceState(null, '', newUrl)
      }
    }, [localityId, locality, searchParams])
    
    const centerLat = useMemo(() => {
        return ((position?.connectedPositions[0]?.latitude ?? 0) + (position?.connectedPositions[1]?.latitude ?? 0)) / 2
    }, [position])

    const centerLon = useMemo(() => {
        return ((position?.connectedPositions[0]?.longitude ?? 0) + (position?.connectedPositions[1]?.longitude ?? 0)) / 2
    }, [position])

    if (loading && !mooringDetails && !position) return <CenteredSpinner />
    return (
      <Suspense fallback={<CenteredSpinner />}>
        {locality ? (
          <StyledGrid columnGap={2} className="pb-4-core pt-4-core pr-2-core" height="fit-content">
            <Flex.Row width={isMobile ? '100%' : '50%'} columnGap={2}>
              <Grid item xs={isMobile ? 5 : 6}>
                {locality && <LocalityDetailsCard selectedLocality={locality} {...locality} removeActions />}
              </Grid>
              <Grid item xs={isMobile ? 5 : 6}>
                <VesselCardContainer />
              </Grid>
              {isMobile && (
                <Grid item xs={2}>
                  <WeatherMinimalCard lat={locality?.latitude ?? 0} lon={locality?.longitude ?? 0} />
                </Grid>
              )}
            </Flex.Row>
            {!isMobile && (
              <Flex.Row width={'50%'} columnGap={2}>
                <Grid item xs={2}>
                  <WeatherMinimalCard lat={locality?.latitude ?? 0} lon={locality?.longitude ?? 0} />
                </Grid>
                <Grid item xs={6}>
                  {!position ? (
                    <ShadowBox borderRadius={'4px'} maxHeight={180}>
                      <EmptyStatusCard
                        height="180px"
                        image={NoCageAssignedImage}
                        header={t('no-cage-assigned', { ns: 'cage-status' })}
                        content={t('no-cage-assigned-description', { ns: 'cage-status' })}
                        imageWidth="150px"
                        headerFontSize="13px"
                        contentFontSize="10px"
                        imageBottomMargin="-25px"
                        imageTopMargin="-25px"
                        contentLineHeight="1.3"
                        contentTopMargin="10px"
                      />
                    </ShadowBox>
                  ) : mooringDetails ? (
                    <MapComponent
                      zoom={17}
                      positions={mooringDetails?.positions}
                      center={[centerLat, centerLon] as LatLngExpression}
                      mooringType={mooringDetails?.type as IMooringType}
                      layerControl={false}
                      zoomControl={false}
                      attributionControl={false}
                    />
                  ) : (
                    <Box maxHeight={200} width={'100%'} padding={9} paddingLeft={20}>
                      <Spinner />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <DeviationCountCard
                    customerId={customerId as EntityId}
                    localityId={localityId as EntityId}
                    selectedMooringId={mooringId}
                    positionId={position?.id}
                    mainComponentId={mainComponentId as EntityId}
                  />
                </Grid>
              </Flex.Row>
            )}
          </StyledGrid>
        ) : (
          <></>
        )}
      </Suspense>
    )
}

const StyledGrid = styled(Flex.Row)`
@media screen and (max-width: 600px) {
  width: 875px;
}
@media screen and (min-width: 600px) and (max-width: 1500px) {
  width: 1500px;
}
@media screen and (min-width: 1500px) {
  width: 100%;
}`

const ShadowBox = styled(Box)(({theme})=>({
boxShadow: theme.shadows[5],
}))

export default PositionSmallHeader