import { useTranslation } from 'react-i18next'
import {
  invalidateCustomPropertyCache,
  useGetCustomPropertyByAdmin,
  useGetCustomPropertyByCustomer,
  useUpdateCustomPropertyByAdmin,
  useUpdateCustomPropertyByCustomer,
} from '../../../api/custom-property'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { CenteredSpinner } from '../../../components/Spinner'
import { ICustomProperty } from '../../../generated-types/customProperty'
import { ShowDialogProps } from '../../../show-dialog'
import { CustomPropertyForm } from './CustomPropertyForm'
import { toast } from 'react-toastify'

interface UpdateCustomPropertyProps extends ShowDialogProps {
  id: EntityId
  isAdmin: boolean
  customerId?: EntityId | any
}
interface UpdateCustomPropertyCustomerProps extends ShowDialogProps {
  id: EntityId
  customerId: EntityId
}
interface UpdateCustomPropertyAdminProps extends ShowDialogProps {
  id: EntityId
}

const UpdateCustomPropertyCustomerDialog: React.FC<UpdateCustomPropertyCustomerProps> = ({ pop, id, customerId }) => {
  const { t: translate } = useTranslation(['custom-property', 'common', 'common-api-message'])
  const mutation = useUpdateCustomPropertyByCustomer(customerId, id as EntityId)

  const onSubmit = async form => {
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateCustomPropertyCache.useGetCustomPropertyCustomer(customerId, id)
        invalidateCustomPropertyCache.useGetCustomPropertiesCustomer(customerId)
        toast.success(translate('custom-property-updated-successfully'))
        pop(true)
      },
      onError: () => {
        toast.error(translate('updatingError', { ns: 'common' }))
      },
    })
  }

  const { data, isLoading } = useGetCustomPropertyByCustomer(customerId, id)
  if (isLoading) return <CenteredSpinner />

  return (
    <GenericUpdateCustomProperty
      title={translate('updateCustomProperty', { ns: 'custom-property' })}
      pop={pop}
      initialValues={data as ICustomProperty}
      onSubmit={onSubmit}
    />
  )
}

const UpdateCustomPropertyAdminDialog: React.FC<UpdateCustomPropertyAdminProps> = ({ pop, id }) => {
  const { t: translate } = useTranslation(['custom-property', 'common', 'common-api-message'])
  const mutation = useUpdateCustomPropertyByAdmin(id as EntityId)

  const onSubmit = async form => {
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateCustomPropertyCache.useGetCustomPropertyAdmin(id)
        invalidateCustomPropertyCache.useGetCustomPropertiesAdmin()
        toast.success(translate('custom-property-updated-successfully'))
        pop(true)
      },
      onError: () => {
        toast.error(translate('updatingError', { ns: 'common' }))
      },
    })
  }

  const { data, isLoading } = useGetCustomPropertyByAdmin(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <GenericUpdateCustomProperty
      title={translate('updateCustomProperty', { ns: 'custom-property' })}
      pop={pop}
      initialValues={data as ICustomProperty}
      onSubmit={onSubmit}
    />
  )
}

const GenericUpdateCustomProperty: React.FC<{
  title: string
  pop: () => void
  initialValues: ICustomProperty
  onSubmit: (form) => void
}> = ({ pop, title, initialValues, onSubmit }) => {
  return (
    <GenericDialogLayout title={title} pop={pop}>
      <CustomPropertyForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={initialValues} />
    </GenericDialogLayout>
  )
}

export const UpdateCustomPropertyDialog: React.FC<UpdateCustomPropertyProps> = ({ pop, id, customerId, isAdmin }) => {
  if (isAdmin) return <UpdateCustomPropertyAdminDialog pop={pop} id={id} />
  else return <UpdateCustomPropertyCustomerDialog pop={pop} id={id} customerId={customerId as EntityId} />
}
