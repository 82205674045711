import { Box } from '@mui/material'
import { DataGridPro, DataGridProProps, GridInitialState, GridRowHeightParams, GridRowHeightReturnValue, GridRowParams, GridSlotsComponent } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiConsts } from '../api/apiConsts'
import { useGet } from '../api/reactQuery'
import { IBaseEntity } from '../generated-types'
import { PaginatedResultList } from '../generated-types/paginated-result-list'

interface Props {
  queryUrl: string
  columns: DataGridProProps['columns']
  onCellClick: any
  initialPage?: number
  initialPageSize?: number
  noRowsMessage?: string
  initialState?: GridInitialState
  isRowHightAuto?: boolean
  components?: Partial<GridSlotsComponent> | undefined
  getDetailPanelContent?: ((params: GridRowParams<any>) => React.ReactNode) | undefined
  queryParams?: object
  getRowHeight?: ((params: GridRowHeightParams) => GridRowHeightReturnValue) | undefined
}

export const DataGridWithServerPagination: React.FC<Props> = ({
  queryUrl,
  columns,
  onCellClick,
  initialPage = 0,
  initialPageSize = apiConsts.PageSize,
  noRowsMessage,
  children,
  initialState,
  isRowHightAuto = false,
  queryParams,
  components,
  getDetailPanelContent,
  getRowHeight,
  ...props
}) => {
  const [page, setPage] = useState(initialPage)
  const [pageSize, setPageSize] = useState(initialPageSize)
  const { data, isLoading } = useGet<PaginatedResultList<IBaseEntity>>(`${queryUrl}`, {
    page,
    pageSize,
    ...queryParams,
  })
  const { t } = useTranslation(['common'])

  let parsedProps = { ...props }

  if (isRowHightAuto) {
    parsedProps = {
      ...props,
      getRowHeight: () => 'auto',
    }
  }

  return (
    <DataGridPro
      sx={{ border: 'none', minHeight: '52px' }}
      className='bg-white'
      onCellClick={cell => {
        if (cell.field !== 'actions') onCellClick(cell)
      }}
      autoHeight
      columns={columns}
      rows={data?.items || []}
      disableColumnFilter
      getDetailPanelContent={getDetailPanelContent}
      pagination
      page={page}
      onPageChange={page => setPage(page)}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[1, 10, 20, 50, 100]}
      rowCount={data?.totalCount || 0}
      paginationMode="server"
      loading={isLoading}
      {...parsedProps}
      initialState={initialState}
      components={{
        ...components, ...{
          NoResultsOverlay: () => (
            <Box className="text-center text-l font-semibold text-gray-600">{noRowsMessage ?? t('noResultFound')}</Box>
          ),
        }
      }}
      getRowHeight={getRowHeight}
    />
  )
}
