import { styled, SxProps, useTheme } from '@mui/material/styles'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowIcon } from 'src/assets/svg/arrow.svg'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

interface StyledAccordionProps {
  accordionHeight?: string
}
const StyledAccordion = styled(Accordion)<StyledAccordionProps>(({ accordionHeight }) => ({
  '& .MuiAccordionSummary-root': {
    height: accordionHeight ?? '',
    minHeight: '32px !important',
    border: '1px solid #E0E0E0',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}))
const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  flexDirection: 'row-reverse',
  height: '45px',
  gap: '4px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
  backgroundColor: 'white',
})

interface Props {
  title: string | ReactElement
  id?: EntityId | number
  iconColor?: MUIThemeColors
  expanded?: boolean
  sx?: SxProps
  onChange?: ((event: React.SyntheticEvent, expanded: boolean) => void)
  accordionHeight?: string
}
const AccordionCommon: React.FC<Props> = ({ title, children, id, iconColor, expanded, sx, onChange, accordionHeight }) => {
  const { t } = useTranslation('document')
  const theme = useTheme()
  return (
    <StyledAccordion key={id} elevation={1} expanded={expanded} onChange={onChange} accordionHeight={accordionHeight}>
      <StyledAccordionSummary
        sx={sx}
        expandIcon={
          <ArrowIcon
            transform="rotate(-90)"
            color={iconColor ? getThemeColor(theme, iconColor) : theme.palette.black[theme.palette.mode]}
          />
        }
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        {title}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default AccordionCommon
