import { IComponent, IUpdateComponentBatchNumbers } from '../generated-types'
import { IProductCertification } from '../generated-types/product-certification'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePatch, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId, positionId: EntityId) =>
  `customers/${customerId}/positions/${positionId}/components`

const baseAddBatchNumberUrl = (customerId: EntityId, positionId: EntityId) =>
  `customers/${customerId}/positions/${positionId}/components/AddBatchNumber`

const baseUpdateInlineValuesUrl = (customerId: EntityId, positionId: EntityId) =>
  `customers/${customerId}/positions/${positionId}/components/update-inline-values`

const baseBulkUpdateInstallationDateUrl = (customerId: EntityId, positionId: EntityId) =>
  `customers/${customerId}/positions/${positionId}/components/update-bulk-installationDate`

const updateBatchNumberBulkUrl = (customerId: EntityId) => `customers/${customerId}/update-batch-numbers-bulk`

export const componentsEndpoints = {
  getComponents(customerId: EntityId, positionId: EntityId) {
    return `${baseUrl(customerId, positionId)}`
  },
  getComponent(customerId: EntityId, positionId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, positionId)}/${id}`
  },
  createComponent(customerId: EntityId, positionId: EntityId) {
    return `${baseUrl(customerId, positionId)}`
  },
  updateComponent(customerId: EntityId, positionId: EntityId) {
    return `${baseUrl(customerId, positionId)}`
  },
  updateComponentInlineValues(customerId: EntityId, positionId: EntityId) {
    return `${baseUpdateInlineValuesUrl(customerId, positionId)}`
  },
  updateInstallationDateBulk(customerId: EntityId, positionId: EntityId) {
    return `${baseBulkUpdateInstallationDateUrl(customerId, positionId)}`
  },
  addBatchNumber(customerId: EntityId, positionId: EntityId, id: EntityId) {
    return `${baseAddBatchNumberUrl(customerId, positionId)}/${id}`
  },
  updateBatchNumberBulk(customerId: EntityId) {
    return `${updateBatchNumberBulkUrl(customerId)}`
  },
  deleteComponent(customerId: EntityId, positionId: EntityId) {
    return `${baseUrl(customerId, positionId)}`
  },
  deleteComponents(customerId: EntityId, positionId: EntityId) {
    return `${baseUrl(customerId, positionId)}/delete-components`
  },
}

export const invalidateComponentsCache = {
  getComponents(customerId: EntityId, positionId: EntityId) {
    queryClient.invalidateQueries(componentsEndpoints.getComponents(customerId, positionId))
  },
  getComponent(customerId: EntityId, positionId: EntityId, componentId) {
    queryClient.invalidateQueries(componentsEndpoints.getComponent(customerId, positionId, componentId))
  },
}

export const useGetComponents = (
  customerId: EntityId,
  positionId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IComponent>>(
    componentsEndpoints.getComponents(customerId, positionId),
    {
      page,
      pageSize,
    },
    { enabled: positionId != undefined },
  )

export const useGetComponent = (customerId: EntityId, positionId: EntityId, id: EntityId) =>
  useGet<IComponent>(componentsEndpoints.getComponent(customerId, positionId, id))

export const useAddComponent = (customerId: EntityId, positionId: EntityId) =>
  usePost<IComponent, IComponent>(componentsEndpoints.createComponent(customerId, positionId))

export const useUpdateComponent = (customerId: EntityId, positionId: EntityId) =>
  usePut<IComponent, IComponent>(componentsEndpoints.updateComponent(customerId, positionId))

export const useAddBatchNumber = (customerId: EntityId, positionId: EntityId, id: EntityId) =>
  usePut<IProductCertification, IProductCertification>(componentsEndpoints.addBatchNumber(customerId, positionId, id))

export const useDeleteComponent = (customerId: EntityId, positionId: EntityId) =>
  useDelete<IComponent>(componentsEndpoints.deleteComponent(customerId, positionId))

export const useDeleteComponents = (customerId: EntityId, positionId: EntityId) =>
  usePatch<IComponent, { componentIds: number[] }>(componentsEndpoints.deleteComponents(customerId, positionId))

export const useUpdateComponentInlineValues = (customerId: EntityId, positionId: EntityId) =>
  usePut<IComponent, IComponent>(componentsEndpoints.updateComponentInlineValues(customerId, positionId))

export const useBulkUpdateComponentInstallationDate = (customerId: EntityId, positionId: EntityId) =>
  usePut<IComponent, { componentIds: number[]; installationDate: Date }>(
    componentsEndpoints.updateInstallationDateBulk(customerId, positionId),
  )

export const useBulkUpdateBatchNumber = (customerId: EntityId) =>
  usePut<IComponent, IUpdateComponentBatchNumbers>(componentsEndpoints.updateBatchNumberBulk(customerId))
