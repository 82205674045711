import { IProductCertification } from '../generated-types/product-certification'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/productCertifications'
const baseByProductUrl = () => 'admin/productCertifications/GetByProductId'

export const productCertificationsEndpoints = {
  getProductCertifications() {
    return `${baseUrl()}`
  },
  getProductCertification(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  getProductCertificationByProductId(productId: EntityId) {
    return `${baseByProductUrl()}/${productId}`
  },
  createProductCertification() {
    return `${baseUrl()}`
  },
  updateProductCertification(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  deleteProductCertification() {
    return `${baseUrl()}`
  },
}

export const invalidateProductCertificationByProductIdCache = {
  getProductCertificationByProductId(productId: EntityId) {
    queryClient.invalidateQueries(productCertificationsEndpoints.getProductCertificationByProductId(productId))
  },
}

export const useGetProductCertifications = () =>
  useGet<IPaginatedResult<IProductCertification>>(productCertificationsEndpoints.getProductCertifications())

export const useGetProductCertification = (id: EntityId) =>
  useGet<IProductCertification>(productCertificationsEndpoints.getProductCertification(id))

export const useGetProductCertificationByProductId = (productId: EntityId) =>
  useGet<IProductCertification[]>(productCertificationsEndpoints.getProductCertificationByProductId(productId),{},{enabled: !!productId})

export const useAddProductCertification = () =>
  usePost<IProductCertification, IProductCertification>(productCertificationsEndpoints.createProductCertification())

export const useUpdateProductCertification = (id: EntityId) =>
  usePut<IProductCertification, IProductCertification>(productCertificationsEndpoints.updateProductCertification(id))

export const useDeleteProductCertification = () =>
  useDelete<IProductCertification>(productCertificationsEndpoints.deleteProductCertification())
