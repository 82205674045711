import { IService } from '../generated-types/service'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/services`

export const endpoints = {
  getServices(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getUnUsedServicesForLocality(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}`
  },
  getService(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  createService(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateService(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  deleteService(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
}

export const invalidateServiceCache = {
  getServices(customerId: EntityId) {
    queryClient.invalidateQueries(endpoints.getServices(customerId))
  },
  getService(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(endpoints.getService(customerId, id))
  },
}

export const useGetServices = (customerId: EntityId) =>
  useGet<IPaginatedResult<IService>>(endpoints.getServices(customerId))

export const useGetUnUsedServicesForLocality = (customerId: EntityId, localityId: EntityId) =>
  useGet<IPaginatedResult<IService>>(endpoints.getUnUsedServicesForLocality(customerId, localityId))

export const useGetService = (customerId: EntityId, id: EntityId) =>
  useGet<IService>(endpoints.getService(customerId, id))

export const useAddService = (customerId: EntityId) => usePost<IService, IService>(endpoints.createService(customerId))

export const useUpdateService = (customerId: EntityId, id: EntityId) =>
  usePut<IService, IService>(endpoints.updateService(customerId, id))

export const useDeleteService = (customerId: EntityId) => useDelete<IService>(endpoints.deleteService(customerId))
