import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { invalidateDocumentCategoryCache, useAddDocumentCategory } from '../../../api/document-category'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { DocumentCategoryForm } from './DocumentCategoryForm'
import { DocumentCategoryTable } from './DocumentCategoryTable'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { ShowDialogProps } from 'src/show-dialog'
import { invalidateCustomerDocumentCategoryCache } from 'src/api/customer-document-category'

const CreateDocumentCategoryDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: translate } = useTranslation(['documentcategory', 'common'])
  const mutation = useAddDocumentCategory()

  useEffect(() => {
    if (mutation.error != null) {
      toast(translate('CannotAddDocumentCategory', { ns: 'document-category' }), { type: 'warning' })
    }
  }, [mutation.error])

  const onSubmit = async form => {
    await mutation.mutateAsync(form)

    invalidateDocumentCategoryCache.useGetDocumentCategories()
    invalidateCustomerDocumentCategoryCache.getCustomerAndAdminDocumentCategories()
    pop(true)
  }

  return (
    <GenericDialogLayout title={translate('addDocumentCategory', { ns: 'document-category' })} pop={pop}>
      <DocumentCategoryForm onSubmit={onSubmit} onCancel={() => pop()} />
    </GenericDialogLayout>
  )
}

export const DocumentCategoryContainer = () => {
  const { t: translate } = useTranslation(['documentcategory', 'common'])
  const {showDialog} = useDialogCtx()

  const onAddDocumentCategory = async () => {
    const added = await showDialog(CreateDocumentCategoryDialog, {
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
    }, undefined, true)
    if (!added) return
  }

  return (
    <>
      <Box className="p-3 mb-2 bg-white shadow-md" borderRadius={2}>
        <ScreenTitleBar
          title={translate('documentCategories', { ns: 'document-category' })}
          addNewItemText={translate('add', { ns: 'common' })}
          onAddNewItemClick={onAddDocumentCategory}
        />
        <DocumentCategoryTable />
      </Box>
    </>
  )
}
