import { Card, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useUploadModelImage, invalidatePositionCache } from 'src/api/positions'

const mainComponentsSectionHeight = (window.innerHeight - 600) / 2 < 389 ? 389 : (window.innerHeight - 600) / 2

export const CageObject = ({ fileURL }) => {
  const { t } = useTranslation('cage-status')
  const { localityId, customerId, positionId, mooringId } = useParams()
  const mutation = useUploadModelImage(customerId as EntityId, localityId as EntityId, positionId as EntityId)

  const onSelectUpload = () => {
    document.getElementById('cage-3d-drawing')?.click()
  }

  const onUpload = uploadedFile => {
    const formData = new FormData()
    formData.append('file', uploadedFile[0])
    mutation.mutateAsync(formData, {
      onSuccess: data => {
        invalidatePositionCache.getPosition(
          customerId as EntityId,
          localityId as EntityId,
          mooringId as EntityId,
          positionId as EntityId,
        )
      },
    })
  }

  return (
    <CardCageObject
      className="hover-pointer"
      onClick={() => onSelectUpload()}
      sx={{
        backgroundImage: `url(${fileURL})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: '#c3ebff',
        width: '100%',
      }}
    >
      <input
        type="file"
        hidden
        id="cage-3d-drawing"
        accept="image/png,image/jpeg"
        onChange={event => onUpload(event.target.files)}
      />
      {fileURL == null && <Typography align="center">{t('cage-image-uploader')}</Typography>}
    </CardCageObject>
  )
}

export default CageObject

//styles
const CardCageObject = styled(Card)`
  height: ${mainComponentsSectionHeight - 119}px;
  background-color: #baeef9 !important;
  justify-content: center;
  align-items: center;
  align-content: center;
`
