import React, { createContext, useEffect, useState } from 'react'
import { User, Profile } from 'oidc-client'
import { userManager } from '../user-manager'
import { Navigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../app-routes'
import { UserRole } from '../enums'
import { axios } from '../axios'

interface ProfileExtension {
  role: UserRole
}

interface ContextProps {
  user: User
  userProfile: Profile & ProfileExtension
}

export const IdentityServerUserCtx = createContext<ContextProps>(null!)

export const IdentityServerUserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    await userManager.clearStaleState()

    try {
      const callbackUser = await userManager.signinCallback()
      if (callbackUser) {
        Logger.info('Got user after login')
        axios.defaults.headers['Authorization'] = `Bearer ${callbackUser.access_token}`
        setUser(callbackUser)
        setLoading(false)
        return
      }
    } catch (e) {
      Logger.info('Searching for user in storage')
    }

    const user = await userManager.getUser()

    Logger.debug('Authorized user from storage: ', user)
    axios.defaults.headers['Authorization'] = `Bearer ${user?.access_token}`

    userManager.startSilentRenew()
    setUser(user)
    setLoading(false)
  }

  if (!user && loading) return null

  if (!user) return <Navigate to={AppRoutesNavigation.Login} />

  const contextValue: ContextProps = {
    user,
    userProfile: user.profile as any,
  }

  return <IdentityServerUserCtx.Provider value={contextValue}>{children}</IdentityServerUserCtx.Provider>
}