import { ISensorType } from '../generated-types/sensortype'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/sensortypes'

export const sensorTypesEndpoints = {
  getSensorTypes() {
    return `${baseUrl()}`
  },
  getSensorType(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  createSensorType() {
    return `${baseUrl()}`
  },
  updateSensorType(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  deleteSensorType() {
    return `${baseUrl()}`
  },
}

export const invalidateSensorTypeCache = {
    useGetSensorTypes() {
    queryClient.invalidateQueries(sensorTypesEndpoints.getSensorTypes())
  },
  getSensorType(id: EntityId) {
    queryClient.invalidateQueries(sensorTypesEndpoints.getSensorType(id))
  },
}

export const useGetSensorTypes = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<ISensorType>>(sensorTypesEndpoints.getSensorTypes(), { page, pageSize })

export const useGetSensorType = (id: EntityId) => useGet<ISensorType>(sensorTypesEndpoints.getSensorType(id))

export const useAddSensorType = () => usePost<ISensorType, ISensorType>(sensorTypesEndpoints.createSensorType())

export const useUpdateSensorType = (id: EntityId) => usePut<ISensorType, ISensorType>(sensorTypesEndpoints.updateSensorType(id))

export const useDeleteSensorType = () => useDelete<ISensorType>(sensorTypesEndpoints.deleteSensorType())
