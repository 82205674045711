import { GridColumns, GridEnrichedColDef, GridEventListener, GridRowParams } from '@mui/x-data-grid-pro'
import React, { ReactElement } from 'react'
import { Flex } from 'src/styles/flexComponent'
import TableDataCard from './TableDataCard'
interface IMobileCardList {
    columns: GridColumns[] | GridEnrichedColDef<any, any, any>[],
    rows: any[],
    mobileViewCard?: (row: any) => ReactElement,
    getDetailPanelContent?: ((params: GridRowParams<any>) => React.ReactNode) | undefined,
    onRowClick: GridEventListener<'rowClick'> | undefined
}

const MobileCardList: React.FC<IMobileCardList> = (props) => {
    const { rows, ...rest } = props
    return (
        <Flex.Column padding={0}>
            {
                rows.map((row, index) =>
                    <TableDataCard key={index} row={row} {...rest} />
                )
            }
        </Flex.Column>
    )
}

export default MobileCardList