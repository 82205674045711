import { UserRegistrationFormType, UserUpdateFormType } from '../forms/UserRegistrationForm'
import { IUserWithAccess } from '../generated-types'
import { IApiResult } from '../generated-types/api-result'
import { userQueryKey } from '../query-keys'
import { queryClient, useGet, usePost } from '@lovoldsystem/core.client'

const baseUrl = () => 'users'

export const userEndpoints = {
  getUserInvite(code: string) {
    return `${baseUrl()}/invites/${code}`
  },
  registerUer() {
    return `${baseUrl()}/register`
  },
  updateUser() {
    return `${baseUrl()}/update-me`
  },
  me: () => `${baseUrl()}/me`
}

export const invalidateCustomerCache = {
  getUserInvite(code: string) {
    return queryClient.invalidateQueries(userQueryKey.GET_USER_INVITE(code))
  },
}

export const useGetUserInvite = (code: string) =>
  useGet<any>(userEndpoints.getUserInvite(code), undefined, { queryKey: userQueryKey.GET_USER_INVITE(code) })

export const useRegisterUser = () => usePost<IApiResult, UserRegistrationFormType>(userEndpoints.registerUer())

export const useUpdateUser = () => usePost<IApiResult, UserUpdateFormType>(userEndpoints.updateUser())

export const useGetMe = () => useGet<IUserWithAccess>(userEndpoints.me())