import { Box } from '@mui/material'
import React from 'react'

interface Props {
  title: string
  textSize?: string
}

export const ScreenTitle: React.FC<Props> = ({ title, textSize = 'text-xl' }) => {
  const className = `text-sm md:text-xl lg:${textSize} text-primary font-bold flex items-center pb-1`
  return <Box className={className}>{title}</Box>
}