import { useCallback } from 'react'
import { invalidateDeviation, useGetDeviationDocuments } from '../../../api/deviation'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@mui/icons-material'
import { Grid, styled, useTheme } from '@mui/material'
import { DeviationDocumentType, IDeviationDocument } from '../../../generated-types/deviation-document'
import ReportViewer from '../../../components/overallReport/ReportViewer'
import { formSchemaReportTypes } from '../../../generated-types/form-schema'
import FormSchemasDialog from '../../../components/overallReport/FormSchemasDialog'
import { formFieldsDefaultValues } from '../../../generated-types/form-field'
import { useGetLocality } from '../../../api/localities'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { permissionValue } from 'src/enums/permissionValue'
import { useDeleteFile } from 'src/api/file'
import { useDeleteFormSchemaReportById } from 'src/api/form-schema'
import { toast } from 'react-toastify'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { CreateAttachment } from './CreateAttachment'
import DocumentViewer from 'src/components/common/content/DocumentViewer'
import { IFile } from 'src/generated-types'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { StyledDetailCard } from 'src/styles/Index'

const DeviationDocuments = ({ customerId, localityId, deviationId, isDisabled, context }) => {
  const { t } = useTranslation(['document', 'common', 'overall-reports'])
  const { data } = useGetDeviationDocuments(customerId, deviationId)
  const theme = useTheme()
  const { data: locality } = useGetLocality(customerId as EntityId, localityId as EntityId)

  const { mutateAsync: fileDeleteAsync } = useDeleteFile()
  const { mutateAsync: deleteReportAsync } = useDeleteFormSchemaReportById(customerId as EntityId)
  const { showDialog, showConfirmDialog, openFilePreviewDialog } = useDialogCtx()

  const handleDelete = async (id: number, type: DeviationDocumentType) => {
    showConfirmDialog(
      type === DeviationDocumentType.Document
        ? t('deleteDocument', { ns: 'document' })
        : t('deleteFormSchemaReportTitle', { ns: 'form-schema-report' }),
      type === DeviationDocumentType.Document
        ? t('documentDeleteConfirmation', { ns: 'document' })
        : t('deleteFormSchemaReportMessage', { ns: 'form-schema-report' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        if (type === DeviationDocumentType.Document) {
          await fileDeleteAsync(id, {
            onSuccess: () => {
              toast.success(t('document-deleted', { ns: 'document' }))
              invalidateDeviation.getDeviationDocuments(customerId as EntityId, deviationId as EntityId)
              pop()
            },
            onError: () => {
              toast.error(t('error-document-deleting', { ns: 'document' }))
            },
          })
        } else {
          await deleteReportAsync(id, {
            onSuccess: () => {
              toast.success(t('form-schema-report-deleted', { ns: 'form-schema-report' }))
              invalidateDeviation.getDeviationDocuments(customerId as EntityId, deviationId as EntityId)
              pop()
            },
            onError: () => {
              toast.error(t('error-deleting-form-schema-report', { ns: 'form-schema-report' }))
            },
          })
        }
      },
    )
  }

  const onAddFormSchema = useCallback(() => {
    showDialog(
      FormSchemasDialog,
      {
        componentProps: {
          customerId: customerId,
          property: {
            deviationId: deviationId,
            type: formSchemaReportTypes.Deviation,
          },
          formInitialValues: {
            [formFieldsDefaultValues.siteNumber]: locality?.locationNr,
            [formFieldsDefaultValues.siteLocationName]: locality?.name,
          },
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }, [deviationId, customerId, locality])

  const onAddAttachment = useCallback(() => {
    showDialog(
      CreateAttachment,
      {
        componentProps: {
          customerId: customerId,
          deviationId: deviationId,
        },
      },
      undefined,
      true,
    )
  }, [deviationId, customerId])

  const onFileClick = callbackData => {
    const deviationDocument = callbackData as IDeviationDocument
    if (deviationDocument?.type == DeviationDocumentType.Document) {
      openFilePreviewDialog(deviationDocument?.file?.url)
    } else {
      showDialog(ReportViewer, {
        componentProps: {
          layouts: JSON.parse(deviationDocument?.formSchemaReport?.formSchemaVersion?.layout || ''),
          schema: deviationDocument?.formSchemaReport?.formSchemaVersion?.formSchema,
          report: deviationDocument?.formSchemaReport,
          createdAt: deviationDocument?.created,
          createdByUser: deviationDocument?.createdByUser,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      })
    }
  }

  return (
    <CommonPageLayout
      titleSection={{ subtitle: t('attachments&reports', { ns: 'deviation' }) }}
      topAction={[
        {
          buttonText: t('new-form', { ns: 'overall-reports' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddFormSchema,
          disabled: isDisabled,
          permissionBinaryValues: [
            permissionValue.Locality.Deviation.Write,
            permissionValue.Locality.Deviation.Update,
            permissionValue.ClientAdminPermission,
            permissionValue.ServiceStation.Write,
            permissionValue.ServiceStation.Update,
            permissionValue.Locality.MainComponent.Deviation.Write,
          ],
          'data-cy': 'addNewForm',
          variant: 'outlined',
          shadowWeight: null,
          context: context,
        },
        {
          buttonText: t('new-attachments', { ns: 'document' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddAttachment,
          disabled: isDisabled,
          permissionBinaryValues: [
            permissionValue.Locality.Deviation.Write,
            permissionValue.Locality.Deviation.Update,
            permissionValue.ClientAdminPermission,
            permissionValue.ServiceStation.Write,
            permissionValue.ServiceStation.Update,
            permissionValue.Locality.MainComponent.Deviation.Write,
          ],
          variant: 'outlined',
          shadowWeight: null,
          context: context,
        },
      ]}
    >
      <StyledCard elevation={0} className="scrollbar p-3 mb-2">
        <Grid container padding={'9px 7px 9px 1px'}>
          {data?.map((document, index) => {
            return (
              <Grid className="ph-6-core pb-12-core" spacing={'12px'} key={document.id} md={4}>
                <DocumentViewer
                  document={true}
                  backgroundColor={
                    index % 2 == 0
                      ? getThemeColor(theme, MUIThemeColors.infoLight)
                      : getThemeColor(theme, MUIThemeColors.white)
                  }
                  file={
                    (document?.type == DeviationDocumentType.Document
                      ? { ...document?.file, ...{ createdByUser: document?.createdByUser } }
                      : {
                          contentType: 'application/pdf',
                          url: '',
                          filename: document?.formSchemaReport?.formSchemaVersion?.formSchema?.name,
                          contentLength: 0,
                          createdByUser: document?.createdByUser,
                        }) as IFile
                  }
                  enablePreview={false}
                  disabled={false}
                  callbackData={document}
                  onFileClick={(e, file, callbackData) => {
                    onFileClick(callbackData)
                  }}
                  onDelete={(e, file, callbackData) => {
                    e.preventDefault()
                    handleDelete(callbackData?.id, callbackData?.type)
                  }}
                  isDeletable={true}
                />
              </Grid>
            )
          })}
        </Grid>
      </StyledCard>
    </CommonPageLayout>
  )
}

export default DeviationDocuments

const StyledCard = styled(StyledDetailCard)(() => ({
  height: '286px',
  overflowY: 'auto',
  borderRadius: '4px',
  boxShadow: '0px 2px 8px #00000040',
}))
