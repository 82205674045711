
export enum AuthorizationType {
    Page = 'Page',
    Hidden = 'Hidden',
    Disabled = 'Disabled',
}

export enum RoleTypes {
    Client,
    Locality,
    ServiceStation
}

export enum FunctionElementsParentsIds {
    LocalityPortal = 1,
    ServiceStationPortal = 2,
    SystemPortal = 3
}