import { IMaterialFactor } from '../generated-types/material-factor'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/material-factors'

export const materialFactorEndpoints = {
  getMaterialFactors(page: number = apiConsts.Page) {
    return `${baseUrl()}`
  },
  getMaterialFactor(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  createMaterialFactor() {
    return `${baseUrl()}`
  },
  updateMaterialFactor(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  deleteMaterialFactor() {
    return `${baseUrl()}`
  },
}

export const invalidateMaterialFactorCache = {
  getMaterialFactors() {
    queryClient.invalidateQueries(materialFactorEndpoints.getMaterialFactors())
  }
}

export const useGetMaterialFactors = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IMaterialFactor>>(materialFactorEndpoints.getMaterialFactors(), { page, pageSize })

export const useGetMaterialFactor = (id: EntityId) => useGet<IMaterialFactor>(materialFactorEndpoints.getMaterialFactor(id))

export const useAddMaterialFactor = () => usePost<IMaterialFactor, IMaterialFactor>(materialFactorEndpoints.createMaterialFactor())

export const useUpdateMaterialFactor = (id: EntityId) =>
  usePut<IMaterialFactor, IMaterialFactor>(materialFactorEndpoints.updateMaterialFactor(id))

export const useDeleteMaterialFactor = () => useDelete<IMaterialFactor>(materialFactorEndpoints.deleteMaterialFactor())
