import { ILocality, ILocalityGroup } from '../generated-types'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/locality-groups`

export const localityGroupsEndpoints = {
  getLocalityGroups(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getLocalityGroup(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  getLocalities(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/localities`
  },
  createLocalityGroup(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateLocalityGroup(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  deleteLocalityGroup(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
}

export const invalidateLocalityGroupsQueries = {
  invalidateGetLocalityGroups(customerId: EntityId) {
    queryClient.invalidateQueries(localityGroupsEndpoints.getLocalityGroups(customerId))
  },
  invalidateGetLocalityGroup(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(localityGroupsEndpoints.getLocalityGroup(customerId, id))
  },
  getLocalities(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(localityGroupsEndpoints.getLocalities(customerId, id))
  },
}

export const useGetLocalityGroups = (
  customerId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) => useGet<IPaginatedResult<ILocalityGroup>>(localityGroupsEndpoints.getLocalityGroups(customerId), { page, pageSize })

export const useGetLocalityGroup = (customerId: EntityId, id: EntityId) =>
  useGet<ILocalityGroup>(localityGroupsEndpoints.getLocalityGroup(customerId, id))

export const useAddLocalityGroup = (customerId: EntityId) =>
  usePost<ILocalityGroup, ILocalityGroup>(localityGroupsEndpoints.createLocalityGroup(customerId))

export const useUpdateLocalityGroup = (customerId: EntityId, id: EntityId) =>
  usePut<ILocalityGroup, ILocalityGroup>(localityGroupsEndpoints.updateLocalityGroup(customerId, id))

export const useDeleteLocalityGroup = (customerId: EntityId) =>
  useDelete<ILocalityGroup>(localityGroupsEndpoints.deleteLocalityGroup(customerId))

export const useGetLocalitiesInLocalityGroups = (customerId: EntityId, id: EntityId) =>
  useGet<ILocality[]>(localityGroupsEndpoints.getLocalities(customerId, id))
