import { IPositionType } from '../generated-types'
import { IPositionDrawing } from '../generated-types/position-drawing'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseAdminUrl = () => 'admin/position-drawings'
const baseUrl = (customerId, mooringId) => `customers/${customerId}/moorings/${mooringId}/position-drawing`

export const positionDrawingEndpoints = {
  getPositionDrawings(searchInput?: string) {
    return `${baseAdminUrl()}?searchInput=${searchInput ?? ''}`
  },
  getPositionDrawingsForMooring(searchInput?: string) {
    return `${baseAdminUrl()}/getPositionDrawingsForMooring?searchInput=${searchInput ?? ''}`
  },
  getSimplePositionDrawings(searchInput?: string) {
    return `${baseAdminUrl()}/getSimplePositionDrawings?searchInput=${searchInput ?? ''}`
  },
  getPositionDrawing(id: EntityId) {
    return `${baseAdminUrl()}/${id}`
  },
  createPositionDrawing() {
    return `${baseAdminUrl()}`
  },
  updatePositionDrawing(id: EntityId) {
    return `${baseAdminUrl()}/${id}`
  },
  deletePositionDrawing() {
    return `${baseAdminUrl()}`
  },
  deletePositionDrawingProduct() {
    return `${baseAdminUrl()}/positionDrawingProducts`
  },
  getPositionDrawingInMooring(customerId, mooringId) {
    return baseUrl(customerId, mooringId)
  },
}

export const invalidatePositionDrawingCache = {
  useGetPositionDrawings() {
    queryClient.invalidateQueries(positionDrawingEndpoints.getPositionDrawings())
  },
  getPositionDrawingById(positionDrawingId: EntityId) {
    queryClient.invalidateQueries(positionDrawingEndpoints.getPositionDrawing(positionDrawingId))
  },
  getPositionDrawingsForMooring() {
    queryClient.invalidateQueries(positionDrawingEndpoints.getPositionDrawingsForMooring())
  },
  getSimplePositionDrawings() {
    queryClient.invalidateQueries(positionDrawingEndpoints.getSimplePositionDrawings())
  },
  getPositionDrawingInMooring(customerId, mooringId) {
    queryClient.invalidateQueries(positionDrawingEndpoints.getPositionDrawingInMooring(customerId, mooringId))
  },
}

export const useGetPositionDrawings = (
  searchInput?: string,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
  positionType?: IPositionType,
  mooringId?: EntityId,
  customerId?: EntityId,
) =>
  useGet<IPaginatedResult<IPositionDrawing>>(positionDrawingEndpoints.getPositionDrawings(searchInput), {
    page,
    pageSize,
    positionType,
    mooringId,
    customerId,
  })

export const useGetPositionDrawingsForMooring = (
  mooringId: EntityId,
  searchInput?: string,
  positionType?: IPositionType,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IPositionDrawing>>(positionDrawingEndpoints.getPositionDrawingsForMooring(searchInput), {
    mooringId,
    positionType,
    page,
    pageSize,
  })

export const useGetSimplePositionDrawings = (searchInput?: string, positionType?: IPositionType, customerId?: number) =>
  useGet<Array<IPositionDrawing>>(positionDrawingEndpoints.getSimplePositionDrawings(searchInput), {
    searchInput,
    positionType,
    customerId,
  })

export const useGetPositionDrawingInMooring = (customerId: EntityId, mooringId: EntityId) =>
  useGet<IPaginatedResult<IPositionDrawing>>(
    positionDrawingEndpoints.getPositionDrawingInMooring(customerId, mooringId),
  )

export const useGetPositionDrawing = (id: EntityId) =>
  useGet<IPositionDrawing>(positionDrawingEndpoints.getPositionDrawing(id))

export const useAddPositionDrawing = () =>
  usePost<IPositionDrawing, IPositionDrawing>(positionDrawingEndpoints.createPositionDrawing())

export const useUpdatePositionDrawing = (id: EntityId) =>
  usePut<IPositionDrawing, IPositionDrawing>(positionDrawingEndpoints.updatePositionDrawing(id))

export const useDeletePositionDrawing = () =>
  useDelete<IPositionDrawing>(positionDrawingEndpoints.deletePositionDrawing())

export const useDeletePositionDrawingProduct = () =>
  useDelete<IPositionDrawing>(positionDrawingEndpoints.deletePositionDrawingProduct())
