import { GridColumns } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IGetDocumentsParams, documentEndpoints, invalidateDocument, useDeleteDocument } from '../../api/document'
import { IDocument } from '../../generated-types/document'
import { ScreenTitleBar } from '../../screens/localities/components/ScreenTitleBar'
import {
  CreateDocumentDialog,
  ICreateDocumentsParams,
} from '../../screens/localities/documents/components/CreateDocumentDialog'
import { EditDocumentDialog } from '../../screens/localities/documents/components/EditDocumentDialog'
import { useMediaQuery, useTheme } from '@mui/material'
import { DeleteOutlineOutlined, Visibility } from '@mui/icons-material'
import { FileUrlLoader } from '../FileUploader'
import DocumentTableMobileViewCard from './DocumentTableMobileViewCard'
import { AuthorizationType } from 'src/enums/auth'
import { permissionValue } from 'src/enums/permissionValue'
import { AuthorizationProvider } from '../common/Authorization'
import GenericTable from '../table/GenericTable'
import { GridActionButton } from '../common/GridActionButton'
import { Flex } from 'src/styles/flexComponent'
import { toast } from 'react-toastify'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { invalidateLocalityCache } from 'src/api/localities'
import { invalidateDocumentCategory } from 'src/api/users/document-category'
import { MooringDropdownDto } from 'src/generated-types/mooring-drop-down-dto'
import { DynamicTextTypography } from '../DynamicTextTypography'
import { DocumentDetailDialog } from 'src/screens/localities/documents/components/DocumentDetailDialog'
import DeleteWarningIcon from '@mui/icons-material/WarningAmberRounded'
import { getFormattedDate } from 'src/utils'

export interface IDocumentsTableProps {
  customerId: EntityId
  localityId: EntityId
  documentsParams: IGetDocumentsParams
  showDocumentsHeader?: boolean
  showAddDocumentButton?: boolean
  showEditDocumentButton?: boolean
  disableAdd?: boolean
  height?: number | string
  pageSize?: number
  autoHeight?: boolean
  isViewAllRecords?: boolean
  context?: React.Context<any>
  moorings?: MooringDropdownDto[]
  isDocumentListView?: boolean
  hideMooringColumn?: boolean
  shouldShowAttachemnts?: boolean
}
const DocumentsTableGeneric: React.FC<IDocumentsTableProps> = ({
  customerId,
  localityId,
  documentsParams,
  showDocumentsHeader = true,
  showAddDocumentButton = false,
  disableAdd = false,
  height,
  pageSize = 10,
  autoHeight = true,
  isViewAllRecords,
  context,
  moorings,
  isDocumentListView = false,
  hideMooringColumn = false,
  shouldShowAttachemnts = false,
}) => {
  const { t } = useTranslation(['document', 'common'])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { showDialog, showConfirmDialog, openFilePreviewDialog } = useDialogCtx()
  const { mutateAsync: mutationDelete } = useDeleteDocument(customerId)

  const onView = async id => {
    await showDialog(
      DocumentDetailDialog,
      {
        opts: {
          maxWidth: 'xl',
          fullWidth: true,
        } as any,
        componentProps: { id: id as EntityId, customerId: customerId as EntityId, localityId: localityId as EntityId },
      },
      undefined,
      true,
    )
  }

  const BASE_STATIC_COLUMNS: GridColumns = useMemo(
    () =>
      isDocumentListView
        ? [
            {
              field: 'file',
              headerName: t('file', { ns: 'common' }),
              maxWidth: 50,
              sortable: false,
              renderCell: params => {
                const document = params.row as IDocument
                return (
                  <Flex.Column justifyContent={'center'} alignItems={'center'} width={'32px'} height={'32px'}>
                    <FileUrlLoader
                      contentTypeValue={params?.row?.fileContentType}
                      fileUrl={document.fileUrl}
                      openOnClick
                    />
                  </Flex.Column>
                )
              },
            },
            {
              field: 'title',
              headerName: t('title', { ns: 'common' }),
              flex: shouldShowAttachemnts ? 1 : 2,
              sortable: false,
            },
            {
              field: 'category',
              headerName: t('category', { ns: 'document' }),
              flex: 1,
              sortable: false,
              renderCell: ({ row }) => (
                <DynamicTextTypography
                  text={row?.documentCategory?.categoryName ?? '-'}
                  variant="body1"
                  maxLength={20}
                  fontWeight={500}
                />
              ),
            },
          ]
        : [
            {
              field: 'file',
              headerName: t('file', { ns: 'common' }),
              maxWidth: 50,
              sortable: false,
              renderCell: params => {
                const document = params.row as IDocument
                return (
                  <Flex.Column justifyContent={'center'} alignItems={'center'} width={'32px'} height={'32px'}>
                    <FileUrlLoader
                      contentTypeValue={params?.row?.fileContentType}
                      fileUrl={document.fileUrl}
                      openOnClick
                    />
                  </Flex.Column>
                )
              },
            },
            {
              field: 'title',
              headerName: t('title', { ns: 'common' }),
              flex: shouldShowAttachemnts ? 1 : 2,
              sortable: false,
              renderCell: ({ row }) => {
                return (
                  <DynamicTextTypography
                    text={!row?.isAttachment ? row?.title : row?.component?.productDescription}
                    variant="body1"
                    maxLength={!shouldShowAttachemnts ? 20 : 8}
                    fontWeight={500}
                    color={
                      !row?.isAttachment
                        ? theme.palette.black[theme.palette.mode]
                        : theme.palette.secondaryDark[theme.palette.mode]
                    }
                  />
                )
              },
            },
          ],
    [isDocumentListView],
  )

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      ...BASE_STATIC_COLUMNS,
      {
        field: 'version',
        flex: shouldShowAttachemnts ? 0.8 : 1,
        headerName: t('version', { ns: 'document' }),
        sortable: false,
        valueGetter: ({ value }) => (value ? value : ' - '),
      },
      {
        field: 'dueDate',
        headerName: t('dueDate', { ns: 'common' }),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => getFormattedDate(row.dueDate),
      },
      {
        field: 'mooring',
        headerName: t('mooring', { ns: 'common' }),
        sortable: false,
        hide: hideMooringColumn,
        flex: 1.5,
        renderCell: params => {
          const document = params.row as IDocument
          const mooring = document.mooringId != null ? moorings?.find(m => m.id == document.mooringId) : null
          return mooring != null ? mooring.name : 'N/A'
        },
      },
      {
        field: 'drawingNumber',
        headerName: t('drawingNumber', { ns: 'document' }),
        sortable: false,
        hide: !shouldShowAttachemnts,
        flex: 1,
        renderCell: params => {
          const attachment = params?.row?.component
          return (
            <Flex.Column justifyContent={'center'} alignItems={'center'} width={'32px'} height={'32px'}>
              {attachment != null ? attachment.positionDrawingNumber : '-'}
            </Flex.Column>
          )
        },
      },
    ],
    [],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'action',
        headerName: '',
        type: 'actions',
        align: 'center',
        width: 100,
        renderCell: ({ row, id }) => {
          return !row.isGlobal ? (
            <Flex.Row alignItems={'center'} gap={1} marginBottom={'2px'}>
              <GridActionButton
                buttonIcon={<Visibility />}
                toolTipTitle={t('view', { ns: 'common' })}
                shadowWeight={0}
                variant="outlined"
                width="32px"
                height="32px"
                onClick={() => {
                  if (row?.isAttachment) {
                    openFilePreviewDialog(row?.fileUrl)
                  } else {
                    onView(id)
                  }
                }}
              />
              <AuthorizationProvider
                permissions={[
                  permissionValue.Locality.Documents.Delete,
                  permissionValue.ClientAdminPermission,
                  permissionValue.ServiceStation.Write,
                  permissionValue.ServiceStation.Update,
                  permissionValue.Locality.MainComponent.Document.Delete,
                ]}
                type={AuthorizationType.Disabled}
                Context={context}
              >
                <GridActionButton
                  buttonIcon={<DeleteOutlineOutlined />}
                  toolTipTitle={t('delete', { ns: 'common' })}
                  shadowWeight={0}
                  variant="outlined"
                  width="32px"
                  height="32px"
                  onClick={() => {
                    handleDeleteClick(id)
                  }}
                  disabled={row?.isAttachment}
                />
              </AuthorizationProvider>
            </Flex.Row>
          ) : (
            <></>
          )
        },
      },
    ],
    [],
  )

  const onAdd = async () => {
    const added = await showDialog(CreateDocumentDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        createDocumentsParams: documentsParams as ICreateDocumentsParams,
      },
    })
    if (!added) return
  }

  const onEdit = async (document: IDocument) => {
    const added = await showDialog(EditDocumentDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        document,
      },
    })
    if (!added) return
  }

  const handleDeleteClick = async (id: EntityId) => {
    showConfirmDialog(
      t('deleteDocument', { ns: 'document' }),
      t('documentDeleteConfirmation', { ns: 'document' }),
      {
        acceptText: t('delete', { ns: 'common' }),
        cancelText: t('cancel', { ns: 'common' }),
      },
      async pop => {
        await mutationDelete(id, {
          onSuccess: () => {
            toast.success(t('document-deleted'))
            invalidateDocument.getDocuments(customerId)
            invalidateDocument.getExpireCounts(customerId, localityId)
            invalidateLocalityCache.getLocalitiesWithInfoCount(customerId)
            invalidateDocumentCategory.getDocumentCategoriesWithDocuments(customerId)
            pop()
          },
          onError: () => {
            toast.error(t('error-document-deleting'))
          },
        })
      },
      {
        Icon: (
          <DeleteWarningIcon
            sx={{ height: '43px', width: '48px', color: theme.palette.errorMain[theme.palette.mode] }}
          />
        ),
        iconBGColor: '#D32F2F4D',
        buttonTextColor: theme.palette.white[theme.palette.mode],
        buttonBGColor: theme.palette.errorMain[theme.palette.mode],
      },
    )
  }

  return (
    <>
      {showDocumentsHeader && (
        <ScreenTitleBar
          title={t('documents', { ns: 'document' })}
          {...(showAddDocumentButton && {
            addNewItemText: t('add', { ns: 'common' }),
            onAddNewItemClick: onAdd,
            disabledButton: disableAdd,
          })}
        />
      )}
      <GenericTable
        id="document-table-generic"
        columns={columns}
        enablePagination={isMobile}
        initialPage={0}
        pageSize={isMobile ? 1 : pageSize}
        enableServerPagination={true}
        queryUrl={documentEndpoints.getDocuments(customerId)}
        queryParams={documentsParams}
        hideDefaultFooterPagination={true}
        rowColor="#e5e4e2"
        enableToolbar={true}
        mobileViewCard={(row: IDocument) => (
          <DocumentTableMobileViewCard localityId={localityId} customerId={customerId} onEdit={onEdit} row={row} />
        )}
        height={isMobile ? undefined : height}
        autoHeight={autoHeight ?? isMobile ? true : false}
        onRowClick={({ row }) => {
          openFilePreviewDialog(row.fileUrl)
        }}
        isViewAllRecords={isViewAllRecords}
        getRowHeight={() => 44}
      />
    </>
  )
}

export default DocumentsTableGeneric
