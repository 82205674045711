import { Box, DialogTitle, DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUpdateDistributor, invalidateDistributorCache, useGetDistributor } from '../../../api/distributor'
import { CenteredSpinner } from '../../../components/Spinner'
import { IDistributor } from '../../../generated-types'
import { ShowDialogProps } from '../../../show-dialog'
import { DistributorForm } from './DistributorForm'

interface UpdateDistributorProps extends ShowDialogProps {
  id: EntityId
}

export const UpdateDistributorDialog: React.FC<UpdateDistributorProps> = ({ pop, id }) => {
  const { t: translate } = useTranslation(['distributor', 'common'])
  const mutation = useUpdateDistributor(id)

  const onSubmit = async form => {
    await mutation.mutateAsync(form)

    invalidateDistributorCache.useGetDistributors()
    pop(true)
  }

  const { data, isLoading } = useGetDistributor(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <Box>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
        }}
        className="text-primary"
      >
        {translate('updateDistributor', { ns: 'distributor' })}
      </DialogTitle>
      <DialogContent>
        <DistributorForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data as IDistributor} />
      </DialogContent>
    </Box>
  )
}
