import { Button, ButtonProps, Stack, Tooltip, Typography, TypographyProps, darken, styled, useTheme } from '@mui/material'
import React from 'react'
import { AuthorizationProvider } from './Authorization'
import { AuthorizationType } from 'src/enums/auth'
import { GetRGBA } from 'src/utils'
import { Spinner } from '../Spinner'

const StyledActionButton = styled(Button) <GridActionButtonProps>`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.bgColor ?? props.theme.palette.white[props.theme.palette.mode]};
  border-radius: 4px;
  border:${({ theme, border }) => border ? `${border}px solid ${theme.palette.secondaryLight[theme.palette.mode]}` : 'none'};
  text-transform: capitalize;
  box-shadow: ${({ theme, shadowWeight }) =>
    shadowWeight
      ? `0px ${shadowWeight}px ${(shadowWeight as number) * 2}px ${GetRGBA(
          theme.palette.darkTransparentBlack[theme.palette.mode],
          0.25,
        )}`
      : undefined};
  & * {
    color: ${props => (props.disabled ? props.theme.palette.secondaryMain[props.theme.palette.mode] : '')};
    cursor: ${props => (props.disabled ? 'not-allowed !important' : '')};
  }
  &.MuiButton-root {
    min-width: ${props => props.width};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color:  ${props => props.bgColor ? darken(props.bgColor as string, 0.2) : props.theme.palette.secondaryLight[props.theme.palette.mode]} !important;
  }
`

export interface GridActionButtonProps extends Omit<ButtonProps, 'title'> {
  toolTipTitle?: string
  height?: string
  width?: string
  bgColor?: string
  buttonText?: string
  buttonIcon?: any
  buttonTextProps?: TypographyProps
  permissionBinaryValues?: number[]
  context?: React.Context<any> | null
  permission?: number
  shadowWeight?: number | null
  border?: number | null
  loading?: boolean
  authorizationType?: AuthorizationType
}

export const GridActionButton: React.FC<GridActionButtonProps> = ({
  toolTipTitle,
  height,
  width,
  bgColor,
  buttonIcon,
  buttonText,
  buttonTextProps,
  context,
  permissionBinaryValues,
  permission,
  shadowWeight = 3,
  border = 1,
  loading = false,
  authorizationType = AuthorizationType.Disabled,
  ...rest
}) => {
  const theme = useTheme()
  const renderButtonComponent = () => {
    return (
      <StyledActionButton
        width={width}
        height={height}
        bgColor={bgColor}
        border={border}
        shadowWeight={shadowWeight}
        {...rest}
      >
        {loading && <Spinner size={20} />}
        <Stack direction={'row'} spacing={0.5} alignItems={'center'} justifyContent={'center'}>
          {buttonIcon}
          {buttonText && (
            <Typography
              color={rest.disabled ? 'disabled' : theme.palette.primaryMain[theme.palette.mode]}
              variant="body1"
              fontWeight={600}
              {...buttonTextProps}
            >
              {buttonText}
            </Typography>
          )}
        </Stack>
      </StyledActionButton>
    )
  }
  return (
    <AuthorizationProvider
      Context={context}
      permissions={permissionBinaryValues}
      permission={permission}
      type={authorizationType}
    >
      {toolTipTitle ? <Tooltip title={toolTipTitle}>{renderButtonComponent()}</Tooltip> : renderButtonComponent()}
    </AuthorizationProvider>
  )
}
