import { UserManager, WebStorageStateStore } from 'oidc-client'

export const userManager = new UserManager({
  authority: process.env.REACT_APP_IDENTITY_SERVER_URL,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  client_id: 'havbruksloggen_frontend',
  scope: 'profile openid email roles offline_access havbruksloggen_api_full picture',
  response_type: 'code',
  post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_URL,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: localStorage }),
})
