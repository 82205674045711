import { Box, Grid } from '@mui/material'
import { useCustomerCtx } from '../../../../hooks/context-hooks'
import { useGetLocalityNames } from '../../../../api/localities'
import { useGetAllResources } from '../../../../api/task-resource'
import { useTranslation } from 'react-i18next'
import { AppForm, FormSelectField, FormSubmitButton } from '../../../../components/FormCore'
import { z } from 'zod'
import { useZodForm } from '../../../../hooks/zod-form'
import { useEffect } from 'react'
import BasicDatePicker from '../../../../components/DateTimePicker'

const TasksFilter = ({ onChange, defaultValues, isDisabled = false }) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation(['common', 'task'])
  const { data } = useGetLocalityNames(customer?.id)
  const { data: resources } = useGetAllResources(customer?.id)

  const taskFilterSchema = z
    .object({
      locality: z
        .array(
          z.object({
            id: z.number(),
          }),
        )
        .nullable(),
      resource: z
        .array(
          z.object({
            id: z.number(),
          }),
        )
        .nullable(),
      startDate: z.date().optional().nullable(),
      endDate: z.date().optional().nullable(),
    })
    .refine(
      data =>
        data.endDate == undefined ||
        data.startDate == undefined ||
        (data.endDate != undefined && data.startDate != undefined && data.endDate > data.startDate),
      {
        message: t('endStartDateError', { ns: 'common' }),
        path: ['endDate'],
      },
    )

  const form = useZodForm(taskFilterSchema, { defaultValues: { locality: [], resource: [] } })

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  return (
    <Box width={'calc(100vw - 700px)'}>
      <AppForm form={form} onSubmit={onChange}>
        <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container md={10}>
            <Grid item md={3}>
              <FormSelectField
                label={t('locality')}
                variant="outlined"
                getOptionLabel={row => row.name}
                data={data || []}
                name={'locality'}
                multiple
              />
            </Grid>
            <Grid item md={4}>
              <FormSelectField
                variant="outlined"
                getOptionLabel={row => row.name}
                label={t('resource', { ns: 'task' })}
                data={resources?.items || []}
                name={'resource'}
                multiple
              />
            </Grid>
            <Grid item md={5}>
              <Grid container>
                <Grid item md={6}>
                  <BasicDatePicker disabled={isDisabled} name="startDate" label={t('startDate')} />
                </Grid>
                <Grid item md={6}>
                  <BasicDatePicker disabled={isDisabled} name="endDate" label={t('endDate')} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormSubmitButton createText={t('filter', { ns: 'common' })} />
        </Grid>
      </AppForm>
    </Box>
  )
}

export default TasksFilter
