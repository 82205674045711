import { Box, BoxProps } from '@mui/material'
import { MainButton } from '../../../components/MainButton'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { AuthorizationProvider } from '../../../components/common/Authorization'
import { AuthorizationType } from '../../../enums/auth'

interface Props extends BoxProps {
  title: string
  addNewItemText?: string
  onAddNewItemClick?: () => any
  disabledButton?: boolean
  dataTag?:string
  permissionBinaryValues?: number[]
  context?: React.Context<any> | null
  permission?: number,
}

export const ScreenTitleBar: React.FC<Props> = ({
  children,
  title,
  addNewItemText,
  onAddNewItemClick,
  disabledButton,
  dataTag,
  permissionBinaryValues,
  context,
  permission,
  ...props
}) => {
  return (
    <Box
      alignItems="stretch"
      className="flex flex-wrap justify-between gap-3 mt-1 mb-2 md:mt-0 lg:mt-0 "
      borderRadius={2}
      {...props}
    >
      <Box className="flex flex-wrap items-stretch justify-between">
        <ScreenTitle title={title} />
        {children}
      </Box>
      {onAddNewItemClick && (
        <AuthorizationProvider
          Context={context}
          data-cy={dataTag}
          permissions={permissionBinaryValues}
          permission={permission} type={AuthorizationType.Disabled}>
          <MainButton disabled={disabledButton} onClick={onAddNewItemClick}>
            {addNewItemText}
          </MainButton>
        </AuthorizationProvider>
      )}
    </Box>
  )
}
