import { AppRoutesNavigation, AppRoutesRouter } from '../../app-routes'
import {
  Category,
  Draw,
  Functions,
  Group,
  GroupAddRounded,
  Notes,
  ProductionQuantityLimits,
  Public,
  SettingsInputComponent,
  SupervisedUserCircle,
  Timer,
  ViewInAr,
  WarningAmber,
} from '@mui/icons-material'

export const adminSidebarItems = [
  {
    name: 'customers',
    path: AppRoutesNavigation.Admin.customers,
    icon: Group,
    pathname: AppRoutesRouter.Admin.customers,
  },
  {
    name: 'material-factor',
    path: AppRoutesNavigation.Admin.materialFactor,
    icon: ViewInAr,
    pathname: AppRoutesRouter.Admin.materialFactor,
  },
  {
    name: 'deviations',
    path: AppRoutesNavigation.Admin.deviations,
    icon: WarningAmber,
    pathname: AppRoutesRouter.Admin.deviations,
  },
  {
    name: 'suppliers',
    path: AppRoutesNavigation.Admin.supplier,
    icon: SupervisedUserCircle,
    pathname: AppRoutesRouter.Admin.supplier,
  },
  // {
  //   name: 'sensor-type',
  //   path: AppRoutesNavigation.Admin.sensorType,
  //   icon: MdListAlt,
  //   pathname: AppRoutesRouter.Admin.sensorType,
  // },
  {
    name: 'document-category',
    path: AppRoutesNavigation.Admin.documentCategory,
    icon: Category,
    pathname: AppRoutesRouter.Admin.documentCategory,
  },
  // {
  //   name: 'Distributor',
  //   path: AppRoutesNavigation.Admin.distributor,
  //   icon: MdListAlt,
  //   pathname: AppRoutesRouter.Admin.distributor,
  // },
  {
    name: 'product',
    path: AppRoutesNavigation.Admin.product,
    icon: ProductionQuantityLimits,
    pathname: AppRoutesRouter.Admin.product,
  },
  {
    name: 'product-categories',
    path: AppRoutesNavigation.Admin.productCategory,
    icon: Category,
    pathname: AppRoutesRouter.Admin.productCategory,
  },
  {
    name: 'position-drawing',
    path: AppRoutesNavigation.Admin.positionDrawing,
    icon: Draw,
    pathname: AppRoutesRouter.Admin.positionDrawing,
  },
  {
    name: 'audit-logs',
    path: AppRoutesNavigation.Admin.auditLogs,
    icon: Notes,
    pathname: AppRoutesRouter.Admin.auditLogs,
  },
  {
    name: 'access-controls',
    path: AppRoutesNavigation.Admin.accessControl,
    icon: GroupAddRounded,
    pathname: AppRoutesRouter.Admin.accessControl,
  },
  {
    name: 'custom-properties',
    path: AppRoutesNavigation.Admin.customProperty,
    icon: Functions,
    pathname: AppRoutesRouter.Admin.customProperty,
  },
  {
    name: 'main-component-types',
    path: AppRoutesNavigation.Admin.mainComponentTypes,
    icon: SettingsInputComponent,
    pathname: AppRoutesRouter.Admin.mainComponentTypes,
  },
  {
    name: 'global-documents',
    path: AppRoutesNavigation.Admin.globalDocuments,
    icon: Public,
    pathname: AppRoutesRouter.Admin.globalDocuments,
  },
  {
    name: 'time-report-types',
    path: AppRoutesNavigation.Admin.timeReportTypes,
    icon: Timer,
    pathname: AppRoutesRouter.Admin.timeReportTypes,
  },
]
