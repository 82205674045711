import { Box, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { invalidateCustomerCache, useAddCustomer } from '../../api/customer-admin'
import { CustomerForm } from '../../forms/CustomerForm'
import { ICustomer } from '../../generated-types'
import { ShowDialogProps } from '../../show-dialog'
import { invalidateCustomer } from 'src/api/customer'

const CreateCustomerDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const mutation = useAddCustomer()
  const { t: translate } = useTranslation(['admin-customer', 'common'])

  const onSubmit = async (form: ICustomer) => {
    await mutation.mutateAsync(form,{
      onSuccess: () => {
        invalidateCustomer.getUniqueAdminCustomers()
        invalidateCustomerCache.getCustomers()
      },
    })

    pop(true)
  }

  return (
    <Box>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
        }}
        className="text-primary"
      >
        {translate('addCustomer', { ns: 'admin-customer' })}
      </DialogTitle>
      <DialogContent>
        <CustomerForm onSubmit={onSubmit} onCancel={() => pop()} />
      </DialogContent>
    </Box>
  )
}

export default CreateCustomerDialog
