import { useTranslation } from 'react-i18next'
import { ShowDialogProps } from '../../../show-dialog'
import { invalidateGlobalDocument, useAddGlobalDocumentRule } from '../../../api/global-documents'
import { GlobalDocumentRuleForm } from './GlobalDocumentRuleForm'
import { INTERNAL_SERVER_ERROR, INVALID_END_DATE, RULES_MERGED, SAME_RULE_ERROR, SUCCESSFULLY_CREATED } from '../../../constant'
import { toast } from 'react-toastify'
import { invalidateDocumentCategoryCache } from '../../../api/document-category'
import { invalidateDocument } from '../../../api/document'

export interface GlobalDocumentRuleAddDialogProps extends ShowDialogProps {
  documentId: EntityId
  currentCustomerId?: EntityId
}

export const GlobalDocumentRuleAddDialog: React.FC<GlobalDocumentRuleAddDialogProps> = ({
  pop,
  documentId,
  currentCustomerId,
}) => {
  const { t: translate } = useTranslation(['global-documents', 'common'])

  const mutation = useAddGlobalDocumentRule(documentId)

  const onSubmit = async form => {
    const mainComponentTypeId = form.mainComponentType?.id
    const customerId = currentCustomerId ?? form.customer?.id
    const { mainComponentType: _, customer: __, ...rest } = form
    await mutation.mutateAsync({ ...rest, mainComponentTypeId, customerId }).then(
      ({ data }) => {
        if (data === SUCCESSFULLY_CREATED) {
          toast.success(translate('successfullyAdded', { ns: 'common-api-message' }))
        }
        if (data === RULES_MERGED) {
          toast.warning(translate('ruleMerged', { ns: 'global-document-rules' }))
        }
        invalidateGlobalDocument.getRules(documentId)
        invalidateGlobalDocument.forApproval()
        invalidateGlobalDocument.approved()
        invalidateGlobalDocument.getCategories()
        invalidateDocumentCategoryCache.getDocumentCategoriesWithDocumentCountByKey()
        invalidateDocument.getDocuments(customerId)

        pop()
      },
      ({ data }) => {
        if (data === SAME_RULE_ERROR) {
          toast.error(translate('sameRuleError', { ns: 'global-document-rules' }))
        }
        if (data === INTERNAL_SERVER_ERROR) {
          toast.error(translate('unExpectedError', { ns: 'common-api-message' }))
        }
        if (data === INVALID_END_DATE) {
          toast.error(translate('invalidEndDate', { ns: 'global-document-rules' }))
        }
      },
    )
  }

  return <GlobalDocumentRuleForm customerId={currentCustomerId} onSubmit={onSubmit} onCancel={pop} />
}
