import { SvgIconComponent } from '@mui/icons-material'
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import { IconType } from 'react-icons/lib'

interface Props {
  title: string
  Icon: SvgIconComponent | IconType
  onClick?: (e) => any
  active?: boolean
  disabled?: boolean
  className?: string,
  textVisibility?: boolean
}

export const SidebarItem: React.FC<Props> = ({
  active = false,
  title,
  Icon,
  onClick,
  disabled = false,
  textVisibility = true,
  className = '!rounded-md !mt-1 !drop-shadow-md',
}) => {
  return (
    <ListItemButton
      className={classNames(className, `${!active && 'hover:!bg-gray-200'}`, '!border !border-gray-600', '!gap-2', {
        ['!bg-white']: !active,
        ['!bg-primary']: active,
        ['!text-white']: active,
      })}
      key={title}
      onClick={onClick}
      disabled={disabled}
    >
      <Tooltip title={title} disableHoverListener={textVisibility}>
        <ListItemIcon sx={{ minWidth: '0' }} >
          <Icon className={active ? 'text-white' : 'text-primary'} />
        </ListItemIcon>
      </Tooltip>
      {
        textVisibility &&
        <ListItemText className={active ? 'text-white !text-sm' : 'text-typo !text-sm'}>{title}</ListItemText>
      }
    </ListItemButton>
  )
}
