import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { invalidateSchemaForm, useDeleteFormSchemaById, useGetAllFormSchema } from '../../../api/form-schema'
import { useTranslation } from 'react-i18next'
import { Box, useTheme } from '@mui/material'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../app-routes'
import { AddCircleOutline, Delete, Edit, RemoveRedEye } from '@mui/icons-material'
import { showDeleteDialog } from '../../../show-dialog'
import Form from './Form'
import { toast } from 'react-toastify'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import GenericTable from 'src/components/table/GenericTable'

const FormSchemas = () => {
  const { showDialog } = useDialogCtx()
  const { customer } = useCustomerCtx()
  const { data, isLoading } = useGetAllFormSchema(customer?.id as EntityId)
  const mutate = useDeleteFormSchemaById(customer?.id as EntityId)
  const navigate = useNavigate()
  const theme = useTheme()
  const { t: translate } = useTranslation(['common', 'form-schema'])
  const STATIC_COLUMNS = [
    {
      field: 'name',
      headerName: translate('name', { ns: 'common' }),
      flex: 5,
    },
    {
      field: 'description',
      headerName: translate('description', { ns: 'common' }),
      flex: 5,
    },
    {
      field: 'isActive',
      headerName: translate('isActive', { ns: 'common' }),
      flex: 2,
      renderCell: params => (params.row.isActive ? 'Yes' : 'No'),
    },
    {
      field: 'actions',
      headerName: t('actions', { ns: 'common' }),
      flex: 2,
      renderCell: params => (
        <Box width={200} display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
          <Edit
            className={'cursor-pointer'}
            onClick={() => {
              navigate(AppRoutesNavigation.Customer.editFormSchema(customer?.id, params.row?.id))
            }}
          />

          <RemoveRedEye
            className={'cursor-pointer'}
            onClick={() => {
              const formSchemaVersion = params?.row?.versions.reduce((previous, current) => {
                return previous.version > current.version ? previous : current
              })
              showDialog(
                Form,
                {
                  componentProps: {
                    disabled: false,
                    form: undefined,
                    onDrop: () => {},
                    addLayout: () => {},
                    deleteLayout: () => {},
                    selectFormField: () => {},
                    layouts: JSON.parse(formSchemaVersion?.layout),
                    title: params?.row?.name,
                    onRearrange: undefined,
                    pop: () => {},
                    cancelEnabled: true,
                  },
                  opts: {
                    maxWidth: 'md',
                    fullWidth: true,
                  },
                  isAutoCloseDisabled: true,
                },
                undefined,
                true,
              )
            }}
          />
          <Delete
            className={'cursor-pointer'}
            onClick={() => {
              showDeleteDialog(
                translate('delete-from-title', { ns: 'form-schema' }),
                translate('delete-from-content', { ns: 'form-schema' }),
              ).then(async value => {
                if (value) {
                  await mutate.mutateAsync(params?.row?.id)
                  invalidateSchemaForm.getAllFormSchema(customer?.id as EntityId)
                  toast.success(translate('deletedSuccessfully', { ns: 'common' }))
                }
              })
            }}
          />
        </Box>
      ),
    },
  ]
  return (
    <CommonPageLayout
      titleSection={{ title: translate('form-schema', { ns: 'form-schema' }) }}
      topAction={[
        {
          buttonText: translate('form-schema', { ns: 'page-container' }),
          buttonIcon: <AddCircleOutline />,
          onClick: () => {
            navigate(AppRoutesNavigation.Customer.formGenerator(customer?.id))
          },
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <GenericTable id="form-schemas" columns={STATIC_COLUMNS} data={data || []} autoHeight={false} height={'81vh'}/>
      </Box>
    </CommonPageLayout>
  )
}

export default FormSchemas
