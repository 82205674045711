import { useCallback, useMemo } from 'react'
import GenericTable from '../../../components/table/GenericTable'
import { GridColumns } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { getFormattedDate } from 'src/utils'
import { mainComponentsSectionHeight } from './CageContainer'
import { invalidationActivityLogCache, useDeleteActivityLog, useGetCageActivityLogs } from 'src/api/activity-log'
import { APIResultCount } from 'src/constant'
import { ActivityLogOriginKey, ActivityLogReferenceKey, ActivityType } from 'src/enums'
import { NavigationColumnContainer } from 'src/containers/TopBar/Index.style'
import { useNavigation } from 'src/context/NavigationContext'
import { AppRoutesNavigation } from 'src/app-routes'
import { Flex } from 'src/styles/flexComponent'
import { Box, Typography } from '@mui/material'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { Delete } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { toast } from 'react-toastify'

const CageActivityLog = ({ id, customerId, localityId, mooringId }) => {
  const { t: translate } = useTranslation(['common', 'activity-logs', 'activity-log-types'])
  const { navigateTo } = useNavigation()
  const { showConfirmDialog } = useDialogCtx()

  const { data, isLoading } = useGetCageActivityLogs({
    positionId: id,
    page: 0,
    pageSize: APIResultCount.Max,
  })
  const { mutateAsync } = useDeleteActivityLog()

  const onDeleteCustomActivityLog = useCallback(
    (id: EntityId) => {
      showConfirmDialog(
        translate('deleteActivityLog', { ns: 'activity-logs' }),
        translate('areYouSureDeleteActivityLog', { ns: 'activity-logs' }),
        {
          acceptText: translate('yes', { ns: 'common' }),
          cancelText: translate('no', { ns: 'common' }),
        },
        async pop => {
          await mutateAsync(id, {
            onSuccess: () => {
              toast.success(translate('activityLogDeletedSuccussfully', { ns: 'activity-logs' }))
              invalidationActivityLogCache.getCageActivityLogs()
              pop()
            },
            onError: () => {
              toast.success(translate('ErrorDeletingActivityLog', { ns: 'activity-logs' }))
            },
          })
        },
      )
    },
    [mutateAsync, showConfirmDialog, translate],
  )

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'activityType',
        headerName: translate('log-type', { ns: 'activity-logs' }).toUpperCase(),
        flex: 1,
        renderCell: params => translate(ActivityType[params.row?.activityType], { ns: 'activity-log-type' }),
      },
      {
        field: 'mainComponentName',
        headerName: translate('name', { ns: 'activity-logs' }).toUpperCase(),
        flex: 1,
        renderCell: params => params.row?.mainComponentName ?? '-',
      },
      {
        field: 'origin',
        headerName: translate('origin', { ns: 'activity-logs' }).toUpperCase(),
        flex: 1,
        renderCell: params => {
          if (params.value !== null) {
            const originKey = params.value
            return (
              <NavigationColumnContainer
                key={'origin-key'}
                onClick={() => {
                  if (originKey == ActivityLogOriginKey.ServiceStation) {
                    navigateTo(
                      AppRoutesNavigation.serviceStation.serviceStation(customerId, params.row?.serviceStationId),
                    )
                  } else if (originKey == ActivityLogOriginKey.Locality) {
                    navigateTo(AppRoutesNavigation.Localities.locality(customerId, params.row?.localityId))
                  } else if (originKey == ActivityLogOriginKey.Cage) {
                    navigateTo(
                      AppRoutesNavigation.Localities.position(
                        customerId,
                        params.row?.localityId,
                        mooringId,
                        params.row?.positionId,
                      ),
                    )
                  }
                }}
                active={true}
              >
                <Flex.Row justifyContent={'flex-start'} alignItems={'center'}>
                  <Typography variant="h6" fontSize={'14px'}>
                    {originKey == ActivityLogOriginKey.ServiceStation
                      ? params.row?.serviceStationName
                      : originKey == ActivityLogOriginKey.Locality
                      ? params.row?.localityName
                      : originKey == ActivityLogOriginKey.Cage
                      ? params.row?.positionName
                      : '-'}
                  </Typography>
                </Flex.Row>
              </NavigationColumnContainer>
            )
          } else {
            return '-'
          }
        },
      },
      {
        field: 'activityDescription',
        headerName: translate('activity-description', { ns: 'activity-logs' }).toUpperCase(),
        flex: 1,
        renderCell: params => translate(params.value, { ns: 'activity-log-type-description' }),
      },
      {
        field: 'reference',
        headerName: translate('reference', { ns: 'activity-logs' }).toUpperCase(),
        flex: 0.75,
        renderCell: params => {
          if (params.value !== null) {
            const referenceKey = params.value
            return (
              <NavigationColumnContainer
                key={'meta-data-navigate-key'}
                onClick={() => {
                  if (referenceKey == ActivityLogReferenceKey.Deviation) {
                    navigateTo(
                      AppRoutesNavigation.Localities.deviation(
                        customerId,
                        localityId,
                        params.row?.metaData['deviationId'],
                      ),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.ServiceStation) {
                    navigateTo(
                      AppRoutesNavigation.serviceStation.serviceStation(customerId, params.row?.serviceStationId),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.Locality) {
                    navigateTo(AppRoutesNavigation.Localities.locality(customerId, params.row?.localityId))
                  } else if (referenceKey == ActivityLogReferenceKey.Document) {
                    navigateTo(
                      AppRoutesNavigation.Localities.document(
                        customerId,
                        params.row?.localityId,
                        params.row?.metaData['documentId'],
                      ),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.ForCage) {
                    navigateTo(
                      AppRoutesNavigation.Localities.position(
                        customerId,
                        params.row?.localityId,
                        mooringId,
                        params.row?.metaData['forCageId'],
                      ),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.Cage) {
                    navigateTo(
                      AppRoutesNavigation.Localities.position(
                        customerId,
                        params.row?.localityId,
                        mooringId,
                        params.row?.positionId,
                      ),
                    )
                  }
                }}
                active={true}
              >
                <Flex.Row justifyContent={'flex-start'} alignItems={'center'}>
                  <Typography variant="h6" fontSize={'14px'}>
                    {referenceKey == ActivityLogReferenceKey.Deviation
                      ? params.row?.metaData['deviationTitle']
                      : referenceKey == ActivityLogReferenceKey.ServiceStation
                      ? params.row?.serviceStationName
                      : referenceKey == ActivityLogReferenceKey.Locality
                      ? params.row?.localityName
                      : referenceKey == ActivityLogReferenceKey.Document
                      ? params.row?.metaData['documentTitle']
                      : referenceKey == ActivityLogReferenceKey.Cage
                      ? params.row?.positionName
                      : referenceKey == ActivityLogReferenceKey.ForCage
                      ? params.row?.metaData != null
                        ? params.row?.metaData['forCageName']
                        : '-'
                      : '-'}
                  </Typography>
                </Flex.Row>
              </NavigationColumnContainer>
            )
          } else {
            return '-'
          }
        },
      },
      {
        field: 'logDate',
        headerName: translate('performed-date', { ns: 'activity-logs' }).toUpperCase(),
        flex: 1,
        renderCell: params => getFormattedDate(new Date(params.row?.logDate + 'Z'), 'YYYY-MM-DD, h:mm a'),
      },
      {
        field: 'effectiveDate',
        headerName: translate('effective-date', { ns: 'activity-logs' }).toUpperCase(),
        flex: 1,
        renderCell: params => {
          return params.row?.effectiveDate !== null
            ? getFormattedDate(new Date(params.row?.effectiveDate + 'Z'))
            : getFormattedDate()
        },
      },
    ],
    [customerId, localityId, mooringId, navigateTo, translate],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'action',
        headerName: '',
        width: 100,
        renderCell: ({ id, row }) => (
          <Box justifyContent={'space-evenly'} flexDirection={'row'} display={'flex'} width={'100%'}>
            <GridActionButton
              variant="outlined"
              buttonIcon={<Delete />}
              disabled={!row.isCustomLog}
              onClick={() => onDeleteCustomActivityLog(id)}
              height="36px"
              width="36px"
              shadowWeight={0}
            />
          </Box>
        ),
      },
    ],
    [STATIC_COLUMNS, onDeleteCustomActivityLog],
  )
  return (
    <>
      <GenericTable
        id={id}
        columns={columns}
        data={data?.items}
        loading={isLoading}
        height={mainComponentsSectionHeight}
        autoHeight={false}
        getRowHeight={() => 44}
        enablePagination={true}
        hideDefaultFooterPagination={true}
        enablePaginationButtons={{ showFirstButton: true, showLastButton: true }}
        pageSize={10}
      />
    </>
  )
}

export default CageActivityLog
