import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { invalidateFrequencyCache, useAddFrequency } from '../../../../api/frequency'
import { ShowDialogProps } from '../../../../show-dialog'
import { FrequencyForm } from './FrequencyForm'
import { FrequencyTable } from './FrequenciesTable'
import { toast } from 'react-toastify'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface CreateFrequencyProps extends ShowDialogProps {
  customerId: EntityId
  serviceId: EntityId
}

interface FrequenciesContainerProps {
  serviceId: EntityId
}

const CreateFrequencyDialog: React.FC<CreateFrequencyProps> = ({ pop, customerId, serviceId }) => {
  const { t } = useTranslation(['frequency', 'common'])

  const { mutateAsync } = useAddFrequency(customerId as EntityId, serviceId as EntityId)
  const onSubmit = async form => {
    const data = await mutateAsync(form, {
      onSuccess: () => {
        toast.success(t('frequencyAddedSuccessfully', { ns: 'service' }))
        invalidateFrequencyCache.getFrequencies(customerId as EntityId, serviceId as EntityId)
        pop()
      },
      onError: _ => {
        toast.error(t('addingError', { ns: 'common' }))
      },
    })
    return data
  }

  return <FrequencyForm onSubmit={onSubmit} onCancel={() => pop()} title={t('addFrequency', { ns: 'frequency' })} />
}

export const FrequenciesContainer = ({ serviceId }: FrequenciesContainerProps) => {
  const { t: translate } = useTranslation(['frequency', 'common'])
  const { id: customerId } = useParams()
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const onAddFrequency = async () => {
    await showDialog(
      CreateFrequencyDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          serviceId: serviceId as EntityId,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }

  return (
    <CommonPageLayout
      titleSection={{ title: translate('frequencies', { ns: 'frequency' }) }}
      topAction={[
        {
          buttonText: translate('addFrequency', { ns: 'frequency' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddFrequency,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <FrequencyTable serviceId={serviceId as EntityId} customerId={customerId as EntityId} />
      </Box>
    </CommonPageLayout>
  )
}
