import {
  IAdminGlobalDocumentQueryParams,
  IApprovedGlobalDocument,
  ICreateGlobalDocument,
  ICreateGlobalDocumentRule,
  ICreateGlobalDocumentVersion,
  IForApprovalGlobalDocument,
  IGlobalDocument,
  IGlobalDocumentCategoriesWithDocumentCount,
  IGlobalDocumentCounts,
  IGlobalDocumentQueryParams,
  IGlobalDocumentRule,
  IGlobalDocumentVersion,
  ISaveDraftGlobalDocument,
  IUpdateGlobalDocument,
  IWithdrawGlobalDocument,
} from '../generated-types/global-documents'
import { PaginatedResultList } from '../generated-types/paginated-result-list'
import { queryClient } from '../query-client'
import { IApiResult } from '../generated-types/api-result'
import { useGet, usePost, usePut, useDelete } from './reactQuery'

const baseUrlAdmin = () => 'admin/global-documents'
const baseUrl = () => 'global-documents'

export const globalDocumentEndpoints = {
  getDocuments() {
    return baseUrlAdmin()
  },
  getDocument(id: EntityId) {
    return `${baseUrlAdmin()}/${id}`
  },
  createDocument() {
    return baseUrlAdmin()
  },
  updateDocument(id: EntityId) {
    return `${baseUrlAdmin()}/${id}`
  },
  deleteDocument() {
    return baseUrlAdmin()
  },
  getDocumentRules(documentId: EntityId) {
    return `${baseUrlAdmin()}/${documentId}/rules`
  },
  getDocumentRule(id: EntityId, documentId: EntityId) {
    return `${baseUrlAdmin()}/${documentId}/rules/${id}`
  },
  createDocumentRule(documentId: EntityId) {
    return `${baseUrlAdmin()}/${documentId}/rules`
  },
  updateDocumentRule(id: EntityId, documentId: EntityId) {
    return `${baseUrlAdmin()}/${documentId}/rules/${id}`
  },
  deleteRule(documentId: EntityId) {
    return `${baseUrlAdmin()}/${documentId}/rules`
  },
  getDocumentVersions(id: EntityId) {
    return `${baseUrlAdmin()}/${id}/versions`
  },
  createVersion(id: EntityId) {
    return `${baseUrlAdmin()}/${id}/versions`
  },
  deleteVersion(id: EntityId) {
    return `${baseUrlAdmin()}/${id}/versions`
  },
  forApproval() {
    return `${baseUrl()}/for-approval`
  },
  approved() {
    return `${baseUrl()}/approved`
  },
  saveDraft() {
    return `${baseUrl()}/save-draft`
  },
  approve() {
    return `${baseUrl()}/approve`
  },
  withdraw() {
    return `${baseUrl()}/withdraw`
  },
  getDocumentCategories() {
    return `${baseUrl()}/categories`
  },
  getDocumentCounts() {
    return `${baseUrl()}/counts`
  },
}

export const useGetGlobalDocuments = (queryParams: IGlobalDocumentQueryParams) =>
  useGet<PaginatedResultList<IGlobalDocument>>(globalDocumentEndpoints.getDocuments(), {
    ...queryParams,
  })

export const useGetGlobalDocument = (id: EntityId) => useGet<IGlobalDocument>(globalDocumentEndpoints.getDocument(id))

export const useAddGlobalDocument = () =>
  usePost<IGlobalDocument, ICreateGlobalDocument>(globalDocumentEndpoints.createDocument())

export const useUpdateGlobalDocument = (id: EntityId) =>
  usePut<IGlobalDocument, IUpdateGlobalDocument>(globalDocumentEndpoints.updateDocument(id))

export const useDeleteDocument = () => useDelete<IGlobalDocument>(globalDocumentEndpoints.deleteDocument())

export const useGetGlobalDocumentVersions = (globalDocumentId: number) =>
  useGet<Array<IGlobalDocument>>(globalDocumentEndpoints.getDocumentVersions(globalDocumentId))

export const useAddGlobalDocumentVersion = (globalDocumentId: EntityId) =>
  usePost<IGlobalDocumentVersion, ICreateGlobalDocumentVersion>(globalDocumentEndpoints.createVersion(globalDocumentId))

export const useDeleteVersion = (globalDocumentId: number) =>
  useDelete<IGlobalDocument>(globalDocumentEndpoints.deleteVersion(globalDocumentId))

export const useGetGlobalDocumentForApproval = (queryParams: IAdminGlobalDocumentQueryParams) =>
  useGet<PaginatedResultList<IForApprovalGlobalDocument>>(globalDocumentEndpoints.forApproval(), queryParams)

export const useGetApprovedGlobalDocument = (queryParams: IAdminGlobalDocumentQueryParams) =>
  useGet<PaginatedResultList<IApprovedGlobalDocument>>(globalDocumentEndpoints.approved(), queryParams)

export const useSaveDraftGlobalDocumentVersion = () =>
  usePost<IApiResult, ISaveDraftGlobalDocument>(globalDocumentEndpoints.saveDraft())

export const useApproveGlobalDocumentVersion = () =>
  usePost<IApiResult, ISaveDraftGlobalDocument>(globalDocumentEndpoints.approve())

export const useWithdrawGlobalDocumentVersion = () =>
  usePut<IApiResult, IWithdrawGlobalDocument>(globalDocumentEndpoints.withdraw())

export const useGetGlobalDocumentRules = (
  documentId: EntityId,
  params?: { customerId: EntityId | number | undefined },
) => useGet<Array<IGlobalDocumentRule>>(globalDocumentEndpoints.getDocumentRules(documentId), params)

export const useGetGlobalDocumentRule = (id: EntityId, documentId: EntityId) =>
  useGet<IGlobalDocumentRule>(globalDocumentEndpoints.getDocumentRule(id, documentId))

export const useAddGlobalDocumentRule = (documentId: EntityId) =>
  usePost<IGlobalDocumentRule, ICreateGlobalDocumentRule>(globalDocumentEndpoints.createDocumentRule(documentId))

export const useUpdateGlobalDocumentRule = (id: EntityId, documentId: EntityId) =>
  usePut<IGlobalDocumentRule, IGlobalDocumentRule>(globalDocumentEndpoints.updateDocumentRule(id, documentId))

export const useDeleteRule = (globalDocumentId: EntityId) =>
  useDelete<IGlobalDocument>(globalDocumentEndpoints.deleteRule(globalDocumentId))

export const useGetGlobalDocumentCategories = (queryParams: { customerId: EntityId; IsApprovedDocs: boolean }) =>
  useGet<Array<IGlobalDocumentCategoriesWithDocumentCount>>(globalDocumentEndpoints.getDocumentCategories(), {
    ...queryParams,
  })

export const useGetGlobalDocumentCounts = (customerId: EntityId) =>
  useGet<IGlobalDocumentCounts>(globalDocumentEndpoints.getDocumentCounts(), {
    customerId,
  })

export const invalidateGlobalDocument = {
  getDocuments() {
    queryClient.invalidateQueries([globalDocumentEndpoints.getDocuments()])
  },
  getDocument(id: EntityId) {
    queryClient.invalidateQueries(globalDocumentEndpoints.getDocument(id))
  },
  getVersions(id: EntityId) {
    queryClient.invalidateQueries(globalDocumentEndpoints.getDocumentVersions(id))
  },
  forApproval() {
    queryClient.invalidateQueries(globalDocumentEndpoints.forApproval())
  },
  approved() {
    queryClient.invalidateQueries(globalDocumentEndpoints.approved())
  },
  getRules(id: EntityId) {
    queryClient.invalidateQueries(globalDocumentEndpoints.getDocumentRules(id))
  },
  getCategories() {
    queryClient.invalidateQueries(globalDocumentEndpoints.getDocumentCategories())
  },
  getCounts() {
    queryClient.invalidateQueries(globalDocumentEndpoints.getDocumentCounts())
  },
}
