import { ICustomer } from '../generated-types'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/customers'

export const endpoints = {
  getCustomers() {
    return `${baseUrl()}`
  },
  getCustomer(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  createCustomer() {
    return `${baseUrl()}`
  },
  updateCustomer(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  deleteCustomer() {
    return `${baseUrl()}`
  },
}

export const invalidateCustomerCache = {
  getCustomers() {
    queryClient.invalidateQueries(endpoints.getCustomers())
  },
  getCustomer(id: EntityId) {
    queryClient.invalidateQueries(endpoints.getCustomer(id))
  },
}

export const useGetCustomers = () => useGet<IPaginatedResult<ICustomer>>(endpoints.getCustomers())

export const useGetCustomer = (id: EntityId) => useGet<ICustomer>(endpoints.getCustomer(id))

export const useAddCustomer = () => usePost<ICustomer, ICustomer>(endpoints.createCustomer())

export const useUpdateCustomer = (id: EntityId) => usePut<ICustomer, ICustomer>(endpoints.updateCustomer(id))

export const useDeleteCustomer = () => useDelete<ICustomer>(endpoints.deleteCustomer())
