import { Box, Menu, MenuItem, styled } from '@mui/material'
import BasicDatePicker from '../DateTimePicker'
import { useState } from 'react'
import { getValidUntilDate } from 'src/utils'
import { useTranslation } from 'react-i18next'
import certificationDuration from '../../utils/durations.json'
import { toast } from 'react-toastify'

interface StyledMenuProps {
  form: any
  name: string
  startDate: Date
  dropdownTitle?: any
  formFieldName: string
}
export const DatePickerWithDurationDropdown: React.FC<StyledMenuProps> = ({
  form,
  name,
  startDate,
  formFieldName,
  dropdownTitle,
}) => {
  const { t: translate } = useTranslation(['common', 'certificate'])
  const [isDropdownOpen, setisDropdownOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuWidth, setMenuWidth] = useState()
  const handleChange = event => {
    if (event.target.value != null) {
      try {
        const validUntilDate = getValidUntilDate(startDate, event.target.value)
        form.setValue(formFieldName, validUntilDate)
        form.clearErrors(formFieldName)
      } catch {
        toast.error(translate('error-null-certificate-date', { ns: 'certificate' }))
      }
    }
    setAnchorEl(null)
  }

  const handleOpenDurationDropdown = event => {
    setMenuWidth(event.target.clientWidth)
    setisDropdownOpen(!isDropdownOpen)
    if (event.target.closest('svg') || event.target.closest('.MuiIconButton-root')) {
      return
    }
    !isDropdownOpen ? setAnchorEl(event.currentTarget) : setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box>
      <BasicDatePicker
        label={translate('validUntil', { ns: 'certificate' })}
        name={name}
        onClick={handleOpenDurationDropdown}
        onFocus={handleOpenDurationDropdown}
      />
      <Styledmenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem sx={{ width: menuWidth }} disabled>
          {dropdownTitle}
        </MenuItem>
        {certificationDuration.map(option => (
          <MenuItem onClick={handleChange} key={option.id} value={option.value} sx={{ width: menuWidth }}>
            {translate(option.key, { ns: 'certificate' })}
          </MenuItem>
        ))}
      </Styledmenu>
    </Box>
  )
}

const Styledmenu = styled(Menu)(props => ({
  '& .MuiPopover-paper': {
    li: {
      font: 'inherit',
      color: props.theme.palette.black[props.theme.palette.mode],
      fontSize: '14px',
      fontWeight: 400,
    },
  },
}))
