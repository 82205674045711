import { FC, useEffect } from 'react'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { z } from 'zod'
import { AppForm, FormSelectField } from '../../../components/FormCore'
import { Box } from '@mui/system'
import { useGetServiceStationGroups } from '../../../api/service-station-group'
import { useTranslation } from 'react-i18next'
import { IServiceStationGroup } from '../../../generated-types'
import { invalidateServiceStationCache, useUpdateServiceStationGroups } from '../../../api/service-station'
import { MainButton } from '../../../components/MainButton'
import { useZodForm } from '../../../hooks/zod-form'
import { ShowDialogProps } from '../../../show-dialog'
import { APIResultCount } from '../../../constant'
import { toast } from 'react-toastify'

interface AddServiceStationGroupsDialogProps extends ShowDialogProps { 
  customerId: EntityId
  serviceStationId: EntityId
}

export const AddServiceStationGroupsDialog: FC<AddServiceStationGroupsDialogProps> = ({ serviceStationId, customerId, pop }) => {
  const { mutateAsync: updateServiceStationGroups } = useUpdateServiceStationGroups(customerId, serviceStationId)
  const { t } = useTranslation(['service-station-group', 'service-station'])
  const { data: serviceStationGroups,refetch } = useGetServiceStationGroups(customerId, { page: 0, pageSize: APIResultCount.Max, serviceStationId})
  const schema = z.object({
    groups: z.array(z.object({ id: z.number() })).transform(data => data.map(item => item.id)),
  })
  const form = useZodForm(schema)

  useEffect(() => {
    refetch()
  }, [serviceStationId])

  const handleSubmit = async form => {
    updateServiceStationGroups(form, {
      onSuccess() {
        toast.success(t('service-station-group-added-successfully', { ns: 'service-station-group' }))
        invalidateServiceStationCache.getServiceStationGroups(customerId, serviceStationId)
        pop()
      },
      onError() {
        toast.success(t('service-station-group-add-error', { ns: 'service-station' }))
      },
    })
  }

  return (
    <GenericDialogLayout
      title={t('add-service-station-groups', { ns: 'service-station' })}
      removedCloseButton={false}
      actions={[
        <MainButton
          key="submit"
          onClick={() => {
            form.handleSubmit(handleSubmit)()
          }}
        >
          {t('add', { ns: 'common' })}
        </MainButton>,
      ]}
      pop={pop}
    >
      <AppForm form={form}>
        <Box py={1}>
          <FormSelectField
            multiple
            noOptionsText={t('no-service-station-groups', { ns: 'service-station' })}
            name="groups"
            data={serviceStationGroups?.items || []}
            defaultValue={[]}
            label={t('service-station-groups', { ns: 'service-station' })}
            getOptionLabel={(option: IServiceStationGroup) => option.name}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {option.name}
              </Box>
            )}
            groupBy={(option: any) => option.type}
            isOptionEqualToValue={(o, v) => o.id === v.id}
          />
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
