import React, { createContext, useEffect, useState } from 'react'
import { IMooring, IPosition } from 'src/generated-types'
import { ISelectedPositionTypes } from 'src/generated-types/position-request-params'

interface MooringsContextProps {
  selectedPosition?: IPosition
  setSelectedPosition: (position?: IPosition) => void
  selectedMooring?: IMooring
  setSelectedMooring: (mooring?: IMooring) => void
  selectedMoorings: IMooring[]
  setSelectedMoorings: (moorings: IMooring[]) => void
  selectedPositions: IPosition[]
  setSelectedPositions: (positions: IPosition[]) => void
  positionTypeFilter: ISelectedPositionTypes
  setPositionTypeFilter: (positionTypeFilter: ISelectedPositionTypes) => void
}

export const MooringsCtx = createContext<MooringsContextProps>(null!)

export const MooringsContextProvider: React.FC = ({ children }) => {
  const [selectedPosition, setSelectedPosition] = useState<IPosition | undefined>()
  const [selectedMooring, setSelectedMooring] = useState<IMooring | undefined>()
  const [selectedMoorings, setSelectedMoorings] = useState<IMooring[]>([])
  const [selectedPositions, setSelectedPositions] = useState<IPosition[]>([])
  const [positionTypeFilter, setPositionTypeFilter] = useState<ISelectedPositionTypes>({
    mooringLines: false,
    corners: false,
    bridles: false,
    frameLines: false,
    cages: false,
  })

  useEffect(() => {
    selectedMoorings.forEach(mooring => {
      mooring.positions.forEach(position => {
        position.isTransparent = selectedPosition ? position.id != selectedPosition?.id : false
      })
    })

    if (
      selectedPosition &&
      selectedMoorings.length > 0 &&
      !selectedMoorings?.map(m => m.id).includes(selectedPosition.mooringId)
    ) {
      setSelectedPosition(undefined)
    }
  }, [selectedMoorings, selectedPosition])

  useEffect(() => {
    return () => {
      selectedMoorings.forEach(mooring => {
        mooring.positions.forEach(position => {
          position.isTransparent = false
        })
      })
    }
  }, [])

  const contextValue: MooringsContextProps = {
    selectedPosition: selectedPosition,
    selectedMooring: selectedMooring,
    selectedMoorings: selectedMoorings,
    selectedPositions: selectedPositions,
    positionTypeFilter: positionTypeFilter,
    setSelectedPosition: setSelectedPosition,
    setSelectedMooring: setSelectedMooring,
    setSelectedMoorings: setSelectedMoorings,
    setSelectedPositions: setSelectedPositions,
    setPositionTypeFilter: setPositionTypeFilter,
  }

  return <MooringsCtx.Provider value={contextValue}>{children}</MooringsCtx.Provider>
}
