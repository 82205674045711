import { LatLngExpression } from 'leaflet'
import { useMemo } from 'react'
import { IPosition, IMooringType } from 'src/generated-types'
import { MapComponent } from '../components/map/MapComponent'

interface ICageMap {
  positions: IPosition[]
  position: IPosition
  type: IMooringType | undefined
}

const CageMap: React.FC<ICageMap> = ({ positions, type, position }) => {
  const centerLat = useMemo(
    () =>
      position?.connectedPositions && position?.connectedPositions.length > 0
        ? (position?.connectedPositions[0].latitude + position?.connectedPositions[1]?.latitude) / 2
        : position && position.latitude !== 0
        ? position.latitude
        : positions && positions.length > 0
        ? positions[0].latitude
        : 0,
    [positions, position],
  )

  const centerLng = useMemo(
    () =>
      position?.connectedPositions && position?.connectedPositions.length > 0
        ? (position?.connectedPositions[0].longitude + position?.connectedPositions[1]?.longitude) / 2
        : position && position.longitude !== 0
        ? position.longitude
        : positions && positions.length > 0
        ? positions[0].longitude
        : 0,
    [positions, position],
  )

  return (
    <MapComponent
      zoom={17}
      positions={positions}
      center={[centerLat, centerLng] as LatLngExpression}
      mooringType={type}
    />
  )
}

export default CageMap
