import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { invalidateDeviation, useAddAttachments } from 'src/api/deviation'
import { ShowDialogProps } from 'src/show-dialog'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { useZodForm } from 'src/hooks/zod-form'
import { z } from 'zod'
import { AppForm, FormFileCoreUpload } from 'src/components/FormCore'
import { ReactComponent as FileIcon } from 'src/assets/svg/fileUploadIcon.svg'
import { MainButton } from 'src/components/MainButton'
import { toast } from 'react-toastify'

interface CreateAttachmentProps extends ShowDialogProps {
  customerId: EntityId
  deviationId: EntityId
}

export const CreateAttachment: FC<CreateAttachmentProps> = ({ customerId, deviationId, pop }) => {
  const { mutateAsync: addAttachmentsAsync, isLoading: addingAttachment } = useAddAttachments(customerId, deviationId)
  const { t } = useTranslation('file-upload')

  const saveAttachment = async form => {
    await addAttachmentsAsync(
      { ...form },
      {
        onSuccess: () => {
          invalidateDeviation.getDeviationDocuments(customerId, deviationId)
          toast.success(t('attachments-saved', { ns: 'document' }))
          pop()
        },
        onError: () => {
          toast.error(t('attachments-not-saved', { ns: 'document' }))
        },
      },
    )
    invalidateDeviation.getDeviationDocuments(customerId, deviationId)
  }

  const schema = z.object({
    fileIds: z.union([z.array(z.string()).min(1), z.array(z.number()).min(1)]),
  })

  const form = useZodForm(schema)

  return (
      <AppForm form={form}>
        <GenericDialogLayout
          title={t('create-new-attachments', { ns: 'document' })}
          pop={pop}
          removedCloseButton={false}
          actions={[
            <MainButton
              loading={addingAttachment}
              key="cert-submit"
              onClick={() => {
                form.handleSubmit(saveAttachment)()
              }}
            >
              {t('save-attachments', { ns: 'document' })}
            </MainButton>,
          ]}
        >
          <FormFileCoreUpload
            label={t('fileName', { ns: 'common' })}
            name="fileIds"
            mapFileKey="id"
            multiple
            required
            FileIcon={FileIcon}
            height='225px'
          />
        </GenericDialogLayout>
      </AppForm>
  )
}
