import { Grid, Stack, styled, Typography, useTheme } from '@mui/material'
import React, { FC } from 'react'
import GenericCard, { IGenericCard } from 'src/components/GenericCard'
import { IMooringType } from 'src/generated-types'
import { useTranslation } from 'react-i18next'
import { getFormattedDate } from 'src/utils'

interface MooringSelectCardProps extends Omit<IGenericCard, 'actions'> {
  mooring: any
  isSelected: boolean
  onClick: () => void
}

const MooringSelectCard: FC<MooringSelectCardProps> = ({ mooring, isSelected, onClick, ...props }) => {
  const { t: translate } = useTranslation(['common', 'mooring'])
  const theme = useTheme()
  return (
    <StyledCard
      title={translate(IMooringType[mooring.type], { ns: 'mooring' })}
      headerIcon={undefined}
      maxHeight={'72px'}
      isKebabMenu={false}
      isSelected={isSelected}
      titleTypographyProps={{ variant: 'h5', fontSize: '18px' }}
      minWidth={'230px'}
      maxLength={13}
      actions={[]}
      onClick={() => onClick()}
      sx={{ cursor: 'pointer', padding: '6px 14px 0px 14px' }}
      {...props}
    >
      <Grid item xs={12}>
        <Stack direction={'row'} gap={1} justifyContent={'flex-start'} alignItems={'left'} paddingBottom={'4px'}>
          <Typography
            sx={{ color: isSelected ? theme.palette.white[theme.palette.mode] : '' }}
            variant={'body2'}
            fontWeight={'normal'}
          >
            {translate('installationDate', { ns: 'mooring' })} : {getFormattedDate(mooring.installationDate)}
          </Typography>
        </Stack>
      </Grid>
    </StyledCard>
  )
}

export default MooringSelectCard

const StyledCard = styled(GenericCard)(() => ({
  '& .MuiCardContent-root': {
    padding: '2px 12px !important',
  },
}))
