import { useTranslation } from 'react-i18next'
import { ProductCertificationTable } from './productCertificationTable'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import React from 'react'

interface ProductCertificationListFormProps {
  productId: EntityId
  pop: (...args: any[]) => any
}

export const ProductCertificationListForm: React.FC<ProductCertificationListFormProps> = ({ productId, pop }) => {
  const { t: translate } = useTranslation(['product-certification', 'common'])

  return (
    <GenericDialogLayout
      title={translate('certificationList', { ns: 'product-certification' })}
      removedCloseButton={false}
      pop={pop}
    >
      <ProductCertificationTable productId={productId} />
    </GenericDialogLayout>
  )
}
