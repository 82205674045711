import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useZodForm } from '../../../../hooks/zod-form'
import { AppForm, FormSelectField, FormSubmitButton, FormTextField } from '../../../../components/FormCore'
import { Box } from '@mui/material'
import { t } from 'i18next'
import { useGetAllUnAssignedResources } from '../../../../api/task-resource'
import { CancelButton } from '../../../../components/CancelButton'
import { IResource } from '../../../../generated-types/resource'
import BasicDatePicker from '../../../../components/DateTimePicker'
import { dateWithoutTimeConvert } from '../../../../utils/function'
import { TaskTypes } from '../../../../enums'

const AssignResourceForm = ({ initialValues, onSubmit, customerId, pop, task }) => {
  const { t: translate } = useTranslation(['locality', 'common', 'task'])
  const { data: resources } = useGetAllUnAssignedResources(customerId)
  const schema = z
    .object({
      resourceId: z.number(),
      description: z
        .string()
        .nonempty(translate('descriptionIsRequired', { ns: 'common' }))
        .max(400),
      startDate: z.string().or(z.date()).optional(),
      endDate: z.string().or(z.date()).optional(),
      resource: z.object({
        id: z.number(),
      }),
      resourceDescription: z.string(),
    })
    .refine(
      values =>
        task.type == TaskTypes.Once ||
        (task.type == TaskTypes.Repeatable && values.startDate != null && values.startDate != ''),
      { path: ['startDate'], message: t('required', { ns: 'common' }) },
    )
    .refine(
      values =>
        task.type == TaskTypes.Once ||
        (task.type == TaskTypes.Repeatable && values.endDate != null && values.endDate != ''),
      { path: ['endDate'], message: t('required', { ns: 'common' }) },
    )
    .refine(
      values =>
        task.type == TaskTypes.Once ||
        (values.startDate != null &&
          values.endDate != null &&
          dateWithoutTimeConvert(values.startDate) < dateWithoutTimeConvert(values.endDate)),
      { message: t('endStartDateError', { ns: 'common' }), path: ['startDate'] },
    )
    .refine(
      values =>
        task.type == TaskTypes.Once ||
        (values.startDate != null &&
          dateWithoutTimeConvert(task.startDate) <= dateWithoutTimeConvert(values.startDate)),
      { message: t('resource-start-date-range', { ns: 'task' }), path: ['startDate'] },
    )
    .refine(
      values =>
        task.type == TaskTypes.Once ||
        (values.endDate != null && dateWithoutTimeConvert(task.endDate) >= dateWithoutTimeConvert(values.endDate)),
      { message: t('resource-end-date-range', { ns: 'task' }), path: ['endDate'] },
    )

  const form = useZodForm(schema, {
    defaultValues: {
      ...initialValues,
      ...{ startDate: new Date(task.startDate).toISOString(), endDate: new Date(task.endDate).toISOString() },
    },
  })

  const onSubmitHandle = useCallback(async formValues => {
    await onSubmit(formValues)
    pop()
  }, [])

  return (
    <AppForm form={form} onSubmit={onSubmitHandle} hasInitialValues={initialValues}>
      <FormSelectField
        variant="outlined"
        getOptionLabel={row => row.name}
        label={t('resource', { ns: 'task' })}
        data={resources?.items || []}
        name={'resource'}
        clearOnBlur={false}
        clearOnEscape={false}
        onChange={(evt, selectedValue) => {
          form.setValue('resource', selectedValue as IResource)
          form.setValue('resourceId', (selectedValue as IResource).id)
          form.setValue('resourceDescription', (selectedValue as IResource).description as string)
        }}
      />
      {task.type == TaskTypes.Repeatable && (
        <>
          <BasicDatePicker label={translate('startDate', { ns: 'common' })} name="startDate" />
          <BasicDatePicker label={translate('endDate', { ns: 'common' })} name="endDate" />
        </>
      )}
      <FormTextField
        name="resourceDescription"
        label={translate('resource-description', { ns: 'task' })}
        multiline
        rows={3}
        inputProps={{ readOnly: true }}
        focused
      />
      <FormTextField rows={5} multiline name="description" label={translate('description', { ns: 'common' })} />
      <Box className="flex justify-end gap-4 pt-4">
        <CancelButton onClick={pop}>{translate('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton createText={translate('add', { ns: 'common' })} />
      </Box>
    </AppForm>
  )
}

export default AssignResourceForm
