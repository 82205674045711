import { useTranslation } from 'react-i18next'
import { CenteredSpinner } from '../../../components/Spinner'
import { IMainComponentType } from '../../../generated-types/main-component-type'
import { ICustomProperty } from '../../../generated-types/customProperty'
import { IFormType } from '../../../generated-types/form-type'
import { ShowDialogProps } from '../../../show-dialog'
import { MainComponentTypeForm } from '../../main-component-type/MainComponentTypeForm'
import {
  invalidateMainComponentTypeCache,
  useUpdateMainComponentTypeAdmin,
  useGetMainComponentTypeAdmin,
  useGetMainComponentCustomPropertiesAdmin,
} from '../../../api/main-component-type'
import { IPrivilegeLevel } from '../../../enums'
import { useEffect } from 'react'
import { ALREADY_EXISTS } from '../../../constant'
import { toast } from 'react-toastify'
import { useGetCustomPropertiesByAdmin } from '../../../api/custom-property'

interface UpdateMainComponentTypeProps extends ShowDialogProps {
  id: EntityId
}

export const UpdateMainComponentTypeDialog: React.FC<UpdateMainComponentTypeProps> = ({ pop, id }) => {
  const { t: translate } = useTranslation(['main-component-type', 'common'])
  const { mutateAsync, error, isLoading: isLoadingUpdate } = useUpdateMainComponentTypeAdmin(id as EntityId)

  useEffect(() => {
    if (error && error['data'] == ALREADY_EXISTS) {
      toast(translate('already-exists'), { type: 'error' })
    }
  }, [error])

  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        toast(translate('successfully-updated'), { type: 'success' })
        invalidateMainComponentTypeCache.useGetMainComponentTypesAdmin()
        invalidateMainComponentTypeCache.useGetMainComponentTypeAdmin(id)
        invalidateMainComponentTypeCache.useGetMainComponentCustomPropertiesAdmin(id)
        pop(true)
      },
      onError: () => {
        toast.error(translate('updatingError', { ns: 'common' }))
      },
    })
  }

  const { data, isLoading } = useGetMainComponentTypeAdmin(id)
  const { data: userCustomProperties } = useGetCustomPropertiesByAdmin()
  const { data: customTypes } = useGetMainComponentCustomPropertiesAdmin(id)

  if (isLoading) return <CenteredSpinner />

  return (
    <MainComponentTypeForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={data as IMainComponentType}
      componentTypeCustomProperties={customTypes}
      userCustomProperties={userCustomProperties?.items as ICustomProperty[]}
      formType={IFormType.Update}
      privilegeLevel={IPrivilegeLevel.SuperAdmin}
      isLoading={isLoadingUpdate}
      title={translate('updateMainComponentType', { ns: 'main-component-type' })}
    />
  )
}
