import { Box, Grid } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useUpdateDocument, invalidateDocument } from '../../../../api/document'
import { invalidateDocumentCategoryCache } from '../../../../api/document-category'
import BasicDatePicker from '../../../../components/DateTimePicker'
import { AppForm, FormTextField, FormSelectField, FormFileCoreUpload } from '../../../../components/FormCore'
import { IDocument } from '../../../../generated-types/document'
import { useZodForm } from '../../../../hooks/zod-form'
import { ShowDialogProps } from '../../../../show-dialog'
import { useGetFile } from '../../../../api/file'
import { GenericDialogLayout } from '../../../../components/GenericDialogLayout'
import { MainButton } from '../../../../components/MainButton'
import { IDocumentCategory } from '../../../../generated-types'
import { invalidateDocumentCategory } from 'src/api/users/document-category'
import { invalidateLocalityCache } from 'src/api/localities'
import { useGetAllMooringsByLocalityId } from 'src/api/moorings'
import { MooringDropdownDto } from 'src/generated-types/mooring-drop-down-dto'
import { useGetCustomerAndAdminDocumentCategories } from 'src/api/customer-document-category'

interface UpdateDocumentDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  document: IDocument
  positionId: EntityId
}
export const EditDocumentDialog: React.FC<UpdateDocumentDialogProps> = ({
  pop,
  customerId,
  localityId,
  document,
  positionId,
}) => {
  const { t } = useTranslation(['Document', 'common'])

  const documentTypes = useGetCustomerAndAdminDocumentCategories(customerId)
  const { data: moorings } = useGetAllMooringsByLocalityId(customerId as EntityId, localityId as EntityId)

  const { data: file, isLoading } = useGetFile(document?.fileId)

  const schema = z.object({
    title: z.string().min(1),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    mooringId: z
      .object({
        id: z.number(),
      })
      .or(z.null())
      .optional()
      .transform(value => (typeof value == 'object' ? value?.id : value)),
    dueDate: z.date().or(z.string().nullable()).optional(),
    notes: z.string().optional(),
    version: z.string().optional(),
    fileId: z.number().default(document?.fileId),
  })

  const form = useZodForm(schema, {
    defaultValues: (() => {
      const { documentCategory, mooringId, ...rest } = document
      return { ...rest, documentCategoryId: documentCategory, mooringId: moorings?.find(m => m.id == mooringId) }
    })(),
  })

  const mutate = useUpdateDocument(customerId, document.id)
  const onSubmit = useCallback(
    async form => {
      await mutate.mutateAsync(form)
      if (document.documentCategory?.id !== form.documentCategoryId) {
        invalidateDocumentCategoryCache.getDocumentCategoriesWithDocuments()
        invalidateDocument.getDocuments(customerId)
      } else {
        invalidateDocument.getDocuments(customerId)
      }
      invalidateDocument.getDocumentsHistory(customerId, document.id)
      invalidateDocument.getExpireCounts(customerId, localityId)
      invalidateDocumentCategory.getDocumentCategoriesWithDocuments(customerId, { positionId })
      invalidateLocalityCache.getLocalitiesWithInfoCount(customerId)
      pop(true)
    },
    [mutate, document.documentCategory?.id, document.id, customerId, localityId, positionId, pop],
  )

  const onClose = () => {
    pop()
  }

  return (
    <GenericDialogLayout
      title={t('updateDocument', { ns: 'document' })}
      pop={onClose}
      removedCloseButton={false}
      actions={[
        <MainButton
          key="update-document"
          loading={isLoading}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
          data-cy="UpdateDocument"
        >
          {t('update', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={12} sm={6} data-cy="docTitle">
            <FormTextField name="title" label={t('title', { ns: 'common' })} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} data-cy="docType">
            <FormSelectField
              name="documentCategoryId"
              data={(documentTypes?.data?.items as IDocumentCategory[]) ?? []}
              label={t('type', { ns: 'common' })}
              getOptionLabel={option => t(option.categoryName)}
              renderOption={(props: any, option) => (
                <Box {...props} key={option}>
                  {t(option.categoryName)}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o === v}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BasicDatePicker label={t('dueDate', { ns: 'common' })} name="dueDate" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="version" label={t('version', { ns: 'global-documents' })} fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name="mooringId"
              data={moorings as MooringDropdownDto[]}
              label={t('mooring', { ns: 'common' })}
              getOptionLabel={option => t(option.name)}
              renderOption={(props: any, option) => (
                <Box {...props} key={option}>
                  {t(option.name)}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o === v}
            />
          </Grid>
        </Grid>
        <FormTextField name="notes" label={t('notes', { ns: 'common' })} fullWidth multiline rows={4} />
        <Box mb={2}>
          <FormFileCoreUpload
            required
            label={t('fileName', { ns: 'common' })}
            name="fileId"
            mapFileKey="id"
            initialFiles={file}
            isDeletable={false}
            height="150px"
          />
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
