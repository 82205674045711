import { IProductCategory } from '../generated-types/product-category'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/product-categories'

export const productCategoryEndpoints = {
  getProductCategories() {
    return `${baseUrl()}/get-all-product-categories`
  },
  getProductCategoriesForDropDown() {
    return `${baseUrl()}/get-all-for-drow-down`
  },
  getProductCategorById(id: EntityId) {
    return `${baseUrl()}/${id}/get-product-category-by-id`
  },
  addProductCategory() {
    return `${baseUrl()}/create-product-category`
  },
  updateProductCategory(id: EntityId) {
    return `${baseUrl()}/${id}/update-product-category`
  },
  deleteProductCategory() {
    return `${baseUrl()}/delete-product-category`
  },
}

export const invalidateProductCategoryCache = {
  getProductCategories() {
    queryClient.invalidateQueries(productCategoryEndpoints.getProductCategories())
  },
  getProductCategoriesForDropDown() {
    queryClient.invalidateQueries(productCategoryEndpoints.getProductCategoriesForDropDown())
  },
  getProductCategorById(id: EntityId){
    queryClient.invalidateQueries(productCategoryEndpoints.getProductCategorById(id))
  }
}

export const useGetProductCategories = () =>
  useGet<IPaginatedResult<IProductCategory>>(productCategoryEndpoints.getProductCategories())

export const useGetProductCategoryById = (productCategoryId: EntityId) =>
  useGet<IProductCategory>(productCategoryEndpoints.getProductCategorById(productCategoryId))

export const useGetProductCategoriesForDropDown = () =>
  useGet<Array<IProductCategory>>(productCategoryEndpoints.getProductCategoriesForDropDown())

export const useAddProductCategory = () =>
  usePost<IProductCategory, IProductCategory>(productCategoryEndpoints.addProductCategory())

export const useUpdateProductCategory = (id: EntityId) =>
  usePut<IProductCategory, IProductCategory>(productCategoryEndpoints.updateProductCategory(id))

export const useDeleteProductCategory = () =>
  useDelete<IProductCategory>(productCategoryEndpoints.deleteProductCategory())
