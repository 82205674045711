import { Box, BoxProps, CircularProgressProps } from '@mui/material'
import React from 'react'
import Circle from '../assets/circle.svg'

export const Spinner: React.FC<CircularProgressProps> = props => {
  return (
    <img src={Circle} className='spinner' style={{ height: props.size, width: props.size }} />
  )
}

interface CenteredSpinnerProps extends CircularProgressProps {
  containerProps?: BoxProps
 }

export const CenteredSpinner: React.FC<CenteredSpinnerProps> = ({containerProps,...props}) => {
  return (
    <Box className="flex items-center justify-center min-h-screen" minWidth='100%' {...containerProps}>
      <Spinner {...props} />
    </Box>
  )
}