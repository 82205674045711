import React from 'react'
import FormGenerator from './FormGenerator'
import { FormRow } from '../../../generated-types/form-field'
import { invalidateSchemaForm, useAddFormSchema } from '../../../api/form-schema'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { IFormSchema, IFormSchemaGeneric, IFormSchemaVersion } from '../../../generated-types/form-schema'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../app-routes'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const AddFormSchema = () => {
  const { customer } = useCustomerCtx()
  const mutation = useAddFormSchema(customer.id as EntityId)
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'form-schema-report'])

  return (
    <FormGenerator
      onCancel={() => {
        navigate(AppRoutesNavigation.Customer.formSchema(customer?.id))
      }}
      buttonTitle={t('save')}
      onSubmit={async (value: FormRow[], details: IFormSchemaGeneric) => {
        const formSchemaVersion: IFormSchemaVersion = {
          layout: JSON.stringify(value),
          version: 1,
        }
        const formSchema: IFormSchema = {
          name: details.name,
          description: details.description,
          hasVideoUpload: details.hasVideoUpload,
          isActive: details.isActive,
          customerId: customer.id,
          versions: [formSchemaVersion],
        }
        await mutation.mutateAsync(formSchema, {
          onSuccess: () => {
            invalidateSchemaForm.getAllFormSchema(customer?.id as EntityId)
            toast.success(t('form-schema-added-successfully', { ns: 'form-schema-report' }))
            navigate(AppRoutesNavigation.Customer.formSchema(customer?.id))
          },
        })
      }}
    />
  )
}

export default AddFormSchema
