import { createContext } from 'react'
import { ICustomer } from '../generated-types'

export const CustomerCtx = createContext<Props>(null!)

interface Props {
  customer: ICustomer
  permission: number
}

export const CustomerCtxProvider: React.FC<Props> = ({ children, customer, permission }) => {

  return <CustomerCtx.Provider value={{ customer, permission: permission }}>
    {permission != -1 && children}
  </CustomerCtx.Provider>
}
