import { AddCircleOutline } from '@mui/icons-material'
import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { MUIThemeColors } from 'src/mui-theme'
import { ShowDialogProps } from 'src/show-dialog'
import { getThemeColor } from 'src/utils'
import { DocumentCategoryForm } from './DocumentCategoryForm'
import { useCustomerCtx, useDialogCtx } from 'src/hooks/context-hooks'
import { DocumentCategoryTable } from './DocumentCategoryTable'
import {
  invalidateCustomerDocumentCategoryCache,
  useAddCustomerDocumentCategory,
} from 'src/api/customer-document-category'
import { toast } from 'react-toastify'

export const DocumentCategoriesContainer = () => {
  const { t: translate } = useTranslation(['document-category', 'common', 'common-api-message'])
  const { showDialog } = useDialogCtx()
  const theme = useTheme()
  const { customer } = useCustomerCtx()
  const mutation = useAddCustomerDocumentCategory(customer?.id)

  const CreateDocumentCategoryDialog: React.FC<ShowDialogProps> = ({ pop }) => {
    const { t: translate } = useTranslation(['documentcategory', 'common'])

    const onSubmit = async form => {
      await mutation.mutateAsync(form, {
        onSuccess: () => {
          invalidateCustomerDocumentCategoryCache.getCustomerDocumentCategories(customer?.id)
          invalidateCustomerDocumentCategoryCache.getCustomerAndAdminDocumentCategories(customer?.id)
          pop(true)
        },
        onError: () => {
          toast(translate('CannotAddDocumentCategory', { ns: 'document-category' }), { type: 'warning' })
        },
      })
    }

    return (
      <DocumentCategoryForm
        onSubmit={onSubmit}
        onCancel={() => pop()}
        title={translate('addDocumentCategory', { ns: 'document-category' })}
      />
    )
  }

  const onAddDocumentCategory = async () => {
    const added = await showDialog(
      CreateDocumentCategoryDialog,
      {
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <>
      <CommonPageLayout
        titleSection={{ title: translate('documentCategories', { ns: 'document-category' }) }}
        topAction={[
          {
            buttonText: translate('document-category', { ns: 'document-category' }),
            buttonIcon: <AddCircleOutline />,
            onClick: onAddDocumentCategory,
            variant: 'outlined',
            shadowWeight: null,
            border: 0,
          },
        ]}
      >
        <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
          <DocumentCategoryTable />
        </Box>
      </CommonPageLayout>
    </>
  )
}
