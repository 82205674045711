import { ButtonTypeMap } from '@mui/material'
import React, { ButtonHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { GridActionButton } from './common/GridActionButton'

interface ExtraProps {
  loading?: boolean
  onClick?: (...args: any[]) => any
  type?: ButtonHTMLAttributes<any>['type']
  className?: string
}

export type CancelButtonProps = ButtonTypeMap<ExtraProps>['props']

export const CancelButton: React.FC<CancelButtonProps> = ({ loading, ...rest }) => {
  const { t } = useTranslation(['common'])

  return (
    <GridActionButton variant='outlined' shadowWeight={0} border={1} height='44px' width='120px' {...rest} buttonText={t('cancel', { ns: 'common' })}/>
  )
}