import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CreateMainComponentTypeDialog, CreateMainComponentTypeProps } from '../mainComponents/MainComponentDashboard'
import { MainComponentTypeTable } from '../../main-component-type/MainComponentTypeTable'
import {
  useDeleteMainComponentType,
  invalidateMainComponentTypeCache,
  customerMainComponentTypeEndpoints,
} from '../../../api/main-component-type'
import { UpdateMainComponentTypeDialog } from './CustomerUpdateMainComponentTypeDialog'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { COMPONENT_EXISTS } from '../../../constant'
import { IPrivilegeLevel } from '../../../enums'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const MainComponentTypesContainer = () => {
  const { t: translate } = useTranslation(['main-component-type', 'common'])
  const { id: customerId } = useParams()
  const theme = useTheme()
  const { showDialog, showConfirmDialog } = useDialogCtx()

  const onAddMainComponentType = async () => {
    const added = await showDialog(
      CreateMainComponentTypeDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          privilegeLevel: IPrivilegeLevel.Admin,
        } as CreateMainComponentTypeProps,
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const { mutateAsync, isLoading: isDeleting, error } = useDeleteMainComponentType(customerId as EntityId)
  useEffect(() => {
    if (error && error['data'] == COMPONENT_EXISTS) {
      toast(translate('component-exists'), { type: 'error' })
    }
  }, [error])

  const onDelete = (id: EntityId) => {
    showConfirmDialog(translate('deleteMainComponentType'), translate('areYouSureDeleteMainComponentType')).then(
      async e => {
        if (e) {
          await mutateAsync(id, {
            onSuccess: () => {
              toast(translate('type-deleted'), { type: 'success' })
              invalidateMainComponentTypeCache.useGetMainComponentTypes(customerId as EntityId)
              invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId as EntityId)
            },
            onError: () => {
              toast.error(translate('deletingError', { ns: 'common' }))
            },
          })
        }
      },
    )
  }

  const onUpdate = async id => {
    const added = await showDialog(
      UpdateMainComponentTypeDialog,
      {
        componentProps: { id: id as EntityId, customerId: customerId as EntityId },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <CommonPageLayout
      titleSection={{ title: translate('main-component-types') }}
      topAction={[
        {
          buttonText: translate('mainComponent-type', { ns: 'page-container' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddMainComponentType,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <MainComponentTypeTable
          baseQuery={customerMainComponentTypeEndpoints.getComponentTypes(customerId as EntityId)}
          onDelete={onDelete}
          isDeleting={isDeleting}
          onUpdate={onUpdate}
          isAdmin={false}
        />
      </Box>
    </CommonPageLayout>
  )
}
