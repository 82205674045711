import { css } from '@mui/material'

export const padding = {
    p1: css`
        padding:1px;
        `,
    p2: css`
        padding:2px;
    `,
    p4: css`
        padding:4px;
    `,
    p6: css`
        padding:6px;
    `,
    p8: css`
        padding:8px;
        @media only screen and (max-width: 600px) {
            padding:4px;
        }
    `,
    p10: css`
        padding:10px;
    `,
    p12: css`
        padding:12px;
    `,
    p14: css`
        padding:14px;
    `,
    p16: css`
        padding:16px;
        @media only screen and (max-width: 600px) {
            padding:8px;
        }
    `,
    p22: css`
        padding:22px;
    `,
    p20: css`
        padding:22px;
    `,
    p24: css`
        padding:24px;
    `,
    pt1: css`
        padding-top:1px;
        `,
    pt2: css`
        padding-top:2px;
    `,
    pt4: css`
        padding-top:4px;
    `,
    pt6: css`
        padding-top:6px;
    `,
    pt8: css`
        padding-top:8px;
    `,
    pt10: css`
        padding-top:10px;
    `,
    pt12: css`
        padding-top:12px;
    `,
    pt14: css`
        padding-top:14px;
    `,
    pt16: css`
        padding-top:16px;
    `,
    pt24: css`
      padding-top:24px;
  `,
    pb1: css`
        padding-bottom:1px;
        `,
    pb2: css`
        padding-bottom:2px;
    `,
    pb4: css`
        padding-bottom:4px;
    `,
    pb6: css`
        padding-bottom:6px;
    `,
    pb8: css`
        padding-bottom:8px;
    `,
    pb10: css`
        padding-bottom:10px;
    `,
    pb12: css`
        padding-bottom:12px;
    `,
    pb14: css`
        padding-bottom:14px;
    `,
    pb16: css`
        padding-bottom:16px;
    `,
    pb18: css`
     padding-bottom:18px;
 `,
    pb24: css`
        padding-bottom:24px;
    `,
    pl1: css`
        padding-left:1px;
        `,
    pl2: css`
        padding-left:2px;
    `,
    pl4: css`
        padding-left:4px;
    `,
    pl6: css`
        padding-left:6px;
    `,
    pl8: css`
        padding-left:8px;
    `,
    pl10: css`
        padding-left:10px;
    `,
    pl12: css`
        padding-left:12px;
    `,
    pl14: css`
        padding-left:14px;
    `,
    pl16: css`
        padding-left:16px;
    `,
    pl22: css`
        padding-left:22px;
    `,
    pl24: css`
        padding-left:24px;
    `,
    pr1: css`
        padding-right:1px;
        `,
    pr2: css`
        padding-right:2px;
    `,
    pr4: css`
        padding-right:4px;
    `,
    pr6: css`
        padding-right:6px;
    `,
    pr8: css`
        padding-right:8px;
    `,
    pr10: css`
        padding-right:10px;
    `,
    pr12: css`
        padding-right:12px;
    `,
    pr14: css`
        padding-right:14px;
    `,
    pr16: css`
        padding-right:16px;
    `
}
