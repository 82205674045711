import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useZodForm } from '../../../hooks/zod-form'
import { AppForm, FormSubmitButton, FormTextField } from '../../../components/FormCore'
import { ShowDialogProps } from '../../../show-dialog'
import React from 'react'
import { Box } from '@mui/material'
import { CancelButton } from '../../../components/CancelButton'
import { IProductCategory } from '../../../generated-types/product-category'

export interface ProductCategoryFormProps extends ShowDialogProps {
  onSubmit: (form: any) => Promise<void>
  initialValues?: IProductCategory
}

export const ProductCategoryForm: React.FC<ProductCategoryFormProps> = ({ onSubmit, pop, initialValues }) => {
  const { t: translate } = useTranslation(['common'])
  const schema = z.object({
    name: z.string().nonempty(translate('nameIsRequired', { ns: 'common' })),
    description: z.string().optional(),
  })

  const onCancel = () => {
    pop()
  }
  const isUpdateForm = initialValues?.id
  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  return (
    <AppForm form={form} onSubmit={onSubmit}>
      {isUpdateForm && <FormTextField name="id" sx={{ display: 'none' }} />}
      <FormTextField name="name" label={translate('name', { ns: 'common' })} fullWidth />
      <FormTextField
          name="description"
          label={translate('description', { ns: 'common' })}
          fullWidth
          multiline
          rows={4}
        />
      <Box className="flex justify-end gap-4 pt-4">
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
      </Box>
    </AppForm>
  )
}