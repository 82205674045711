import { Box, Grid } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { ShowDialogProps } from '../../../show-dialog'
import { useAdminGetDocumentCategories } from '../../../api/document-category'
import { useZodForm } from '../../../hooks/zod-form'
import { invalidateGlobalDocument, useAddGlobalDocument } from '../../../api/global-documents'
import {
  AppForm,
  FormCheckbox,
  FormFileCoreUpload,
  FormSelectField,
  FormSubmitButton,
  FormTextField,
} from '../../../components/FormCore'
import { CancelButton } from '../../../components/CancelButton'
import { IDocumentCategory } from '../../../generated-types'
import { toast } from 'react-toastify'

export interface GlobalDocumentCreateDialogProps extends ShowDialogProps {
  supplierId?: EntityId
}

export const GlobalDocumentCreateDialog: React.FC<GlobalDocumentCreateDialogProps> = ({ pop, supplierId }) => {
  const { t } = useTranslation(['global-documents', 'common'])
  const documentTypes = useAdminGetDocumentCategories()

  const schema = z.object({
    title: z.string().nonempty(t('titleIsRequired', { ns: 'common' })),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    notes: z.string().optional(),
    version: z.string(),
    fileId: z.union([z.string(), z.number()]),
    isActive: z.boolean().optional(),
  })

  const form = useZodForm(schema)
  const mutation = useAddGlobalDocument()

  const onSubmit = useCallback(
    async form => {
      form.supplierId = supplierId
      await mutation.mutateAsync(form, {
        onSuccess: ({ data }: { data: string }) => {
          invalidateGlobalDocument.getDocuments()
          toast.success(t(data, { ns: 'document' }))
          pop()
        },
        onError: () => {
          toast.error(t('unExpectedError', { ns: 'common-api-message' }))
        },
      })
    },
    [supplierId, t],
  )

  const onCancel = () => {
    pop()
  }

  const handleChange = useCallback((category: IDocumentCategory) => {
    form.setValue('documentCategoryId', category as any)
  }, [])

  const onChangeDocumentCategory = useCallback(
    (_e, value) => {
      handleChange(value)
    },
    [handleChange],
  )

  return (
    <AppForm form={form} onSubmit={onSubmit}>
      <FormTextField name="title" label={t('title', { ns: 'common' })} fullWidth />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormSelectField
            name="documentCategoryId"
            data={documentTypes.data?.items as IDocumentCategory[]}
            label={t('category', { ns: 'common' })}
            getOptionLabel={option => option.categoryName}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {option.categoryName}
              </Box>
            )}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={onChangeDocumentCategory}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField name="version" label={t('version', { ns: 'global-documents' })} fullWidth />
        </Grid>
      </Grid>
      <FormTextField name="notes" label={t('description', { ns: 'common' })} fullWidth multiline rows={4} />
      <FormFileCoreUpload label={t('fileName', { ns: 'common' })} name="fileId" mapFileKey="id" height='150px'/>
      <FormCheckbox name="isActive" label={t('isActive', { ns: 'common' })} defaultValue={true} />
      <Box className="flex justify-end gap-4 pt-4">
        <CancelButton onClick={onCancel}>{t('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton createText={t('add', { ns: 'common' })} />
      </Box>
    </AppForm>
  )
}
