import { FC } from 'react'
import { Card } from '@mui/material'
import { EmptyStatusCard } from 'src/components/mooring/EmptyStatusCard'

export const VesselCardContainer: FC = () => {
  return (
    <Card sx={{ height: '180px', width: '100%' }}>
      <EmptyStatusCard
        imageWidth={'85px'}
        imageBottomMargin="12px"
        image={'/empty_status/vessel.svg'}
        header="vessel-empty-card-header"
        headerFontSize="14px"
        content="vessel-empty-card-content"
        contentFontSize="10px"
        contentTopMargin="5px"
        imageContainerClasses=""
        contentClasses="mt-6"
      />
      {/* 
      This will implement when integrated with vessel
      <CommonCarousal<VesselDetail> items={items} ContentComponent={VesselCard} /> */}
    </Card>
  )
}
