import { Route, Routes } from 'react-router-dom'
import { AppRoutesRouter } from '../app-routes'
import { AdminSidebar } from '../screens/admin/AdminSidebar'
import { CustomerSidebar } from '../screens/customer/CustomerSidebar'
import { LocalitiesSidebar } from '../screens/localities/LocalitiesSidebar'
import { ServiceStationSidebar } from '../screens/serviceStations/ServiceStationSidebar'
import { Box, IconButton, styled, useTheme } from '@mui/material'
import { useMediaQuery, Drawer } from '@mui/material'
import { useMemo } from 'react'
import { drawerWidth } from '../styles/constants'
import { ChevronLeft, Close, Menu } from '@mui/icons-material'
import logo from '../assets/lovold_logo.png'
import { Flex } from 'src/styles/flexComponent'
import { useSidebarCtx } from 'src/hooks/context-hooks'
import { MUIThemeColors } from 'src/mui-theme'
import { getThemeColor } from 'src/utils'

export const SidebarLayout = ({ children }) => {
  const { collapsibleSidebarOpen, setCollapsibleSidebarOpen } = useSidebarCtx()
  const theme = useTheme()
  const isSmall = useMediaQuery<any>(theme.breakpoints.down('md'))
  const isNotLarge = useMediaQuery<any>(theme.breakpoints.down(2000))

  const handleDrawerClose = () => {
    setCollapsibleSidebarOpen(false)
  }

  const contentMarginWidth = useMemo(() => {
    return isSmall ? 0 : !collapsibleSidebarOpen ? '80px' : drawerWidth
  }, [collapsibleSidebarOpen, isSmall])

  const drawerContentWidth = useMemo(() => {
    return !collapsibleSidebarOpen ? (isSmall ? 0 : '80px') : drawerWidth
  }, [collapsibleSidebarOpen, isSmall])

  return (
    <Box sx={{ width: contentMarginWidth, transition: '300ms linear' }}>
      {!isNotLarge ? (
        <LargeContainer>{children}</LargeContainer>
      ) : (
        <Drawer
          variant={isSmall ? 'temporary' : 'permanent'}
          open={collapsibleSidebarOpen}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: isSmall ? false : true,
            onBackdropClick: handleDrawerClose,
          }}
          sx={
            !collapsibleSidebarOpen
              ? {
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: isSmall ? 0 : 80,
                    transition: '300ms linear',
                    overflow: 'visible',
                  },
                }
              : {
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                    transition: '300ms linear',
                    overflow: 'visible',
                  },
                }
          }
        >
          <DrawerHeader isDrawerOpen={collapsibleSidebarOpen}>
            {collapsibleSidebarOpen && (
              <Flex.Row padding={'18px 12px 40px 12px'} justifyContent={'space-between'}>
                <img className="object-scale-down w-100" src={logo} />
                <CloseIcon visible={isSmall} fontSize="large" onClick={() => handleDrawerClose()} />
              </Flex.Row>
            )}
            {collapsibleSidebarOpen ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeft />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setCollapsibleSidebarOpen(true)
                }}
              >
                <Menu />
              </IconButton>
            )}
          </DrawerHeader>
          <Box width={drawerContentWidth} overflow={'hidden'}>
            <ContentContainer>{children}</ContentContainer>
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export const DrawerLayout = () => {
  return (
    <Routes>
      <Route
        path={AppRoutesRouter.Localities.basePath}
        element={
          <SidebarLayout>
            <LocalitiesSidebar />
          </SidebarLayout>
        }
      />
      <Route
        path={AppRoutesRouter.Localities.sidebarPath}
        element={
          <SidebarLayout>
            <LocalitiesSidebar />
          </SidebarLayout>
        }
      />
      <Route
        path={AppRoutesRouter.serviceStation.basePath}
        element={
          <SidebarLayout>
            <ServiceStationSidebar />
          </SidebarLayout>
        }
      />
      <Route
        path={AppRoutesRouter.serviceStation.sidebarPath}
        element={
          <SidebarLayout>
            <ServiceStationSidebar />
          </SidebarLayout>
        }
      />
      <Route
        path={AppRoutesRouter.Admin.basePath}
        element={
          <SidebarLayout>
            <AdminSidebar />
          </SidebarLayout>
        }
      />
      <Route
        path={AppRoutesRouter.Customer.basePath}
        element={
          <SidebarLayout>
            <CustomerSidebar />
          </SidebarLayout>
        }
      />
      <Route path={'*'} element={null} />
    </Routes>
  )
}

//styles

interface DrawerHeaderProps {
  isDrawerOpen: boolean
}

const DrawerHeader = styled('div')<DrawerHeaderProps>(({ theme, isDrawerOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  height: '80px',
  ...theme.mixins.toolbar,
  justifyContent: !isDrawerOpen ? 'center' : 'space-between',
}))

const CloseIcon = styled(Close)<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
`
const ContentContainer = styled(Box)`
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
`

const LargeContainer = styled(Box)`
  padding-top: 95px;
  background-color: ${props => getThemeColor(props.theme, MUIThemeColors.white)};
  width: ${drawerWidth}px;
`
