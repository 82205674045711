import { ILocality } from '../generated-types'
import { IPaginatedResult } from './paginated-result'
import { useDelete } from './reactQuery'

const localityGroupBaseURL = (customerId: EntityId) => `customers/${customerId}/locality-groups`

export const endpoints = {
  deleteLocalityGroup(customerId: EntityId, localityId: EntityId) {
    return `${localityGroupBaseURL(customerId)}/unassigns/${localityId}/locality`
  },
  deleteLocalities(customerId: EntityId, localityGroupId: EntityId) {
    return `${localityGroupBaseURL(customerId)}/unassigns/${localityGroupId}/localityGroup`
  }
}

export const useDeleteLocalityGroup = (customerId: EntityId, localityId: EntityId) =>
  useDelete<IPaginatedResult<ILocality>>(endpoints.deleteLocalityGroup(customerId, localityId))


export const useDeleteLocality = (customerId: EntityId, localityGroupId: EntityId) =>
  useDelete<IPaginatedResult<ILocality>>(endpoints.deleteLocalities(customerId, localityGroupId))