import { useRoutes } from 'react-router-dom'
import { LoginScreen } from './screens/LoginScreen'
import { MainRoutes } from './layouts/routes/MainRoutes'
import { AppRoutesRouter } from './app-routes'
import { UserRegistrationScreen } from './screens/UserRegistrationScreen'
import { RegistrationCompleteScreen } from './screens/RegistrationCompleteScreen'

export const MainRouter = () => {
  return useRoutes([
    { path: AppRoutesRouter.Login, element: <LoginScreen /> },
    { path: AppRoutesRouter.RegisterComplete, element: <RegistrationCompleteScreen /> },
    { path: AppRoutesRouter.UserRegistration, element: <UserRegistrationScreen /> },
    {
      path: '/*',
      element: <MainRoutes />,
    },
  ])
}
