import { IUnit } from '../generated-types'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useGet } from './reactQuery'

const baseUrl = () => 'units'

export const unitsEndpoints = {
  getUnits() {
    return `${baseUrl()}`
  },
  getUnit(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
}

export const useGetUnits = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IUnit>>(unitsEndpoints.getUnits(), { page, pageSize })

export const useGetUnit = (id: EntityId) => useGet<IUnit>(unitsEndpoints.getUnit(id))
