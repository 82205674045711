import { useTranslation } from 'react-i18next'
import { CenteredSpinner } from '../../../components/Spinner'
import { IMainComponentType } from '../../../generated-types/main-component-type'
import { ICustomProperty } from '../../../generated-types/customProperty'
import { IFormType } from '../../../generated-types/form-type'
import { ShowDialogProps } from '../../../show-dialog'
import { MainComponentTypeForm } from '../../main-component-type/MainComponentTypeForm'
import {
  useUpdateMainComponentType,
  invalidateMainComponentTypeCache,
  useGetMainComponentType,
  useGetMainComponentCustomProperties,
} from '../../../api/main-component-type'
import { IPrivilegeLevel } from '../../../enums'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { ALREADY_EXISTS } from '../../../constant'
import { useGetAdminAndCustomerCustomProperties } from '../../../api/custom-property'

interface UpdateMainComponentTypeProps extends ShowDialogProps {
  id: EntityId
  customerId: EntityId
}

export const UpdateMainComponentTypeDialog: React.FC<UpdateMainComponentTypeProps> = ({ pop, id, customerId }) => {
  const { t: translate } = useTranslation(['main-component-type', 'common'])
  const {
    mutateAsync,
    error,
    isLoading: isLoadingUpdate,
  } = useUpdateMainComponentType(id as EntityId, customerId as EntityId)
  useEffect(() => {
    if (error && error['data'] == ALREADY_EXISTS) {
      toast(translate('already-exists'), { type: 'error' })
    }
  }, [error, translate])

  const onSubmit = async form => {
    form.customerId = customerId
    await mutateAsync(form, {
      onSuccess: () => {
        toast(translate('successfully-updated'), { type: 'success' })
        invalidateMainComponentTypeCache.useGetMainComponentTypes(customerId)
        invalidateMainComponentTypeCache.useGetMainComponentType(id, customerId)
        invalidateMainComponentTypeCache.useGetMainComponentCustomProperties(id, customerId)
        invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId)
        pop(true)
      },
      onError: () => {
        toast.error(translate('updatingError', { ns: 'common' }))
      },
    })
  }

  const { data, isLoading } = useGetMainComponentType(id, customerId)
  const { data: userCustomProperties } = useGetAdminAndCustomerCustomProperties(customerId as EntityId)
  const { data: customTypes } = useGetMainComponentCustomProperties(id, customerId)

  if (isLoading) return <CenteredSpinner />

  return (
    <MainComponentTypeForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={data as IMainComponentType}
      componentTypeCustomProperties={customTypes}
      userCustomProperties={userCustomProperties?.items as ICustomProperty[]}
      formType={IFormType.Update}
      privilegeLevel={IPrivilegeLevel.Admin}
      isLoading={isLoadingUpdate}
      title={translate('updateMainComponentType', { ns: 'main-component-type' })}
    />
  )
}
