import { Box, IconButton, Tooltip, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TasksFilter from './tasks/TasksFilter'
import GenericTable from '../../../components/table/GenericTable'
import { AddCircleOutline, CalendarMonth, List } from '@mui/icons-material'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useCallback, useMemo, useState } from 'react'
import { MdEdit } from 'react-icons/md'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import TaskResources from './tasks/TaskResources'
import TaskCalendar from './tasks/TaskCalendar'
import { taskEndpoints, useGetFilterTask } from '../../../api/tasks'
import TaskForm from './tasks/TaskForm'
import TaskEditForm from './tasks/TaskEditForm'
import { getFormattedDate, getThemeColor } from '../../../utils'
import TaskDeleteButton from './tasks/TaskDeleteButton'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { MUIThemeColors } from 'src/mui-theme'
import { Flex } from 'src/styles/flexComponent'

const Tasks = () => {
  const { t } = useTranslation('task')
  const theme = useTheme()
  const { showDialog } = useDialogCtx()
  const [isCalendar, setIsCalendar] = useState(false)
  const { customer } = useCustomerCtx()
  const [queryParams, setQueryParams] = useState({})
  const [page, setPage] = useState(10)
  const { data } = useGetFilterTask(customer?.id, 0, page, queryParams)
  const [defaultFilterValues, setDefaultFilterValues] = useState({})

  const showResources = useCallback(
    row => {
      showDialog(TaskResources, {
        componentProps: {
          taskResources: row.taskResources,
          customerId: customer.id,
          taskId: row.id,
          localityId: row.localityId,
          task: row,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        title: t('resources'),
      },undefined,true)
    },
    [customer.id, showDialog, t],
  )

  const editTask = useCallback(
    row => {
      showDialog(TaskEditForm, {
        componentProps: {
          initialValues: row,
          customerId: customer?.id,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        title: t('edit-task'),
      })
    },
    [customer?.id, showDialog, t],
  )

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'title',
        flex: 1,
        headerName: t('title', { ns: 'common' }),
        cellClassName: 'font-weight-semi-bold',
      },
      {
        field: 'locality.name',
        headerName: t('locality', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
        renderCell: row => row.row.locality?.name,
      },
      {
        field: 'description',
        flex: 1,
        headerName: t('description', { ns: 'common' }),
      },
      {
        field: 'startDate',
        flex: 1,
        headerName: t('startDate', { ns: 'common' }),
        renderCell: params => getFormattedDate(params?.row?.startDate),
      },
      {
        field: 'endDate',
        flex: 1,
        headerName: t('endDate', { ns: 'common' }),
        renderCell: params => getFormattedDate(params?.row?.endDate),
      },
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 60,
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={`edit-${row.id}`}
            label={t('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => {
              editTask(row)
            }}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={`delete-${row.id}`}
            label={''}
            icon={<TaskDeleteButton task={row} />}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [editTask, t],
  )

  const dateRangeChange = useCallback(date => {
    const monthStartDate = new Date(date)
    monthStartDate.setDate(1)
    const monthEndDate = new Date(date)
    monthEndDate.setMonth(monthEndDate.getMonth() + 1)
    monthEndDate.setDate(1)
    setDefaultFilterValues({ startDate: monthStartDate, endDate: monthEndDate })
  }, [])

  const onAdd = useCallback(() => {
    showDialog(
      TaskForm,
      {
        componentProps: {
          customerId: customer?.id,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }, [customer?.id, showDialog, t])

  const onCalendarIconClick = useCallback(() => {
    setPage(1000)
    setIsCalendar(true)
    dateRangeChange(new Date(Date.now()))
  }, [])

  const taskFilterChange = useCallback(params => {
    params.resourceIds = params.resource.map(re => re.id).join(',')
    params.localityIds = params.locality.map(re => re.id).join(',')
    delete params.locality
    delete params.resource
    setQueryParams(params)
  }, [])

  return (
    <CommonPageLayout
      titleSection={{ title: t('task-management') }}
      topAction={[
        <TasksFilter
          onChange={taskFilterChange}
          defaultValues={defaultFilterValues}
          isDisabled={isCalendar}
          key="filter"
        />,
        {
          buttonText: t('add-task'),
          buttonIcon: <AddCircleOutline />,
          onClick: onAdd,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
          height: '44px',
        },
      ]}
      topActionContainerProps={{ justifyContent: 'center', alignItems: 'center', spacing: 3 }}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        {isCalendar ? (
          <>
            <Flex.Row justifyContent={'flex-end'} pb={2}>
              <Tooltip
                title={`${t('list-view')}`}
                onClick={() => {
                  setPage(10)
                  setIsCalendar(false)
                  setQueryParams({
                    startDate: defaultFilterValues['startDate'],
                    endDate: defaultFilterValues['endDate'],
                  })
                }}
              >
                <IconButton sx={{ color: getThemeColor(theme, MUIThemeColors.primaryMain) }}>
                  <List className="cursor-pointer" />
                </IconButton>
              </Tooltip>
            </Flex.Row>
            <TaskCalendar
              onClick={showResources}
              events={data?.items}
              onMonthChange={date => {
                dateRangeChange(date)
              }}
            />
          </>
        ) : (
          <GenericTable
            toolbarOptions={[
              {
                Icon: <CalendarMonth />,
                label: t('task-calendar'),
                onClick: onCalendarIconClick,
              },
            ]}
            enableToolbar
            queryUrl={taskEndpoints.filterTask(customer?.id)}
            queryParams={queryParams}
            columns={columns}
            onRowClick={({ row }) => showResources(row)}
            enableServerPagination
            id={'customer-resources'}
            autoHeight={false}
            height={'72vh'}
          />
        )}
      </Box>
    </CommonPageLayout>
  )
}

export default Tasks
