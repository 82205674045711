import React from 'react'
import FormGenerator from './FormGenerator'
import { FormRow } from '../../../generated-types/form-field'
import { useUpdateFormSchema, useGetFormSchemaById, invalidateSchemaForm } from '../../../api/form-schema'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { IFormSchema, IFormSchemaGeneric, IFormSchemaVersion } from '../../../generated-types/form-schema'
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../app-routes'
import { useTranslation } from 'react-i18next'

const EditFormSchema = () => {
    const { customer } = useCustomerCtx()
    const { formSchemaId } = useParams()
    const { data } = useGetFormSchemaById(customer.id as EntityId, formSchemaId as EntityId)
    const mutation = useUpdateFormSchema(customer.id as EntityId, formSchemaId as EntityId)
    const navigate = useNavigate()
    const { t } = useTranslation('common')

    return (<FormGenerator isEdit={true} onCancel={() => {
        navigate(AppRoutesNavigation.Customer.formSchema(customer?.id))
    }} initialData={data} buttonTitle={t('update')} onSubmit={async (value: FormRow[], details: IFormSchemaGeneric) => {
        const formSchemaVersion: IFormSchemaVersion = {
            layout: JSON.stringify(value),
            version: data?.version || 1
        }
        const formSchema: IFormSchema = {
            id: parseInt(formSchemaId || ''),
            name: details.name,
            description: details.description,
            hasVideoUpload: details.hasVideoUpload,
            isActive: details.isActive,
            customerId: customer.id,
            versions: [formSchemaVersion],
        }
        await mutation.mutateAsync(formSchema)
        invalidateSchemaForm.getSchemaById(customer.id as EntityId, formSchemaId as EntityId)
        invalidateSchemaForm.getAllFormSchema(customer.id as EntityId)
        navigate(AppRoutesNavigation.Customer.formSchema(customer?.id))
    }} />
    )
}


export default EditFormSchema