import { useTranslation } from "react-i18next";

export interface ISelectType<T> {
    id: [keyof T];
    value: string;
}

export const EnumToSelectTypes = <T>(enumType: T, ns: string): ISelectType<T>[] => {
    const { t } = useTranslation([ns])
    return Object.keys(enumType as any)
        .filter(v => isNaN(Number(v)))
        .map(
            d => ({ id: enumType[d], value: t(d) } as ISelectType<T>),
        )
}