import React, { createContext } from 'react'

export const AppFormContext = createContext<Props>(null!)

interface Props {
  hasInitialValues: boolean
  initialValues?: any
}

export const AppFormProvider: React.FC<Props> = ({ hasInitialValues, initialValues, children }) => {
  return (
    <AppFormContext.Provider value={{ hasInitialValues: hasInitialValues ?? !!initialValues, initialValues }}>
      {children}
    </AppFormContext.Provider>
  )
}
