import { Tooltip, Typography, TypographyProps, styled } from '@mui/material'
import { FC } from 'react'

export interface IDynamicTextTypographyProps extends TypographyProps {
  text: string
  maxLength?: number
  overWriteText?: string
}

const StyledDiv = styled('div')(() => ({
  cursor: 'pointer',
  fontSize: 'inherit',
}))

export const DynamicTextTypography: FC<IDynamicTextTypographyProps> = ({
  text,
  maxLength,
  overWriteText = '...',
  children,
  ...rest
}) => {
  const shouldTruncate = maxLength != null && text?.length > maxLength
  const truncatedText = shouldTruncate ? text?.slice(0, maxLength) + overWriteText : text

  return (
    <Typography {...rest} display="flex" flexDirection="row">
      {shouldTruncate ? (
        <Tooltip title={text}>
          <StyledDiv>{truncatedText}</StyledDiv>
        </Tooltip>
      ) : (
        text
      )}
      {children}
    </Typography>
  )
}
