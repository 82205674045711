import { Button, Stack, styled, Typography, useTheme } from '@mui/material'
import { FC, memo } from 'react'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { UpdateBatchNumberDialog } from '../UpdateBatchNumberDialog'
import { useTranslation } from 'react-i18next'
import { padding } from 'src/styles/paddingCss'
import { AddCircleOutline } from '@mui/icons-material'

interface IBatchNumberCellProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  batchNumbersJson: string
  productId: EntityId
  positionId: EntityId
  componentId: EntityId
  productDescription: string
}

export const BatchNumberButton = styled(Button)`
  background-color: ${props => props.theme.palette.white[props.theme.palette.mode]};
  border: 1px solid #d9e1e7cc;
  text-transform: capitalize;
  border-radius: 4px;
  ${padding.p4}
  cursor: pointer;
`

export const BatchNumberCell: FC<IBatchNumberCellProps> = memo(
  ({ customerId, localityId, mooringId, batchNumbersJson, productId, positionId, componentId, productDescription }) => {
    const batchNumbers = (batchNumbersJson ?? '')?.split(',')
    const { showDialog } = useDialogCtx()
    const { t } = useTranslation(['position'])
    const theme = useTheme()

    const batchNumberOnClick = async () => {
      const added = await showDialog(
        UpdateBatchNumberDialog,
        {
          opts: {
            maxWidth: 'sm',
            fullWidth: true,
          },
          componentProps: {
            customerId: customerId,
            localityId: localityId,
            mooringId: mooringId,
            productId: productId,
            positionId: positionId,
            componentId: componentId,
            productDescription: productDescription,
          },
        },
        undefined,
        true,
      )
      if (!added) return
    }

    return (
      <Stack direction={'row'} spacing={1}>
        {batchNumbers?.map((batchNumber, index) => {
          if (index > 0) {
            return null
          }

          if (batchNumber?.length == 0 || batchNumber == undefined) {
            return (
              <BatchNumberButton key={index} onClick={batchNumberOnClick}>
                <AddCircleOutline
                  style={{ fontSize: '18px' }}
                  htmlColor={theme.palette.primaryMain[theme.palette.mode]}
                />
              </BatchNumberButton>
            )
          }

          return (
            <BatchNumberButton key={index} onClick={batchNumberOnClick}>
              <Typography variant="body1" fontWeight={450}>
                {batchNumber}
              </Typography>
            </BatchNumberButton>
          )
        })}
        {batchNumbers?.length > 1 && (
          <Button onClick={batchNumberOnClick} style={{ textTransform: 'capitalize' }}>
            <Typography variant="subtitle2">
              {batchNumbers?.length - 1}
              {t('more', { ns: 'common' })}
            </Typography>
          </Button>
        )}
      </Stack>
    )
  },
)
