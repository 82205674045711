import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetDocument } from '../../../api/document'
import { CenteredSpinner } from '../../../components/Spinner'
import { DocumentHistoryTable } from './DocumentHistoryTable'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { DocumentDetailsSection } from './components/DocumentDetailsSection'
import { IDocument } from 'src/generated-types/document'
import { EditOutlined, VisibilityOutlined } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { EditDocumentDialog } from './components/EditDocumentDialog'
import { permissionValue } from 'src/enums/permissionValue'
import { Context } from 'react'

interface IDocumentContainer {
  context?: Context<any> | null,
}

export const DocumentContainer = ({ context = null }: IDocumentContainer) => {
  const { t } = useTranslation(['document', 'common', 'certificate'])
  const { customerId, localityId, documentId } = useParams()
  const { showDialog, openFilePreviewDialog } = useDialogCtx()

  const { data, isLoading } = useGetDocument(customerId as EntityId, documentId as EntityId)

  if (isLoading) return <CenteredSpinner />

  const onEdit = async (document: IDocument) => {
    const added = await showDialog(EditDocumentDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        document,
      },
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
    }, undefined, true)
    if (!added) return
  }

  return (
    <>
      <CommonPageLayout
        titleSection={{ title: t('details', { ns: 'document' }), subtitle: data?.title }}
        topAction={[
          {
            buttonText: t('edit', { ns: 'common' }),
            buttonIcon: <EditOutlined />,
            onClick: () => onEdit(data as IDocument),
            permissionBinaryValues: [
              permissionValue.Locality.Documents.Write,
              permissionValue.ClientAdminPermission,
              permissionValue.Locality.MainComponent.Document.Write,
            ],
            variant: 'outlined',
            shadowWeight: null,
            context: context,
          },
          {
            buttonText: t('view-attachment', { ns: 'common' }),
            buttonIcon: <VisibilityOutlined />,
            onClick: () => {
              data?.fileUrl  && openFilePreviewDialog(data?.fileUrl)
            },
            permissionBinaryValues: [
              permissionValue.Locality.Documents.Read,
              permissionValue.ClientAdminPermission,
              permissionValue.Locality.MainComponent.Document.Read,
            ],
            variant: 'outlined',
            shadowWeight: null,
            context: context,
          },
        ]}
        enableBackButton
      >
        <DocumentDetailsSection data={data as IDocument} />
        <CommonPageLayout titleSection={{ subtitle: t('documentHistory', { ns: 'document' }) }}>
          <DocumentHistoryTable
            customerId={customerId as EntityId}
            localityId={localityId as EntityId}
            documentId={documentId as EntityId}
          />
        </CommonPageLayout>
      </CommonPageLayout>
    </>
  )
}
