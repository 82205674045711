import { Box, Card as MUICard, styled } from '@mui/material'

export const Card = styled(MUICard) <{ touchable?: boolean, draggable?: boolean }>`
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #D9E1E7;
    border-radius: 4px;
    cursor: ${props => props.draggable ? 'grab' : props.touchable ? 'pointer' : 'default'};
`

export const StyledDetailCard = styled(Card)(({ theme }) => ({
    border: `1px solid ${theme.palette.secondaryLight[theme.palette.mode]}`,
}))

export const ContainerBox = styled(Box)`
    border: ${(props) => `1px solid ${props.theme.palette.secondaryLight[props.theme.palette.mode]}`};
    width:100%;
    padding:8px;
`