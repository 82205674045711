import { Box } from '@mui/material'
import { ProductCategoryTable } from './ProductCategoryTable'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useTranslation } from 'react-i18next'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { ProductCategoryAddDialog } from './ProductCategoryAddDialog'

export const ProductCategoryContainer = () => {
  const { t: translate } = useTranslation(['product-category', 'common'])
  const { showDialog } = useDialogCtx()

  const onAddProductCategory = async () => {
    const added = await showDialog(ProductCategoryAddDialog, {
      title: translate('add-product-category', { ns: 'product-category' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {},
    })
    if (!added) return
  }

  return (
    <Box className="mb-2 bg-white shadow-md p-3" borderRadius={2}>
      <ScreenTitleBar
        title={translate('product-categories', { ns: 'product-category' })}
        addNewItemText={translate('add', { ns: 'common' })}
        onAddNewItemClick={onAddProductCategory}
      />
      <ProductCategoryTable />
    </Box>
  )
}
