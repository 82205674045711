import React, { useCallback } from 'react'
import { FormField, IOption } from '../../generated-types/form-field'
import { FormFieldType } from '../../enums'
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, styled, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { useUploadFile } from '../../api/file'
import { AppTextField } from '../AppTextField'

interface IFieldSelector {
    field: FormField
    layoutIndex: number
    index: number
    setSelectedSettingField: () => void
    disabled?: boolean
    fieldDisabled?: boolean
    onChange: (values: any) => void
    form?: UseFormReturn
    onDrag?: () => void
}

const FieldSelector: React.FC<IFieldSelector> = ({ field, layoutIndex, index, setSelectedSettingField, disabled, onDrag, onChange, form, fieldDisabled }) => {
    const mutate = useUploadFile(false)
    const values = form?.getValues()

    const onDragStart = useCallback(() => {
        if (onDrag)
            onDrag()
    }, [])

    const selectField = () => {
        switch (field.type) {
            case (FormFieldType.textField):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>{field.name}<span className='ml-2 text-red-500' >{field.isRequired && '*'}</span></StyledTypography>
                    </Box>
                    <AppTextField type='text' defaultValue={values && values[field.name]} name={field.name} fullWidth={true} placeholder={field.name} disabled={disabled || fieldDisabled} onChange={(evt) => { onChange(evt.target.value) }} />
                </Box>
            case (FormFieldType.numberField):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>{field.name}<span className='ml-1 text-red-500' >{field.isRequired && '*'}</span></StyledTypography>
                    </Box>
                    <AppTextField type='number' fullWidth={true} placeholder={field.name} disabled={disabled} onChange={(evt) => { onChange(evt.target.value) }} />
                </Box>
            case (FormFieldType.datePicker):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>
                            {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                        </StyledTypography>
                    </Box>
                    <AppTextField type='date' fullWidth={true} placeholder={field.name} disabled={disabled} onChange={(evt) => { onChange(evt.target.value) }} />
                </Box>
            case (FormFieldType.textArea):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>
                            {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                        </StyledTypography>
                    </Box>
                    <AppTextField type='text' multiline rows={6} fullWidth={true} placeholder={field.name} disabled={disabled} onChange={(evt) => { onChange(evt.target.value) }} />
                </Box>
            case (FormFieldType.checkBox):
                return <Box display={'flex'} flexDirection={'row'} draggable={true} onDragStart={onDragStart}>
                    <Checkbox disabled={disabled} onChange={(evt) => { onChange(evt.target.checked ? 'Yes' : 'No') }} />
                    <Box paddingTop={1}>
                        <StyledTypography>{field.name}</StyledTypography>
                    </Box>
                </Box>
            case (FormFieldType.dateTime):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>
                            {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                        </StyledTypography>
                    </Box>
                    <AppTextField disabled={disabled} type='datetime-local' fullWidth={true} placeholder={field.name} onChange={(evt) => { onChange(evt.target.value) }} />
                </Box>
            case (FormFieldType.fileUpload):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>
                            {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                        </StyledTypography>
                    </Box>
                    <input disabled={disabled} type='file' placeholder={field.name} onChange={async (evt) => {
                        if (evt.target?.files) {
                            const file = evt.target?.files[0]
                            const form = new FormData()
                            form.append('file', file)
                            const result = await mutate.mutateAsync(form)
                            onChange(result.data.url)
                        }
                    }} />
                </Box>
            case (FormFieldType.timePicker):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>
                            {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                        </StyledTypography>
                    </Box>
                    <AppTextField disabled={disabled} type='time' fullWidth={true} placeholder={field.name} onChange={(evt) => { onChange(evt.target.value) }} />
                </Box>
            case (FormFieldType.checkBoxList):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>
                            {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                        </StyledTypography>
                    </Box>
                    {
                        field.options?.map((option: IOption, index: number) => {
                            return <Box key={index} display={'flex'} flexDirection={'row'}>
                                <Checkbox disabled={disabled} onChange={(evt) => {
                                    if (form) {
                                        const valuesList = form?.getValues()
                                        const existingValue = valuesList[field.name]
                                        if (existingValue) {
                                            const values: any[] = JSON.parse(existingValue)
                                            if (evt.target.checked) {
                                                values.push(option.value)
                                            } else {
                                                values.splice(values.indexOf(option.value), 1)
                                            }
                                            onChange(JSON.stringify(values))
                                        } else {
                                            onChange(JSON.stringify([option.value]))
                                        }
                                    }

                                }} />
                                <Box paddingTop={1}>
                                    <Typography>{option.label}</Typography>
                                </Box>
                            </Box>
                        })
                    }

                </Box>
            case (FormFieldType.radio):
                return <Box draggable={true} onDragStart={onDragStart}>
                    <Box paddingTop={1}>
                        <StyledTypography>
                            {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                        </StyledTypography>
                    </Box>
                    <RadioGroup
                        aria-labelledby={'radioButton' + field.name}
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={(evt, value) => {
                            onChange(value)
                        }}
                    >
                        {
                            field.options?.map((option: IOption, index: number) => {
                                return <FormControlLabel key={index} value={option.value} label={option.label} control={<Radio />} disabled={disabled} />
                            })
                        }
                    </RadioGroup>
                </Box>
            case (FormFieldType.selectField):
                return <FormControl fullWidth draggable={true} onDragStart={onDragStart}>
                    <InputLabel id={'selectedFieldLabel' + field.name}>
                        {field.name} <span className='ml-1 text-red-500' >{field.isRequired && '*'}</span>
                    </InputLabel>
                    <Select
                        fullWidth={true}
                        labelId={'selectedFieldLabel' + field.name}
                        id="demo-simple-select"
                        label={field.name}
                        onChange={(evt) => {
                            onChange(evt.target.value)
                        }}
                    >
                        {
                            field.options?.map((option: IOption, index: number) => {
                                return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            case (FormFieldType.title):
                return <Typography variant='subtitle1' draggable={true} onDragStart={onDragStart}>{field.name}</Typography>
            default:
                return null
        }
    }
    return <Box padding={1} onClick={() => { setSelectedSettingField() }}>
        {selectField()}
    </Box>

}

const StyledTypography = styled(Typography)({
    fontSize: 13
})

export default FieldSelector