import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { IMooring, IMooringType, IPosition, IPositionType } from '../../../generated-types'
import { LatLngExpression } from 'leaflet'
import { MdImage } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { ComponentsContainer } from '../positionsComponents/ComponentsContainer'
import { AddPositionDrawingModalComponent } from './components/AddPositionDrawingModalComponent'
import { CreateDocumentDialog, ICreateDocumentsParams } from '../documents/components/CreateDocumentDialog'
import { Sediment } from '../../../enums'
import { GpsToDms } from '../../../components/GpsToDms'
import { useMemo } from 'react'
import { AuthorizationProvider } from '../../../components/common/Authorization'
import { permissionValue } from '../../../enums/permissionValue'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { Card, ContainerBox } from 'src/styles/Index'
import { useGetSvgForPosition } from 'src/api/moorings'
import { Flex } from 'src/styles/flexComponent'
import { AddCircleOutline, ArrowOutward, GpsFixedTwoTone, GpsNotFixed } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { AppRoutesNavigation } from 'src/app-routes'
import { useDialogCtx } from 'src/hooks/context-hooks'
import MapSvgCardSwapper from 'src/components/common/MapSvgCardSwapper'
import ROVContainer from 'src/components/ROVContainer'
import { DocumentsTable } from '../documents/components/DocumentsTable'
import { APIResultCount } from 'src/constant'
import { useNavigation } from 'src/context/NavigationContext'
import { IPositionDrawing } from 'src/generated-types/position-drawing'

export const PositionContent: React.FC<{
  position: IPosition
  mooringDetails: IMooring
  positionId: string | undefined
}> = ({ position, mooringDetails, positionId }) => {
  const { t: translate } = useTranslation(['position', 'component', 'common'])
  const { customerId, localityId, mooringId } = useParams()
  const { navigateTo } = useNavigation()
  const selectedDrawing = useMemo(() => {
    return {
      id: position?.positionDrawingId as number,
      drawingNumber: position?.positionDrawingNumber as string,
      fileUrl: position?.positionDrawingFileUrl as string,
    } as IPositionDrawing
  }, [position])

  const { data: svg } = useGetSvgForPosition(customerId as EntityId, localityId as EntityId, mooringId as EntityId, {
    positionId: parseInt(positionId ?? '0'),
  })
  const { showDialog } = useDialogCtx()
  const positions = useMemo(() => mooringDetails?.positions as IPosition[], [mooringDetails?.positions])

  const centerLat = useMemo(
    () =>
      position?.connectedPositions && position?.connectedPositions.length > 0
        ? position?.connectedPositions[0].latitude
        : position && position.latitude !== 0
        ? position.latitude
        : positions && positions.length > 0
        ? positions[0].latitude
        : 0,
    [positions, position],
  )

  const centerLng = useMemo(
    () =>
      position?.connectedPositions && position?.connectedPositions.length > 0
        ? position?.connectedPositions[0].longitude
        : position && position.longitude !== 0
        ? position.longitude
        : positions && positions.length > 0
        ? positions[0].longitude
        : 0,
    [positions, position],
  )

  const onClickSelectPositionDrawing = async () => {
    const added = await showDialog(AddPositionDrawingModalComponent, {
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        mooringId: mooringId as EntityId,
        positionId: parseInt(positionId ?? '') as number | undefined,
        positionDrawingId: position?.positionDrawingId as EntityId,
        positionType: position?.type as IPositionType,
        selectedPositionDrawing: selectedDrawing,
      },
      opts: {
        maxWidth: 'lg',
        fullWidth: true,
      },
    })

    if (!added) return
  }

  const onAdd = async () => {
    const added = await showDialog(
      CreateDocumentDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          localityId: localityId as EntityId,
          createDocumentsParams: {
            positionId: positionId,
            mooringId: mooringId,
            mooringName: mooringDetails?.name,
          } as ICreateDocumentsParams,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onSelectDeviation = (id: number) => {
    navigateTo(
      AppRoutesNavigation.Localities.componentDeviations(
        customerId as EntityId,
        localityId as EntityId,
        positionId as EntityId,
        id,
      ),
    )
  }

  return (
    <>
      <Grid container>
        <Grid item md={6} className="pr-8-core">
          <Grid item md={12} container>
            <Card sx={{ height: '180px', width: '100%' }} className="pt-16-core pl-6-core pr-6-core">
              <Grid container className="pt-5-core">
                <DetailsCard
                  icon={<GpsFixedTwoTone color="primary" sx={{ fontSize: '28px' }} />}
                  title={translate('longitude', { ns: 'common' })}
                  value={<GpsToDms decimalGPS={position.longitude} isLongitude={true}></GpsToDms>}
                />
                <DetailsCard
                  icon={<GpsNotFixed color="primary" sx={{ fontSize: '28px' }} />}
                  title={translate('latitude', { ns: 'common' })}
                  value={<GpsToDms decimalGPS={position.latitude} isLongitude={false}></GpsToDms>}
                />
                <DetailsCard
                  icon={<img src={'/icons/break.svg'} color="primary" />}
                  title={translate('mbl', { ns: 'position' })}
                  value={position?.mbl ?? '-'}
                />
                <DetailsCard
                  icon={<img src={'/icons/load.svg'} color="primary" />}
                  title={translate('accident-load', { ns: 'position' })}
                  value={position?.accidentLoad ?? '-'}
                />
                <DetailsCard
                  icon={<img src={'/icons/sediment.svg'} color="primary" />}
                  title={translate('sediment', { ns: 'position' })}
                  value={position?.sediment != null ? Sediment[position?.sediment] : '-'}
                />
                <DetailsCard
                  icon={<img src={'/icons/depth.svg'} color="primary" />}
                  title={translate('depth', { ns: 'position' })}
                  value={position?.depth ?? '-'}
                />
                <DetailsCard
                  icon={<ArrowOutward color="primary" sx={{ fontSize: '28px' }} />}
                  title={translate('direction', { ns: 'position' })}
                  value={position?.direction ?? '-'}
                />
                <DetailsCard
                  icon={<img src={'/icons/tlw.svg'} color="primary" />}
                  title={translate('tl-min', { ns: 'position' })}
                  value={`${(position?.testLoad ?? 0.0).toFixed(2)}/${(position?.testLoadTime ?? 0.0).toFixed(2)}`}
                />
              </Grid>
            </Card>
          </Grid>
          <Grid item md={12} className="pt-16-core">
            <MapSvgCardSwapper
              positions={mooringDetails?.positions}
              center={[centerLat, centerLng] as LatLngExpression}
              type={mooringDetails?.type as IMooringType}
              svg={svg as string}
              height={'270px'}
            />
          </Grid>
        </Grid>
        <Grid item md={6}>
          <ContainerBox
            className="flex items-center justify-center w-full rounded-lg md:w-full lg:w-3/6"
            sx={{
              backgroundImage: `url(${position?.positionDrawingFileUrl})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundColor: '#c3ebff',
              width: '100%',
              ':hover': {
                cursor: position?.positionDrawingFileUrl ? 'pointer' : 'default',
              },
              height: 466,
            }}
            onClick={onClickSelectPositionDrawing}
          >
            {!position?.positionDrawingFileUrl && (
              <AuthorizationProvider permissions={[permissionValue.Locality.Mooring.Write]}>
                <Button onClick={onClickSelectPositionDrawing} sx={{ borderColor: 'grey.500' }}>
                  <MdImage /> {translate('selectPositionDrawing', { ns: 'position' })}
                </Button>
              </AuthorizationProvider>
            )}
          </ContainerBox>
        </Grid>
      </Grid>
      <Grid item md={12} className="pt-16-core">
        <ComponentsContainer onSelectDeviation={onSelectDeviation} />
      </Grid>
      <Grid container spacing={'12px'}>
        <Grid item md={4}>
          <CommonPageLayout
            titleSection={{ subtitle: translate('documents', { ns: 'document' }) }}
            topAction={[
              {
                buttonText: translate('documents', { ns: 'document' }),
                buttonIcon: <AddCircleOutline />,
                onClick: onAdd,
                permissionBinaryValues: [permissionValue.Locality.Documents.Write],
                variant: 'outlined',
                shadowWeight: 0,
              },
            ]}
          >
            <Card style={{ height: window.innerHeight / 3 }}>
              {position && (
                <DocumentsTable
                  showDocumentsHeader={false}
                  customerId={customerId!}
                  localityId={localityId!}
                  documentsParams={{
                    positionId: position?.id,
                    customerId: customerId as EntityId,
                    shouldFetchAttachements: true,
                  }}
                  showEditDocumentButton={true}
                  height={window.innerHeight / 3}
                  pageSize={APIResultCount.Max}
                  autoHeight={false}
                  hideMooringColumn={true}
                  shouldShowAttachemnts={true}
                />
              )}
            </Card>
          </CommonPageLayout>
        </Grid>
        <Grid item md={8}>
          <CommonPageLayout
            titleSection={{ subtitle: translate('rovMovies', { ns: 'rov' }) }}
            topAction={[
              {
                buttonText: translate('rovMovies', { ns: 'rov' }),
                buttonIcon: <AddCircleOutline />,
                onClick: () => {},
                permissionBinaryValues: [permissionValue.Locality.Documents.Write],
                variant: 'outlined',
                shadowWeight: 0,
              },
            ]}
          >
            <Card className="scrollbar" style={{ height: window.innerHeight / 3, overflowY: 'auto' }}>
              <ROVContainer />
            </Card>
          </CommonPageLayout>
        </Grid>
      </Grid>
    </>
  )
}

export const DetailsCard = ({ icon, title, value, isExceed = false }) => {
  const theme = useTheme()
  const fontColor = isExceed
    ? getThemeColor(theme, MUIThemeColors.white)
    : getThemeColor(theme, MUIThemeColors.primaryMain)

  return (
    <Grid item md={3} className="p-4-core">
      <Flex.Row
        className="p-14-core pb-12-core"
        alignContent={'center'}
        alignItems={'center'}
        sx={{
          backgroundColor: isExceed
            ? getThemeColor(theme, MUIThemeColors.errorMain)
            : getThemeColor(theme, MUIThemeColors.inputSearch),
        }}
      >
        <Flex.Row
          justifyContent={'center'}
          height={'36px'}
          width={'42px'}
          alignContent={'center'}
          alignItems={'center'}
          paddingRight={'8px'}
        >
          {icon}
        </Flex.Row>
        <Flex.Column>
          <Typography variant="body2" fontWeight={'medium'} color={fontColor}>
            {title}
          </Typography>
        </Flex.Column>
      </Flex.Row>
    </Grid>
  )
}
