import { Button, Checkbox, Divider, FormControlLabel, Tooltip } from '@mui/material'
import { Box } from '@mui/material'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { Controller, useController, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { z } from 'zod'
import { AppForm, AppFormComponent, FormSubmitButton, useFormFieldError } from '../components/FormCore'
import { SelectField } from '../components/SelectField'
import { Spinner } from '../components/Spinner'
import { CustomerAccessLevel } from '../enums'
import { useCustomerCtx } from '../hooks/context-hooks'
import { useZodForm } from '../hooks/zod-form'
import {
  getLocalityAccessLevels,
  getUserByEmail,
} from '../http'
import { Queries } from '../queries'
import { AppTextField } from '../components/AppTextField'
import { MdClear, MdInfo } from 'react-icons/md'
import { useGetLocalities } from '../api/localities'
import { IAccessGroup, ILocality, ILocalityGroup } from '../generated-types'
import { useGetLocalityGroups } from '../api/locality-groups'
import { useGetAccessGroups } from '../api/access-level-groups'
import { APIResultCount } from '../constant'

const schema = z
  .object({
    users: z.array(z.any()).nonempty(),
    customerAccessLevel: z.nativeEnum(CustomerAccessLevel).default(CustomerAccessLevel.Admin),
    locationsOrGroups: z.array(z.any()).default([]),
    accessGroup: z.any().optional(),
    manualAccessLevels: z.array(z.any()).optional(),
  })
  .refine(
    form => {
      if (
        form.customerAccessLevel == CustomerAccessLevel.User &&
        !form.accessGroup &&
        !form.manualAccessLevels?.length
      ) {
        return false
      }

      return true
    },
    {
      message: 'Either choose an access level group or set access levels manually',
      path: ['accessGroup'],
    },
  )
  .transform(({ users, manualAccessLevels, locationsOrGroups, accessGroup, ...rest }) => {
    return {
      ...rest,
      locationIds: locationsOrGroups.filter(l => l.type === 'Localities').map(l => l.id),
      locationGroupId: locationsOrGroups.filter(l => l.type === 'Groups').map(l => l.id)[0],
      accessGroupId: accessGroup?.id,
      userEmails: users.map(u => u.email),
      manualAccessLevels: manualAccessLevels?.map(al => al.id),
    }
  })

export type InviteUserFormType = z.infer<typeof schema>

export const CustomerInviteUserForm: AppFormComponent<any, { customerId: EntityId }> = ({
  onSubmit,
  onCancel,
  customerId,
}) => {
  const form = useZodForm(schema, {
    shouldUnregister: false,
    defaultValues: { customerAccessLevel: CustomerAccessLevel.Admin },
  })
  const { append, remove, fields } = useFieldArray<any, any, any>({
    name: 'users',
    keyName: 'temp',
    control: form.control,
  })

  return (
    <AppForm form={form} onSubmit={onSubmit}>
      <Box className="flex-col gap-6">
        <Box className="w-3/6" py={2}>
          <UsersTextField fields={fields} onChange={append} />
        </Box>

        <Box className="flex flex-wrap gap-4">
          {fields.map((f, i) => (
            <Box key={f.email}>
              <UserChip email={f.email} onClear={() => remove(i)} />
            </Box>
          ))}
        </Box>

        <Box py={1}>
          <UserRoles />
        </Box>

        <Box py={2}>
          <AccessControl customerId={customerId} />
        </Box>
      </Box>

      <Box className="flex gap-4 pt-4 justify-end">
        <Button variant='text' onClick={onCancel}>Cancel</Button>
        <FormSubmitButton createText="Add" />
      </Box>
    </AppForm>
  )
}

const AccessControl = ({ customerId }) => {
  const customerAccessLevel = useWatch({ name: 'customerAccessLevel' })

  if (customerAccessLevel == CustomerAccessLevel.Admin)
    return (
      <Box className="bg-primary text-white p-4 rounded-md">
        <Box className="text-xl font-semibold pb-4">Information</Box>
        <Box>This will give the user(s) full administrator access</Box>
      </Box>
    )

  return <AccessLevels customerId={customerId} />
}

const UsersTextField = ({ fields, onChange }) => {
  const { setError, clearErrors } = useFormContext()
  const { hasError, message } = useFormFieldError('users')
  const [val, setVal] = useState('')

  return (
    <AppTextField
      value={val}
      fullWidth
      onChange={e => setVal(e.target.value)}
      error={hasError}
      helperText={message}
      onKeyDown={e => {
        if (e.code !== 'Enter') return
        e.stopPropagation()
        e.preventDefault()

        if (!val || !!fields.find(f => f.email === val)) {
          setVal('')
          return
        }

        const validator = z.string().email({ message: 'Must be an email' })
        const parseResult = validator.safeParse(val)
        if (!parseResult.success) {
          const message = parseResult.error.flatten().formErrors[0]
          setError('users', { message })
          return
        } else {
          clearErrors('users')
        }

        onChange({ email: val, id: val })
        setVal('')
      }}
      label="Add email..."
    />
  )
}

const UserChip: React.FC<{ email: string; onClear?: () => any }> = ({ email, onClear }) => {
  const { data, isLoading } = useQuery({
    queryKey: Queries.UserByEmail(email),
    queryFn: () => getUserByEmail(email),
    refetchOnMount: true,
  })

  if (isLoading)
    return (
      <Box className="py-2 px-12 text-white bg-gray-400 rounded-lg relative">
        <Spinner color="inherit" />
      </Box>
    )

  return (
    <Box className="p-2 text-white bg-gray-400 rounded-lg relative">
      {data && (
        <>
          <Box className="flex items-center justify-between">
            <Box className="font-bold">{`${data.firstName} ${data.lastName}`}</Box>
            {onClear && (
              <Box className="px-2 animated-hover" onClick={onClear}>
                <MdClear />
              </Box>
            )}
          </Box>
          <Box>{email}</Box>
        </>
      )}
      {!data && (
        <Tooltip title="User wan't found, an email invitation will be sent">
          <Box className="flex justify-between items-center">
            <Box className="pr-2">
              <MdInfo />
            </Box>
            <Box>{email}</Box>
            {onClear && (
              <Box className="px-2 animated-hover" onClick={onClear}>
                <MdClear />
              </Box>
            )}
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}

const UserRoles = () => {
  return (
    <Controller
      name="customerAccessLevel"
      render={({ field }) => {
        const isAdmin = field.value === CustomerAccessLevel.Admin
        const isRegular = field.value === CustomerAccessLevel.User
        return (
          <Box className="flex basis-full grow bg-gray-100 rounded-md gap-10 drop-shadow-md" px={2} py={1}>
            <Box
              px={3}
              py={1}
              className={classNames('bg-white rounded-md animated-hover', {
                'border border-primary': isAdmin,
              })}
              onClick={() => field.onChange(CustomerAccessLevel.Admin)}
            >
              <span className={classNames({ 'border-b border-black': isAdmin })}>Admin</span>
            </Box>
            <Box
              px={3}
              py={1}
              className={classNames('bg-white rounded-md animated-hover', {
                'border border-primary': isRegular,
              })}
              onClick={() => field.onChange(CustomerAccessLevel.User)}
            >
              <span className={classNames({ 'border-b border-black': isRegular })}>User</span>
            </Box>
          </Box>
        )
      }}
    />
  )
}

const AccessLevels = ({ customerId }) => {
  const { hasError, message } = useFormFieldError('accessGroup')
  const { field } = useController({ name: 'accessGroup' })
  const { setValue } = useFormContext()

  const { data, isLoading } = useGetAccessGroups(customerId)

  const onChange = (group: any) => {
    field.onChange(group)

    const accessLevels = group.accessLevelEntries.map(e => ({ id: e.accessLevel }))
    setValue('manualAccessLevels', accessLevels)
  }

  return (
    <>
      <Box className="flex flex-col items-center">
        <Box className="flex gap-4">
          <Box className="basis-7/12">
            <LocationAndGroups />
          </Box>
          <Box className="basis-5/12 text-gray-500">
            By leaving location group and location blank, you will grant the user(s) access to all locations for this
            customer
          </Box>
        </Box>
        <Box py={3} className="w-6/12">
          <Divider color="black" />
        </Box>
        <Box className="w-6/12">
          <SelectField
            loading={isLoading}
            fullWidth={false}
            error={hasError}
            helperText={message}
            label="Choose access level group"
            data={data?.items as IAccessGroup[]}
            getOptionLabel={g => g.name}
            value={field.value ?? null}
            onChange={(_, val) => onChange(val)}
            isOptionEqualToValue={(o, v) => o.id === v.id}
          />
        </Box>
      </Box>

      <Box pt={2} className="flex flex-col items-center">
        <ManualAccessLevels />
      </Box>
    </>
  )
}

function mapLocalitiesAndGroups(localities: any[], groups: any[]) {
  return [...groups.map(g => ({ type: 'Groups', ...g })), ...localities.map(l => ({ type: 'Localities', ...l }))]
}

const LocationAndGroups = () => {
  const { customer } = useCustomerCtx()

  const { field } = useController({ name: 'locationsOrGroups', defaultValue: [] })

  const { data: localities, isLoading: localitiesLoading } = useGetLocalities(customer.id, {
    page: 0,
    pageSize: APIResultCount.Max,
  })

  const { data: groups, isLoading: groupsLoading } = useGetLocalityGroups(customer.id)

  const loading = localitiesLoading || groupsLoading

  const grouped = useMemo(() => (loading ? [] : mapLocalitiesAndGroups(localities?.items as ILocality[], groups?.items.filter(g => !g.isStaticGroup) as ILocalityGroup[])), [loading])

  return (
    <SelectField
      loading={loading}
      fullWidth
      multiple
      getOptionDisabled={o => {
        const selectedGroup = field.value.find(f => f.type === 'Groups')
        if (selectedGroup) return selectedGroup.name !== o.name

        return !!field.value.find(f => f.type !== o.type)
      }}
      data={grouped}
      groupBy={o => o.type}
      isOptionEqualToValue={(o, v) => o.type === v.type && o.id === v.id}
      onChange={(_, v) => {
        field.onChange(v)
      }}
      getOptionLabel={o => o.name}
      label="Select location group or location(s)"
    />
  )
}

const ManualAccessLevels = () => {
  const { data, isLoading } = useQuery(Queries.LocalitiesAccessLevels, getLocalityAccessLevels)
  const { setValue, getValues } = useFormContext()
  const { append, remove, fields } = useFieldArray<any, any, any>({ name: 'manualAccessLevels', keyName: 'temp' })

  const onRemove = (al: string) => {
    remove(fields.findIndex(f => f.id === al))
  }

  const onChange = (checked: boolean, accessLevel: any) => {
    if (checked) append(accessLevel)
    else onRemove(accessLevel.id)

    const accessGroup = getValues('accessGroup')
    if (accessGroup) {
      setValue('accessGroup', null)
    }
  }

  return (
    <>
      <Box className="text-2xl text-gray-500 font-semibold pb-4">Or choose access levels manually</Box>
      <Box className="flex w-full p-5 bg-gray-100 rounded-md drop-shadow-md">
        <Box className="flex  gap-40 text-gray-500">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Box className="flex flex-col">
                <Box className="font-semibold pb-4">General access</Box>
                {data['general'].map(al => (
                  <FormControlLabel
                    key={al.id}
                    control={
                      <Checkbox
                        onChange={(_, checked) => onChange(checked, al)}
                        checked={!!fields.find(l => l.id === al.id)}
                      />
                    }
                    label={al.name}
                  />
                ))}
              </Box>
              <Box className="flex flex-col">
                <Box className="font-semibold pb-4">Deviation</Box>
                {data['deviation'].map(al => (
                  <FormControlLabel
                    key={al.id}
                    control={
                      <Checkbox
                        onChange={(_, checked) => onChange(checked, al)}
                        checked={!!fields.find(l => l.id === al.id)}
                      />
                    }
                    label={al.name}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
