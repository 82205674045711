import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { MdDelete, MdEdit } from 'react-icons/md'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { FileType } from '../enums'
import { bytesToSize, contentType, isFileImage, isFileUrlExtensionImage, shortenFileName } from '../utils'
import { IDocumentCategory, IFile } from '../generated-types'
import { AppForm, FormSelectField, FormTextField, useFormFieldError } from './FormCore'
import BasicDatePicker from './DateTimePicker'
import { invalidateDocument, useAddDocument, useGetDocumentByFileId, useUpdateDocumentByFileId } from '../api/document'
import { useDeleteFile, useUploadFile, useGetSharedFileUrl } from '../api/file'
import { useUserGetDocumentCategories } from '../api/document-category'
import { toast } from 'react-toastify'
import { z } from 'zod'
import { useZodForm } from '../hooks/zod-form'
import { GenericDialog } from './GenericDialog'
import { invalidateLocalityFrequencyCache } from '../api/locality-frequency'
import FileSVG from 'src/assets/svg/file.svg'
import { Spinner } from './Spinner'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface PropsBase {
  name: string
  initialFiles?: IFile[] | IFile | string
  dropMsg?: string
  inactiveMsg?: string
  enablePreview?: boolean
  type?: FileType
  disabled?: boolean
  acceptedFileTypes?: string[]
  maxFileSize?: number
  previewDialog?: React.FC<any>
  onPreview?: (file: IFile) => any
  required?: boolean
  getUploadedFiles?: (files: IFile | IFile[]) => any
}

interface PropsDocument extends PropsBase {
  document?: boolean
  customerId?: EntityId
  localityId?: EntityId
  localityFrequencyIds?: Array<number | undefined>
}

interface PropsMultiple {
  multiple?: true
  onChange: (files: IFile[]) => any
}

interface PropsSingle {
  multiple?: false
  onChange: (file: IFile) => any
}

function getInitialFiles(files?: IFile[] | IFile | string): IFile[] {
  if (!files) return []

  if (Array.isArray(files)) {
    return files.map(f => {
      if (typeof f === 'string')
        return { url: f, filename: 'unknown', viewToken: '', id: 0, contentType: '' } as unknown as IFile

      return f
    })
  } else {
    // @ts-ignore
    if (typeof files === 'string') return [{ url: files, filename: 'unknown', viewToken: '', id: 0, contentType: '' }]

    return [files]
  }
}

interface IFileMetaDataDialogProps {
  customerId: EntityId
  localityId: EntityId
  localityFrequencyIds?: Array<number | undefined>
  file: IFile
  onClose: () => any
}
const UpdateFileMetaDataDialog: React.FC<IFileMetaDataDialogProps> = ({
  customerId,
  localityId,
  localityFrequencyIds,
  file,
  onClose,
}) => {
  const { t } = useTranslation(['file', 'common'])
  const [open, setOpen] = React.useState(true)

  const { data, isLoading } = useGetDocumentByFileId(customerId as EntityId, file.id as EntityId)

  const schema = z.object({
    id: z
      .number()
      .nullable()
      .default(data ? data.id : null)
      .optional(),
    title: z.string().min(1),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    dueDate: z.date().or(z.string()).optional(),
    notes: z.string().optional(),
    version: z.string().optional(),
    fileId: z.number().default(file.id),
  })

  const form = useZodForm(schema, {
    defaultValues: { ...data },
  })

  useEffect(() => {
    if (data) {
      form.reset({ ...data })
    }
    form.setValue('documentCategoryId', data?.documentCategory as any)
  }, [data, form])

  const documentTypes = useUserGetDocumentCategories()

  const { mutateAsync: addMutation } = useAddDocument(customerId as EntityId)
  const { mutateAsync: updateMutation } = useUpdateDocumentByFileId(customerId as EntityId, file.id as EntityId)

  const handleSubmit = useCallback(
    async form => {
      form.localityFrequencyIds = localityFrequencyIds
      data ? await updateMutation(form) : await addMutation(form)
      invalidateDocument.getDocumentByFileId(customerId, file.id)
      invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId as EntityId, localityId as EntityId)
      invalidateLocalityFrequencyCache.useGetLocalityFrequency(customerId as EntityId, localityId as EntityId)
      onClose()
      setOpen(false)
    },
    [data, customerId, file.id, onClose],
  )

  const onCancel = () => {
    setOpen(false)
    onClose()
  }

  return (
    <AppForm form={form}>
      <GenericDialog
        minWidth={400}
        open={open}
        onClose={onClose}
        title={data ? t('updateDocument', { ns: 'document' }) : t('addDocument', { ns: 'document' })}
        actions={[
          <Button
            key={'submit-btn'}
            variant="contained"
            color="primary"
            onClick={async () => {
              form.handleSubmit(handleSubmit)()
            }}
          >
            {data ? t('update', { ns: 'common' }) : t('add', { ns: 'common' })}
          </Button>,
        ]}
      >
        <FormTextField name="title" label={t('title', { ns: 'document' })} fullWidth />
        <FormSelectField
            name="documentCategoryId"
            data={(documentTypes?.data?.items as IDocumentCategory[]) ?? []}
            label={t('document-category', { ns: 'document' })}
            getOptionLabel={option => t(option.categoryName)}
            renderOption={(props: any, option) => (
              <Box {...props} key={option}>
                {t(option.categoryName)}
              </Box>
            )}
            isOptionEqualToValue={(o, v) => o === v}
          />
        <BasicDatePicker label={t('dueDate', { ns: 'document' })} name="dueDate" />
        <FormTextField name="notes" label={t('notes', { ns: 'document' })} fullWidth multiline rows={4} />
      </GenericDialog>
    </AppForm>
  )
}

interface IBaseFileUrlLoaderProps {
  fileUrl: string
  openOnClick?: boolean
  className?: string
  contentTypeValue?: string
}

export const FileUrlLoader: React.FC<IBaseFileUrlLoaderProps> = memo(
  ({ fileUrl, openOnClick, className, contentTypeValue }) => {
    return isFileUrlExtensionImage(fileUrl) ? (
      <FileUrlImageLoader fileUrl={fileUrl} openOnClick={openOnClick} className={className} />
    ) : (
      <FileUrlDocumentLoader
        fileUrl={fileUrl}
        openOnClick={openOnClick}
        className={className}
        contentTypeValue={contentTypeValue}
      />
    )
  },
)

export const FileUrlDocumentLoader: React.FC<IBaseFileUrlLoaderProps> = ({
  fileUrl,
  openOnClick,
  className = 'text-xl h-24px w-24px',
  contentTypeValue = null,
}) => {
  const { openFilePreviewDialog } = useDialogCtx()
  
  const Icon =
    contentTypeValue && contentType[contentTypeValue]?.icon ? contentType[contentTypeValue]?.icon : FileSVG
  return (
    <Box className={openOnClick ? ' cursor-pointer' : ''} onClick={(e) => {
      if (openOnClick) {
        e.stopPropagation()
        openFilePreviewDialog(fileUrl)
      }
    }}>
      <img src={Icon} className={className} />
    </Box>
  )
}

export const FileUrlImageLoader: React.FC<IBaseFileUrlLoaderProps> = ({
  fileUrl,
  openOnClick,
  className = 'max-w-[24px] h-[24px] object-cover',
}) => {
  const { openFilePreviewDialog } = useDialogCtx()
  const { data, isLoading } = useGetSharedFileUrl(fileUrl)

  if (isLoading) return <Spinner size={'small'} className={className} />
  
  return (
    <img
      className={className + (openOnClick ? ' cursor-pointer' : '')}
      src={data}
      onClick={() => {
        data && openFilePreviewDialog(data)
      }}
    />
  )
}

export type FileUploaderProps = PropsBase & (PropsMultiple | PropsSingle) & PropsDocument

export const FileUploader: React.FC<FileUploaderProps> = ({
  name,
  dropMsg,
  inactiveMsg = '',
  maxFileSize,
  enablePreview = true,
  acceptedFileTypes,
  initialFiles,
  multiple = false,
  onPreview,
  disabled = false,
  onChange,
  type = FileType.File,
  document = false,
  customerId,
  localityId,
  required = false,
  localityFrequencyIds,
  getUploadedFiles,
}) => {
  const { t } = useTranslation('file-upload')
  const { hasError } = useFormFieldError(name)
  const [files, setFiles] = useState<IFile[]>(getInitialFiles(initialFiles))
  const [uploadedFile, setUploadedFile] = useState<IFile | null>(null)
  const [showDocumentDialog, setShowDocumentDialog] = useState(false)
  const id = useMemo(() => uuid(), [])
  const mutation = useUploadFile(type === FileType.Image)

  useEffect(() => {
    setFiles(getInitialFiles(initialFiles))
  }, [initialFiles])

  const onDrop: DropzoneOptions['onDrop'] = async uploadedFiles => {
    for (const uploadedFile of uploadedFiles) {
      if (acceptedFileTypes)
        if (!acceptedFileTypes.includes(uploadedFile.type)) {
          toast(`${t('invalid-type')} ${uploadedFile.name} ${t('allowed-types')} ${acceptedFileTypes.join(', ')}`, {
            type: 'error',
          })
          continue
        }

      if (maxFileSize && uploadedFile.size > maxFileSize) {
        toast(
          `${t('file-size-big')} ${uploadedFile.name} ${t('upload-size')} ${uploadedFile.size} ${t(
            'max-allowed',
          )} ${maxFileSize}`,
          { type: 'error' },
        )
        continue
      }

      const formData = new FormData()
      formData.append('file', uploadedFile)

      mutation.mutateAsync(formData).then(
        result => {
          const file = result.data as IFile
          const newFiles = multiple ? [...files, file] : [file]
          setFiles(newFiles)
          getUploadedFiles && getUploadedFiles(file)
          // @ts-ignore
          onChange(multiple ? newFiles : newFiles[0])
          if (document) {
            setUploadedFile(file)
            setShowDocumentDialog(true)
          }
        },
        error => {
          console.error('Failed to upload', error?.message)
        },
      )
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 104857600,
  })

  const displayMsg = () => {
    return isDragActive ? (
      <span>{dropMsg || t('drop')}</span>
    ) : (
      <span className="text-gray-500">{inactiveMsg || t('inactive')}</span>
    )
  }

  const onFileClick = (e: React.MouseEvent, file: IFile) => {
    e.stopPropagation()
    if (!isFileImage(file)) return

    if (onPreview) return onPreview(file)

    //showDialog(previewDialog, { componentProps: { imageName: file.filename, url: getFilePreviewPath(file) } })
  }

  const deleteMutation = useDeleteFile()
  const onDelete = async (e: React.MouseEvent, file: IFile) => {
    e.stopPropagation()
    try {
      if (file.id) {
        await deleteMutation.mutateAsync(file.id)
        invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId as EntityId, localityId as EntityId)
        invalidateLocalityFrequencyCache.useGetLocalityFrequency(customerId as EntityId, localityId as EntityId)
        const newFiles = files.filter(f => f.id !== file.id)
        setFiles(newFiles)

        // @ts-ignore
        onChange(multiple ? newFiles : newFiles[0])
      } else {
        const newFiles = files.filter(f => f.url !== file.url)
        setFiles(newFiles)

        // @ts-ignore
        onChange(multiple ? newFiles : newFiles[0])
      }
    } catch (e) {
      console.error('Failed to delete')
    }
  }

  const onEdit = async (e: React.MouseEvent, file: IFile) => {
    try {
      setUploadedFile(file)
      setShowDocumentDialog(true)
    } catch (e) {
      Logger.info('Failed to update.')
    }
  }

  const fileTable = (files: IFile[]) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('file', { ns: 'common' })}</TableCell>
              <TableCell>{t('name', { ns: 'common' })}</TableCell>
              <TableCell>{t('type', { ns: 'common' })}</TableCell>
              <TableCell width="100px">{t('size', { ns: 'common' })}</TableCell>
              {document && (
                <TableCell align="center" width="120px">
                  {t('actions', { ns: 'common' })}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map(file => {
              const { fileName, isShorten } = shortenFileName(file.filename, 20)
              return (
                <TableRow key={file.id}>
                  <TableCell component="th" scope="row" onClick={e => onFileClick(e, file)}>
                    {file && <FileUrlLoader fileUrl={file.url} openOnClick={enablePreview} />}
                  </TableCell>
                  <TableCell>
                    {
                      <Tooltip title={isShorten ? file.filename : ''}>
                        <Typography>{fileName}</Typography>
                      </Tooltip>
                    }
                  </TableCell>
                  <TableCell>{file.contentType}</TableCell>
                  <TableCell>{bytesToSize(file.contentLength)}</TableCell>
                  <TableCell align="right">
                    {document && (
                      <IconButton disabled={disabled} onClick={e => onEdit(e, file)}>
                        <MdEdit fontSize={24} />
                      </IconButton>
                    )}
                    {document && (
                      <IconButton disabled={disabled} onClick={e => onDelete(e, file)}>
                        <MdDelete fontSize={24} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Box>
      <Box
        className="relative p-8 text-center border-4 border-dashed rounded border-white-200"
        {...(disabled ? () => { } : getRootProps())}
      >
        <input name={name} id={id} {...getInputProps()} />
        {displayMsg()}
        {((required == true && files?.length === 0) || hasError) && (
          <FormHelperText error={true} sx={{ textAlign: 'center' }}>
            ({t('provideValidFile', { ns: 'common' })})
          </FormHelperText>
        )}
      </Box>
      {files?.length > 0 && <Box className="pt-2">{fileTable(files)}</Box>}
      {showDocumentDialog && (
        <UpdateFileMetaDataDialog
          customerId={customerId as EntityId}
          localityId={localityId as EntityId}
          localityFrequencyIds={localityFrequencyIds}
          file={uploadedFile as IFile}
          onClose={() => setShowDocumentDialog(false)}
        />
      )}
    </Box>
  )
}
