import { FormFieldType } from "../enums";

export interface FormField {
    type: FormFieldType
    name: string
    isRequired?: boolean
    options?: IOption[]
    fieldIndex?: number
    rowIndex?: number
    columnIndex?: number
    identifier?: string
    defaultValue?: string
    disabled?: boolean
}

export interface FormLayout {
    fields: FormField[]
}

export interface IOption {
    label: string
    value: any
}

export interface FormRow {
    columns: FormLayout[]
}

export enum formFieldsDefaultValues {
    facilityName = '@AnleggNavn@',
    siteLocationName = '@LokalitetNavn@',
    siteNumber = '@LokalitetNR@',
    productionNumber = '@RessursProduksjonsnr@',
    orderNumber = '@RessursOrdrenr@',
    netType = '@RessursNotType@',
    netFunction = '@RessursNotFunksjon@',
    perimeter = '@RessursOmkrets@',
    cagePlacement = '@RessursBurPlassering@',
    resourcesName = '@RessursNavn@',
}

export const CollectionFormFieldList = [FormFieldType.checkBoxList, FormFieldType.selectField, FormFieldType.radio]

export const DefaultValueIncludedFieldList = [FormFieldType.textField]

export const NotFieldTypes = [FormFieldType.title]