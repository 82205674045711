import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const FormGenericData = ({ fieldDetails, setFieldDetails, pop }) => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [hasVideoUpload, setHasVideoUpload] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const { t } = useTranslation(['form-schema', 'common'])

    useEffect(() => {
        setTitle(fieldDetails.name)
        setDescription(fieldDetails.description)
        setHasVideoUpload(fieldDetails.hasVideoUpload)
        setIsActive(fieldDetails.isActive)
    }, [fieldDetails])

    const onSubmit = () => {
        setFieldDetails({
            name: title,
            description,
            hasVideoUpload,
            isActive
        })
        pop()
    }
    return (<>
        <DialogTitle>{t('general-details')}</DialogTitle>
        <DialogContent>
            <Box display={'flex'} flexDirection={'column'}>
                <Box marginTop={1} width={'100%'}>
                    <Typography>{t('title', { ns: 'common' })}</Typography>
                    <TextField placeholder={t('title', { ns: 'common' })} fullWidth variant='outlined' value={title}
                        onChange={(evt) => {
                            setTitle(evt.target.value)
                        }} />
                </Box>
                <Box marginTop={1} width={'100%'}>
                    <Typography>{t('description', { ns: 'common' })}</Typography>
                    <TextField placeholder={t('description', { ns: 'common' })} fullWidth multiline rows={5} variant='outlined' value={description}
                        onChange={(evt) => {
                            setDescription(evt.target.value)
                        }}
                    />
                </Box>
                <Box marginTop={1} width={'100%'} display={'flex'} flexDirection={'row'}>
                    <Checkbox checked={hasVideoUpload}
                        onChange={(evt) => {
                            setHasVideoUpload(evt.target.checked)
                        }} />
                    <Box marginTop={1} width={'100%'} display={'flex'} flexDirection={'row'}>
                        <Typography>{t('has-video-upload')}</Typography>
                    </Box>
                </Box>
                <Box marginTop={1} width={'100%'} display={'flex'} flexDirection={'row'}>
                    <Checkbox checked={isActive}
                        onChange={(evt) => {
                            setIsActive(evt.target.checked)
                        }} />
                    <Box marginTop={1} width={'100%'} display={'flex'} flexDirection={'row'}>
                        <Typography>{t('isActive', { ns: 'common' })}</Typography>
                    </Box>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' onClick={() => onSubmit()} >{t('save', { ns: 'common' })}</Button>
            <Button onClick={() => pop()} >{t('cancel', { ns: 'common' })}</Button>
        </DialogActions>
    </>
    )
}

export default FormGenericData