import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BasicDatePicker from '../../../../components/DateTimePicker'
import { generateUUID } from '../../../../utils/function'
import { ShowDialogProps } from '@lovoldsystem/ui'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'

interface InstallationDateDialogProps extends ShowDialogProps {
  onSubmit: (date: any,pop:()=>void) => void
  isLoading?: boolean
}

export const InstallationDateDialog: React.FC<InstallationDateDialogProps> = ({ onSubmit, isLoading, pop }) => {
  const { t } = useTranslation(['work-assignment', 'common'])
  const [installationDate, setInstallationDate] = useState<null | Date>(null)

  return (
    <GenericDialogLayout
      title={t('setInstallDate', { ns: 'work-assignment' })}
      pop={pop}
      actions={[
        <Button
          key={generateUUID()}
          variant="contained"
          onClick={() => onSubmit(installationDate as Date,pop)}
          disabled={installationDate === null || isLoading}
        >
          {t('setInstallDate', { ns: 'work-assignment' })}
        </Button>,
      ]}
      sx={{minWidth:'400px'}}
    >
      <BasicDatePicker
        name="installationDate"
        label={t('installationDate', { ns: 'component' })}
        onChange={date => setInstallationDate(date)}
        value={installationDate}
        isWithoutAppForm={true}
      />
    </GenericDialogLayout>
  )
}
