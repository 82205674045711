import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppForm, AppFormProps } from 'src/components/FormCore'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { MainButton } from 'src/components/MainButton'
import GenericTable from 'src/components/table/GenericTable'
import { IBatchNumberDetails } from 'src/generated-types'
import { useZodForm } from 'src/hooks/zod-form'
import { GlobalStyled } from 'src/styles/globalPaddingStyles'
import { z } from 'zod'

const schema = z.object({
  commonBatchNumbers: z.array(z.string()),
  componentIds: z.array(z.number()),
  positionIds: z.array(z.number()),
})

export interface UpdateBatchNumberFormProps extends AppFormProps {
  pop: any
  positions: IBatchNumberDetails[]
  componentId: number
  isLoading: boolean
  productDescription: string
}

export const UpdateBatchNumberForm: FC<UpdateBatchNumberFormProps> = ({
  pop,
  onSubmit,
  positions,
  componentId,
  isLoading,
  productDescription,
}) => {
  const { t } = useTranslation(['position'])
  const [selectedIds, setSelectedIds] = useState<number[]>([componentId])
  const [selectedPositions, setSelectedPositions] = useState<IBatchNumberDetails[]>([])
  const [commonBatchNumbers, setCommonBatchNumbers] = useState<string[]>([])
  const form = useZodForm(schema)

  useEffect(() => {
    setSelectedIds([componentId])
  }, [componentId])

  useEffect(() => {
    const selectedPositions = positions.filter(item => selectedIds.includes(item.id))
    const batchNumbers = selectedPositions?.map(item => item.potencialCertificateBNs)
    const commonBatchNumbers =
      batchNumbers.length > 0
        ? batchNumbers.reduce((accumulator, currentArray) => {
          return accumulator?.filter(value => currentArray?.includes(value))
        })
        : []

    setCommonBatchNumbers(commonBatchNumbers ?? [])
    setSelectedPositions(selectedPositions)
  }, [form, positions, selectedIds])

  useEffect(() => {
    form.setValue('commonBatchNumbers', commonBatchNumbers)
  }, [commonBatchNumbers, form])

  useEffect(() => {
    form.setValue('componentIds', selectedPositions?.map(p => p.id) ?? [])
    form.setValue('positionIds', selectedPositions?.map(p => p.positionId) ?? [])
  }, [form, selectedPositions])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      { field: 'positionName', headerName: t('position', { ns: 'position' }), flex: 1 },
      { field: 'positionDrawingNumber', headerName: t('position-drawing-number', { ns: 'position' }), flex: 1 },
      {
        field: 'potencialCertificateBNs',
        headerName: t('batch-numbers', { ns: 'position' }),
        flex: 1,
        renderCell: params => <span>{params.value.join(', ')}</span>,
      },
    ],
    [t],
  )
  return (
    <GlobalStyled>
      <AppForm form={form} onSubmit={onSubmit}>
        <GenericDialogLayout
          title={t('update-batch-numbers', { ns: 'position' })}
          pop={pop}
          removedCloseButton={false}
          actions={[
            <MainButton
              key="position-form-btn"
              loading={isLoading}
              onClick={() => {
                form.handleSubmit(onSubmit)()
              }}
            >
              {t('update', { ns: 'common' })}
            </MainButton>,
          ]}
        >
          <Box className="pb-8-core">
            <Typography variant="subtitle2" fontWeight={'500'}>
              {productDescription}
            </Typography>
          </Box>
          <Box mb={2}>
            <GenericTable
              id="positions"
              columns={STATIC_COLUMNS}
              data={positions}
              getRowHeight={() => 'auto'}
              checkboxSelection={true}
              selectionModel={selectedIds}
              onSelectionModelChange={ids => {
                setSelectedIds(ids as number[])
              }}
              getRowClassName={params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
              height={600}
              autoHeight={false}
            />
          </Box>
          <Box className="pt-12-core">
            <Autocomplete
              fullWidth
              multiple
              options={commonBatchNumbers as string[]}
              isOptionEqualToValue={(option, value) => option === value}
              value={commonBatchNumbers}
              freeSolo
              onChange={(_, v) => {
                setCommonBatchNumbers(v)
              }}
              renderTags={(value: readonly string[], getTagProps) => {
                return value?.map((option: string, index: number) => {
                  const { key, className: _, ...rest } = getTagProps({ index })
                  return (
                    <Chip
                      key={key}
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{ m: 0.1 }}
                      label={option ?? ''}
                      {...rest}
                    />
                  )
                })
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  name="batchNo"
                  variant="outlined"
                  label={t('update-batch-numbers', { ns: 'position' })}
                  InputProps={{ ...params.InputProps, endAdornment: null }}
                />
              )}
            />
          </Box>
        </GenericDialogLayout>
      </AppForm>
    </GlobalStyled>
  )
}
