import { Box, LinearProgress, Typography } from '@mui/material'

interface GenericProgressBarProps {
  value: number
  color?: string | undefined
  customText?: string
  height?: string
  boxShadow?: string
  borderRadius?: string
}

const GenericProgressBar: React.FC<GenericProgressBarProps> = ({
  value,
  color = 'transparent',
  customText = '',
  height = '8px',
  boxShadow = '0px 3px 6px #00000029',
  borderRadius = '6px',
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} className="justify-end w-full">
      <LinearProgress variant="determinate" value={value} sx={{ bgcolor: color, height, boxShadow, borderRadius }} />
      <Typography variant="body2" fontWeight={600}>
        {customText}
      </Typography>
    </Box>
  )
}

export default GenericProgressBar
