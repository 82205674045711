import { Box, DialogActions, Grid, IconButton, useTheme } from '@mui/material'
import LayoutBox from '../../../components/formGenerator/LayoutBox'
import { AddIcon } from './Icons'
import { MdClose } from 'react-icons/md'

const Form = ({ layouts, disabled, onDrop, addLayout, deleteLayout, selectFormField, title, form, withColorLayout = false, onRearrange, cancelEnabled=false,pop=()=>{} }) => {
  const theme = useTheme()
    return (
        <Box width={'100%'} padding={1} flex={'1'} height={window.innerHeight - 200} className='scrollbar' style={{ overflowY: 'auto', overflowX: 'hidden' }} >
            {cancelEnabled && 
                <DialogActions>
                <IconButton aria-label="close" onClick={pop} >
                <MdClose color={theme.palette.primaryMain[theme.palette.mode]}/>
              </IconButton>
            </DialogActions>
            }
            {
                disabled &&
                <Grid item md={12}>
                    <Box justifyContent={'center'} display={'flex'} flexDirection={'row'}>
                        <AddIcon onClick={() => { addLayout(0) }} />
                    </Box>
                </Grid>
            }
            {
                layouts.map((row, index) => {
                    return <Grid key={index} container width={'100%'}>
                        {
                            row.columns.map((layout, indexCol) => {
                                return <LayoutBox
                                    size={12 / row.columns.length}
                                    key={indexCol}
                                    form={form}
                                    indexCol={indexCol}
                                    disabled={disabled}
                                    layoutIndex={index}
                                    layout={layout}
                                    withColorLayout={withColorLayout}
                                    onDrop={(indexVal, fieldIndex) => onDrop(indexVal, indexCol, fieldIndex)}
                                    onCreateLayout={(columnIndex) => addLayout(index, columnIndex)}
                                    deleteLayout={() => deleteLayout(index, indexCol)}
                                    setSelectedSettingField={selectFormField}
                                    onRearrange={onRearrange} />
                            })
                        }
                        {
                            disabled &&
                            <Grid item md={12}>
                                <Box justifyContent={'center'} display={'flex'} flexDirection={'row'}>
                                    <AddIcon onClick={() => { addLayout(index + 1) }} />
                                </Box>
                            </Grid>
                        }
                    </Grid>
                })
            }
        </Box>
    )
}

export default Form