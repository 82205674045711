import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { IMooring, IMooringType, IPosition } from '../../../generated-types'
import { useGetMainComponents, useGetOutgoingMainComponents } from '../../../api/main-component'
import AdditionalEquipment from './AdditionalEquipment'
import CageActivityLog from './CageHistory'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@mui/icons-material'
import { MainComponentAddComponent } from '../positions/components/MainComponentAddModal'
import { APIResultCount } from '../../../constant'
import { permissionValue } from '../../../enums/permissionValue'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { Card } from 'src/styles/Index'
import { Flex } from 'src/styles/flexComponent'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { DetailsCard } from '../positions/PositionContent'
import { Padding } from 'src/styles/paddingComponent'
import { useGetCagesForDropDownByMooringId } from 'src/api/positions'
import { PositionsNavigationListContainer } from '../positions/PositionContainer'
import { CageCustomLogAddDialog } from '../positions/components/CageCustomLogAddDialog'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { toast } from 'react-toastify'
import { useGetLocality } from 'src/api/localities'
import CageObject from './CageObject'
import CageMap from './CageMap'
import { useEffect, useState } from 'react'

export const mainComponentsSectionHeight = (window.innerHeight - 600) / 2 < 389 ? 389 : (window.innerHeight - 600) / 2

const CageContainer: React.FC<{ position: IPosition; mooringDetails: IMooring; loading: boolean }> = ({
  position,
  mooringDetails,
  loading,
}) => {
  const { showDialog } = useDialogCtx()
  const [properties, setProperties] = useState<any>({})
  const { localityId, customerId, mooringId, positionId } = useParams()
  const isMobileScreen = useMediaQuery('(max-width: 600px)')
  const { data } = useGetCagesForDropDownByMooringId(
    customerId as EntityId,
    localityId as EntityId,
    mooringId as EntityId,
  )
  const theme = useTheme()

  const { data: mainComponents, isLoading: isMainComponentLoading } = useGetMainComponents(
    customerId as EntityId,
    { localityId, mooringId, positionId },
    0,
    APIResultCount.Max,
  )

  const { data: locality } = useGetLocality(customerId as EntityId, localityId as EntityId)

  const { data: outgoingMainComponents } = useGetOutgoingMainComponents(customerId as EntityId, localityId as EntityId)

  const { t } = useTranslation(['common', 'main-component', 'position'])

  const onAdd = async () => {
    if (outgoingMainComponents?.items?.length === 0) {
      toast(t('no-outgoing-components', { ns: 'main-component' }), { type: 'warning' })
      return
    }
    const added = await showDialog(
      MainComponentAddComponent,
      {
        componentProps: {
          customerId: customerId as EntityId,
          mooringId: mooringId as EntityId,
          positionId: positionId as EntityId,
          localityId: localityId as EntityId,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onCustomLogAdd = async () => {
    const added = await showDialog(
      CageCustomLogAddDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          mooringId: mooringId as EntityId,
          positionId: positionId as EntityId,
          localityId: localityId as EntityId,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  useEffect(() => {
    if (locality && mainComponents) {
      const propertyObject = {
        hs: {
          value: locality.hs,
          isExceed: false,
        },
        vc5m: {
          value: locality.vC5M,
          isExceed: false,
        },
        vc15m: {
          value: locality.vC15M,
          isExceed: false,
        },
        solidity: {
          value: locality.solidityAndCircumferences?.reduce((max, cur) => Math.max(max, cur.solidity), 0),
          isExceed: false,
        },
        circumferences: {
          value: locality.solidityAndCircumferences?.reduce((max, cur) => Math.max(max, cur.circumference), 0),
          isExceed: false,
        },
      }

      mainComponents?.items?.map(mainComponent => {
        mainComponent?.mainComponentProperties?.map(property => {
          if (propertyObject?.hs?.value && property.hs < propertyObject?.hs?.value) {
            propertyObject.hs = { value: property.hs, isExceed: true }
          }

          if (propertyObject?.vc5m?.value && property.vC5 < propertyObject?.vc5m?.value) {
            propertyObject.vc5m = { value: property.vC5, isExceed: true }
          }

          if (propertyObject?.vc15m?.value && property.vC15 < propertyObject?.vc15m?.value) {
            propertyObject.vc15m = { value: property.vC15, isExceed: true }
          }
        })

        if (
          propertyObject?.solidity?.value &&
          mainComponent?.solidity &&
          mainComponent?.solidity > propertyObject?.solidity?.value
        ) {
          propertyObject.solidity = { value: mainComponent?.solidity, isExceed: true }
        }

        if (
          propertyObject?.circumferences?.value &&
          mainComponent?.circumference &&
          mainComponent?.circumference != propertyObject?.circumferences?.value
        ) {
          propertyObject.circumferences = { value: mainComponent?.circumference, isExceed: true }
        }
      })

      setProperties(propertyObject)
    }
  }, [locality, mainComponents])

  return (
    <div style={{ background: '#F1F5F7' }}>
      <CommonPageLayout enableBackButton titleSection={{ title: t('cage-overview', { ns: 'position' }) }}>
        <Padding.pb10>
          <PositionsNavigationListContainer positions={data} filter={[]} selectedPosition={position} />
        </Padding.pb10>
        <Flex.Column rowGap={'16px'}>
          <Grid container>
            <Grid md={6} paddingRight={'8px'}>
              <Card sx={{ height: '160px', overflowX: 'auto' }} className="pt-6-core pl-6-core pr-6-core">
                <Box sx={{ height: '130px', minWidth: '500px' }}>
                  <Grid container className="pt-5-core" sx={{ height: '150px' }}>
                    <DetailsCard
                      icon={<SVGContainer src={'/icons/hs.svg'} isExceed={properties?.hs?.isExceed} />}
                      title={t('hs', { ns: 'common' })}
                      value={properties?.hs?.value}
                      isExceed={properties?.hs?.isExceed}
                    />
                    <DetailsCard
                      icon={<SVGContainer src={'/icons/vc.svg'} isExceed={properties?.vc5m?.isExceed} />}
                      title={t('vc5m', { ns: 'common' })}
                      value={properties?.vc5m?.value}
                      isExceed={properties?.vc5m?.isExceed}
                    />
                    <DetailsCard
                      icon={<SVGContainer src={'/icons/vc15.svg'} isExceed={properties?.vc15m?.isExceed} />}
                      title={t('vc15m', { ns: 'common' })}
                      value={properties?.vc15m?.value}
                      isExceed={properties?.vc15m?.isExceed}
                    />
                    <DetailsCard
                      icon={<img src={'/icons/depth.svg'} color="primary" />}
                      title={t('depth', { ns: 'position' })}
                      value={position?.depth ?? '-'}
                    />
                    <DetailsCard
                      icon={
                        <SVGContainer src={'/icons/solidity.svg'} isExceed={properties?.circumferences?.isExceed} />
                      }
                      title={t('solidity', { ns: 'main-component' })}
                      value={properties?.solidity?.value}
                      isExceed={properties?.solidity?.isExceed}
                    />
                    <DetailsCard
                      icon={
                        <SVGContainer
                          src={'/icons/circumference.svg'}
                          isExceed={properties?.circumferences?.isExceed}
                        />
                      }
                      title={t('circumference', { ns: 'main-component' })}
                      value={properties?.circumferences?.value}
                      isExceed={properties?.circumferences?.isExceed}
                    />
                    <DetailsCard
                      icon={<SVGContainer src={'/icons/current.svg'} isExceed={false} />}
                      title={t('current', { ns: 'common' })}
                      value={position?.mbl ?? '-'}
                    />
                    <DetailsCard
                      icon={<SVGContainer src={'/icons/degree-of-load.svg'} isExceed={false} />}
                      title={t('degree-of-load', { ns: 'position' })}
                      value={position?.accidentLoad ?? '-'}
                    />
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid md={6} paddingLeft={'8px'}>
              <Card sx={{ height: '160px' }}>
                <CageMap
                  positions={mooringDetails?.positions}
                  position={position}
                  type={mooringDetails?.type as IMooringType}
                />
              </Card>
            </Grid>
          </Grid>
          <Card className="p-12-core" sx={{ height: mainComponentsSectionHeight }}>
            <CommonPageLayout
              titleSection={{ subtitle: t('equipment', { ns: 'sidebar' }) }}
              topAction={[
                <GridActionButton
                  onClick={onAdd}
                  key="add"
                  variant="outlined"
                  buttonTextProps={{ variant: 'subtitle1', fontWeight: 600 }}
                  buttonText={isMobileScreen ? undefined : t('add-equipment', { ns: 'position' })}
                  shadowWeight={1}
                  permissionBinaryValues={[permissionValue.Locality.Mooring.Write]}
                  buttonIcon={<AddCircleOutline htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
                  height="36px"
                />,
              ]}
            >
              <Grid container columnSpacing={'16px'}>
                <Grid item md={7}>
                  <Padding.ph12 border={'1px solid #E8E8E8'}>
                    <AdditionalEquipment additionalEquipment={mainComponents?.items} />
                  </Padding.ph12>
                </Grid>
                <Grid item md={5}>
                  <CageObject fileURL={position?.modelImageURL} />
                </Grid>
              </Grid>
            </CommonPageLayout>
          </Card>
          <Card className="p-20-core">
            <Flex.Row className="pl-8-core pr-8-core pt-8-core" justifyContent={'space-between'}>
              <Typography color={getThemeColor(theme, MUIThemeColors.primaryMain)} variant="h6">
                {t('activity-log', { ns: 'common' })}
              </Typography>
              <GridActionButton
                onClick={onCustomLogAdd}
                key="add"
                variant="outlined"
                buttonTextProps={{ variant: 'subtitle1', fontWeight: 600 }}
                buttonText={isMobileScreen ? undefined : t('custom-log', { ns: 'activity-logs' })}
                shadowWeight={1}
                permissionBinaryValues={[permissionValue.Locality.Mooring.Write]}
                buttonIcon={<AddCircleOutline htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
                height="36px"
              />
            </Flex.Row>
            <CageActivityLog id={positionId} customerId={customerId} localityId={localityId} mooringId={mooringId} />
          </Card>
        </Flex.Column>
      </CommonPageLayout>
    </div>
  )
}

export default CageContainer

//styled components
const SVGContainer = styled('div')<{ src: string; isExceed: boolean }>`
  width: 36px;
  height: 36px;
  background-color: ${props =>
    getThemeColor(
      props.theme,
      props.isExceed ? MUIThemeColors.white : MUIThemeColors.primaryMain,
    )}; /* Change this to change the color */
  mask: url(${props => props.src}) no-repeat center;
  -webkit-mask: url(${props => props.src}) no-repeat center;
`
