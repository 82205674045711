import { AppBar, IconButton, Stack, styled, Tab, TabProps } from '@mui/material'
import { MUIThemeColors } from '../../mui-theme'
import { getThemeColor } from 'src/utils'

interface CustomTabProps extends TabProps {
  isSelected: boolean
}

export const StyledTab = styled(Tab)<CustomTabProps>(({ isSelected, theme }) => ({
  margin: 4,
  width: 'auto',
  maxHeight: 'auto',
  color: isSelected ? `${getThemeColor(theme, MUIThemeColors.primaryMain)} !important` : undefined,
  backgroundColor: isSelected ? `${getThemeColor(theme, MUIThemeColors.secondaryLight)}` : undefined,
}))

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: '100%',
  boxShadow: 'none !important',
  backgroundColor: getThemeColor(theme, MUIThemeColors.white),
  overflowX: 'auto',
}))

export const DataContent = styled(Stack)(({ height, theme }) => ({
  minHeight: `${height ?? window.innerHeight * 0.5}`,
  color: getThemeColor(theme, MUIThemeColors.black),
  padding: 8,
}))

export const TabContent = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const TabIcon = styled(IconButton)(({ theme }) => ({
  color: getThemeColor(theme, MUIThemeColors.primaryLight),
  '& span': {
    fontSize: '16px !important',
  },
}))
