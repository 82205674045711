import React from 'react'
import { Box, Card, Stack, Typography, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IServiceStation } from '../../../generated-types/service-station'
import { GetRGBA, getThemeColor } from 'src/utils'
import { ReactComponent as IncommingIcon } from '../../../assets/svg/incomming_service.svg'
import { ReactComponent as InServiceIcon } from '../../../assets/svg/inservice_service.svg'
import { ReactComponent as InStockIcon } from '../../../assets/svg/instock_service.svg'
import { ReactComponent as OutGoingIcon } from '../../../assets/svg/outgoing_service.svg'
import CountWithIconBox from 'src/components/common/CountWithIconBox'
import { ServiceStationType } from 'src/enums'
import { MUIThemeColors } from 'src/mui-theme'
import { Flex } from 'src/styles/flexComponent'

interface ServiceStationCardProps {
  serviceStation: IServiceStation
  className?: string
  onClick: (e) => void
  active?: boolean
}

const Name = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'auto',
  display: 'inline-block',
  fontWeight: 600,
}))

const ServiceStationHeaderCard = styled(Card)(({ theme }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.white[theme.palette.mode],
    color: theme.palette.primaryDark[theme.palette.mode],
    boxShadow: `0px 0px 6px ${GetRGBA(theme.palette.black[theme.palette.mode], 0.29)}`,
  },
  backgroundSize: '300px auto',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  color: theme.palette.white[theme.palette.mode],
}))

const HoverCardContainer = styled(Box)`
  position:absolute;
  display:flex;
  flex-direction:row;
  justify-content:center;
  background-color: ${props => getThemeColor(props.theme, MUIThemeColors.primaryMain)};
  border-radius: 5px;
  cursor: pointer;
  transition: height 500ms;
  overflow: hidden;
  width: 100%;
`

const ServiceStationCard: React.FC<ServiceStationCardProps> = ({ serviceStation, className, onClick, active }) => {
  const { t } = useTranslation('main-component')
  const theme = useTheme()

  return (
    <Box onClick={onClick} className={className} width={'100%'} position={'relative'} onMouseLeave={(e) => {
      e.preventDefault()
    }}>
      <ServiceStationHeaderCard style={{ height: active ? '100px' : '44px', backgroundColor: getThemeColor(theme, MUIThemeColors.secondaryLight) }}
        sx={{ backgroundImage: `url(${serviceStation.photo?.url ?? ''})` }}>
        <Box flex={1} paddingTop={active ? '8px' : '3px'} >
          <Stack direction={'column'} className="pl-12-core pt-2-core pr-6-core pb-2-core" alignItems={'flex-start'} justifyContent={'center'} sx={{ backgroundColor: getThemeColor(theme, MUIThemeColors.primaryMain), width: 'fit-content', height: '37px', borderRadius: '2px' }} >
            <Name variant='body1' fontWeight={200} lineHeight={'18px'} color={getThemeColor(theme, MUIThemeColors.white)} >
              {serviceStation.name}
            </Name>
            {
              active &&
              <>
                <Typography fontWeight={400} lineHeight={'12px'} variant='caption' color={getThemeColor(theme, MUIThemeColors.white)} >
                  {serviceStation?.address && serviceStation?.address}
                </Typography >
              </>
            }
          </Stack >
        </Box>
        {active && <Flex.Column justifyContent={'flex-end'} className='pl-12-core pb-8-core'>
          <Flex.Row flex={1} gap={'2px'}>
            {(serviceStation?.type == ServiceStationType.ServiceStation || serviceStation?.type == ServiceStationType.Service) && (
              <Box>
                <CountWithIconBox
                  title={t('incoming')}
                  Component={IncommingIcon}
                  value={serviceStation.incomingCount ?? 0}
                  iconBgColor={theme.palette.white[theme.palette.mode]}
                  color={theme.palette.white[theme.palette.mode]}
                  bgColor={theme.palette.primaryMain[theme.palette.mode]}
                />
              </Box>)}

            {(serviceStation?.type == ServiceStationType.ServiceStation || serviceStation?.type == ServiceStationType.Service) && (
              <Box>
                <CountWithIconBox
                  title={t('in-service')}
                  Component={InServiceIcon}
                  value={serviceStation.inServiceCount ?? 0}
                  iconBgColor={theme.palette.white[theme.palette.mode]}
                  color={theme.palette.white[theme.palette.mode]}
                  bgColor={theme.palette.primaryMain[theme.palette.mode]}
                />
              </Box>)}

            {(serviceStation?.type == ServiceStationType.ServiceStation || serviceStation?.type == ServiceStationType.Storage) && (
              <Box>
                <CountWithIconBox
                  title={t('in-storage')}
                  Component={InStockIcon}
                  value={serviceStation.inStockCount ?? 0}
                  iconBgColor={theme.palette.white[theme.palette.mode]}
                  color={theme.palette.white[theme.palette.mode]}
                  bgColor={theme.palette.primaryMain[theme.palette.mode]}
                />
              </Box>)}

            <Box>
              <CountWithIconBox
                title={t('outgoing')}
                Component={OutGoingIcon}
                value={serviceStation.outgoingCount ?? 0}
                iconBgColor={theme.palette.white[theme.palette.mode]}
                color={theme.palette.white[theme.palette.mode]}
                bgColor={theme.palette.primaryMain[theme.palette.mode]}
              />
            </Box>

          </Flex.Row>
        </Flex.Column>}
      </ServiceStationHeaderCard>
    </Box>
  )
}

export default ServiceStationCard
