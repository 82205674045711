import { Box, Checkbox, InputBase, Stack, Typography, styled } from '@mui/material'
import { IMooringType, IPositionType } from '../../../generated-types'
import { useTranslation } from 'react-i18next'
import { MdSearch } from 'react-icons/md'

const CheckboxTypography = styled(Typography)`
  color: ${props => props.theme.palette.primaryDark[props.theme.palette.mode]};
  font-variant: ${props => props.theme.typography.h6};
  font-weight: 400;
  padding-right: 8px;
  margin-left: -5px;
  cursor: pointer;
`
interface PositionTableFilterProps {
  filteredTypes: IPositionType[]
  mooringType?: IMooringType
  setFilteredTypes: React.Dispatch<React.SetStateAction<IPositionType[]>>
  searchInput?: string
  setSearchInput: React.Dispatch<React.SetStateAction<string>>
}

export const PositionTableFilter: React.FC<PositionTableFilterProps> = ({
  setFilteredTypes,
  filteredTypes,
  mooringType,
  setSearchInput,
  searchInput,
}) => {
  const { t } = useTranslation(['position', 'common'])
  const positionTypesArray: IPositionType[] = [
    IPositionType.MooringLine,
    mooringType != IMooringType.Barge ? IPositionType.Corner : IPositionType.BargeCorner,
    IPositionType.Bridle,
    IPositionType.FrameLines,
    IPositionType.Cage,
  ]

  const handleCheckboxChange = type => {
    if (type == 'all' && filteredTypes.length != positionTypesArray.length) {
      setFilteredTypes(positionTypesArray)
    } else {
      if (type == 'all' && filteredTypes.length == positionTypesArray.length) {
        setFilteredTypes([])
        return
      }
      if (filteredTypes.includes(type)) {
        setFilteredTypes(filteredTypes.filter(t => t !== type))
      } else {
        setFilteredTypes([...filteredTypes, type])
      }
    }
  }

  const onSearchInputChange = input => {
    setSearchInput(input)
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width={'100% !important'}
      gap={2}
      paddingBottom={'24px'}
      minWidth={'1000px'}
    >
      <SearchBar searchInput={searchInput} placeholder={t('search', { ns: 'common' })} onChange={onSearchInputChange} />
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Checkbox
          checked={filteredTypes.length === positionTypesArray.length}
          onChange={() => handleCheckboxChange('all')}
        />
        <CheckboxTypography onClick={() => handleCheckboxChange('all')}>
          {t('all', { ns: 'common' })}
        </CheckboxTypography>
        {positionTypesArray?.map(type => (
          <Stack key={type} direction="row" justifyContent="flex-start" alignItems="center">
            <Checkbox checked={filteredTypes.includes(type)} onChange={() => handleCheckboxChange(type)} />
            <CheckboxTypography onClick={() => handleCheckboxChange(type)}>
              {t(IPositionType[type], { ns: 'position' })}
            </CheckboxTypography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

interface SearchBarProps {
  searchInput?: any
  placeholder?: any
  onChange?: any
  width?: any
}
export const SearchBar: React.FC<SearchBarProps> = ({ searchInput, placeholder, onChange, width }) => {
  return (
    <Box
      className="flex items-center gap-3 mr-2 w-30 md:w-40 lg:w-60"
      sx={{
        borderRadius: 2,
        borderColor: 'gray',
        borderWidth: '1px',
        minWidth: width,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '5px',
      }}
    >
      <InputBase
        sx={{ borderColor: 'white', padding: '4px 16px', width: '75%' }}
        autoFocus={searchInput != '' ? true : false}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        defaultValue={searchInput}
        onChange={event => onChange(event.target.value)}
      />
      <Box className="mr-3">
        <MdSearch color="black" size={20} />
      </Box>
    </Box>
  )
}
