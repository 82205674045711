import { FC } from 'react'
import { invalidatePositionCache, useGetBatchNumberDetailsByProductId } from 'src/api/positions'
import { ShowDialogProps } from 'src/show-dialog'
import { UpdateBatchNumberForm } from './UpdateBatchNumberForm'
import { invalidateComponentsCache, useBulkUpdateBatchNumber } from 'src/api/components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

interface UpdateBatchNumberDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  productId: EntityId
  positionId: EntityId
  componentId: EntityId
  productDescription: string
}

export const UpdateBatchNumberDialog: FC<UpdateBatchNumberDialogProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  productId,
  positionId,
  componentId,
  productDescription,
}) => {
  const { t: translate } = useTranslation(['common'])

  const { mutateAsync } = useBulkUpdateBatchNumber(customerId as EntityId)
  const { data: positions, isLoading } = useGetBatchNumberDetailsByProductId(customerId, localityId, mooringId, {
    productId: productId as number,
  })

  const onSubmit = async (form: any) => {
    await mutateAsync(form)
      .then(({ data }: { data: string }) => {
        invalidatePositionCache.getBatchNumberDetailsByProductId(customerId, localityId, mooringId)
        invalidatePositionCache.getPosition(customerId, localityId, mooringId, positionId)
        invalidateComponentsCache.getComponents(customerId, positionId)
        form.positionIds?.map(positionId => {
          invalidateComponentsCache.getComponents(customerId, positionId)
        })
        toast.success(translate(data, { ns: 'common-api-message' }))
        pop()
      })
      .catch(({ data }: { data: string }) => {
        toast.error(translate(data, { ns: 'common-api-message' }))
      })
  }

  return (
    <UpdateBatchNumberForm
      pop={pop}
      positions={positions ?? []}
      onSubmit={onSubmit}
      componentId={componentId as number}
      isLoading={isLoading}
      productDescription={productDescription}
    />
  )
}
