import { AxiosError, AxiosResponse } from 'axios'
import { useQuery, useMutation, useQueryClient, UseQueryOptions } from 'react-query'
import { IBaseEntity } from '../generated-types'
import { requestProvider } from './requestProvider'

export type QueryKeyT = [string, object | undefined] | ParamType[] | string

export type ParamType = string | number | EntityId | any[]

interface ParamsObject {
  [key: string]: ParamType | undefined
}

interface QueryConfig<T> extends UseQueryOptions<T, Error, T, QueryKeyT>{
  enabled?: boolean
}

export const generateQueryKey = (baseKey: string, params?: ParamsObject) => {
  const keyParams: ParamType[] = []
  if (params) {
    for (const param of Object.values(params)) {
      if (param !== undefined) {
        keyParams.push(param.toString())
      }
    }
  }
  return keyParams.length > 0 ? [baseKey, ...keyParams] : baseKey
}

export const fetcher = <T>(url: string, params?: object, pageParam?: object): Promise<T> => {
  return requestProvider.get<T>(url, { params: { ...params, ...pageParam } }).then(res => res.data)
}

export const usePrefetch = <T>(url: string | null, params?: object) => {
  const queryClient = useQueryClient()

  return () => {
    if (!url) {
      return
    }

    queryClient.prefetchQuery<T, Error, T, QueryKeyT>([url!, params], ({ pageParam }) =>
      fetcher(url!, params!, pageParam),
    )
  }
}

export const useGet = <T>(url: string, params?: object, config?: QueryConfig<T>) => {
  const context = useQuery<T, Error, T, QueryKeyT>(
    config?.queryKey ?? [url!, params],
    ({ pageParam }) => fetcher(url, params, pageParam),
    {
      enabled: !!url,
      ...config,
    },
  )

  return context
}

const useGenericMutation = <T, S>(
  func: (data: S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined,
) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, S>(func, {
    onMutate: async data => {
      await queryClient.cancelQueries([url!])

      const previousData = queryClient.getQueryData([url!])

      if (updater) {
        queryClient.setQueryData<T>([url], oldData => {
          return updater(oldData!, data)
        })
      } else {
        removeLastUrlIdSegment()

        queryClient.setQueryData<S>([url!], () => data)
      }

      return previousData

      function removeLastUrlIdSegment() {
        if (data && typeof data === 'object') {
          const dataBaseEntity = data as unknown as IBaseEntity
          const urlSplit = url.split('/')
          const lastSegment = urlSplit.pop() || urlSplit.pop() // handle potential trailing slash
          if (lastSegment && dataBaseEntity.id && lastSegment == dataBaseEntity.id.toString()) {
            url = url.slice(0, url.lastIndexOf('/'))
          }
        }
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!], context)
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!])
    },
  })
}

export const useDelete = <T>(url: string, params?: object, updater?: (oldData: T, id: string | number) => T) => {
  return useGenericMutation<T, string | number>(id => requestProvider.delete(`${url}/${id}`), url, params, updater)
}

export const usePost = <T, S>(
  url: string,
  params?: object,
  responseOptions?: any,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>(data => requestProvider.post<S>(url, data, responseOptions), url, params, updater)
}

export const usePut = <T, S>(url: string, params?: object, updater?: (oldData: T, newData: S) => T) => {
  return useGenericMutation<T, S>(data => requestProvider.put<S>(url, data), url, params, updater)
}

export const usePatch = <T, S>(url: string, params?: object, updater?: (oldData: T, newData: S) => T) => {
  return useGenericMutation<T, S>(data => requestProvider.patch<S>(url, data), url, params, updater)
}
