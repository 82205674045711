import { styled, Typography, useTheme } from '@mui/material'
import { MUIThemeColors } from 'src/mui-theme'
import { Flex } from 'src/styles/flexComponent'
import { getThemeColor } from 'src/utils'
import Circle from 'src/assets/circle.svg'
import { Padding } from 'src/styles/paddingComponent'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const theme = useTheme()
    const { t } = useTranslation('footer')
    return <Flex.Row padding={'16px'} sx={{ backgroundColor: getThemeColor(theme, MUIThemeColors.white) }}>
        <img src={Circle} style={{ height: '21px', width: '21px' }} />
        <Padding.pl8 paddingTop={'2px'}>
            <Typography variant='body1' color={getThemeColor(theme, MUIThemeColors.primaryMain)} >
                © Havbruksloggen 2024,  {t('build')}: {process.env.REACT_APP_VERSION} <PaddingSpan>|</PaddingSpan>{t('term-&-Condition')}<PaddingSpan>|</PaddingSpan>{t('privacy-policy')}
            </Typography>
        </Padding.pl8>
    </Flex.Row>
}

export default Footer

//styled components
const PaddingSpan = styled('span')`
    padding-left: 15px;
    padding-right: 15px;
`