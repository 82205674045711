import { toast } from 'react-toastify'
import { invalidateProductCategoryCache, useAddProductCategory } from '../../../api/product-category'
import { ShowDialogProps } from '../../../show-dialog'
import { ProductCategoryForm } from './ProductCategoryForm'
import { useTranslation } from 'react-i18next'
import { IProductCategory } from '../../../generated-types/product-category'

export const ProductCategoryAddDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: translate } = useTranslation(['common-api-message'])
  const { mutateAsync } = useAddProductCategory()

  const onSubmit = async (form: IProductCategory) => {
    await mutateAsync(form)
      .then(({ data }: { data: string }) => {
        invalidateProductCategoryCache.getProductCategories()
        toast.success(translate(data, { ns: 'common-api-message' }))
        pop()
      })
      .catch(({ data }: { data: string }) => {
        toast.error(translate(data, { ns: 'common-api-message' }))
      })
  }
  return <ProductCategoryForm onSubmit={onSubmit} pop={pop} />
}
