import { ITimeReportType } from '../generated-types/time-report-type'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = () => 'admin/timereporttypes'

export const timeReportTypesEndpoints = {
  getTimeReportTypes() {
    return `${baseUrl()}`
  },
  getTimeReportType(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  createTimeReportType() {
    return `${baseUrl()}`
  },
  updateTimeReportType(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  deleteTimeReportType() {
    return `${baseUrl()}`
  },
}

export const invalidateTimeReportTypeCache = {
  useGetTimeReportTypes() {
    queryClient.invalidateQueries(timeReportTypesEndpoints.getTimeReportTypes())
  },
  getTimeReportType(id: EntityId) {
    queryClient.invalidateQueries(timeReportTypesEndpoints.getTimeReportType(id))
  },
}

export const useGetTimeReportTypes = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<ITimeReportType>>(timeReportTypesEndpoints.getTimeReportTypes(), { page, pageSize })

export const useGetTimeReportType = (id: EntityId) => useGet<ITimeReportType>(timeReportTypesEndpoints.getTimeReportType(id))

export const useAddTimeReportType = () => usePost<ITimeReportType, ITimeReportType>(timeReportTypesEndpoints.createTimeReportType())

export const useUpdateTimeReportType = (id: EntityId) =>
  usePut<ITimeReportType, ITimeReportType>(timeReportTypesEndpoints.updateTimeReportType(id))

export const useDeleteTimeReportType = () => useDelete<ITimeReportType>(timeReportTypesEndpoints.deleteTimeReportType())
