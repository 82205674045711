export const Queries = {
  Users: 'users',
  LocalitiesAccessLevels: 'localities-access-levels',
  LocalitiesAccessLevelsList: 'localities-access-levels-list',
  CustomerLocalityGroups: (id: EntityId) => ['customer-locality-groups', id],
  CustomerLocalities: (id: EntityId) => ['customer-localities', id],
  CustomerAccessGroups: (id: EntityId) => ['customer-access-groups', id],
  UserCustomers: 'user-customers',
  Customer: (customerId: EntityId) => ['customer', customerId],
  File: (fileId: EntityId) => ['file', fileId],
  LocalityGroupDetailsAll: (customerId: EntityId) => ['locality-group-details', customerId],
  LocalityGroupDetails: (customerId: EntityId, groupId: EntityId) => ['locality-group-details', customerId, groupId],
  LocalityGroup: (id: EntityId) => ['locality-group', parseInt(id as string)],
  Locality: (localityId: EntityId) => ['locality', parseInt(localityId as string)],
  AccessLevelGroup: (groupId: EntityId) => ['access-group', parseInt(groupId as string)],
  UserByEmail: (email: string) => ['user', email],
  UserInvite: (code: string) => ['user-invite', code],
  UserInvites: (customerId: EntityId) => ['user-invites', parseInt(customerId as string)],
  Services: (customerId: EntityId) => ['services', parseInt(customerId as string)],
  MaterialFactors: 'material-factors',
  Products: 'products',
  Units: 'units',
}
