import { GridColumns, GridNativeColTypes, GridActionsCellItem } from '@mui/x-data-grid-pro'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdEdit, MdDelete } from 'react-icons/md'
import {
  invalidateTimeReportTypeCache,
  timeReportTypesEndpoints,
  useDeleteTimeReportType,
  useUpdateTimeReportType,
} from '../../../api/time-report-type'
import { useDialogCtx } from '../../../hooks/context-hooks'
import TimeReportTypeFrom from './TimeReportTypeForm'
import GenericTable from '../../../components/table/GenericTable'
import { toast } from 'react-toastify'
import { CANNOT_ADD, SUCCESSFULLY_UPDATED } from '../../../constant'

export const TimeReportTypeTable: React.FC = () => {
  const { t: translate } = useTranslation(['time-report-types', 'common'])
  const [selectedId, setSelectedId] = useState(0)
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { mutateAsync: updateMutate } = useUpdateTimeReportType(selectedId)
  const { mutateAsync: deleteMutate } = useDeleteTimeReportType()

  const onUpdate = useCallback(
    row => {
      setSelectedId(row.id)
      showDialog(TimeReportTypeFrom, {
        title: translate('updateTimeReportType'),
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        componentProps: {
          onSubmit: async values => {
            await updateMutate(values,{
              onSuccess: (data) => {
                if (data.data === SUCCESSFULLY_UPDATED) {
                  invalidateTimeReportTypeCache.useGetTimeReportTypes()
                  toast.success(translate('timeReportTypeUpdatedSuccessfully', { ns: 'time-report-types' }))
                }
              },
              onError: (error) => {
                if (error['data'] === CANNOT_ADD) {
                  toast.error(translate('cannot-add-same-time-report-type-name'))
                }
              }
            })
          },
          initialValues: { ...row },
          isUpdateForm: true,
        },
      })
    },
    [showDialog, translate, updateMutate],
  )

  const onDelete = useCallback(
    async row => {
      showConfirmDialog(
        translate('deleteTimeReportTypeTitle'),
        translate('deleteTimeReportTypeMessage'),
        {
          acceptText: translate('yes', { ns: 'common' }),
          cancelText: translate('no', { ns: 'common' }),
        },
        async pop => {
          await deleteMutate(row.id, {
            onSuccess: () => {
              toast.success(translate('timeReportTypeDeletedSuccessfully', { ns: 'time-report-types' }))
              invalidateTimeReportTypeCache.useGetTimeReportTypes()
              pop()
            },
            onError: () => {
              toast.error(translate('deletingError', { ns: 'common' }))
            },
          })
        },
      )
    },
    [deleteMutate, showConfirmDialog, translate],
  )

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'typeName',
        headerName: translate('name', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
      },
    ],
    [translate],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        headerName: translate('actions', { ns: 'common' }),
        type: 'actions' as GridNativeColTypes,
        width: 150,
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={'edit'}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(row)}
            disabled={row.isSystemGenerated}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={'delete'}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(row)}
            disabled={row.isSystemGenerated}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [STATIC_COLUMNS, onDelete, onUpdate, translate],
  )

  return (
    <GenericTable
      queryUrl={timeReportTypesEndpoints.getTimeReportTypes()}
      columns={columns}
      enableServerPagination
      enablePagination
      id={'time-report-types'}
    />
  )
}
