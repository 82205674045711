import { useEffect, useState } from 'react'

export function useLocalStorage(key: string, defaultValue?: any, callback?: (val: any) => void) {
  const val = localStorage.getItem(key) || defaultValue
  const [storageItem, setStorageItem] = useState(callback ? callback(val) : val)

  useEffect(() => {
    localStorage.setItem(key, storageItem)
  }, [key, storageItem])

  return [storageItem, setStorageItem]
}
