import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import { flex } from './flexCss'

export const Flex = {
    Row: styled(Stack)`
        ${flex.row}
    `,
    Column: styled(Stack)`
        ${flex.column}
    `,
}