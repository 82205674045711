import { Box } from '@mui/system'
import { FC } from 'react'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useTranslation } from 'react-i18next'
import { FacilityTable } from './FacilityTable'
import { FacilityTypes, RoleTypes } from '../../../enums'

interface IFacilitySectionProps {
  title: string
  handleAdd: () => void
  clientId: EntityId
  userId: string
  facilityType: FacilityTypes
  roleTypes: Array<RoleTypes>
  functionElementParents: number[]
}
export const FacilitySection: FC<IFacilitySectionProps> = ({ title, handleAdd, clientId, userId, facilityType, roleTypes, functionElementParents }) => {
  const { t } = useTranslation(['user-resources', 'common'])
  return (
    <>
      <Box className="px-4 py-2 mt-2 mb-2 bg-white shadow-md" borderRadius={2}>
        <ScreenTitleBar title={title} addNewItemText={t('add', { ns: 'common' })} onAddNewItemClick={handleAdd} />
        <FacilityTable functionElementParents={functionElementParents} clientId={clientId} userId={userId} facilityType={facilityType} roleTypes={roleTypes} />
      </Box>
    </>
  )
}
