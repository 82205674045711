export const nbNO = {
  corner: 'Hjørne',
  mooringLine: 'Fortøyningsline',
  net: 'Not',
  ring: 'Ring',
  cage: 'Bur',
  frameLine: 'Rammeline',
  innerMooringLine: 'Indre fortøyningsline',
  bridle: 'Hanefot',
  fleet: 'Flåte',
  frameConstruction: 'Ramme',
  steelFrameConstruction: 'Stålramme',
  ladderFrameConstruction: 'Stige',
}

export const fleetOptions = {
  deviationColor: 'red',
  cornerColor: 'yellow',
  mooringColor: 'gray',
  anchorColor: 'black',
  frameLineColor: 'green',
  innerMooringColor: 'orange',
  fleetColor: 'black',
  lang: nbNO,
}

export const mapOptions = {
  deviationColor: 'red',
  cornerColor: 'yellow',
  mooringColor: 'gold',
  innerMooringColor: 'grey',
  anchorColor: 'gray',
  frameLineColor: 'green',
  bridleColor: '#989769',
  netLetterColorInactive: 'black',
  netLetterColor: 'white',
  netColor: 'gray',
  netColorInactive: 'lightgray',
  ringColor: 'black',
  ringColorInactive: 'gray',
  fleetColor: 'blue',
  frameCageColor: 'cyan',
  updatedColor: '#a41a1a',
  lang: nbNO,
}

export const steelFrameOptions = {
  deviationColor: 'red',
  frameColor: 'black',
  cornerColor: 'yellow',
  mooringColor: 'orange',
  anchorColor: 'black',
  frameLineColor: 'green',
  cageColor: 'gray',
  cageColorInactive: 'lightgray',
  netLetterColorInactive: 'black',
  netLetterColor: 'white',
  lang: nbNO,
}

export const opacity = 1.0
export const grayedOpacity = 0.3
export const defaultRadiusSize = 4
export const totalDeviationMultiplier = 2
export const localityDeviationMultiplier = 500
export const locationsDeviationMultiplier = 2
export const deviationPositions: number[][] = []
export const localityDeviationPositions: number[][] = []
export const maxZoom = 20
export const minZoom = 13

export const letterArray = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Æ',
  'Ø',
  'Å',
]

export const barentsWatchZones = {
  ila_combat:
    'https://geo.barentswatch.no/geoserver/bw/ows?service=WFS&version=1.3.0&request=GetFeature&typeName=bw:ilaprotectionzone&srsName=EPSG:4326&maxFeatures=100&outputFormat=application/json',
  ila_monitoring:
    'https://geo.barentswatch.no/geoserver/bw/ows?service=WFS&version=1.3.0&request=GetFeature&typeName=bw:ilasurveillancezone&srsName=EPSG:4326&maxFeatures=100&outputFormat=application/json',
  pd_monitoring:
    'https://geo.barentswatch.no/geoserver/bw/ows?service=WFS&version=1.3.0&request=GetFeature&typeName=bw:pdsurveillancezone&srsName=EPSG:4326&maxFeatures=100&outputFormat=application/json',
  pd_protection:
    'https://geo.barentswatch.no/geoserver/bw/ows?service=WFS&version=1.3.0&request=GetFeature&typeName=bw:pdprotectionzone&srsName=EPSG:4326&maxFeatures=100&outputFormat=application/json',
}

export const agricultureZones = {
  agricultureRegistryMapServer: '//gis.fiskeridir.no/server/services/fiskeridirWMS/MapServer/WMSServer',
  agricultureRegistryGeoServer: '//geoserver.mast.is/geoserver/wms',
  extremePoint: '//gis.fiskeridir.no/server/services/fiskeridirWMS/MapServer/WMSServer',
}

export const mapLayers = {
  map_norway: '//opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norgeskart_bakgrunn&zoom={z}&x={x}&y={y}',
  map_norway_sea: '//opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=sjokartraster&zoom={z}&x={x}&y={y}',
  map_norway_depth: '//opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=havbunn_grunnkart&zoom={z}&x={x}&y={y}',
  map_norway_image: 'https://wms.geonorge.no/skwms1/wms.nib?',
  map_google_satellite: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
}

export const getKeyValue = (key: string, value: boolean) => {
  switch (key) {
    case 'facility':
      return { facility: value }
      break
    case 'fleet':
      return { fleet: value }
      break
    case 'deviation':
      return { deviation: value }
      break
    case 'barentsWatch':
      return { barentsWatch: value }
      break
    case 'aquaculture':
      return { aquaculture: value }
      break
    default:
      return {}
  }
}
