import { ILocality, ILocalityNameAndLogo } from '../../generated-types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Box, Collapse } from '@mui/material'
import classNames from 'classnames'
import { LocationHeader } from './LocationHeader'
import { AppRoutesNavigation } from '../../app-routes'
import MenuItemTemplate from './MenuItemTemplate'
import { IMenuItem } from 'src/screens/localities/LocalitiesSidebar'
import { Flex } from 'src/styles/flexComponent'
import { Padding } from 'src/styles/paddingComponent'
import { useGetLocalityWithActivityCounts } from 'src/api/localities'

export const ExpandedSideMenu: React.FC<{ location: ILocalityNameAndLogo; menuItems: (location?: ILocality) => IMenuItem[], customerId: EntityId }> = ({
  location,
  customerId,
  menuItems
}) => {
  const navigate = useNavigate()
  const { localityId } = useParams()
  const [open, setOpen] = useState(location.id == (localityId as EntityId))
  const { pathname } = useLocation()
  const { data: localityInfo, isLoading } = useGetLocalityWithActivityCounts(customerId as EntityId, localityId as EntityId)

  useEffect(() => {
    setOpen(location.id == (localityId as EntityId))
  }, [localityId, location.id])

  const active = !!pathname && pathname.split('/')[6]

  const menuItemList = menuItems(localityInfo).map((menuItem, index) => (
    <MenuItemTemplate key={index}
      {...menuItem}
      isMinified={false}
      active={active === menuItem.path}
      isLoading={isLoading}
    />
  ))

  return (
    <Box
      sx={{ padding: '6px 12px' }}
      id={`locality-${location.id}`}
      className={classNames('bg-white', {
        'cursor-pointer  ': !open
      })}
    >
      <LocationHeader active={open} locality={location}
        localityInfo={localityInfo} isLoading={isLoading}
        onClick={e => {
          e.stopPropagation()
          navigate(AppRoutesNavigation.Localities.locality(customerId, location.id))
        }} />
      <Collapse in={open}>
        <Padding.pt10>
          <Flex.Column spacing={'4px'}>
            {menuItemList}
          </Flex.Column>
        </Padding.pt10>
      </Collapse>
    </Box>
  )
}