import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import {
  invalidateProductCategoryCache,
  productCategoryEndpoints,
  useDeleteProductCategory,
} from '../../../api/product-category'
import GenericTable from '../../../components/table/GenericTable'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { toast } from 'react-toastify'
import { ProductCategoryUpdateDialog } from './ProductCategoryUpdateDialog'

export const ProductCategoryTable = () => {
  const { t: translate } = useTranslation(['product-category', 'common'])
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { mutateAsync: deleteProductCategory } = useDeleteProductCategory()

  const onDelete = useCallback(
    (id: EntityId) => {
      showConfirmDialog(
        translate('delete-product-category-title', { ns: 'product-category' }),
        translate('delete-product-category-message', { ns: 'product-category' }),
        {
          acceptText: translate('yes', { ns: 'common' }),
          cancelText: translate('no', { ns: 'common' }),
        },
        async pop => {
          await deleteProductCategory(id)
            .then(() => {
              toast.success(translate('product-category-deleted', { ns: 'product-category' }))
              invalidateProductCategoryCache.getProductCategories()
              pop()
            })
            .catch(() => {
              toast.error(translate('error-deleting-product-category', { ns: 'product-category' }))
            })
        },
      )
    },
    [deleteProductCategory, showConfirmDialog, translate],
  )

  const onUpdate = useCallback(
    async id => {
      const updated = await showDialog(ProductCategoryUpdateDialog, {
        title: translate('update-product-category', { ns: 'product-category' }),
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          productCategoryId: id as EntityId,
        },
      })
      if (!updated) return
    },
    [showDialog, translate],
  )
  const STATIC_COLUMNS: GridColumns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: translate('name', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
      },
      {
        field: 'description',
        headerName: translate('description', { ns: 'common' }),
        flex: 1,
      },
    ]
  }, [translate])

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(row.id)}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(row.id)}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [STATIC_COLUMNS, onDelete, onUpdate, translate],
  )
  return (
    <GenericTable
      id="product-category-table"
      enablePagination
      enableServerPagination
      pageSize={10}
      pageOptions={[10, 25, 50]}
      queryUrl={productCategoryEndpoints.getProductCategories()}
      queryParams={undefined}
      columns={columns}
      enableToolbar={true}
      getRowHeight={() => 'auto'}
    />
  )
}
