import { Box, Button, FormHelperText, Typography, styled, useTheme } from '@mui/material'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import { z } from 'zod'
import { Flex } from 'src/styles/flexComponent'
import { Padding } from 'src/styles/paddingComponent'
import { IDocumentCategory, IFile } from 'src/generated-types'
import { FileType } from 'src/enums'
import { contentType, getThemeColor, isFileImage, isFileUrlExtensionImage } from 'src/utils'
import { useDeleteFile, useUploadFile } from 'src/api/file'
import { useGetDocumentByFileId, useAddDocument, useUpdateDocumentByFileId, invalidateDocument } from 'src/api/document'
import { useUserGetDocumentCategories } from 'src/api/document-category'
import { invalidateLocalityFrequencyCache } from 'src/api/locality-frequency'
import BasicDatePicker from 'src/components/DateTimePicker'
import { AppForm, FormSelectField, FormTextField, useFormFieldError } from 'src/components/FormCore'
import { GenericDialog } from 'src/components/GenericDialog'
import DocumentViewer from '../content/DocumentViewer'
import FileSVG from 'src/assets/svg/file.svg'
import { useZodForm } from 'src/hooks/zod-form'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { MUIThemeColors } from 'src/mui-theme'
import { ReactComponent as FileUploadIcon } from 'src/assets/svg/fileUploadIcon.svg'


export interface PropsBase {
  name: string
  initialFiles?: IFile[] | IFile | string
  dropMsg?: string
  inactiveMsg?: string
  enablePreview?: boolean
  type?: FileType
  disabled?: boolean
  acceptedFileTypes?: string[]
  maxFileSize?: number
  previewDialog?: React.FC<any>
  onPreview?: (file: IFile) => any
  required?: boolean
  getUploadedFiles?: (files: IFile | IFile[]) => any
  FileIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  height?: string
  userMessage?: string
}

export interface PropsDocument extends PropsBase {
  document?: boolean
  customerId?: EntityId
  localityId?: EntityId
  localityFrequencyIds?: Array<number | undefined>
  isDeletable?: boolean
}

export interface PropsMultiple {
  multiple?: true
  onChange: (files: IFile[]) => any
}

export interface PropsSingle {
  multiple?: false
  onChange: (file: IFile) => any
}

export function getInitialFiles(files?: IFile[] | IFile | string): IFile[] {
  if (!files) return []

  if (Array.isArray(files)) {
    return files.map(f => {
      if (typeof f === 'string')
        return { url: f, filename: 'unknown', viewToken: '', id: 0, contentType: '' } as unknown as IFile

      return f
    })
  } else {
    // @ts-ignore
    if (typeof files === 'string') return [{ url: files, filename: 'unknown', viewToken: '', id: 0, contentType: '' }]

    return [files]
  }
}

interface IFileMetaDataDialogProps {
  customerId: EntityId
  localityId: EntityId
  localityFrequencyIds?: Array<number | undefined>
  file: IFile
  onClose: () => any
}
const UpdateFileMetaDataDialog: React.FC<IFileMetaDataDialogProps> = ({
  customerId,
  localityId,
  localityFrequencyIds,
  file,
  onClose,
}) => {
  const { t } = useTranslation(['file', 'common'])
  const [open, setOpen] = React.useState(true)

  const { data } = useGetDocumentByFileId(customerId as EntityId, file.id as EntityId)

  const schema = z.object({
    id: z
      .number()
      .nullable()
      .default(data ? data.id : null)
      .optional(),
    title: z.string().min(1),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    dueDate: z.date().or(z.string()).optional(),
    notes: z.string().optional(),
    version: z.string().optional(),
    fileId: z.number().default(file.id),
  })

  const form = useZodForm(schema, {
    defaultValues: { ...data },
  })

  useEffect(() => {
    if (data) {
      form.reset({ ...data })
    }
    form.setValue('documentCategoryId', data?.documentCategory as any)
  }, [data, form])

  const documentTypes = useUserGetDocumentCategories()

  const { mutateAsync: addMutation } = useAddDocument(customerId as EntityId)
  const { mutateAsync: updateMutation } = useUpdateDocumentByFileId(customerId as EntityId, file.id as EntityId)

  const handleSubmit = useCallback(
    async form => {
      form.localityFrequencyIds = localityFrequencyIds
      data ? await updateMutation(form) : await addMutation(form)
      invalidateDocument.getDocumentByFileId(customerId, file.id)
      invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId as EntityId, localityId as EntityId)
      invalidateLocalityFrequencyCache.useGetLocalityFrequency(customerId as EntityId, localityId as EntityId)
      onClose()
      setOpen(false)
    },
    [data, customerId, file.id, onClose],
  )

  const onCancel = () => {
    setOpen(false)
    onClose()
  }

  return (
    <AppForm form={form}>
      <GenericDialog
        minWidth={400}
        open={open}
        onClose={onClose}
        title={data ? t('updateDocument', { ns: 'document' }) : t('addDocument', { ns: 'document' })}
        actions={[
          <Button
            key={'submit-btn'}
            variant="contained"
            color="primary"
            onClick={async () => {
              form.handleSubmit(handleSubmit)()
            }}
          >
            {data ? t('update', { ns: 'common' }) : t('add', { ns: 'common' })}
          </Button>,
        ]}
      >
        <FormTextField name="title" label={t('title', { ns: 'document' })} fullWidth />
        <FormSelectField
          name="documentCategoryId"
          data={(documentTypes?.data?.items as IDocumentCategory[]) ?? []}
          label={t('document-category', { ns: 'document' })}
          getOptionLabel={option => t(option.categoryName)}
          renderOption={(props: any, option) => (
            <Box {...props} key={option}>
              {t(option.categoryName)}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o === v}
        />
        <BasicDatePicker label={t('dueDate', { ns: 'document' })} name="dueDate" />
        <FormTextField name="notes" label={t('notes', { ns: 'document' })} fullWidth multiline rows={4} />
      </GenericDialog>
    </AppForm>
  )
}

interface IBaseFileUrlLoaderProps {
  fileUrl: string
  openOnClick?: boolean
  className?: string
  contentTypeValue?: string
}

export const FileUrlLoader: React.FC<IBaseFileUrlLoaderProps> = memo(
  ({ fileUrl, openOnClick, className, contentTypeValue }) => {
    return isFileUrlExtensionImage(fileUrl) ? (
      <FileUrlImageLoader fileUrl={fileUrl} openOnClick={openOnClick} className={className} />
    ) : (
      <FileUrlDocumentLoader
        fileUrl={fileUrl}
        openOnClick={openOnClick}
        className={className}
        contentTypeValue={contentTypeValue}
      />
    )
  },
)

export const FileUrlDocumentLoader: React.FC<IBaseFileUrlLoaderProps> = ({
  fileUrl,
  openOnClick,
  className = 'text-[32px] h-32px w-32px',
  contentTypeValue = null,
}) => {
  const { openFilePreviewDialog } = useDialogCtx()

  const Icon = contentTypeValue && contentType[contentTypeValue]?.icon ? contentType[contentTypeValue]?.icon : FileSVG
  return (
    <Box
      className={openOnClick ? ' cursor-pointer' : ''}
      onClick={e => {
        if (openOnClick) {
          e.stopPropagation()
          openFilePreviewDialog(fileUrl)
        }
      }}
    >
      <img src={Icon} className={className} />
    </Box>
  )
}

export const FileUrlImageLoader: React.FC<IBaseFileUrlLoaderProps> = ({
  fileUrl,
  openOnClick,
  className = 'max-w-[32px] h-[32px] object-cover',
}) => {
  const { openFilePreviewDialog } = useDialogCtx()
  return (
    <img
      className={className + (openOnClick ? ' cursor-pointer' : '')}
      src={fileUrl}
      onClick={() => openOnClick && openFilePreviewDialog(fileUrl)}
    />
  )
}

export type CommonFileUploaderProps = PropsBase & (PropsMultiple | PropsSingle) & PropsDocument

export const CommonFileUploader: React.FC<CommonFileUploaderProps> = ({
  name,
  dropMsg,
  inactiveMsg = '',
  maxFileSize,
  enablePreview = true,
  acceptedFileTypes,
  initialFiles,
  multiple = false,
  onPreview,
  disabled = false,
  onChange,
  type = FileType.File,
  document = false,
  customerId,
  localityId,
  required = false,
  localityFrequencyIds,
  getUploadedFiles,
  FileIcon = FileUploadIcon,
  height = '315px',
  isDeletable = true,
  userMessage
}) => {
  const { t } = useTranslation('file-upload')
  const { hasError } = useFormFieldError(name)
  const [files, setFiles] = useState<IFile[]>(getInitialFiles(initialFiles))
  const [uploadedFile, setUploadedFile] = useState<IFile | null>(null)
  const [showDocumentDialog, setShowDocumentDialog] = useState(false)
  const id = useMemo(() => uuid(), [])
  const mutation = useUploadFile(type === FileType.Image)
  const theme = useTheme()
  const { showConfirmDialog } = useDialogCtx()

  useEffect(() => {
    setFiles(getInitialFiles(initialFiles))
  }, [initialFiles])

  const onDrop: DropzoneOptions['onDrop'] = async uploadedFiles => {
    for (const uploadedFile of uploadedFiles) {
      if (acceptedFileTypes)
        if (!acceptedFileTypes.includes(uploadedFile.type)) {
          toast(`${t('invalid-type')} ${uploadedFile.name} ${t('allowed-types')} ${acceptedFileTypes.join(', ')}`, {
            type: 'error',
          })
          continue
        }

      if (maxFileSize && uploadedFile.size > maxFileSize) {
        toast(
          `${t('file-size-big')} ${uploadedFile.name} ${t('upload-size')} ${uploadedFile.size} ${t(
            'max-allowed',
          )} ${maxFileSize}`,
          { type: 'error' },
        )
        continue
      }

      const formData = new FormData()
      formData.append('file', uploadedFile)

      mutation.mutateAsync(formData).then(
        result => {
          const file = result.data as IFile
          const newFiles = multiple ? [...files, file] : [file]
          setFiles(newFiles)
          getUploadedFiles && getUploadedFiles(file)
          // @ts-ignore
          onChange(multiple ? newFiles : newFiles[0])
          if (document) {
            setUploadedFile(file)
            setShowDocumentDialog(true)
          }
        },
        error => {
          console.error('Failed to upload', error?.message)
        },
      )
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 104857600,
  })

  const displayMsg = () => {
    return isDragActive ? (
      <span>{dropMsg || t('drop')}</span>
    ) : (
      <span className="text-gray-500">{inactiveMsg || t('inactive')}</span>
    )
  }

  const onFileClick = (e: React.MouseEvent, file: IFile) => {
    e.stopPropagation()
    if (!isFileImage(file)) return
    if (onPreview) return onPreview(file)
  }

  const deleteMutation = useDeleteFile()

  const handleDeleteDocument = useCallback(
    async (e: React.MouseEvent, file: IFile) => {
      showConfirmDialog(
        t('deleteDocument', { ns: 'document' }),
        t('documentDeleteConfirmation', { ns: 'document' }),
        {
          acceptText: t('yes', { ns: 'common' }),
          cancelText: t('no', { ns: 'common' }),
        },
        async pop => {
          await onDelete(e, file)
            .then(() => {
              toast.success(t('document-deleted', { ns: 'document' }))
              pop()
            })
            .catch(() => {
              toast.error(t('error-document-deleting', { ns: 'document' }))
            })
        },
      )
    },
    [t],
  )

  const onDelete = async (e: React.MouseEvent, file: IFile) => {
    e.stopPropagation()
    try {
      if (file.id) {
        await deleteMutation.mutateAsync(file.id)
        invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId as EntityId, localityId as EntityId)
        invalidateLocalityFrequencyCache.useGetLocalityFrequency(customerId as EntityId, localityId as EntityId)
        const newFiles = files.filter(f => f.id !== file.id)
        setFiles(newFiles)

        // @ts-ignore
        onChange(multiple ? newFiles : newFiles[0])
      } else {
        const newFiles = files.filter(f => f.url !== file.url)
        setFiles(newFiles)

        // @ts-ignore
        onChange(multiple ? newFiles : newFiles[0])
      }
    } catch (e) {
      console.error('Failed to delete')
    }
  }

  const onEdit = async (e: React.MouseEvent, file: IFile) => {
    try {
      setUploadedFile(file)
      setShowDocumentDialog(true)
    } catch (e) {
      Logger.info('Failed to update.')
    }
  }

  const fileTable = (files: IFile[]) => {
    return (
      <>
        {files.map(file => {
          return (
            <DocumentViewer
              onFileClick={onFileClick}
              key={file.id}
              file={file}
              enablePreview
              onDelete={handleDeleteDocument}
              onEdit={onEdit}
              disabled={disabled}
              document={document}
              isDeletable={isDeletable}
            />
          )
        })}
      </>
    )
  }

  return (
    <Box
      my={1}
      mx={1}
      px={2}
      py={2}
      border={`1px solid ${getThemeColor(theme, MUIThemeColors.secondaryLight)}`}
      borderRadius={'4px'}
    >
      <DragAndDropBox height={height} {...(disabled ? () => {} : getRootProps())}>
        <input name={name} id={id} {...getInputProps()} />
        <Flex.Row justifyContent={'center'}>
          <Flex.Column justifyContent={'center'} alignItems={'center'} spacing={0.3}>
            {FileIcon && <FileIcon />}
            <Typography
              variant="subtitle1"
              fontWeight={500}
              color={getThemeColor(theme, MUIThemeColors.primaryDark)}
              textAlign="center"
            >
              {t('dragndrop-title')}
            </Typography>
            <Flex.Row>
              <Typography variant="subtitle2" fontWeight={400} textAlign="center">
                {userMessage ??
                  t('uploader-content', {
                    types:
                      acceptedFileTypes && acceptedFileTypes?.length != 0
                        ? acceptedFileTypes?.concat(',')
                        : t('document', { ns: 'documents' }),
                  })}
              </Typography>
              <Typography
                sx={{ color: theme.palette.primaryLight[theme.palette.mode], textDecoration: 'underline' }}
                variant="subtitle2"
                fontWeight={400}
                textAlign="center"
                paddingLeft={1}
              >
                {t('browse')}
              </Typography>
            </Flex.Row>
            <Padding.p1>
              {((required == true && files?.length === 0) || hasError) && (
                <FormHelperText error={true} sx={{ textAlign: 'center', fontSize: '14px' }}>
                  ({t('provideValidFile', { ns: 'common' })})
                </FormHelperText>
              )}
            </Padding.p1>
          </Flex.Column>
        </Flex.Row>
      </DragAndDropBox>
      {files?.length > 0 && <Box className="pt-2">{fileTable(files)}</Box>}
      {showDocumentDialog && (
        <UpdateFileMetaDataDialog
          customerId={customerId as EntityId}
          localityId={localityId as EntityId}
          localityFrequencyIds={localityFrequencyIds}
          file={uploadedFile as IFile}
          onClose={() => {
            invalidateLocalityFrequencyCache.useGetLocalityFrequency(customerId as EntityId, localityId as EntityId)
            setShowDocumentDialog(false)
          }}
        />
      )}
    </Box>
  )
}

//styles

const DragAndDropBox = styled(Box)`
  border: 2px dashed ${({theme}) => getThemeColor(theme,MUIThemeColors.secondaryLight)};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`
