import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import { useRemoveLocalityService } from '../../../../api/locality-service'
import { invalidateServiceCache } from '../../../../api/service'
import GenericTable from '../../../../components/table/GenericTable'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { invalidateLocalityFrequencyCache } from '../../../../api/locality-frequency'
import { invalidateMooringCache, mooringsEndpoints } from '../../../../api/moorings'
import { IMooring, IMooringType } from '../../../../generated-types'
import { invalidateLocalityCache } from 'src/api/localities'
import { toast } from 'react-toastify'

export const ServiceMooringTable: React.FC<{
  customerId: EntityId
  serviceId: EntityId
}> = ({ customerId, serviceId }) => {
  const { t: translate } = useTranslation(['mooring-service', 'common'])
  const { mutateAsync, isLoading: isDeleting, error } = useRemoveLocalityService(customerId, serviceId)
  const { showConfirmDialog } = useDialogCtx()
  const onRmoveMooring = (mooringId: EntityId, row: IMooring) => {
    showConfirmDialog(
      translate('removeMooringConfirmationHeader'),
      translate('areYouSureRemoveMooring'),
      {
        acceptText: translate('remove', { ns: 'common' }),
        cancelText: translate('cancel', { ns: 'common' }),
      },
      async pop => {
        await mutateAsync(mooringId, {
          onSuccess: () => {
            toast.success(translate('mooringRemovedSuccessfully', { ns: 'mooring-service' }))
            invalidateServiceCache.getServices(customerId)
            invalidateMooringCache.getMooringsForService(customerId, serviceId)
            invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId, row?.localityId)
            invalidateLocalityCache.getLocalitiesWithInfoCount(customerId as EntityId)
            pop()
          },
          onError: () => {
            toast.error(translate('deletingError', { ns: 'common' }))
          },
        })
      },
    )
  }

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'name',
      headerName: translate('name', { ns: 'common' }),
      flex: 1,
    },
    { field: 'description', headerName: t('description', { ns: 'common' }), flex: 1 },
    {
      field: 'type',
      headerName: t('type', { ns: 'common' }),
      flex: 1,
      valueGetter: value => t(IMooringType[value.value], { ns: 'mooring' }),
    },
    {
      field: 'localityName',
      headerName: translate('localityName', { ns: 'mooring-service' }),
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: translate('startDate', { ns: 'common' }),
      flex: 1,
      valueGetter: ({ value }) => {
        return typeof value === 'string' ? new Date(value).toLocaleDateString() : '-'
      },
    },
    {
      field: 'endDate',
      headerName: translate('endDate', { ns: 'common' }),
      flex: 1,
      valueGetter: ({ value }) => {
        return typeof value === 'string' ? new Date(value).toLocaleDateString() : '-'
      },
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 60,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label={t('removeMooring', { ns: 'mooring-service' })}
            icon={<MdDelete size={24} />}
            onClick={() => onRmoveMooring(id, row)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [],
  )

  return (
    <GenericTable
      id={'service-moorings'}
      columns={columns}
      defaultColumnOption={columns.map(c => c.headerName as string)}
      enableToolbar={true}
      queryUrl={mooringsEndpoints.getMooringsForService(customerId as EntityId, serviceId)}
      enableServerPagination={true}
      enableFilter={true}
      autoHeight={false}
      height={'30vh'}
    />
  )
}
