import { useTranslation } from 'react-i18next'
import GenericProgressBar from '../../../../components/common/GenericProgressBar'
import { IComponent } from '../../../../generated-types'

interface DesignatedLifetimeProps {
  row: IComponent
}

const DesignatedLifetime: React.FC<DesignatedLifetimeProps> = ({ row }) => {
  const { t: translate } = useTranslation(['common'])

  const currentDate = new Date()
  const installationDate = row.installationDate ? new Date(row.installationDate) : new Date()

  const expectedLifetime = row.overriddenLifetime || row.product?.expectedLifetime || 0

  const endOfLifeDate = new Date(installationDate.setMonth(installationDate.getMonth() + expectedLifetime))
  const remainingLife =
    (endOfLifeDate.getFullYear() - currentDate.getFullYear()) * 12 + endOfLifeDate.getMonth() - currentDate.getMonth()

  const designatedLifeTimeValue = expectedLifetime > 0 ? (remainingLife / expectedLifetime) * 100 : 0
  const designatedLifeTimePercentage = expectedLifetime > 0 ? Math.floor(designatedLifeTimeValue) : 0

  const customText = `${designatedLifeTimePercentage >= 0 ? designatedLifeTimePercentage : 0}% - ${
    remainingLife >= 0 ? remainingLife : 0
  } ${translate('months-left')}`

  const progressBarColor =
    'transparent linear-gradient(180deg, #00416A 0%, #003E65 17%, #003B5F 33%, #00395A 50%, #003654 67%, #00334F 83%, #00304A 100%) 0% 0% no-repeat padding-box'

  return <GenericProgressBar value={designatedLifeTimeValue} color={progressBarColor} customText={customText} />
}

export default DesignatedLifetime
