import { useTranslation } from 'react-i18next'
import { IDeviation } from '../../../generated-types/deviation'
import { DeviationFollowUpTable, DeviationFollowUpTableAdmin } from './DeviationFollowUpTable'
import DeviationDocuments from './DeviationDocuments'
import { permissionValue } from '../../../enums/permissionValue'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { DeviationDetailSection } from './DeviationDetailSection'
import { AddCircleOutline, EditOutlined } from '@mui/icons-material'
import { styled } from '@mui/material'
import { Context, useMemo } from 'react'
import { StyledDetailCard } from 'src/styles/Index'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { Flex } from 'src/styles/flexComponent'
import { Chip } from 'src/components/common/Chip'
import { firstLetterToUpperCase } from 'src/utils/function'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

export const GenericDeviationContainer: React.FC<{
  customerId?: EntityId
  localityId?: EntityId
  deviationId: EntityId
  data?: IDeviation
  updateDeviation: (deviation: IDeviation) => void
  onAdd: () => void
  isAdminLayout?: boolean
  context?: Context<any> | null
}> = ({ customerId, localityId, deviationId, data, updateDeviation, onAdd, isAdminLayout, context }) => {
  const { t } = useTranslation(['deviation', 'common'])

  const closedBy = useMemo(() => {
    if (data?.status) {
      const closedFollowup = data?.deviationFollowUps?.find(df => df.status)
      const createdUser = closedFollowup?.createdByUser
      if (createdUser) {
        return ` ${createdUser?.firstName ? firstLetterToUpperCase(createdUser?.firstName) : ''} ${createdUser?.lastName ? firstLetterToUpperCase(createdUser?.lastName) : ''
          }`
      }
    }
    return ''
  }, [data])

  return (
    <div style={{ background: '#F1F5F7' }}>
      <CommonPageLayout
        titleSection={{ title: t('details', { ns: 'deviation' }), subtitle: data?.title }}
        enableBackButton
        topActionContainerProps={{ style: { marginTop: '38px' } }}
        topAction={[
          <Flex.Column key="action">
            {data?.status ? (
              <SignedChip
                variant="outlined"
                label={`${t('closedBy', { ns: 'common' })} : ${closedBy}`}
                sx={{ height: '38px', fontSize: '16px', padding: '8px' }}
              />
            ) : (
              <GridActionButton
                buttonText={t('edit', { ns: 'common' })}
                buttonIcon={<EditOutlined sx={{ height: '24px', width: '24px' }} />}
                onClick={() => updateDeviation(data as unknown as IDeviation)}
                disabled={data?.status}
                permissionBinaryValues={
                  isAdminLayout
                    ? undefined
                    : [
                      permissionValue.Locality.Deviation.Update,
                      permissionValue.Locality.MainComponent.Deviation.Write,
                      permissionValue.ClientAdminPermission,
                      permissionValue.ServiceStation.Write,
                      permissionValue.ServiceStation.Update,
                    ]
                }
                context={context}
                variant={'outlined'}
                shadowWeight={null}
                width="79px"
              />
            )}
          </Flex.Column>,
        ]}
      >
        <DeviationDetailSection deviation={data as IDeviation} />
        <CommonPageLayout
          className="pt-8-core"
          titleSection={{ subtitle: t('deviationFollowUps', { ns: 'deviation' }) }}
          topAction={[
            {
              buttonText: t('follow-up', { ns: 'deviation' }),
              buttonIcon: <AddCircleOutline />,
              onClick: onAdd,
              disabled: data?.status,
              context: context,
              permissionBinaryValues: isAdminLayout
                ? undefined
                : [
                  permissionValue.Locality.Deviation.Write,
                  permissionValue.Locality.Task.Update,
                  permissionValue.Locality.Task.Write,
                  permissionValue.Locality.Service.Update,
                  permissionValue.Locality.Service.Write,
                  permissionValue.ClientAdminPermission,
                  permissionValue.ServiceStation.Write,
                  permissionValue.ServiceStation.Update,
                ],
              variant: 'outlined',
              shadowWeight: null,
              width: '130px',
            },
          ]}
        >
          <StyledDetailCard
            elevation={0}
            className="scrollbar p-3 mb-2"
            style={{ height: '286px', overflowY: 'auto', borderRadius: '4px', boxShadow: '0px 2px 8px #00000040' }}
          >
            {!isAdminLayout ? (
              <DeviationFollowUpTable context={context} deviation={data as IDeviation} />
            ) : (
              <DeviationFollowUpTableAdmin context={context} deviation={data as IDeviation} />
            )}
          </StyledDetailCard>
        </CommonPageLayout>
        {!isAdminLayout ? (
          <DeviationDocuments
            customerId={customerId as EntityId}
            localityId={localityId as EntityId}
            deviationId={deviationId}
            isDisabled={data?.status}
            context={context}
          />
        ) : (
          <></>
        )}
      </CommonPageLayout>
    </div>
  )
}

//styled components
const SignedChip = styled(Chip)`
  background-color: ${props => getThemeColor(props.theme, MUIThemeColors.white)};
  border: 1px solid ${props => getThemeColor(props.theme, MUIThemeColors.successLight)};
  color: ${props => getThemeColor(props.theme, MUIThemeColors.successLight)};
`
