import { SvgIconComponent } from '@mui/icons-material'
import { Box } from '@mui/material'
import { ICustomer } from '../../generated-types'
import { useLocation } from 'react-router-dom'
import { MenuItemTemplate } from './MenuItemTemplate'
import { IconType } from 'react-icons'

interface IMenuItem {
  title: string
  Icon: SvgIconComponent | IconType | React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
    title?: string;
  }>
  isMinified: boolean
  onClick?: (e: any) => void
  activePath?: string
  disabled?: boolean
  className?: string
  path?: string
  count?: number
}

export const CollapsedSideMenu: React.FC<{
  collapsibleSidebarOpen: boolean
  customer: ICustomer | undefined
  menuItems: IMenuItem[]
  setPath: React.Dispatch<React.SetStateAction<string>>
  setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ customer, menuItems }) => {
  const { pathname } = useLocation()
  const active = !!pathname && pathname.split('/')[6]

  const menuItemList = menuItems.map((menuItem, index) => (
    <MenuItemTemplate key={index}
      {...menuItem}
      isMinified={true}
      active={active === menuItem.path}
    />
  ))

  return (
    <Box className="h-full" >
      <div style={{ width: '84px' }}
        className={'h-full flex flex-col gap-36 shadow-md transition-transform top-0 !sticky bg-white relative'}
      >
        <div>
          <Box flexBasis="20%" width={'40px'} height={'40px'} overflow={'hidden'} className="flex items-center justify-center mx-auto my-2 bg-white border-2 rounded-full border-primary">
            {customer?.image ? <img className=" max-h-[40px]" src={customer?.image} /> : null}
          </Box>
          <div className="flex flex-col items-center justify-center gap-4 my-6 ">
            {menuItemList}
          </div>
        </div>
      </div>
    </Box>
  )
}