import React, { createContext, useEffect, useMemo, useState } from 'react'
import { ILocality } from '../generated-types'
import { useGetLocality } from '../api/localities'
import { useParams } from 'react-router-dom'
import { axios } from '@lovoldsystem/core.client'

interface LocationContextProps {
  selectedLocation: ILocality
  permission: number
}

export const CustomerLocationCtx = createContext<LocationContextProps>(null!)

export const LocationContextProvider: React.FC = ({ children }) => {

  const { localityId, customerId } = useParams()
  const [permission, setPermission] = useState(-1)
  const { data, isLoading } = useGetLocality(customerId as EntityId, localityId as EntityId, {
    isIgnoreQueryFilters: sessionStorage.getItem('readModeEnabledLocalityId') == localityId,
  })
  const selectedLocation = useMemo(() => data as ILocality, [data])
  useEffect(() => {
    if (data) {
      axios.defaults.headers['X-Permissions'] = data.encryptedPermission
      setPermission(data?.permission as number)
    }
  }, [data])


  const contextValue: LocationContextProps = {
    selectedLocation,
    permission
  }

  return <CustomerLocationCtx.Provider value={contextValue}>
    {!isLoading && permission != -1 ? children : null}
  </CustomerLocationCtx.Provider>
}
