import { IFrequency } from '../generated-types/frequency'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId, serviceId: EntityId) =>
  `customers/${customerId}/services/${serviceId}/frequencies`

export const endpoints = {
  getFrequencies(customerId: EntityId, serviceId: EntityId) {
    return `${baseUrl(customerId, serviceId)}`
  },
  getFrequency(customerId: EntityId, serviceId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, serviceId)}/${id}`
  },
  createFrequency(customerId: EntityId, serviceId: EntityId) {
    return `${baseUrl(customerId, serviceId)}`
  },
  updateFrequency(customerId: EntityId, serviceId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, serviceId)}/${id}`
  },
  deleteFrequency(customerId: EntityId, serviceId: EntityId) {
    return `${baseUrl(customerId, serviceId)}`
  },
}

export const invalidateFrequencyCache = {
  getFrequencies(customerId: EntityId, serviceId: EntityId) {
    queryClient.invalidateQueries(endpoints.getFrequencies(customerId, serviceId))
  },
  getFrequency(customerId: EntityId, serviceId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(endpoints.getFrequency(customerId, serviceId, id))
  },
}

export const useGetFrequencies = (customerId: EntityId, serviceId: EntityId, page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IFrequency>>(endpoints.getFrequencies(customerId, serviceId), { page, pageSize })

export const useGetFrequency = (customerId: EntityId, serviceId: EntityId, id: EntityId) =>
  useGet<IFrequency>(endpoints.getFrequency(customerId, serviceId, id))

export const useAddFrequency = (customerId: EntityId, serviceId: EntityId) =>
  usePost<IFrequency, IFrequency>(endpoints.createFrequency(customerId, serviceId))

export const useUpdateFrequency = (customerId: EntityId, serviceId: EntityId, id: EntityId) =>
  usePut<IFrequency, IFrequency>(endpoints.updateFrequency(customerId, serviceId, id))

export const useDeleteFrequency = (customerId: EntityId, serviceId: EntityId) =>
  useDelete<IFrequency>(endpoints.deleteFrequency(customerId, serviceId))
