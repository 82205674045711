import { Box, Button, List } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { SidebarItem } from '../../components/SidebarItem'
import { useSidebarCtx } from '../../hooks/context-hooks'
import { adminSidebarItems } from './admin-sidebar-items'
import { useEffect } from 'react'

export const AdminSidebar = () => {
  const { t: translate } = useTranslation('admin-sidebar')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { setCollapsibleSidebarOpen, collapsibleSidebarOpen } = useSidebarCtx()

  useEffect(() => {
    setCollapsibleSidebarOpen(true)
  }, [])
  return (
    <Box width={'100%'} sx={{ paddingTop: collapsibleSidebarOpen ? '0px' : '90px' }} >
      <Box display={collapsibleSidebarOpen ? 'block' : 'none'} pt={2} className="p-1 px-1 py-1">
        <Button
          variant="contained"
          color="primary"
          className="w-full"
          sx={{
            fontSize: {
              xs: '15px',
              sm: '15px',
              lg: '20px',
            },
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          {translate('systemAdministrator')}
        </Button>
      </Box>

      <List style={{ width: collapsibleSidebarOpen ? '100%' : '72px' }} className="!px-1 !py-1 p-1">
        {adminSidebarItems.map(item => (
          <SidebarItem
            key={item.name}
            active={
              pathname != null &&
              (pathname.split('/').slice(-1)[0] === item.pathname || pathname.split('/')[2] === item.pathname)
            }
            textVisibility={collapsibleSidebarOpen}
            title={translate(item.name)}
            Icon={item.icon}
            onClick={() => navigate(item.path)}
          />
        ))}
      </List>
    </Box>
  )
}