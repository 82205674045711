import React, { createContext, useEffect, useRef, useState } from 'react'
import { IUserWithAccess } from '../generated-types'
import { useGetMe } from '../api/user'
import { UserStatus } from '../enums/user-status'
import { Navigate, useLocation } from 'react-router-dom'
import { AppRoutesNavigation } from '../app-routes'

interface UserContextProps {
  user: IUserWithAccess
  isLoadingUser: boolean
  refetch: () => void
}

export const UserCtx = createContext<UserContextProps>(null!)

export const UserContextProvider: React.FC = ({ children }) => {
  const { data, isLoading, refetch } = useGetMe()
  const [isNavigated, setIsNavigated] = useState(false)
  const { pathname } = useLocation()

  const timeOut = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current)
      }
    }
  }, [])

  const contextValue: UserContextProps = {
    user: data!,
    isLoadingUser: isLoading,
    refetch,
  }

  if (!isLoading && data?.status == UserStatus.InComplete && !isNavigated && pathname !== AppRoutesNavigation.Profile) {
    timeOut.current = setTimeout(() => {
      setIsNavigated(true)
    }, 100)

    return <Navigate to={AppRoutesNavigation.Profile} state={{ prevPath: window.location.pathname }} />
  }

  return <UserCtx.Provider value={contextValue}>{children}</UserCtx.Provider>
}
