import { useTranslation } from 'react-i18next'
import { AppForm, AppFormProps, FormSubmitButton, FormTextField } from 'src/components/FormCore'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { useZodForm } from 'src/hooks/zod-form'
import { z } from 'zod'

interface DocumentCategoryFormProps extends AppFormProps {
  title: string
}

export const DocumentCategoryForm: React.FC<DocumentCategoryFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  title,
}) => {
  const { t: translate } = useTranslation(['document-category', 'common'])

  const schema = z.object({
    categoryName: z.string().nonempty(translate('categoryNameIsRequired')),
  })

  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  const isUpdateForm = initialValues?.id

  const handleSubmit = async form => {
    onSubmit(form)
  }

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={initialValues}>
      <GenericDialogLayout
        title={title}
        pop={onCancel}
        actions={[
          <FormSubmitButton
            key="submit"
            createText={!isUpdateForm ? translate('add', { ns: 'common' }) : translate('update', { ns: 'common' })}
          />,
        ]}
        removedCloseButton={false}
      >
        {isUpdateForm && <FormTextField name="id" type="hidden" />}
        <FormTextField name="categoryName" label={translate('categoryName', { ns: 'document-category' })} />
      </GenericDialogLayout>
    </AppForm>
  )
}
