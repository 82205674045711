import { useMutation } from 'react-query'
import { IDistributor } from '../generated-types'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'
import { requestProvider } from './requestProvider'

const baseUrl = () => 'admin/distributors'

export const distributorsEndpoints = {
  getDistributors() {
    return `${baseUrl()}`
  },
  getDistributor(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  createDistributor() {
    return `${baseUrl()}`
  },
  updateDistributor(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  resetDistributor(id: EntityId){
    return `${baseUrl()}/${id}/password-reset`
  },
  deleteDistributor() {
    return `${baseUrl()}`
  },
}

export const invalidateDistributorCache = {
  useGetDistributors() {
    queryClient.invalidateQueries(distributorsEndpoints.getDistributors())
  },
  getDistributor(id: EntityId) {
    queryClient.invalidateQueries(distributorsEndpoints.getDistributor(id))
  },
}

export const useGetDistributors = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IDistributor>>(distributorsEndpoints.getDistributors(), { page, pageSize })

export const useGetDistributor = (id: EntityId) => useGet<IDistributor>(distributorsEndpoints.getDistributor(id))

export const useAddDistributor = () => usePost<IDistributor, IDistributor>(distributorsEndpoints.createDistributor())

export const useUpdateDistributor = (id: EntityId) => usePut<IDistributor, IDistributor>(distributorsEndpoints.updateDistributor(id))

export const useDeleteDistributor = () => useDelete<IDistributor>(distributorsEndpoints.deleteDistributor())

export const useUpdateDistributorStatus = () => useMutation((data: IDistributor) => requestProvider.put(distributorsEndpoints.updateDistributor(data?.id), data))

export const useResetDistributor = () => useMutation((data: IDistributor) => requestProvider.patch(distributorsEndpoints.resetDistributor(data.id), data))
