import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import UnAuthorized from '../../assets/svg/401.svg'
import { useTranslation } from 'react-i18next'

export const UnAuthorizationPage: FC = () => {
  const { t } = useTranslation('common')
  return (
    <Stack justifyContent={'center'} alignItems={'center'} minHeight={'calc(100vh - 100px)'} width={'100%'}>
      <img src={UnAuthorized} />
      <Typography variant="h5" color={'primary'} style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
        {t('page-not-authorize')}
      </Typography>
    </Stack>
  )
}
