import { Autocomplete, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetCagePositionsByLocality } from 'src/api/positions'
import { AppTextField } from 'src/components/AppTextField'
import BasicDatePicker from 'src/components/DateTimePicker'
import { IPosition } from 'src/generated-types'
import { getCageName } from 'src/utils/function'

export const SelectPlacement: React.FC<{
  customerId: EntityId
  localityId: EntityId
  disabled: boolean
  form: any
}> = ({ customerId, localityId, form, disabled }) => {
  const { data: cages } = useGetCagePositionsByLocality(customerId, localityId)
  const { t } = useTranslation(['service-station', 'common'])

  return (
    <>
      <Autocomplete
        onChange={(_, value) => {
          form.setValue('selectedPositionId', (value as IPosition).id)
          form.setValue('selectedMooringId', (value as IPosition).mooringId)
        }}
        disabled={disabled}
        groupBy={option => option.mooringName ?? ''}
        options={(cages ?? []) as IPosition[]}
        autoHighlight
        getOptionLabel={o => getCageName(o.name)}
        renderOption={(props, o) => (
          <MenuItem key={o.id} value={o.id} {...props}>
            {getCageName(o.name)}
          </MenuItem>
        )}
        renderInput={params => (
          <AppTextField
            {...params}
            label={t('selectPlacement', { ns: 'main-component' })}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <BasicDatePicker
        label={t('installationDate', { ns: 'main-component' })}
        name="installationDate"
        disabled={disabled}
      />
    </>
  )
}
