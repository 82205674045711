import { IAccessGroup } from '../generated-types'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/access-level-groups`

export const accessGroupsEndpoints = {
  getAccessGroups(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getAccessGroup(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  createAccessGroup(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateAccessGroup(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  deleteAccessGroup(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
}

export const invalidateAccessGroupsQueries = {
  invalidateGetAccessGroups(customerId: EntityId) {
    queryClient.invalidateQueries(accessGroupsEndpoints.getAccessGroups(customerId))
  },
  invalidateGetAccessGroup(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(accessGroupsEndpoints.getAccessGroup(customerId, id))
  },
}

export const useGetAccessGroups = (
  customerId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IAccessGroup>>(accessGroupsEndpoints.getAccessGroups(customerId), {
    page,
    pageSize,
  })

export const useGetAccessGroup = (customerId: EntityId, id: EntityId) =>
  useGet<IAccessGroup>(accessGroupsEndpoints.getAccessGroup(customerId, id))

export const useAddAccessGroup = (customerId: EntityId) =>
  usePost<IAccessGroup, IAccessGroup>(accessGroupsEndpoints.createAccessGroup(customerId))

export const useUpdateAccessGroup = (customerId: EntityId, id: EntityId) =>
  usePut<IAccessGroup, IAccessGroup>(accessGroupsEndpoints.updateAccessGroup(customerId, id))

export const useDeleteAccessGroup = (customerId: EntityId) =>
  useDelete<IAccessGroup>(accessGroupsEndpoints.deleteAccessGroup(customerId))
