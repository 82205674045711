import { Box, useTheme } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { useGetDocumentHistory } from '../../../api/document'
import { useUserGetDocumentCategories } from '../../../api/document-category'
import { FileUrlLoader } from '../../../components/FileUploader'
import { CenteredSpinner } from '../../../components/Spinner'
import { IDocument, IDocumentHistory } from '../../../generated-types/document'
import { bytesToSize, getFormattedDate } from '../../../utils'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { Flex } from 'src/styles/flexComponent'
import GenericTable from 'src/components/table/GenericTable'

export interface IDocumentHistoryTableProps {
  customerId: EntityId
  localityId: EntityId
  documentId: EntityId
  columns?: GridColumns<IDocument>
}

export const DocumentHistoryTable: React.FC<IDocumentHistoryTableProps> = ({ customerId, documentId }) => {
  const { t } = useTranslation(['Document', 'common', 'certificate'])
  const documentTypes = useUserGetDocumentCategories()
  const { data, isLoading } = useGetDocumentHistory(customerId, documentId)
  const theme = useTheme()
  const { openFilePreviewDialog } = useDialogCtx()

  if (isLoading) return <CenteredSpinner />

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'file',
      headerName: t('file', { ns: 'common' }),
      flex: 1,
      renderCell: params => {
        const document = params.row as IDocument
        return (
          <Flex.Column justifyContent={'center'} alignItems={'center'} width={'30px'} height={'30px'}>
            <FileUrlLoader
              fileUrl={document.fileUrl}
              contentTypeValue={document.fileContentType}
              className="max-w-[30px] h-[30px] object-cover rounded p-1"
            />
          </Flex.Column>
        )
      },
    },
    {
      field: 'title',
      headerName: t('title', { ns: 'common' }),
      flex: 1,
      renderCell: params => {
        const document = params.row as IDocumentHistory
        return document?.title
      },
    },
    {
      field: 'revision',
      headerName: t('revision', { ns: 'document' }),
      flex: 1,
      renderCell: params => {
        const document = params.row as IDocumentHistory
        return (
          <Box>
            {document.documentId}.{document.id}
          </Box>
        )
      },
    },
    {
      field: 'documentType',
      headerName: t('documentType', { ns: 'certificate' }),
      flex: 1,
      renderCell: ({ row }) => {
        const document = row as IDocumentHistory
        return <Box>{documentTypes.data?.items?.find(x => x.id === document.documentCategoryId)?.categoryName}</Box>
      },
    },
    {
      field: 'dueDate',
      headerName: t('dueDate', { ns: 'common' }),
      flex: 1,
      renderCell: ({ row }) => getFormattedDate(row.dueDate),
    },
    { field: 'fileContentType', headerName: t('fileContentType', { ns: 'file-upload' }), flex: 1 },
    {
      field: 'fileContentLength',
      headerName: t('fileSize', { ns: 'file-upload' }),
      flex: 0.5,
      valueGetter: value => bytesToSize(value.value),
    },
  ]

  const columns: GridColumns = [...STATIC_COLUMNS]

  return (
    <Box sx={{ width: '99.8%' }}>
      <GenericTable
      id="document-history-table"
      columns={columns}
      data={data?.items ?? []}
      onRowClick={({ row }) => {
        row.fileUrl && openFilePreviewDialog(row.fileUrl)
      }}
      sortModel={[{ field: 'id', sort: 'desc' }]}
      autoHeight={false}
      height={250}
    />
    </Box>
  )
}
