import { useFormContext } from 'react-hook-form'
import { useGetMainComponentsForLocality } from '../../../api/main-component'
import { IMainComponent } from '../../../generated-types/main-component'
import { ISelectMainComponentStepProps, GenericMainComponentSelectStep } from './SelectMainComponentStep'

export const SelectLocalityMainComponentStep: React.FC<ISelectMainComponentStepProps> = ({
  customerId,
  setDisabled,
  handleNext,
}) => {
  const { getValues } = useFormContext()

  const { data: releasedMainComponents, isLoading } = useGetMainComponentsForLocality(
    customerId as EntityId,
    {
      localityId: getValues('locality')?.id as EntityId,
    }
  )

  return (
    <GenericMainComponentSelectStep
      mainComponents={releasedMainComponents as IMainComponent[]}
      isLoading={isLoading}
      setDisabled={setDisabled}
      handleNext={handleNext}
    />
  )
}
