import { useTranslation } from 'react-i18next'
import { Button, FormControl, IconButton, Input, InputAdornment, styled, Tooltip } from '@mui/material'
import { MdDelete, MdEdit } from 'react-icons/md'
import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridWithServerPagination } from '../../../components/DataGridWithServerPagination'
import { distributorsEndpoints, invalidateDistributorCache, useDeleteDistributor, useResetDistributor, useUpdateDistributorStatus } from '../../../api/distributor'
import { IDistributor } from '../../../generated-types'
import classNames from 'classnames'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { UpdateDistributorDialog } from './UpdateDistributorDialog'
import { VisibilityOff, Visibility, Replay } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const DistributorTable: React.FC = () => {
  const { t: translate } = useTranslation(['distributor', 'common'])
  const apiRef = useGridApiRef()
  const { mutate, isLoading: isDeleting } = useDeleteDistributor()
  const updateStatus = useUpdateDistributorStatus()
  const resetPassword = useResetDistributor()
  const [showPassword, setShowPassword] = useState(-1)
  const { showDialog, showConfirmDialog } = useDialogCtx()
  
  const STATIC_COLUMNS: GridColumns = [
    { field: 'name', headerName: translate('name', { ns: 'common' }), flex: 1, cellClassName: 'font-weight-semi-bold' },
    { field: 'key', headerName: translate('key', { ns: 'distributor' }), flex: 1, },
    { field: 'userName', headerName: translate('userName', { ns: 'distributor' }), flex: 1 }
  ]

  const onDeleteDistributor = (id: EntityId) => {
    showConfirmDialog(
      translate('deleteDistributor', { ns: 'distributor' }),
      translate('areYouSureDeleteDistributor', { ns: 'distributor' }),
    ).then(e => {
      if (e) {
        mutate(id)
        if (apiRef.current) {
          apiRef.current.updateRows([{ id, _action: 'delete' }])
        }
      }
    })
  }

  const onResetDistributor = (data: IDistributor) => {
    showConfirmDialog(
      translate('resetPassword', { ns: 'distributor' }),
      translate('areYouSureResetPassword', { ns: 'distributor' }),
    ).then(e => {
      if (e) {
        resetPassword.mutate(data)
      }
    })
  }

  const onUpdate = async id => {
    const added = await showDialog(UpdateDistributorDialog, {
      componentProps: { id: id as EntityId },
    })
    if (!added) return

    invalidateDistributorCache.useGetDistributors()
    invalidateDistributorCache.getDistributor(id)
  }

  const onToggleStatus = async data => {
    data.status = !data.status
    updateStatus.mutate(data as IDistributor)
  }

  const handleTogglePassword = (id) => setShowPassword(prevId => {
    if (prevId == id) {
        return -1
    } else {
        return id
    } 
})

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'password',
        headerName: translate('password', { ns: 'distributor' }),
        flex: 1,
        sortable: false,
        cellStyle: { maxWidth: 50 },
        renderCell:(rowData => {
          return <FormControl variant="outlined" >
              <StyleInput
                  id="outlined-adornment-password"
                  type={showPassword == rowData.row.id ? 'text' : 'password'}
                  value={rowData.row.password}
                  endAdornment={
                      <InputAdornment position="end" >
                          {                            
                             rowData.row.password ? (
                                  <>
                                      <Tooltip title={'Show Password'}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleTogglePassword(rowData.row.id)}
                                        >
                                            {showPassword == rowData.row.id ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={'Reset Password'}>
                                          <IconButton
                                              onClick={() => onResetDistributor(rowData.row)}>
                                              <Replay />
                                          </IconButton>
                                      </Tooltip>
                                  </>
                              ): <></>
                          }
                      </InputAdornment>
                  }
              />
          </FormControl>
        }
      )},
      {
        field: 'status',
        headerName: translate('status', { ns: 'distributor' }),
        flex: 1,
        renderCell:(rowData: any)=>{
          return <p>{rowData.value ? 'Enabled' : 'Disabled'}</p>
        }
      },
      {
        field: 'updateStatus',
        headerName: '',
        flex: 1,
        sortable: false,
        cellStyle: { maxWidth: 50 },
        renderCell:(rowData: any)=>{
            return <div className={classNames('flex flex-row justify-evenly w-[150px]')}>
                <Button disabled={rowData.row.status} variant="contained" type='button' color="primary"
                  onClick={()=>{
                    onToggleStatus(rowData.row)
                  }}
                >{t('enable')}</Button>
                <Button disabled={!rowData.row.status} variant="contained" type='button' color="primary"
                  onClick={()=>{
                    onToggleStatus(rowData.row)
                }} >{t('disable')}</Button>
            </div>
        }
      },
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        sortable: false,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteDistributor(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [showPassword],
  )

  return (showPassword ? 
    <DataGridWithServerPagination
      queryUrl={distributorsEndpoints.getDistributors()}
      onCellClick={cell => {}}
      columns={columns}
    />
    : <></>
  )  
}

export const StyleInput = styled(Input)({
  '& .underline': {
    '&&&:before': {
        borderBottom: 'none'
    },
    '&&:after': {
        borderBottom: 'none'
    }
}
})
