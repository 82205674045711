import { queryClient, usePut } from '@lovoldsystem/core.client'
import { IAccessGroup } from '../generated-types'
import { useDelete, useGet } from './reactQuery'
import { userInviteQueryKey } from '../query-keys'
import { IApiResult } from '../generated-types/api-result'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/user-invites`

export const userInvitesEndpoints = {
    getUserInvites(customerId: EntityId, page?: number, pageSize?: number) {
        return page && pageSize ?
            `${baseUrl(customerId)}?page=${page}&pageSize=${pageSize}` :
            `${baseUrl(customerId)}`
    },
    deleteUserInvite(customerId: EntityId) { return `${baseUrl(customerId)}` },
    renewUserInvite(customerId: EntityId) { return `${baseUrl(customerId)}` }
}

export const invalidateUserInviteCache = {
    useGetUserInvites(customerId: EntityId) {
        return queryClient.invalidateQueries(userInviteQueryKey.GET_USER_INVITES(customerId))
    }

}

export const useGetUserInvites = (customerId: EntityId, page?: number, pageSize?: number) =>
    useGet<IAccessGroup[]>(userInvitesEndpoints.getUserInvites(customerId, page, pageSize))

export const useRenewUserInvite = (customerId: EntityId) =>
    usePut<IApiResult, {id:EntityId}>(userInvitesEndpoints.renewUserInvite(customerId))

export const useDeleteUserInvite = (customerId: EntityId) =>
    useDelete<IAccessGroup>(userInvitesEndpoints.deleteUserInvite(customerId))
