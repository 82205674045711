import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GlobalDocumentTableByDocumentCategory } from './GlobalDocumentTableByDocumentCategory'
import { useGetGlobalDocumentCategories } from '../../../api/global-documents'
import { CenteredSpinner } from '../../../components/Spinner'

export interface IAccordionGlobalDocumentTableProps {
  customerId: EntityId
  IsApprovedDocs: boolean
}

export const AccordionGlobalDocumentTable: React.FC<IAccordionGlobalDocumentTableProps> = ({
  customerId,
  IsApprovedDocs,
}) => {
  const { t } = useTranslation(['Document', 'common'])
  const { data: documentCategories, isLoading } = useGetGlobalDocumentCategories({ customerId, IsApprovedDocs })

  if (isLoading) return <CenteredSpinner />

  return (
    <>
      {documentCategories && documentCategories.length > 0 ? (
        documentCategories.map(documentCategory => {
          return (
            <GlobalDocumentTableByDocumentCategory
              key={documentCategory.documentCategoryId}
              customerId={customerId}
              documentCategoryId={documentCategory.documentCategoryId}
              documentCategoryName={documentCategory.documentCategoryName}
              documentsCount={documentCategory.documentCount}
              IsApprovedDocs={IsApprovedDocs}
            />
          )
        })
      ) : (
        <Box py={2} className="text-center">
          {t('noResultFound', { ns: 'common' })}
        </Box>
      )}
    </>
  )
}
