import { useTranslation } from 'react-i18next'
import { useGetLocalityNames } from '../../../../api/localities'
import { useGetAllResources } from '../../../../api/task-resource'
import { useCustomerCtx } from '../../../../hooks/context-hooks'
import { z } from 'zod'
import { useZodForm } from '../../../../hooks/zod-form'
import { useGetTimeReportTypes } from '../../../../api/time-report-type'
import { Box, Grid } from '@mui/material'
import BasicDatePicker from '../../../../components/DateTimePicker'
import { AppForm, FormSelectField, FormSubmitButton } from '../../../../components/FormCore'

const TimeReportFilter = ({ onChange }) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation(['time-reports', 'task', 'time-report-types', 'common'])
  const { data: localities } = useGetLocalityNames(customer?.id)
  const { data: resources } = useGetAllResources(customer?.id)
  const { data: timeReportTypes } = useGetTimeReportTypes()

  const timeReportsFilterSchema = z
    .object({
      locality: z
        .array(
          z.object({
            id: z.number(),
          }),
        )
        .nullable(),
      resource: z
        .array(
          z.object({
            id: z.number(),
          }),
        )
        .nullable(),
      timeReportType: z
        .array(
          z.object({
            id: z.number(),
          }),
        )
        .nullable(),
      startDate: z.date().optional().nullable(),
      endDate: z.date().optional().nullable(),
    })
    .refine(
      data =>
        data.endDate == undefined ||
        data.startDate == undefined ||
        (data.endDate != undefined && data.startDate != undefined && data.endDate > data.startDate),
      {
        message: t('endStartDateError', { ns: 'common' }),
        path: ['endDate'],
      },
    )

  const form = useZodForm(timeReportsFilterSchema, {
    defaultValues: { locality: [], resource: [], timeReportType: [] },
  })

  return (
    <Box width={'calc(100vw - 600px)'}>
      <AppForm form={form} onSubmit={onChange}>
        <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container md={11}>
            <Grid item md={2}>
              <FormSelectField
                label={t('locality', { ns: 'common' })}
                variant="outlined"
                getOptionLabel={row => row.name}
                data={localities || []}
                name={'locality'}
                multiple
              />
            </Grid>
            <Grid item md={2}>
              <FormSelectField
                variant="outlined"
                getOptionLabel={row => row.name}
                label={t('resource', { ns: 'task' })}
                data={resources?.items || []}
                name={'resource'}
                multiple
              />
            </Grid>
            <Grid item md={3}>
              <FormSelectField
                variant="outlined"
                getOptionLabel={row => row.typeName}
                label={t('timeReportType', { ns: 'time-report-types' })}
                data={timeReportTypes?.items || []}
                name={'timeReportType'}
                multiple
              />
            </Grid>
            <Grid item md={5}>
              <Grid container>
                <Grid item md={6}>
                  <BasicDatePicker name="startDate" label={t('startDate', { ns: 'common' })} />
                </Grid>
                <Grid item md={6}>
                  <BasicDatePicker name="endDate" label={t('endDate', { ns: 'common' })} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1}>
            <FormSubmitButton createText={t('filter', { ns: 'common' })} />
          </Grid>
        </Grid>
      </AppForm>
    </Box>
  )
}

export default TimeReportFilter
