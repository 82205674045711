import { useTranslation } from 'react-i18next'
import { Autocomplete, Box, Grid } from '@mui/material'
import { z } from 'zod'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { CancelButton } from '../../../components/CancelButton'
import { MainComponentStatus, Months } from '../../../enums'
import { useGetMainComponentTypesAdmin } from '../../../api/main-component-type'
import { IMainComponentType } from '../../../generated-types/main-component-type'
import { useCallback, useState } from 'react'
import { useGetCustomers } from '../../../api/customer-admin'
import { ICustomer } from '../../../generated-types'
import i18n from '../../../i18n'
import BasicDatePicker from '../../../components/DateTimePicker'
import { AppTextField } from '../../../components/AppTextField'

export interface GlobalDocumentRuleFormProps extends AppFormProps {
  customerId?: EntityId
}

export const GlobalDocumentRuleForm: React.FC<GlobalDocumentRuleFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  customerId,
}) => {
  const { t: translate } = useTranslation(['global-document-rules', 'common'])
  const { data: customers } = useGetCustomers()

  const [producedFromYear, setProducedFromYear] = useState<Date | null | string>(
    initialValues?.producedFromYear?.toString(),
  )
  const [producedForYear, setProducedForYear] = useState<Date | null | string>(
    initialValues?.producedForYear?.toString(),
  )

  const [producedFromMonth, setProducedFromMonth] = useState<Date | null | string>(
    initialValues?.producedFromMonth != undefined
      ? `${Months[initialValues?.producedFromMonth]} ${initialValues?.producedFromYear}`
      : null,
  )
  const [producedForMonth, setProducedForMonth] = useState<Date | null | string>(
    initialValues?.producedForMonth != undefined
      ? `${Months[initialValues?.producedForMonth]} ${initialValues?.producedForYear}`
      : null,
  )

  const mainComponentStatus = Object.keys(MainComponentStatus)
    .filter(v => isNaN(Number(v)))
    .map(t => MainComponentStatus[t])

  const mainComponentTypes = useGetMainComponentTypesAdmin()
  const getTranslatedLabel = useCallback(
    (property: IMainComponentType) =>
      i18n.language == 'en' ? JSON.parse(property.name as string).en : JSON.parse(property.name as string).no,
    [],
  )

  const schema = z
    .object({
      customer: z
        .object({
          id: z.number().or(z.null()).optional(),
        })
        .or(z.null())
        .optional(),
      mainComponentType: z
        .object({
          id: z.number().or(z.null()).optional(),
        })
        .or(z.null())
        .optional(),
      mainComponentStatus: z.number().or(z.null()).optional(),
      producedFromMonth: z
        .string()
        .or(z.number())
        .or(z.null())
        .optional()
        .transform(v => (v != null ? Number(v) : null)),
      producedFromYear: z
        .string()
        .or(z.number())
        .or(z.null())
        .optional()
        .transform(v => (v != null ? Number(v) : null)),
      producedForMonth: z
        .string()
        .or(z.number())
        .or(z.null())
        .optional()
        .transform(v => (v != null ? Number(v) : null)),
      producedForYear: z
        .string()
        .or(z.number())
        .or(z.null())
        .optional()
        .transform(v => (v != null ? Number(v) : null)),
    })
    .refine(
      data => {
        return !(
          data.producedFromYear !== null &&
          data.producedForYear !== null &&
          data.producedFromYear !== data.producedForYear &&
          data.producedFromYear > data.producedForYear
        )
      },
      {
        message: translate('invalidProducedForYear', { ns: 'global-document-rules' }),
        path: ['producedForYear'],
      },
    )
    .refine(
      data =>
        !(
          data.producedFromYear !== null &&
          data.producedForYear !== null &&
          data.producedFromMonth !== null &&
          data.producedForMonth !== null &&
          data.producedFromYear === data.producedForYear &&
          data.producedForMonth < data.producedFromMonth
        ),
      {
        message: translate('invalidProducedForMonth', { ns: 'global-document-rules' }),
        path: ['producedForMonth'],
      },
    )

  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  const isUpdateForm = initialValues?.id

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
      {isUpdateForm && <FormTextField name="id" sx={{ display: 'none' }} />}
      {!customerId && (
        <Autocomplete
          options={(customers?.items || []) as ICustomer[]}
          getOptionLabel={option => option.name}
          renderOption={(props: any, option) => (
            <Box {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(e, value) => {
            form.setValue('customer', value as ICustomer)
          }}
          defaultValue={initialValues?.customer}
          renderInput={params => (
            <AppTextField
              {...params}
              label={translate('customer', { ns: 'global-document-rules' })}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      )}
      <Autocomplete
        options={(mainComponentTypes.data?.items || []) as IMainComponentType[]}
        getOptionLabel={option => getTranslatedLabel(option)}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            {getTranslatedLabel(option)}
          </Box>
        )}
        defaultValue={initialValues?.mainComponentType}
        onChange={(e, value) => {
          form.setValue('mainComponentType', value as IMainComponentType)
        }}
        renderInput={params => (
          <AppTextField
            {...params}
            label={translate('main-component-type', { ns: 'global-document-rules' })}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />

      <Autocomplete
        options={mainComponentStatus ?? []}
        getOptionLabel={option => translate(MainComponentStatus[option], { ns: 'main-component' })}
        onChange={(e, value) => {
          form.setValue('mainComponentStatus', value)
        }}
        defaultValue={initialValues?.mainComponentStatus}
        renderOption={(props: any, option) => (
          <Box {...props} key={option}>
            {MainComponentStatus[option]}
          </Box>
        )}
        autoHighlight
        renderInput={params => (
          <AppTextField
            {...params}
            label={translate('main-component-status', { ns: 'global-document-rules' })}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option === value}
      />
      <Grid container className="!flex !flex-wrap justify-between">
        <Grid item xs={6}>
          <BasicDatePicker
            name="producedFromYear"
            views={['year']}
            inputFormat={'yyyy'}
            label={translate('producedFromYear', { ns: 'global-document-rules' })}
            openTo={'year'}
            onChange={newValue => {
              onYearChange(form, newValue, setProducedFromYear, 'producedFromYear')
            }}
            value={producedFromYear}
            allowNullable
          />
        </Grid>
        <Grid item xs={6}>
          <BasicDatePicker
            name="producedFromMonth"
            views={['year', 'month']}
            inputFormat={'MMMM'}
            label={translate('producedFromMonth', { ns: 'global-document-rules' })}
            openTo={'month'}
            onChange={newValue => {
              onMonthChange(form, newValue, setProducedFromMonth, 'producedFromMonth')
            }}
            disabled={producedFromYear == null}
            value={producedFromMonth}
            allowNullable
          />
        </Grid>
      </Grid>
      <Grid container className="!flex !flex-wrap justify-between">
        <Grid item xs={6}>
          <BasicDatePicker
            name="producedForYear"
            views={['year']}
            inputFormat={'yyyy'}
            label={translate('producedForYear', { ns: 'global-document-rules' })}
            openTo={'year'}
            onChange={newValue => {
              onYearChange(form, newValue, setProducedForYear, 'producedForYear')
            }}
            value={producedForYear}
            allowNullable
          />
        </Grid>
        <Grid item xs={6}>
          <BasicDatePicker
            name="producedForMonth"
            views={['year', 'month']}
            inputFormat={'MMMM'}
            label={translate('producedForMonth', { ns: 'global-document-rules' })}
            openTo={'month'}
            onChange={newValue => {
              onMonthChange(form, newValue, setProducedForMonth, 'producedForMonth')
            }}
            disabled={producedForYear == null}
            value={producedForMonth}
            allowNullable
          />
        </Grid>
      </Grid>
      <Box className="flex justify-end gap-4 pt-4">
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
      </Box>
    </AppForm>
  )
}

function onMonthChange(
  form: any,
  newValue: any,
  setProducedFromMonth: React.Dispatch<React.SetStateAction<string | Date | null>>,
  formParameterName: string,
) {
  form.setValue(formParameterName, newValue ? new Date(newValue || 0).getMonth() : null)
  setProducedFromMonth(newValue)
}

function onYearChange(form: any, newValue: any, setProducedForYear: React.Dispatch<any>, formParameterName: string) {
  form.setValue(formParameterName, newValue ? new Date(newValue || 0).getFullYear().toString() : null)
  setProducedForYear(newValue)
}
