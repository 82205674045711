import { Card, Grid, Paper, Typography, styled, useTheme } from '@mui/material'
import { Padding } from '../../styles/paddingComponent'
import React, { ReactElement, useMemo } from 'react'
import { padding } from 'src/styles/paddingCss'
import { GridCallbackDetails, GridColumns, GridEnrichedColDef, GridEventListener, GridRowParams } from '@mui/x-data-grid-pro'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { ArrowRightRounded } from '@mui/icons-material'
interface ITableDataCard {
    row: any,
    columns: GridColumns[] | GridEnrichedColDef<any, any, any>[],
    mobileViewCard?: (row: any) => ReactElement,
    getDetailPanelContent?: ((params: GridRowParams<any>) => React.ReactNode) | undefined,
    onRowClick: GridEventListener<'rowClick'> | undefined,
}

const DetailsPanel = ({ content }) => {
    return (
        <>
            {content}
        </>
    )
}

const TableDataCard: React.FC<ITableDataCard> = ({ row, columns, getDetailPanelContent, onRowClick, mobileViewCard }) => {
    const { palette } = useTheme()
    const { showDialog } = useDialogCtx()
    const action: any = useMemo(() => {
        if (columns) {
            return (columns as any[]).find((c: any) => ['actions'].includes(c.type))
        }
        return null
    }, [columns])

    const onSelect = () => {
        if (getDetailPanelContent)
            showDialog(DetailsPanel, {
                componentProps: {
                    content: getDetailPanelContent({ row: (row as GridRowParams<any>), id: 0, columns: columns as GridEnrichedColDef<any, any, any>[], getValue: () => { } })
                }
            })
    }

    return (
        <Padding.pb8>
            {
                mobileViewCard ? mobileViewCard(row) :
                    <Paper component={RowCard} onClick={(e) => {
                        if (onRowClick)
                            onRowClick({ row: (row as GridRowParams<any>), id: 0, columns: columns as GridEnrichedColDef<any, any, any>[], getValue: () => { } }, e, {} as GridCallbackDetails<any>)
                    }} >
                        <Grid container>
                            {
                                action != null &&
                                <GridRow item xs={12} justifyContent={'flex-end'} >
                                    {action.renderCell ? action.renderCell({ row: row }) : row[action.field]}
                                    {(columns as any[]).find((c: any) => ['detailPanelToggle'].includes(c.type)) &&
                                        <ArrowRightRounded onClick={() => { onSelect() }} />}
                                </GridRow>
                            }
                            <Grid container >
                                {
                                    columns.map(column => {
                                        return <React.Fragment key={column.field}>
                                            {
                                                !['actions', 'detailPanelToggle'].includes(column.type) &&
                                                <>
                                                    <GridRow item xs={6} >
                                                        <Typography fontWeight={600} variant='subtitle1' color={palette.blueGray[palette.mode]}>
                                                            {column.headerName}
                                                        </Typography>
                                                    </GridRow>
                                                    <GridRow item xs={6}>
                                                        <Typography fontWeight={'medium'} variant='subtitle1' >
                                                            {column.renderCell ? column.renderCell({ row: row }) :
                                                                column.valueGetter ? column.valueGetter({ row, value: row[column.field] }) : (row[column.field] ?? '-')}
                                                        </Typography>
                                                    </GridRow>
                                                </>
                                            }
                                        </React.Fragment>
                                    })
                                }
                            </Grid>
                        </Grid >
                    </Paper>
            }
        </Padding.pb8>
    )
}

export default TableDataCard

const RowCard = styled(Card)`
   ${padding.p16};
`

const GridRow = styled(Grid)`
    display: flex;
    flex-direction: row;
    ${padding.p8};
`