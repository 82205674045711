import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import AuditLogsTable from '../components/AuditLogsTable'

export const AuditLogsContainer = () => {
  const { t: translate } = useTranslation(['audit-logs', 'common'])

  return (
    <>
     
      
      <Box className="mb-2 bg-white shadow-md p-3" borderRadius={2}>
        <ScreenTitleBar
          title={translate('auditLogs', { ns: 'audit-logs' })}
        />
        <AuditLogsTable />
      </Box>
    </>
  )
}
