import { Box, Card, styled } from '@mui/material'
import { LatLngExpression } from 'leaflet'
import React, { useState } from 'react'
import { IMooringType, IPosition } from 'src/generated-types'
import { MapComponent } from 'src/screens/localities/components/map/MapComponent'
import { ReactComponent as MapIcon } from 'src/assets/icons/map.svg'
import { ReactComponent as TechnicalDrawingIcon } from 'src/assets/icons/technicalDrawing.svg'
import { Flex } from 'src/styles/flexComponent'
import ToggleGroupButtons from './ToggleGroupButtons'

interface IMapScgCardSwapper {
    positions: IPosition[]
    center: LatLngExpression
    type: IMooringType | undefined
    svg: string,
    height: string | number
}

const MapSvgCardSwapper: React.FC<IMapScgCardSwapper> = ({ positions, center, type, svg, height }) => {
    const [viewMap, setViewMap] = useState(true)
    return (
        <MapStyleInjectCard sx={{ height: height }} >
            {
                viewMap ?
                    <MapComponent
                        zoom={17}
                        positions={positions}
                        center={center}
                        mooringType={type}

                    /> :
                    <Box height={height} dangerouslySetInnerHTML={{ __html: svg || '' }} />
            }
            <Flex.Row justifyContent={'flex-end'} marginTop={'-259px'} paddingRight={'10px'} position={'relative'}>
                <Flex.Row position={'absolute'}>
                    <ToggleGroupButtons height='36px' style={{ zIndex: 900, width: '36px', padding: '3px' }}
                        actions={[{ title: '', value: true, Icon: MapIcon }, { title: '', value: false, Icon: TechnicalDrawingIcon }]}
                        defaultValue={true} value={viewMap} setViewMode={setViewMap} />

                </Flex.Row>
            </Flex.Row>
        </MapStyleInjectCard>
    )
}

const MapStyleInjectCard = styled(Card)`
    & .leaflet-touch .leaflet-control-layers-toggle{
        height: 36px;
        width: 33px;
    }
    & .leaflet-control-layers ,leaflet-control {
        right:75px;
    }
`

export default MapSvgCardSwapper