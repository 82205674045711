import React from 'react'
import { getCustomerUsers } from '../../../http'
import { permissionBinding, permissionLevel } from '../../../constant'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { FacilityTypes, RoleTypes } from '../../../enums'
import { ILocalityGroup } from '../../../generated-types'
import { invalidateAccessGroups, useGetLocalityGroupUsers } from '../../../api/access-group'
import { Box, Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LocalitiesInLocalityGroups from './LocalitiesInLocalityGroups'
import { AuthorizationUsersMapping } from '@lovoldsystem/authorization.client'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { IAuthUserInitialValues } from '@lovoldsystem/authorization.client/components/users/authorizationMapping.action'
import { FunctionElementsParentsIds } from '../../../enums/auth'

interface props {
  localityGroup: ILocalityGroup
}

const LocalityGroupDetailPanel: React.FC<props> = ({ localityGroup }) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation('access-control')
  const { data } = useGetLocalityGroupUsers(customer.id, localityGroup.id)
  const { showDialog, showConfirmDialog } = useDialogCtx()
  return (
    <Box className="px-4 py-2 mt-2 mb-2 shadow-md">
      <Stack spacing={2}>
        <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', p: 2, borderRadius: '5px' }}>
          <ScreenTitle title={t('localities', { ns: 'locality-group' })} />
          <LocalitiesInLocalityGroups localityGroupId={localityGroup.id} />
        </Paper>
        <AuthorizationUsersMapping
          invalidateUsers={() => {
            invalidateAccessGroups.getLocalityGroupUsers(customer.id, localityGroup.id)
          }}
          showDialog={showDialog}
          showConfirmDialog={showConfirmDialog}
          permissionBinding={permissionBinding}
          title={t('locality-group-users')}
          functionElementParents={[FunctionElementsParentsIds.LocalityPortal]}
          permissionLevel={permissionLevel}
          initialValues={{
            facilityId: localityGroup.id,
            facilityName: localityGroup.name,
            type: FacilityTypes.localityGroup,
            facilityTypeText: t('locality-group'),
          } as IAuthUserInitialValues}
          clientId={customer.id}
          roleTypes={[RoleTypes.Locality]}
          facilityUsers={data || []}
          useGetUsers={getCustomerUsers}
        />
      </Stack>
    </Box>
  )
}

export default LocalityGroupDetailPanel
