import React, { createContext, useState } from 'react'
import { IMainComponent } from '../generated-types/main-component'

interface MainComponentContextProps {
  selectedMainComponent: IMainComponent
  setSelectedMainComponent: (f: IMainComponent) => any
}

export const MainComponentCtx = createContext<MainComponentContextProps>(null!)

export const MainComponentContextProvider: React.FC = ({ children }) => {
  const [selectedMainComponent, setSelectedMainComponent] = useState<IMainComponent>(null!)

  const contextValue: MainComponentContextProps = {
    selectedMainComponent,
    setSelectedMainComponent,
  }

  return <MainComponentCtx.Provider value={contextValue}>{children}</MainComponentCtx.Provider>
}