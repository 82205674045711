import { useTranslation } from 'react-i18next'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { DeviationsContainer } from '../localities/deviations/DeviationsContainer'

const DeviationsPage = () => {
  const { t } = useTranslation(['deviation', 'common'])

  return (
    <CommonPageLayout
      titleSection={{ title: t('deviations', { ns: 'deviation' }) }}
    >
      <DeviationsContainer isAdminLayout={true} />
    </CommonPageLayout>
  )
}

export default DeviationsPage
