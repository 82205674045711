import React, { createContext, useEffect, useMemo, useState } from 'react'
import { IServiceStation } from '../generated-types/service-station'
import { useParams } from 'react-router-dom'
import { useGetServiceStationById } from '../api/service-station'
import { CenteredSpinner } from '../components/Spinner'
import { axios } from '../axios'

interface ServiceStationContextProps {
  selectedServiceStation: IServiceStation
  permission: number
}

export const CustomerServiceStationCtx = createContext<ServiceStationContextProps>(null!)

export const ServiceStationContextProvider: React.FC = ({ children }) => {
  const { customerId, serviceStationId } = useParams()
  const { data, isLoading: selectedServiceStationLoading } = useGetServiceStationById(customerId as EntityId, serviceStationId as EntityId)
  const [permission, setPermission] = useState<number>(-1)
  const selectedServiceStation = useMemo(() => data as IServiceStation, [data])

  useEffect(() => {
    if (data) {
      axios.defaults.headers['X-Permissions'] = data.encryptedPermission
      setPermission(data?.permission as number)
    }
  }, [data])

  const contextValue: ServiceStationContextProps = {
    selectedServiceStation,
    permission,
  }

  if (selectedServiceStationLoading) return <CenteredSpinner />

  return <CustomerServiceStationCtx.Provider value={contextValue}>{permission != -1 && children}</CustomerServiceStationCtx.Provider>
}
