import React from 'react'
import { CenteredSpinner } from '../components/Spinner'

interface Props {
  loading: boolean
  showSpinner?: boolean
}
export const LoadingGuard: React.FC<Props> = ({ loading, showSpinner, children }) => {
  if (loading) {
    return showSpinner ? <CenteredSpinner /> : null
  }

  return <>{children}</>
}
