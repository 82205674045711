import { useFormContext } from 'react-hook-form'
import { useGetAdditionalEquipments } from '../../../api/main-component'
import { ISelectMainComponentStepProps, GenericMainComponentSelectStep } from './SelectMainComponentStep'

export const SelectEquipmentStep: React.FC<ISelectMainComponentStepProps> = ({
  customerId,
  setDisabled,
  handleNext,
}) => {
  const { getValues } = useFormContext()

  const { data: equipments, isLoading } = useGetAdditionalEquipments(
    customerId as EntityId,
    getValues('locality')?.id as EntityId,
  )

  return (
    <GenericMainComponentSelectStep
      mainComponents={equipments}
      isLoading={isLoading}
      setDisabled={setDisabled}
      handleNext={handleNext}
    />
  )
}
