import { queryClient, useGet } from '@lovoldsystem/core.client'
import { IDocumentCategoryRequestDto, IDocumentCategoryInfoDto } from '../../generated-types'
import { DocumentCategoryKeys } from '../../query-keys'


const baseUrl = (customerId) => `customers/${customerId}/documents`

export const documentCategoryEndpoints = {
  getDocumentCategoriesWithDocuments(customerId) {
    return `${baseUrl(customerId)}/category/getAll`
  }
}

export const invalidateDocumentCategory = {
  getDocumentCategoriesWithDocuments(customerId?: EntityId, queryParams?: IDocumentCategoryRequestDto) {
    queryClient.invalidateQueries(DocumentCategoryKeys.GET_DOCUMENT_CATEGORIES_FOR_DOCUMENTS(customerId, queryParams))
  }
}

export const useGetDocumentCategoriesWithDocuments = (customerId: EntityId, queryParams: IDocumentCategoryRequestDto) =>
  useGet<Array<IDocumentCategoryInfoDto>>(documentCategoryEndpoints.getDocumentCategoriesWithDocuments(customerId), queryParams, {
    queryKey: DocumentCategoryKeys.GET_DOCUMENT_CATEGORIES_FOR_DOCUMENTS(customerId, queryParams),
  })
