import { useTranslation } from 'react-i18next'
import GenericCard from '../../../../components/GenericCard'
import MaintenanceIcon from '../../../../assets/svg/maintenanceIcon.svg'
import { Box, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { muiTheme } from '../../../../mui-theme'
import { MaintenanceType } from '../../../../enums'
import ServiceCount from '../../../../assets/svg/serviceCount.svg'
import DeviationCount from '../../../../assets/svg/deviationCount.svg'
import TaskCount from '../../../../assets/svg/taskCount.svg'
import WorkAssignmentCount from '../../../../assets/svg/workOrderCount.svg'
import { useParams } from 'react-router-dom'
import { IMaintenanceTypeCount } from '../../../../generated-types/IMaintenanceTypesCount'
import { MaintenanceToggleButton } from '../../../../components/locality-dashboard/MaintenanceToggleButton'
import { useState } from 'react'
import { LocalityMaintenanceGrid } from '../../../../components/locality-dashboard/LocalityMaintenanceGrid'
import { Flex } from 'src/styles/flexComponent'
import { CheckCircle } from '@mui/icons-material'
import { useGetMaintenanceTypesCount } from 'src/api/maintenance'

export const mapMaintenanceTypeToIcon = (maintenanceType: MaintenanceType) => {
  switch (maintenanceType) {
    case MaintenanceType.Deviation:
      return DeviationCount
    case MaintenanceType.Service:
      return ServiceCount
    case MaintenanceType.Task:
      return TaskCount
    case MaintenanceType.WorkAssignment:
      return WorkAssignmentCount
  }
}

export const mapMaintenanceTypeToColor = (maintenanceType: MaintenanceType) => {
  switch (maintenanceType) {
    case MaintenanceType.Deviation:
      return muiTheme.palette.pink.main
    case MaintenanceType.Service:
      return muiTheme.palette.cerulean.main
    case MaintenanceType.Task:
      return muiTheme.palette.cerulean.main
    case MaintenanceType.WorkAssignment:
      return muiTheme.palette.cerulean.main
  }
}

interface IMaintenanceTypeCountBox {
  count?: number
  type: MaintenanceType
  name: string
  selectedMaintenanceType: MaintenanceType | undefined
  setMaintenanceType: React.Dispatch<React.SetStateAction<MaintenanceType | undefined>>
}

const MaintenanceTypeCount = ({
  count,
  type,
  name,
  selectedMaintenanceType: selectedMaintenanceType,
  setMaintenanceType,
}: IMaintenanceTypeCountBox) => {
  const theme = useTheme()
  const isMobile = useMediaQuery('(max-width: 450px)')
  const handleClick = (type: MaintenanceType) => {
    if (type === selectedMaintenanceType) {
      setMaintenanceType(undefined)
      return
    }
    setMaintenanceType(type)
  }

  return (
    <Flex.Column
      bgcolor={
        type == selectedMaintenanceType
          ? theme.palette.primaryMain[theme.palette.mode]
          : theme.palette.secondaryLight[theme.palette.mode]
      }
      onClick={() => handleClick(type)}
      minWidth={isMobile ? '75px' : '85px'}
      borderRadius={'4px'}
      className="pb-4-core"
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
      maxHeight="60px"
    >
      <Flex.Row position={'relative'} className="p-4-core" height={0}>
        {type == selectedMaintenanceType && (
          <CheckCircle sx={{ color: theme.palette.white[theme.palette.mode], width: '15px', height: '15px' }} />
        )}
      </Flex.Row>
      <Typography
        textAlign={'center'}
        variant="subtitle1"
        fontWeight={600}
        color={
          type == selectedMaintenanceType
            ? theme.palette.white[theme.palette.mode]
            : theme.palette.black[theme.palette.mode]
        }
      >
        {(count ?? 0)?.toString().padStart(3, '0')}
      </Typography>
      <Typography
        textAlign={'center'}
        variant="body2"
        fontWeight={400}
        color={
          type == selectedMaintenanceType
            ? theme.palette.white[theme.palette.mode]
            : theme.palette.secondaryDark[theme.palette.mode]
        }
      >
        {name}
      </Typography>
    </Flex.Column>
  )
}

const calculateAllMaintenanceCount = (maintenanceCount: IMaintenanceTypeCount) => {
  const total =
    (maintenanceCount?.taskCount ?? 0) +
    (maintenanceCount?.deviationsCount ?? 0) +
    (maintenanceCount?.servicesCount ?? 0) +
    (maintenanceCount?.workOrdersCount ?? 0)
  return total
}

export const LocalityMaintenanceCard = () => {
  const theme = useTheme()
  const { t: translate } = useTranslation(['common', 'maintenance'])
  const [maintenanceForWeek, setMaintenanceForWeek] = useState(true)
  const [maintenanceType, setMaintenanceType] = useState<MaintenanceType | undefined>()
  const { customerId, localityId } = useParams()
  const isLargeLayout = useMediaQuery('(max-width: 1550px)')
  const isMobile = useMediaQuery(theme.breakpoints.up('xs'))

  const { data: maintenanceTypesCount, isLoading: maintenanceCountLoading } = useGetMaintenanceTypesCount(
    customerId as EntityId,
    localityId as EntityId,
    { maintenanceForWeek: maintenanceForWeek },
  )

  const renderSelectionContainer = () => {
    return (
      <Stack width={'100%'} direction={'row'} spacing={1}>
        <MaintenanceTypeCount
          type={MaintenanceType.Service}
          name={translate('services', { ns: 'common' })}
          count={maintenanceTypesCount?.servicesCount}
          setMaintenanceType={setMaintenanceType}
          selectedMaintenanceType={maintenanceType}
        />
        <MaintenanceTypeCount
          type={MaintenanceType.Deviation}
          count={maintenanceTypesCount?.deviationsCount}
          name={translate('deviations', { ns: 'common' })}
          setMaintenanceType={setMaintenanceType}
          selectedMaintenanceType={maintenanceType}
        />
        <MaintenanceTypeCount
          type={MaintenanceType.Task}
          count={maintenanceTypesCount?.taskCount}
          name={translate('tasks', { ns: 'common' })}
          setMaintenanceType={setMaintenanceType}
          selectedMaintenanceType={maintenanceType}
        />
        <MaintenanceTypeCount
          type={MaintenanceType.WorkAssignment}
          count={maintenanceTypesCount?.workOrdersCount}
          name={translate('assignment', { ns: 'common' })}
          setMaintenanceType={setMaintenanceType}
          selectedMaintenanceType={maintenanceType}
        />
      </Stack>
    )
  }

  return (
    <StyledCard
      actions={
        <Stack width={'100%'} direction={'row'} spacing={2} className="pt-8-core">
          {!isLargeLayout && renderSelectionContainer()}
          <MaintenanceToggleButton state={maintenanceForWeek} setState={setMaintenanceForWeek} />
        </Stack>
      }
      headerIcon={
        <Box>
          <img src={MaintenanceIcon} />
        </Box>
      }
      maxHeight={'508px'}
      isLoading={maintenanceCountLoading}
    >
      {isLargeLayout && (
        <Grid container>
          <Grid
            item
            lg={9}
            sm={9}
            xs={12}
            display={'flex'}
            justifyContent={'left'}
            width={'100%'}
            paddingBottom={isMobile ? 2 : 0}
          >
            {renderSelectionContainer()}
          </Grid>
        </Grid>
      )}
      <Box>
        <LocalityMaintenanceGrid
          customerId={customerId}
          localityId={localityId}
          maintenanceType={maintenanceType}
          maintenanceForWeek={maintenanceForWeek}
          totalCount={calculateAllMaintenanceCount(maintenanceTypesCount as IMaintenanceTypeCount)}
        />
      </Box>
    </StyledCard>
  )
}

//styled component
const StyledCard = styled(GenericCard)({
  '& .MuiCardHeader-action': {
    flex: 1,
  },
  '& .MuiCardHeader-content': {
    flex: 'none',
  },
  '& .generic-card-actions': {
    paddingLeft: '0px',
  },
})
