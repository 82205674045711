import { ICustomProperty } from '../generated-types/customProperty'
import { queryClient } from '../query-client'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const adminBaseUrl = () => 'admin/custom-property'
const customerBaseUrl = (customerId: EntityId) => `customers/${customerId}/custom-property`

export const customPropertyEndpoints = {
  customPropertiesByAdmin() {
    return `${adminBaseUrl()}`
  },
  customPropertiesByCustomer(customerId: EntityId) {
    return `${customerBaseUrl(customerId)}`
  },
  allCustomPropertiesByCustomer(customerId: EntityId) {
    return `${customerBaseUrl(customerId)}/all`
  },
  customPropertyByAdmin(id: EntityId) {
    return `${adminBaseUrl()}/${id}`
  },
  customPropertyByCustomer(customerId: EntityId, id: EntityId) {
    return `${customerBaseUrl(customerId)}/${id}`
  },
}

export const invalidateCustomPropertyCache = {
  useGetCustomPropertiesCustomer(customerId: EntityId) {
    queryClient.invalidateQueries(customPropertyEndpoints.customPropertiesByCustomer(customerId))
    queryClient.invalidateQueries(customPropertyEndpoints.allCustomPropertiesByCustomer(customerId))
  },
  useGetCustomPropertiesAdmin() {
    queryClient.invalidateQueries(customPropertyEndpoints.customPropertiesByAdmin())
  },
  useGetCustomPropertyCustomer(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(customPropertyEndpoints.customPropertyByCustomer(customerId, id))
  },
  useGetCustomPropertyAdmin(id: EntityId) {
    queryClient.invalidateQueries(customPropertyEndpoints.customPropertyByAdmin(id))
  },
}

export const useGetCustomPropertiesByCustomer = (customerId: EntityId) =>
  useGet<{ items: Array<ICustomProperty> }>(customPropertyEndpoints.customPropertiesByCustomer(customerId))

export const useGetAdminAndCustomerCustomProperties = (customerId: EntityId) =>
  useGet<{ items: Array<ICustomProperty> }>(customPropertyEndpoints.allCustomPropertiesByCustomer(customerId))

export const useGetCustomPropertiesByAdmin = () =>
  useGet<{ items: Array<ICustomProperty> }>(customPropertyEndpoints.customPropertiesByAdmin())

export const useGetCustomPropertyByCustomer = (customerId: EntityId, id: EntityId) =>
  useGet<ICustomProperty>(customPropertyEndpoints.customPropertyByCustomer(customerId, id))

export const useGetCustomPropertyByAdmin = (id: EntityId) =>
  useGet<ICustomProperty>(customPropertyEndpoints.customPropertyByAdmin(id))

export const useAddCustomPropertyByAdmin = () =>
  usePost<ICustomProperty, ICustomProperty>(customPropertyEndpoints.customPropertiesByAdmin())

export const useAddCustomPropertyByCustomer = (customerId: EntityId) =>
  usePost<ICustomProperty, ICustomProperty>(customPropertyEndpoints.customPropertiesByCustomer(customerId))

export const useUpdateCustomPropertyByAdmin = (id: EntityId) =>
  usePut<ICustomProperty, ICustomProperty>(customPropertyEndpoints.customPropertyByAdmin(id))

export const useUpdateCustomPropertyByCustomer = (customerId: EntityId, id: EntityId) =>
  usePut<ICustomProperty, ICustomProperty>(customPropertyEndpoints.customPropertyByCustomer(customerId, id))

export const useDeleteCustomPropertyByAdmin = () =>
  useDelete<ICustomProperty>(customPropertyEndpoints.customPropertiesByAdmin())

export const useDeleteCustomPropertyByCustomer = (customerId: EntityId) =>
  useDelete<ICustomProperty>(customPropertyEndpoints.customPropertiesByCustomer(customerId), { invalidate: true })
