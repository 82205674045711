import React from 'react'
import { useUserCtx } from '../hooks/context-hooks'
import { UnAuthorizationPage } from '../components/common/UnAuthorizationPage'
import { permissionValue } from '../enums/permissionValue'
import { Spinner } from '../components/Spinner'

interface Props {
  isNavigateToUnAuthorized?: boolean
}

export const UserRoleGuard: React.FC<Props> = ({ isNavigateToUnAuthorized = false, children }) => {
  const { user, isLoadingUser } = useUserCtx()
  if (isLoadingUser) return <Spinner size={20} />

  if (!(user.permission > 0) || ((BigInt(user.permission) & BigInt(permissionValue.SystemAdminPermission)) != BigInt(permissionValue.SystemAdminPermission))) {
    if (isNavigateToUnAuthorized) {
      return <UnAuthorizationPage />
    }
    return null
  }

  return <>{children}</>
}
