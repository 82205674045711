import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CustomPropertyTable } from './CustomPropertyTable'
import { CreateCustomPropertyDialog } from './CustomPropertyAddDialog'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const CustomPropertyContainer = ({ isSuperAdmin = false }) => {
  const { t: translate } = useTranslation(['custom-property', 'common', 'admin-sidebar'])
  const { id: customerId } = useParams()
  const theme = useTheme()
  const {showDialog} = useDialogCtx()

  const onAddCustomProperty = async () => {
    const added = await showDialog(CreateCustomPropertyDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        isAdmin: isSuperAdmin,
      },
    }, undefined, true)
    if (!added) return
  }

  return (
    <CommonPageLayout
      titleSection={{ title: translate('custom-properties', { ns: 'admin-sidebar' }) }}
      topAction={[
        {
          buttonText: translate('custom-property', { ns: 'page-container' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddCustomProperty,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <CustomPropertyTable customerId={customerId as EntityId} isSuperAdmin={isSuperAdmin} />
      </Box>
    </CommonPageLayout>
  )
}
