import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import { CenteredSpinner } from '../../../components/Spinner'
import { useEffect, useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  invalidateDocumentCategoryCache,
  documentCategoryEndpoints,
  useGetDocumentCategory,
  useUpdateDocumentCategory,
  useDeleteDocumentCategory
} from '../../../api/document-category'
import { DocumentCategoryForm } from './DocumentCategoryForm'
import { DataGridWithServerPagination } from '../../../components/DataGridWithServerPagination'
import { toast } from 'react-toastify'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { IDocumentCategory } from '../../../generated-types'
import { ShowDialogProps } from 'src/show-dialog'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface UpdateDocumentCategoryProps extends ShowDialogProps {
  id: EntityId
}

const UpdateDocumentCategoryDialog: React.FC<UpdateDocumentCategoryProps> = ({ pop, id }) => {
  const { t: translate } = useTranslation(['documentcategory', 'common'])
  const mutation = useUpdateDocumentCategory(id as EntityId)

  useEffect(() => {
    if (mutation.error != null) {
        toast(translate('CannotUpdateDocumentCategory', { ns: 'document-category' }), {type:'warning'})
    }
  }, [mutation.error])

  const onSubmit = async form => {
    await mutation.mutateAsync(form)
    invalidateDocumentCategoryCache.useGetDocumentCategories()
    pop(true)
  }

  const { data, isLoading } = useGetDocumentCategory(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <GenericDialogLayout title={translate('updateDocumentCategory', { ns: 'document-category' })} pop={pop}>
    <DocumentCategoryForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data as IDocumentCategory} />
  </GenericDialogLayout>
  )
}

export const DocumentCategoryTable: React.FC = () => {
  const {showDialog, showConfirmDialog} = useDialogCtx()
  const { t: translate } = useTranslation(['documentcategory', 'common'])
  const apiRef = useGridApiRef()

  const STATIC_COLUMNS: GridColumns = [
    { field: 'categoryName', headerName: translate('categoryName', { ns: 'document-category' }), flex: 1, cellClassName: 'font-weight-semi-bold' },
  ]

  const { mutate, isLoading: isDeleting, error } = useDeleteDocumentCategory()

  useEffect(() => {
    if (error && error['data'] == 'CannotDeleteDocumentCategory') {
        toast(translate('CannotDeleteDocumentCategory', { ns: 'document-category' }), {type:'error'})
    }
    if (error && error['data'] == 'CannotDeleteSystemGeneratedDocumentCategory') {
      toast(translate('cannotDeleteSystemGeneratedDocumentCategory', { ns: 'document-category' }), {type:'error'})
    }
  }, [error])

  const onDeleteDocumentCategory = (id: EntityId) => {
    showConfirmDialog(
      translate('deleteDocumentCategory', { ns: 'document-category' }),
      translate('areYouSureDeleteDocumentCategory', { ns: 'document-category' }),
    ).then(e => {
      if (e) {
        mutate(id)
        apiRef.current.updateRows([{ id, _action: 'delete' }])
      }
    })
  }

  const onUpdate = async id => {
    const added = await showDialog(
      UpdateDocumentCategoryDialog,
      {
        componentProps: { id: id as EntityId },
      },
      undefined,
      true,
    )
    if (!added) return

    invalidateDocumentCategoryCache.useGetDocumentCategories()
    invalidateDocumentCategoryCache.getDocumentCategory(id)
  }

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteDocumentCategory(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [],
  )

  return (
    <DataGridWithServerPagination
      queryUrl={documentCategoryEndpoints.getDocumentCategories()}
      onCellClick={cell => console.log(cell)}
      columns={columns}
    />
  )
}
