import { Box } from '@mui/material'
import { Spinner } from '../../../components/Spinner'
import { LocalityGroupForm } from '../../../forms/LocalityGroupFrom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import {
  invalidateLocalityGroupsQueries,
  useGetLocalityGroup,
  useUpdateLocalityGroup,
} from '../../../api/locality-groups'

export const UpdateLocalityGroup = ({ pop, id, customerId }) => {
  const { t } = useTranslation(['common-api-message'])

  const { data, isLoading } = useGetLocalityGroup(customerId, id!)
  const { mutateAsync } = useUpdateLocalityGroup(customerId, id!)

  const onSubmit = useCallback(
    async form => {
      await mutateAsync(form).then(
        async () => {
          toast(t('successfullyUpdated'), { type: 'success' })
          invalidateLocalityGroupsQueries.invalidateGetLocalityGroups(customerId)
          invalidateLocalityGroupsQueries.getLocalities(customerId, id!)
          pop()
        },
        error => {
          toast.error(error.response.data ?? error.response.data.message)
        },
      )
    },
    [customerId, id],
  )

  return (
    <Box >
      {isLoading ? (
        <Spinner />
      ) : (
        <LocalityGroupForm onSubmit={onSubmit} initialValues={data} customerId={customerId} />
      )}
    </Box>
  )
}

export default UpdateLocalityGroup
