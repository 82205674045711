import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import { Fragment, useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { IMainComponentType as IComponentType } from '../../enums'
import { Grid } from '@mui/material'
import GenericTable from 'src/components/table/GenericTable'

interface IMainComponentTypeTable {
  baseQuery: string
  onDelete: (id: EntityId) => void
  isDeleting: boolean
  onUpdate: (id: EntityId) => void
  isAdmin?: boolean
}
export const MainComponentTypeTable: React.FC<IMainComponentTypeTable> = ({
  baseQuery,
  onUpdate,
  onDelete,
  isDeleting,
  isAdmin,
}) => {
  const { t: translate } = useTranslation(['main-component-type', 'common'])

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'name',
      headerName: translate('name'),
      flex: 1,

      renderCell: row => {
        const labelNames = JSON.parse(row.value)
        return (
          <Grid container>
            {Object.keys(labelNames)
              .sort((a, b) => a.localeCompare(b))
              .map((key, index) => (
                <Fragment key={index}>
                  <Grid item xs={2}>
                    {key}
                  </Grid>
                  <Grid item xs={10}>
                    {labelNames[key]}
                  </Grid>
                </Fragment>
              ))}
          </Grid>
        )
      },
    },
    {
      field: 'maxCountForCage',
      headerName: translate('maxCountForCage'),
      flex: 1,
      renderCell: row => {
        return <div>{row.value}</div>
      },
    },
    {
      field: 'componentType',
      headerName: translate('componentType'),
      flex: 1,
      renderCell: row => {
        return <div>{IComponentType[row.value]}</div>
      },
    },
  ]
  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: '',
        width:60,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            disabled={(isAdmin ? row.customerId != null : row.customerId == null) || isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(id)}
            disabled={(isAdmin ? row.customerId != null : row.customerId == null) || isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [isAdmin],
  )

  return (
    <GenericTable
      id="main-component-type-table"
      queryUrl={baseQuery}
      columns={columns}
      autoHeight={false}
      height={'81vh'}
      enableServerPagination
    />
  )
}
