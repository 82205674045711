import React, { useCallback, useMemo } from 'react'
import GenericTable from '../../../components/table/GenericTable'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { invalidateGlobalDocument, useDeleteVersion, useGetGlobalDocumentVersions } from '../../../api/global-documents'
import { CenteredSpinner } from '../../../components/Spinner'
import { staticGlobalDocumentVersionColumns } from './constants'
import { GridColumnHeaderParams, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { MdDelete } from 'react-icons/md'
import { AlertDialog } from '../../../show-dialog'
import { toast } from 'react-toastify'
import { CANNOT_DELETE } from '../../../constant'
import { invalidateDocumentCategoryCache } from '../../../api/document-category'
import { invalidateDocument } from '../../../api/document'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const GlobalDocumentVersionTable: React.FC<{ globalDocumentId: number; customerId?: EntityId }> = ({
  globalDocumentId,
  customerId,
}) => {
  const { data: globalDocsVersions, isLoading: globalDocsVersionsLoading } =
    useGetGlobalDocumentVersions(globalDocumentId)

  const { mutateAsync } = useDeleteVersion(globalDocumentId)

  const { showDialog, openFilePreviewDialog } = useDialogCtx()

  const handleDeleteVersion = useCallback(
    async (id: EntityId) => {
      showDialog(AlertDialog, {
        componentProps: {
          title: t('deleteVersion', { ns: 'global-documents' }),
          text: t('areYouSureVersion', { ns: 'global-documents' }),
          onConfirm: async pop => {
            await mutateAsync(id).then(
              () => {
                toast(t('deletedSuccessfully', { ns: 'common' }), { type: 'success' })
                pop(true)
                invalidateGlobalDocument.getDocuments()
                invalidateGlobalDocument.getVersions(globalDocumentId)
                customerId && invalidateDocumentCategoryCache.getDocumentCategoriesWithDocumentCountByKey()
                customerId && invalidateDocument.getDocuments(customerId)
              },
              error => {
                if (error.data == CANNOT_DELETE) {
                  toast(t('cannotDeleteVersion', { ns: 'global-documents' }), { type: 'error' })
                } else {
                  toast(t('deletingError', { ns: 'common' }), { type: 'error' })
                }
                pop(false)
              },
            )
          },
        },
      })
    },
    [t],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...staticGlobalDocumentVersionColumns,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: 'Actions',
        flex: 0.2,
        renderCell: ({ row }) => [
          <Tooltip title={`${t('delete', { ns: 'common' })}`} key={'delete'}>
            <IconButton onClick={() => handleDeleteVersion(row.id)}>
              <MdDelete size={24} />
            </IconButton>
          </Tooltip>,
        ],
      },
    ],
    [t],
  )

  if (globalDocsVersionsLoading) {
    return <CenteredSpinner />
  }

  return (
    <Stack p={2} bgcolor={'#e5e4e2'}>
      <GenericTable
        id="global-document-table"
        data={globalDocsVersions || []}
        columns={columns.map(col => ({
          ...col,
          renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
        }))}
        pageSize={10}
        rowColor="#e5e4e2"
        getDetailPanelHeight={() => 'auto'}
        onRowClick={({ row }) => {
          openFilePreviewDialog(row.fileUrl)
        }}
      />
    </Stack>
  )
}
