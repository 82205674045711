import { Grid } from '@mui/material'
import { FormSelectField, FormTextField } from '../../../components/FormCore'
import { ControllerTypes, DataTypes } from '../../../enums'
import i18n from 'i18next'
import { ICustomProperty } from '../../../generated-types/customProperty'

export const CustomMainComponentFields: React.FC<{ customFields: Array<ICustomProperty> }> = ({ customFields }) => {
  return (
    <Grid container pt={1} className="!flex !flex-wrap justify-between">
      {customFields?.map((field, index) => {
        const label = i18n.language == 'en' ? JSON.parse(field.label as string).en : JSON.parse(field.label as string).no
        const name = JSON.parse(field.label as string).en.toLowerCase()
        return (
          <Grid item xs={12} md={6} lg={6} key={index}>
            {(field.controllerType === ControllerTypes.TextBox ||
              field.controllerType === ControllerTypes.NumberTextBox) && (
              <FormTextField name={name} label={label} type={field.dataType == DataTypes.Integer ? 'number' : 'text'} />
            )}
            {field.controllerType === ControllerTypes.Dropdown && (
              <FormSelectField data={JSON.parse(field.dropdownOptions)} name={name} label={label} />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}
