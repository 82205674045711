import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MainButton } from '../../../components/MainButton'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { CreateDistributorDialog } from './CreateDistributorDialog'
import { DistributorTable } from './DistributorTable'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const DistributorContainer = () => {
  const { t: translate } = useTranslation(['distributor', 'common'])
  const { showDialog } = useDialogCtx()

  const onAddDistributor = async () => {
    const added = await showDialog(CreateDistributorDialog, undefined)
    if (!added) return
  }

  return (
    <>
      <Box className="flex justify-between mb-12">
        <ScreenTitle title={translate('distributors', { ns: 'distributor' })} />
        <MainButton onClick={() => onAddDistributor()}>{translate('add', { ns: 'common' })}</MainButton>
      </Box>
      <DistributorTable />
    </>
  )
}
