import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z, ZodSchema } from 'zod'

export const customZodErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    return { message: 'required' }
  }

  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.received === 'undefined') return { message: 'required' }

    if (issue.received === 'null') return { message: 'required' }
  }

  return { message: ctx.defaultError }
}

export const zodHelpers = {
  numberOrString: z
    .union([z.string(), z.number()])
    .transform(x => parseInt(x as string))
    .optional(),
}

const zodResolverOpts = { errorMap: customZodErrorMap }
export function useZodForm<T extends ZodSchema<any, any>>(
  schema: T,
  formOpts?: UseFormProps,
): UseFormReturn<z.infer<typeof schema>> {
  const form = useForm({
    resolver: zodResolver(schema, zodResolverOpts),
    ...formOpts,
  })

  return form
}
