import { ICustomer } from "./customer"
import { IDeviation } from "./deviation"
import { ILocalityFrequency } from "./locality-frequency"
import { IUser } from "./user"
export interface IFormSchemaGeneric {
    name: string
    description: string
    hasVideoUpload: boolean
    isActive: boolean
}

export interface IFormSchema extends IFormSchemaGeneric {
    id?: number
    customer?: ICustomer
    customerId: number
    versions: IFormSchemaVersion[]
}

export interface IFormSchemaDto extends IFormSchemaGeneric {
    id?: number
    formSchemaVersion?: IFormSchemaVersion
    formSchemaReports?: IFormSchemaReport[]
}

export interface IFormSchemaVersion {
    id?: number
    layout: string
    version: number
    formSchemaId?: number
    formSchema?: IFormSchema
    reports?: IFormSchemaReport[]
}

export interface IFormSchemaReport {
    id?: number
    formSchemaVersionId?: number
    formSchemaVersion?: IFormSchemaVersion
    values: string
    type: formSchemaReportTypes
    deviationId?: string
    localityFrequencyId?: number
    groupId?: string
    mainComponentId?: string
    deviation?: IDeviation
    service?: ILocalityFrequency
    createdByUser?: IUser
    createdAt?: string
    createdByUserId?: string
    scheduledTaskId?: number
}

export enum formSchemaReportTypes {
    Document = 1,
    Deviation = 2,
    Task = 3,
    Service = 4,
    MainComponent = 5
}