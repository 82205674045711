import { IClientUserLinkDto } from '@lovoldsystem/authorization.client'
import { queryClient, useGet } from '@lovoldsystem/core.client'
import { accessGroupQueryKey } from '../query-keys'
import { IFacility } from '../generated-types/facility'
import { IFacilityRequestDto } from './facility-request-dto'
import { FacilityTypes } from '../enums'

const baseUrl = (customerId: EntityId) => `auth/${customerId}`

export const endpoints = {
  getLocalities(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/locality/${localityId}`
  },
  getLocalityGroupUsers(customerId: EntityId, localityGroupId: EntityId) {
    return `${baseUrl(customerId)}/localityGroup/${localityGroupId}/users`
  },
  getServiceStationGroups(customerId: EntityId, serviceStationGroupId: EntityId) {
    return `${baseUrl(customerId)}/service-station-group/${serviceStationGroupId}`
  },
  getServiceStation(customerId: EntityId, serviceStationId: EntityId) {
    return `${baseUrl(customerId)}/service-station/${serviceStationId}`
  },
  getFacilities(customerId: EntityId) {
    return `${baseUrl(customerId)}/facilities`
  },
}

export const invalidateAccessGroups = {
  getAccessGroup(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(endpoints.getLocalities(customerId, id))
  },
  getLocalityGroupUsers(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(endpoints.getLocalityGroupUsers(customerId, id))
  },
  getLocalityUsers(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(accessGroupQueryKey.GET_LOCALITY_USERS(customerId, id))
  },
  getServiceStationGroupUsers(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(accessGroupQueryKey.GET_SERVICE_STATION_GROUP_USERS(customerId, id))
  },
  getServiceStationUsers(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(accessGroupQueryKey.GET_SERVICE_STATION_USERS(customerId, id))
  },
  getFacilities(queryParams?: IFacilityRequestDto) {
    queryClient.invalidateQueries(accessGroupQueryKey.GET_FACILITIES(queryParams))
  },
}

export const useGetAccessGroup = (customerId: EntityId, id: EntityId) =>
  useGet<IClientUserLinkDto[]>(endpoints.getLocalities(customerId, id))

export const useGetLocalityGroupUsers = (customerId: EntityId, id: EntityId) =>
  useGet<IClientUserLinkDto[]>(endpoints.getLocalityGroupUsers(customerId, id))

export const useGetAccessGroupForLocality = (customerId: EntityId, id: EntityId) =>
  useGet<any[]>(endpoints.getLocalities(customerId, id), undefined, {
    queryKey: accessGroupQueryKey.GET_LOCALITY_USERS(customerId, id),
  })

export const useGetAccessGroupForServiceStationGroup = (customerId: EntityId, id: EntityId) =>
  useGet<any[]>(endpoints.getServiceStationGroups(customerId, id), undefined, {
    queryKey: accessGroupQueryKey.GET_SERVICE_STATION_GROUP_USERS(customerId, id),
  })

export const useGetAccessGroupForServiceStation = (customerId: EntityId, id: EntityId) =>
  useGet<any[]>(endpoints.getServiceStation(customerId, id), undefined, {
    queryKey: accessGroupQueryKey.GET_SERVICE_STATION_USERS(customerId, id),
  })

export const useGetFacilities = (queryParams: IFacilityRequestDto) =>
  useGet<IFacility[]>(endpoints.getFacilities(queryParams.clientId), queryParams, {
    queryKey: accessGroupQueryKey.GET_FACILITIES(queryParams),
  })

export const useLocalities = ({ clientId }: { clientId: EntityId }) =>
  useGetFacilities({ clientId: clientId, facilityType: FacilityTypes.locality, isWithUserMappings: false  })

export const useLocalityGroups = ({ clientId }: { clientId: EntityId }) =>
  useGetFacilities({ clientId: clientId, facilityType: FacilityTypes.localityGroup, isWithUserMappings: false  })

export const useServiceStations = ({ clientId }: { clientId: EntityId }) =>
  useGetFacilities({ clientId: clientId, facilityType: FacilityTypes.serviceStation, isWithUserMappings: false  })

export const useServiceStationGroups = ({ clientId }: { clientId: EntityId }) =>
  useGetFacilities({ clientId: clientId, facilityType: FacilityTypes.serviceStationGroup, isWithUserMappings: false  })
