import { APIResultCount } from '../../../constant'
import { useGetAdminSuppliers } from '../../../api/supplier'
import { GlobalDocumentContainer } from './GlobalDocumentContainer'
import { CenteredSpinner } from '../../../components/Spinner'

export const GlobalDocumentAdminContainer: React.FC = () => {
  const { data: adminSuppliers, isLoading: adminSuppliersLoading } = useGetAdminSuppliers(0, APIResultCount.Max)

  if (adminSuppliersLoading) {
    return <CenteredSpinner />
  }
  return <GlobalDocumentContainer adminSuppliers={adminSuppliers} isSuperAdmin={true} />
}
