import { Button, ButtonProps } from '@mui/material'
import React from 'react'
import { Spinner } from './Spinner'

export interface MainButtonProps extends ButtonProps {
  loading?: boolean
}

export const MainButton: React.FC<MainButtonProps> = ({ children, variant, loading, disabled, ...rest }) => {
  return (
    <Button
      variant={variant || 'contained'}
      disabled={disabled || loading}
      sx={{ minWidth: '120px', minHeight: '44px', textTransform: 'none' }}
      {...rest}
    >
      {loading && <Spinner size={20} />}
      {children}
    </Button>
  )
}
