import React, { useState } from 'react'
import { useGetLocalityGroups } from '../../../api/locality-groups'
import { SelectField } from '../../../components/SelectField'
import { ILocalityGroup } from '../../../generated-types'
import { MainButton } from '@lovoldsystem/ui'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import { invalidateLocalityCache, useAssignLocalityGroups } from '../../../api/localities'

const AssignLocalityGroup = ({ customerId, localityId, pop }) => {
    const { data } = useGetLocalityGroups(customerId)
    const { t } = useTranslation('common')
    const [localityGroups, setLocalityGroups] = useState<ILocalityGroup[]>([])
    const { mutateAsync } = useAssignLocalityGroups(customerId, localityId)
    const assignLocalityGroups = async () => {
        await mutateAsync(localityGroups.map(l => l.id))
        invalidateLocalityCache.getLocalityGroups(customerId, localityId)
        pop()
    }
    return (
        <div>
            <SelectField onChange={(event, value) => {
                setLocalityGroups(value as ILocalityGroup[])
            }} multiple data={data?.items || []}
                getOptionLabel={params => params.name} />
            <Box display='flex' flexDirection='row' justifyContent='flex-end' className='pt-2'>
                <MainButton color='primary' onClick={async () => {
                    await assignLocalityGroups()
                }}>
                    {t('assign')}
                </MainButton>
            </Box>
        </div>
    )
}

export default AssignLocalityGroup