import { IDocumentCategory } from 'src/generated-types'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'
import { queryClient } from 'src/query-client'
import { CustomerDocumentCategoryKeys } from 'src/query-keys'

const documentCustomerAdminBaseURL = (customerId: EntityId) => `customers/${customerId}/documentCategories`

export const customerDocumentCategoryEndpoints = {
  getCustomerDocumentCategories(customerId: EntityId) {
    return `${documentCustomerAdminBaseURL(customerId)}`
  },
  getCustomerAndAdminDocumentCategories(customerId: EntityId) {
    return `${documentCustomerAdminBaseURL(customerId)}/getCustomerAndAdminDocumentCategories`
  },
  getCustomerDocumentCategory(customerId: EntityId, id: EntityId) {
    return `${documentCustomerAdminBaseURL(customerId)}/${id}`
  },
  createCustomerDocumentCategory(customerId: EntityId) {
    return `${documentCustomerAdminBaseURL(customerId)}`
  },
  updateCustomerDocumentCategory(customerId: EntityId, id: EntityId) {
    return `${documentCustomerAdminBaseURL(customerId)}/${id}`
  },
  deleteCustomerDocumentCategory(customerId: EntityId) {
    return `${documentCustomerAdminBaseURL(customerId)}`
  },
}

export const invalidateCustomerDocumentCategoryCache = {
  getCustomerDocumentCategories(customerId: EntityId) {
    queryClient.invalidateQueries(customerDocumentCategoryEndpoints.getCustomerDocumentCategories(customerId))
  },
  getCustomerAndAdminDocumentCategories(customerId?: EntityId) {
    queryClient.invalidateQueries(CustomerDocumentCategoryKeys.GET_CUSTOMER_AND_ADMIN_DOCUMENT_CATEGORIES(customerId))
  },
  getCustomerDocumentCategory(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(customerDocumentCategoryEndpoints.getCustomerDocumentCategory(customerId, id))
  },
}

export const useGetCustomerDocumentCategories = (
  customerId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IDocumentCategory>>(
    customerDocumentCategoryEndpoints.getCustomerDocumentCategories(customerId),
    { page, pageSize },
  )

export const useGetCustomerAndAdminDocumentCategories = (
  customerId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IDocumentCategory>>(
    customerDocumentCategoryEndpoints.getCustomerAndAdminDocumentCategories(customerId),
    { page, pageSize },
    { queryKey: CustomerDocumentCategoryKeys.GET_CUSTOMER_AND_ADMIN_DOCUMENT_CATEGORIES(customerId) },
  )

export const useGetCustomerDocumentCategory = (customerId: EntityId, id: EntityId) =>
  useGet<IDocumentCategory>(customerDocumentCategoryEndpoints.getCustomerDocumentCategory(customerId, id))

export const useAddCustomerDocumentCategory = (customerId: EntityId) =>
  usePost<IDocumentCategory, IDocumentCategory>(
    customerDocumentCategoryEndpoints.createCustomerDocumentCategory(customerId),
  )

export const useUpdateCustomerDocumentCategory = (customerId: EntityId, id: EntityId) =>
  usePut<IDocumentCategory, IDocumentCategory>(
    customerDocumentCategoryEndpoints.updateCustomerDocumentCategory(customerId, id),
  )

export const useDeleteCustomerDocumentCategory = (customerId: EntityId) =>
  useDelete<IDocumentCategory>(customerDocumentCategoryEndpoints.deleteCustomerDocumentCategory(customerId))
