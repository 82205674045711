import React from 'react'
import { z } from 'zod'
import { AppForm, AppFormProps, FormSubmitButton, FormTextField } from '../components/FormCore'
import { useZodForm } from '../hooks/zod-form'
import { t } from 'i18next'
import { passwordRegex } from '../constant'
import { Stack } from '@mui/material'
import { CancelButton } from '../components/CancelButton'

const baseSchema = z.object({
  email: z.string(),
  firstName: z.string().min(1, { message: t('first-name-required', { ns: 'user-register' }) }),
  lastName: z.string().min(1, { message: t('last-name-required', { ns: 'user-register' }) }),
  phoneNumber: z.string().optional(),
})
const registerSchema = z
  .object({
    password: z
      .string()
      .min(1, { message: t('password-required', { ns: 'user-register' }) })
      .regex(passwordRegex, { message: t('password-validate', { ns: 'user-register' }) }),
    confirmPassword: z.string().min(1, { message: t('confirm-password-required', { ns: 'user-register' }) }),
  })
  .merge(baseSchema)
  .refine(form => (form.confirmPassword != '' ? form.password === form.confirmPassword : true), {
    path: ['password'],
    message: t('passwords-must-match', { ns: 'user-register' }),
  })

export type UserRegistrationFormType = z.infer<typeof registerSchema>

export type UserUpdateFormType = Omit<z.infer<typeof baseSchema>, 'email'>

interface UserRegistrationFormProps extends AppFormProps {
  isRegisterForm?: boolean
  isLoading?: boolean
  onCancel?: () => void
  disabled?: boolean
}

export const UserRegistrationForm: React.FC<UserRegistrationFormProps> = ({
  onSubmit,
  initialValues,
  isRegisterForm = true,
  isLoading,
  disabled,
  onCancel,
}) => {
  const form = useZodForm(isRegisterForm ? registerSchema : baseSchema, { defaultValues: initialValues })

  return (
    <AppForm onSubmit={onSubmit} form={form}>
      <FormTextField label="Email" disabled type="email" name="email" />
      <FormTextField label="First name" name="firstName" />
      <FormTextField label="Last name" name="lastName" />
      <FormTextField label="Phone number" name="phoneNumber" />
      {isRegisterForm && (
        <>
          <FormTextField label="Password" type="password" name="password" />
          <FormTextField label="Confirm password" type="password" name="confirmPassword" />
        </>
      )}
      <Stack direction={'row-reverse'} py={1} spacing={1}>
        <FormSubmitButton
          createText={t(isRegisterForm ? 'register' : 'update', { ns: 'common' })}
          disabled={isLoading || disabled}
        />
        <CancelButton onClick={onCancel} disabled={disabled} />
      </Stack>
    </AppForm>
  )
}
