import { t } from 'i18next'
import { TaskIntervals, TaskTypes } from '../../../../enums'

export const taskTypeList = [
  { option: t('once', { ns: 'task' }), value: TaskTypes.Once },
  { option: t('repeatable', { ns: 'task' }), value: TaskTypes.Repeatable },
]

export const taskIntervalList = [
  { option: t('daily', { ns: 'task' }), value: TaskIntervals.Daily },
  { option: t('weekdays', { ns: 'task' }), value: TaskIntervals.Weekdays },
  { option: t('weekly', { ns: 'task' }), value: TaskIntervals.Weekly },
  { option: t('biweekly', { ns: 'task' }), value: TaskIntervals.BiWeekly },
  { option: t('every3weeks', { ns: 'task' }), value: TaskIntervals.Every3Weeks },
  { option: t('monthly', { ns: 'task' }), value: TaskIntervals.Monthly },
  { option: t('bimonthly', { ns: 'task' }), value: TaskIntervals.Bimonthly },
  { option: t('every3months', { ns: 'task' }), value: TaskIntervals.Every3months },
  { option: t('every4months', { ns: 'task' }), value: TaskIntervals.Every4months },
  { option: t('every6months', { ns: 'task' }), value: TaskIntervals.Every6months },
  { option: t('yearly', { ns: 'task' }), value: TaskIntervals.Yearly },
]
