import { IApiResult } from '../generated-types/api-result'
import {
  IFormSchema,
  IFormSchemaDto,
  IFormSchemaReport,
  IFormSchemaVersion,
  formSchemaReportTypes,
} from '../generated-types/form-schema'
import { queryClient } from '../query-client'
import { formSchemaQueryKeys } from '../query-keys'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/form-schema`
export const endpoints = {
  getFormSchemas(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getFormAllSchemas(customerId: EntityId) {
    return `${baseUrl(customerId)}/all`
  },
  getFormSchemaById(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  getFormSchemaWithReports(customerId: EntityId) {
    return `${baseUrl(customerId)}/form-schema-reports`
  },
  deleteFormSchemaById(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  createFormSchema(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  createFormSchemaReport(customerId: EntityId) {
    return `${baseUrl(customerId)}/form-schema-report`
  },
  deleteFormSchemaReportById(customerId: EntityId) {
    return `${baseUrl(customerId)}/form-schema-report`
  },
  updateFormSchema(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  generateBulkExcelExportF(customerId: EntityId) {
    return `${baseUrl(customerId)}/excel-bulk`
  },
}
export const invalidateSchemaForm = {
  getFormSchemaReports(
    customerId: EntityId,
    type?: formSchemaReportTypes,
    localityFrequencyIds?: Array<number | undefined>,
    deviationId?: string,
    mainComponentId?: string,
    scheduledTaskId?: number,
  ) {
    queryClient.invalidateQueries(
      formSchemaQueryKeys.GetFormSchemaReports({
        customerId,
        type,
        localityFrequencyIds,
        deviationId,
        mainComponentId,
        scheduledTaskId,
      }),
    )
  },
  getSchemaById(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries([endpoints.getFormSchemaById(customerId, id)])
  },
  getAllFormSchema(customerId: EntityId) {
    queryClient.invalidateQueries([endpoints.getFormAllSchemas(customerId)])
  },
}

export const useGetFormSchema = (customerId: EntityId) => useGet<IFormSchema[]>(endpoints.getFormSchemas(customerId))

export const useGetAllFormSchema = (customerId: EntityId) =>
  useGet<IFormSchema[]>(endpoints.getFormAllSchemas(customerId))

export const useGetFormSchemaById = (customerId: EntityId, id: EntityId) =>
  useGet<IFormSchemaVersion>(endpoints.getFormSchemaById(customerId, id))

export const useGetFormSchemaReports = (
  customerId: EntityId,
  queryParams: {
    type: formSchemaReportTypes
    localityFrequencyId?: number
    groupId?: string
    deviationId?: string
    mainComponentId?: string
    scheduledTaskId?: number
  },
) =>
  useGet<IFormSchemaDto[]>(endpoints.getFormSchemaWithReports(customerId), queryParams, {
    queryKey: formSchemaQueryKeys.GetFormSchemaReports({
      customerId,
      ...queryParams,
    }),
  })

export const useDeleteFormSchemaById = (customerId: EntityId) =>
  useDelete<IFormSchemaVersion>(endpoints.deleteFormSchemaById(customerId))

export const useAddFormSchema = (customerId: EntityId) =>
  usePost<IFormSchema, IFormSchema>(endpoints.createFormSchema(customerId))

export const useAddFormSchemaReport = (customerId: EntityId) =>
  usePost<IFormSchemaReport, IFormSchemaReport>(endpoints.createFormSchemaReport(customerId))

export const useDeleteFormSchemaReportById = (customerId: EntityId) =>
  useDelete<IApiResult>(endpoints.deleteFormSchemaReportById(customerId))

export const useUpdateFormSchema = (customerId: EntityId, id: EntityId) =>
  usePut<IFormSchema, IFormSchema>(endpoints.updateFormSchema(customerId, id))

export const useBulkExcelExport = (customerId: EntityId) =>
  usePost<any, object>(endpoints.generateBulkExcelExportF(customerId), {}, { responseType: 'arraybuffer' })
