import { ThemeProvider } from '@mui/system'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { Theme, ToastContainer, ToastPosition } from 'react-toastify'
import { MainRouter } from './MainRouter'
import { muiTheme } from './mui-theme'
import { useEffect } from 'react'
import { caching } from './utils'
import { GlobalStyled } from './styles/globalPaddingStyles'
import { queryClient } from '@lovoldsystem/core.client'
import { ToastContainer as AuthToastContainer } from '@lovoldsystem/authorization.client'
import 'react-toastify/dist/ReactToastify.css'
import { NavigationProvider } from './context/NavigationContext'

function App() {
  useEffect(() => {
    caching()
  }, [])

  const toastProps = {
    hideProgressBar: false,
    newestOnTop: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    autoClose: 5000,
    theme: 'light' as Theme,
    position: 'top-right' as ToastPosition
  }

  return (
    <>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <GlobalStyled>
          <BrowserRouter>
            <NavigationProvider>
              <ThemeProvider theme={muiTheme}>
                <div id="dialog-root"></div>
                <MainRouter />
                <AuthToastContainer {...toastProps} />
                <ToastContainer {...toastProps} />
              </ThemeProvider>
            </NavigationProvider>
          </BrowserRouter>
        </GlobalStyled>
      </QueryClientProvider>
    </>
  )
}

export default App
