import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { LocalityGroupsTable } from './LocalityGroupsTable'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { CreateLocalityGroupDialog } from './CreateLocalityGroupDialog'
import { FC } from 'react'
import { ResourceScreenProps } from '../../../generated-types/resource-screen'
import {
  invalidateLocalityGroupsQueries,
  localityGroupsEndpoints,
  useDeleteLocalityGroup,
} from '../../../api/locality-groups'
import { toast } from 'react-toastify'
import UpdateLocalityGroup from './UpdateLocalityGroup'
import LocalityGroupDetailPanel from './LocalityGroupDetailPanel'
import { ILocalityGroup } from '../../../generated-types'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

export const LocalityGroupsScreen: FC<ResourceScreenProps> = ({
  renderTableExpand,
  query,
  params,
  generateQueryKey,
  handleUpdate,
  handleDelete,
  handleAdd,
  isActionAvailable = true,
  isTableExpandable = true,
}) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation('common')
  const { showConfirmDialog, showDialog } = useDialogCtx()
  const { mutateAsync: deleteGroup } = useDeleteLocalityGroup(customer?.id)
  const theme = useTheme()

  const onAdd = async () => {
    const added = await showDialog(
      CreateLocalityGroupDialog,
      {
        title: t('create-locality-group', { ns: 'access-control' }),
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        componentProps: {
          customerId: customer.id as EntityId,
        },
      },
      undefined,
    )
    if (!added) return
  }

  const deleteLocalityGroupAction = async (id: EntityId) => {
    showConfirmDialog(
      t('delete-confirm-locality-group-title', { ns: 'locality-group' }),
      t('delete-confirm-locality-group-description', { ns: 'locality-group' }),
      { acceptText: t('yes', { ns: 'common' }), cancelText: t('no', { ns: 'common' }) },
      async pop => {
        await deleteGroup(id)
        invalidateLocalityGroupsQueries.invalidateGetLocalityGroups(customer?.id)
        toast(t('successfullyRemoved', { ns: 'common-api-message' }), { type: 'success' })
        pop()
      },
    )
  }

  const editLocalityGroup = async id => {
    showDialog(UpdateLocalityGroup, {
      componentProps: {
        id: id,
        customerId: customer.id as EntityId,
      },
      ops: {
        maxWidth: 'md',
      },
      title: t('update-locality-group', { ns: 'access-control' }),
    })
  }

  return (
    <CommonPageLayout
      titleSection={{ title: t('locality-groups', { ns: 'locality-group' }) }}
      topAction={[
        {
          buttonText: t('locality-group', { ns: 'page-container' }),
          buttonIcon: <AddCircleOutline />,
          onClick: handleAdd ?? onAdd,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <LocalityGroupsTable
          onUpdate={isActionAvailable ? handleUpdate ?? editLocalityGroup : undefined}
          onDelete={isActionAvailable ? handleDelete ?? deleteLocalityGroupAction : undefined}
          queryUrl={query ?? localityGroupsEndpoints.getLocalityGroups(customer.id)}
          queryParams={params}
          generateQueryKey={generateQueryKey}
          getDetailPanelContent={
            isTableExpandable
              ? renderTableExpand ??
                (params => <LocalityGroupDetailPanel localityGroup={params.row as ILocalityGroup} />)
              : undefined
          }
        />
      </Box>
    </CommonPageLayout>
  )
}
