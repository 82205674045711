import { Box, Collapse, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { IconType } from 'react-icons/lib'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { SidebarItem } from './SidebarItem'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { SvgIconComponent } from '@mui/icons-material'

interface Props {
  title: string
  Icon: SvgIconComponent | IconType
  item?: any
  pathname?: string
  id?: any
  textVisibility?: boolean
}

export const SidebarHeaderItem: React.FC<Props> = ({ item, title, Icon, pathname, id, textVisibility = true }) => {
  const { t } = useTranslation('admin-sidebar')
  const isSelected = [...item.children.map(c => c.pathname)].includes(location.pathname.split('/')[4])
  const [open, setOpen] = useState(isSelected)
  const theme = useTheme()
  const navigate = useNavigate()

  const onOpen = e => {
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={open ? 'white' : 'inherit'}
      paddingTop={0}
      className="!rounded-md !mt-2 !drop-shadow-md"
    >
      <ListItemButton
        className={classNames(`${!isSelected && 'hover:!bg-gray-200'}`, '!border !border-gray-600', {
          ['!bg-white']: !isSelected,
          ['!bg-primary']: isSelected,
          ['!text-white']: isSelected,
        })}
        key={title}
        onClick={onOpen}
      >
        <ListItemIcon sx={{ minWidth: 30 }}>
          <Icon className={isSelected ? 'text-white' : 'text-primary'} />
        </ListItemIcon>
        {
          textVisibility &&
          <ListItemText className={isSelected ? 'text-white !text-sm' : 'text-typo !text-sm'}>{t(title)}</ListItemText>
        }
        <ListItemIcon>
          {open ? (
            <MdKeyboardArrowDown size={30} color={isSelected ? 'white' : theme.palette.primary.main} />
          ) : (
            <MdKeyboardArrowUp size={30} color={isSelected ? 'white' : theme.palette.primary.main} />
          )}
        </ListItemIcon>
      </ListItemButton>
      <Collapse in={open}>
        <Box padding={textVisibility ? 3 : 0} className="border-l-2 border-primary">
          {item.children.map(child => (
            <SidebarItem
              key={child.name}
              active={!!pathname && pathname.split('/')[4] === child.pathname}
              title={t(child.name)}
              Icon={child.icon}
              onClick={() => navigate(child.path(id!))}
              textVisibility={textVisibility}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  )
}
