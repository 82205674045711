import { axios } from '../axios'

export const requestProvider = {
  get: async <T>(url: string, params?: object) =>
    await axios.get<T>(url, {
      ...params,
    }),
  post: async <T>(url: string, data: any, responseOptions?: any) =>
    await axios.post<T>(url, data, responseOptions ?? {}),
  put: async <T>(url: string, data: any) => await axios.put<T>(url, data, {}),
  patch: async <T>(url: string, data: any) => await axios.patch<T>(url, data, {}),
  delete: async <T>(url: string) => await axios.delete<T>(url, {}),
}
