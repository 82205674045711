import React from 'react'
import { FormField } from '../../generated-types/form-field'
import { FormFieldType } from '../../enums'
import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
interface IFieldSelector {
  field: FormField
  value?: string
}

const FieldSelectorViewer: React.FC<IFieldSelector> = ({ field, value }) => {
  const { t } = useTranslation(['common', 'overall-reports'])
  const selectField = () => {
    switch (field.type) {
      case FormFieldType.textField:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.numberField:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.datePicker:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value && new Date(value).toLocaleDateString()}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.textArea:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.checkBox:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value ? t('yes') : t('no')}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.dateTime:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value && new Date(value).toLocaleString()}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.fileUpload:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              {value && (
                <UploadedFiles rel="noreferrer" target="_blank" href={value}>
                  {t('uploaded-file-link', { ns: 'overall-reports' })}
                </UploadedFiles>
              )}
            </ValueContainer>
          </Box>
        )
      case FormFieldType.timePicker:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.checkBoxList:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              {JSON.parse(value || '[]')?.map((singleValue, index) => {
                return <StyledTypography key={index}>{singleValue}</StyledTypography>
              })}
            </ValueContainer>
          </Box>
        )
      case FormFieldType.radio:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.selectField:
        return (
          <Box>
            <LabelContainer>
              <Typography variant="subtitle2">{field.name}</Typography>
            </LabelContainer>
            <ValueContainer>
              <StyledTypography>{value}</StyledTypography>
            </ValueContainer>
          </Box>
        )
      case FormFieldType.title:
        return <Typography variant="h6">{field.name}</Typography>
      default:
        return null
    }
  }
  return <Box padding={1}>{selectField()}</Box>
}

const ValueContainer = styled('div')(({ theme }) => ({
  minHeight: 40,
  paddingTop: 8,
  paddingLeft: 10,
  paddingRight: 20,
  border: `1px solid ${getThemeColor(theme, MUIThemeColors.primaryMain)}`,
  borderRadius: 5,
  minWidth: '50%'
}))

const LabelContainer = styled('div')({
  height: 30,
})

const StyledTypography = styled(Typography)({
  height: 30,
})

const UploadedFiles = styled('a')(({ theme }) => ({
  color: getThemeColor(theme, MUIThemeColors.primaryMain),
  marginLeft: 5,
  fontStyle: 'italic',
  borderBottom: `1px solid ${getThemeColor(theme, MUIThemeColors.primaryMain)}`,
}))

export default FieldSelectorViewer
