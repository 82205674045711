import { toast } from 'react-toastify'
import {
  invalidateProductCategoryCache,
  useGetProductCategoryById,
  useUpdateProductCategory,
} from '../../../api/product-category'
import { ShowDialogProps } from '../../../show-dialog'
import { ProductCategoryForm } from './ProductCategoryForm'
import { useTranslation } from 'react-i18next'
import { CenteredSpinner } from '../../../components/Spinner'

interface ProductCategoryUpdateDialogProps extends ShowDialogProps {
  productCategoryId?: EntityId
}

export const ProductCategoryUpdateDialog: React.FC<ProductCategoryUpdateDialogProps> = ({ pop, productCategoryId }) => {
  const { t: translate } = useTranslation(['common-api-message', 'common'])

  const { mutateAsync } = useUpdateProductCategory(productCategoryId as EntityId)
  const { data, isLoading } = useGetProductCategoryById(productCategoryId as EntityId)
  if (isLoading) return <CenteredSpinner />

  const onSubmit = async (form: any) => {
    await mutateAsync(form)
      .then(({ data }: { data: string }) => {
        invalidateProductCategoryCache.getProductCategories()
        invalidateProductCategoryCache.getProductCategorById(productCategoryId as EntityId)
        toast.success(translate(data, { ns: 'common-api-message' }))
        pop()
      })
      .catch(({ data }: { data: string }) => {
        toast.error(translate(data, { ns: 'common-api-message' }))
      })
  }
  return <ProductCategoryForm initialValues={data} onSubmit={onSubmit} pop={pop} />
}
