import { DialogContent, DialogTitle } from '@mui/material'
import { useMutation } from 'react-query'
import { CustomerCtxProvider } from '../context/CustomerContext'
import { CustomerInviteUserForm, InviteUserFormType } from '../forms/CustomerInviteUserForm'
import { ICustomer } from '../generated-types'
import { addUserToCustomer } from '../http'
import { ShowDialogProps } from '../show-dialog'

interface Props extends ShowDialogProps {
  customer: ICustomer
}

export const CustomerUserInviteDialog: React.FC<Props> = ({ pop, customer }) => {
  const { mutateAsync } = useMutation<any, any, InviteUserFormType>(form => addUserToCustomer(customer.id, form))

  const onSubmit = async form => {
    await mutateAsync(form)
    pop(true)
  }

  return (
    <CustomerCtxProvider customer={customer} permission={customer.permission}>
      <DialogTitle>Add user(s)</DialogTitle>
      <DialogContent>
        <CustomerInviteUserForm onSubmit={onSubmit} onCancel={() => pop()} customerId={customer.id} />
      </DialogContent>
    </CustomerCtxProvider>
  )
}
