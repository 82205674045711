import { GridColumns } from '@mui/x-data-grid-pro'
import {
  mapMaintenanceTypeToColor,
  mapMaintenanceTypeToIcon,
} from '../../screens/localities/locality-dashboard/InformationCards/LocalityMaintenanceCard'
import { muiTheme } from '../../mui-theme'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IMaintenance } from '../../generated-types/IMaintenance'
import moment from 'moment'
import { getLocalDateTime } from '../../utils/function'
import { maintenanceEndpoints } from '../../api/maintenance'
import { MaintenanceType } from '../../enums'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'
import GenericTable from '../table/GenericTable'
import { useNavigation } from 'src/context/NavigationContext'

export const RowMaintenanceTypeImageBox = ({ type }) => {
  return (
    <Box
      bgcolor={mapMaintenanceTypeToColor(type)}
      width={'24px'}
      height={'24px'}
      borderRadius={'3px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <img src={mapMaintenanceTypeToIcon(type)} />
    </Box>
  )
}

interface ILocalityMaintenanceGrid {
  customerId?: string
  localityId?: string
  maintenanceType?: MaintenanceType
  maintenanceForWeek?: boolean
  totalCount?: number
}

export const LocalityMaintenanceGrid = ({
  customerId,
  localityId,
  maintenanceType,
  maintenanceForWeek,
  totalCount,
}: ILocalityMaintenanceGrid) => {
  const { t: translate } = useTranslation(['common', 'maintenance'])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const { navigateTo } = useNavigation()
  const isSmallLayout = useMediaQuery('(max-width: 1550px)')

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'title',
      headerName: translate('title', { ns: 'maintenance' }),
      flex: 1,
      sortable: false,
      hideSortIcons: true,
      renderCell: params => {
        const maintenance = params.row as IMaintenance
        return (
          <>
            <Stack display={'flex'} columnGap={'12px'} flexDirection={'row'}>
              <Box>{RowMaintenanceTypeImageBox({ type: params.row.entityType })}</Box>
              <Box>
                {maintenance.title}
                {maintenance.type ? '- ' + maintenance.type : ''}
              </Box>
            </Stack>
          </>
        )
      },
    },
    {
      field: 'createdBy',
      headerName: translate('responsible', { ns: 'maintenance' }),
      flex: 0.5,
      hideSortIcons: true,
      sortable: false,
      renderCell: params => {
        const maintenance = params.row as IMaintenance
        return <>{maintenance.createdBy ? maintenance.createdBy : translate('n/a', { ns: 'common' })}</>
      },
    },
    {
      field: 'dueDate',
      headerName: translate('dueDate', { ns: 'common' }),
      flex: 0.5,
      sortable: false,
      hideSortIcons: true,
      renderCell: params => {
        return <>{moment(getLocalDateTime(params.row.dueDate)).format('YYYY-MM-DD ')}</>
      },
    },
  ]

  const handleRowClick = params => {
    if (params.row.entityType == MaintenanceType.Deviation) {
      navigateTo(
        AppRoutesNavigation.Localities.deviation(customerId as EntityId, localityId as EntityId, params.row.entityId),
      )
    } else if (params.row.entityType == MaintenanceType.Service) {
      navigateTo(
        AppRoutesNavigation.Localities.localityService(
          customerId as EntityId,
          localityId as EntityId,
          params.row.entityId,
        ),
      )
    } else if (params.row.entityType == MaintenanceType.Task) {
      navigateTo(
        AppRoutesNavigation.Localities.task(customerId as EntityId, localityId as EntityId, params.row.entityId),
      )
    } else if (params.row.entityType == MaintenanceType.WorkAssignment) {
      navigate(
        AppRoutesNavigation.Localities.workAssignment(
          customerId as EntityId,
          localityId as EntityId,
          params.row.entityId,
        ),
      )
    }
  }

  return (
    <GenericTable
      id={'maintenance-grid'}
      queryUrl={maintenanceEndpoints.getMaintenances(customerId as EntityId, localityId as EntityId)}
      queryParams={{ maintenanceForWeek: maintenanceForWeek, maintenanceType }}
      columns={STATIC_COLUMNS}
      enableServerPagination={true}
      enablePagination={isMobile}
      pageSize={isMobile ? 5 : totalCount}
      initialPage={0}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
      hideDefaultFooterPagination={true}
      hideFooterSelectedRowCount={true}
      noRowsMessage={translate('noMaintenance', { ns: 'maintenance' })}
      isRowSelectable={() => true}
      onRowClick={params => handleRowClick(params)}
      mobileViewCard={row => {
        const maintenance = row as IMaintenance
        return (
          <Box>
            <Stack display={'flex'} columnGap={'12px'} flexDirection={'row'}>
              <Box>{RowMaintenanceTypeImageBox({ type: row.entityType })}</Box>
              <Box display={'flex'} flexDirection={'column'} rowGap={'6px'}>
                <Typography letterSpacing={'initial'} variant="subtitle2" fontWeight={600} textAlign={'left'}>
                  {maintenance.title}
                  {maintenance.type ? '- ' + maintenance.type : ''}
                </Typography>
                <Box display={'flex'} flexDirection={'row'} columnGap={'6px'}>
                  <Box
                    bgcolor={muiTheme.palette.primaryLight[theme.palette.mode]}
                    borderRadius={'4px'}
                    paddingX={'10px'}
                    paddingY={'4px'}
                  >
                    <Typography
                      letterSpacing={'initial'}
                      variant="subtitle2"
                      fontWeight={600}
                      color={muiTheme.palette.white[theme.palette.mode]}
                    >
                      {maintenance.createdBy ? maintenance.createdBy : translate('n/a', { ns: 'common' })}
                    </Typography>
                  </Box>
                  <Box
                    bgcolor={muiTheme.palette.primaryLight[theme.palette.mode]}
                    borderRadius={'4px'}
                    paddingX={'10px'}
                    paddingY={'4px'}
                  >
                    <Typography
                      letterSpacing={'initial'}
                      variant="subtitle2"
                      fontWeight={600}
                      color={muiTheme.palette.white[theme.palette.mode]}
                    >
                      {moment(getLocalDateTime(row.dueDate)).format('Do MMMM YYYY')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        )
      }}
      height={!isSmallLayout ? 400 : 355}
      autoHeight={false}
    />
  )
}
