import { FC, Suspense, lazy } from 'react'
import { Spinner } from '../../../../components/Spinner'
import 'leaflet/dist/leaflet.css'
import { MapComponentProps } from './LeafletMapContainer'
import { Stack } from '@mui/material'
const LeafletMapContainer = lazy(async () => await import('./LeafletMapContainer'))

export interface IGroupSelector {
  facility: boolean
  fleet: boolean
  deviation: boolean
  barentsWatch: boolean
  aquaculture: boolean
}

export const MapComponent: FC<MapComponentProps> = props => {
  return (
    <Suspense
      fallback={
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} minHeight={'100%'}>
          <Spinner />
        </Stack>
      }
    >
      <LeafletMapContainer {...props} />
    </Suspense>
  )
}
