import { Box, Tooltip, Typography, styled } from '@mui/material'
import { firstLetterToUpperCase } from '../../utils/function'
import { GetRGBA } from 'src/utils'
import { Flex } from 'src/styles/flexComponent'
import React from 'react'

interface StyledCardProps {
  customChipWidth?: any
}

interface CountWithIconBoxProps {
  Component?: any
  value?: any
  iconBgColor?: any
  title?: any
  color?: any
  styles?: any
  customChipWidth?: any
  bgColor?: any
}

const CountWithIconBox: React.FC<CountWithIconBoxProps> = ({
  Component,
  value,
  iconBgColor,
  title,
  color,
  styles = {},
  customChipWidth,
  bgColor
}) => {
  return (
    <Tooltip title={firstLetterToUpperCase(title)}>
      <StyledCard customChipWidth={customChipWidth} bgcolor={bgColor}>
        <Flex.Row alignItems="center" justifyContent="center">
          <IconBox sx={{ backgroundColor: iconBgColor }}>
            <Component />
          </IconBox>
          <Number sx={styles} variant="body2" color={color}>
            {value.toString().padStart(2, '0')}
          </Number>
        </Flex.Row>
      </StyledCard>
    </Tooltip>
  )
}

export default CountWithIconBox

const Number = styled(Typography)(() => ({
  paddingLeft: '3px',
  textAlign: 'right',
  fontWeight: 600,
  marginLeft: 'auto'
}))

const IconBox = styled(Box)(() => ({
  padding: '3px',
  height: '16px',
  width: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px'
}))

const StyledCard = styled(Box)<StyledCardProps>(({ theme, customChipWidth }) => ({
  width: customChipWidth ?? '',
  height: '22px',
  padding: '2px 6px',
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '11px',
  border: `0.5px solid ${GetRGBA(theme.palette.secondaryMain[theme.palette.mode], 1)}`
}))
