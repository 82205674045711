import { Box, Collapse, Grid, Stack, Typography, styled, useTheme } from '@mui/material'
import { ICustomer, ILocality, ILocalityGroup } from '../../../generated-types'
import { AppRoutesNavigation } from '../../../app-routes'
import { useNavigate } from 'react-router-dom'
import { Chevron, CustomerHeader, ShadowedCard } from 'src/components/sidemenu/CustomerHeader'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import MultipleSelectChip from '../components/LocalitiesFilter'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { LocationHeader } from 'src/components/sidemenu/LocationHeader'
import { useGetGrouppedLocalities } from 'src/api/localities'
import { CenteredSpinner } from 'src/components/Spinner'
import { useGetCustomersActivityCounts } from 'src/api/customer'
import { Chip } from 'src/components/common/Chip'
import { Padding } from 'src/styles/paddingComponent'

const Area = styled(Typography)(() => ({
  fontWeight: 'bold',
  paddingLeft: '4px',
}))

const removeDuplicatedLocalities = (localities: ILocality[], localityGroups: ILocalityGroup[]): ILocality[] => {
  if (localityGroups?.length > 0) {
    const localityIdsInGroups = new Set<number>()
    localityGroups?.forEach(group => {
      group.localities?.forEach(locality => {
        localityIdsInGroups.add(locality.id)
      })
    })

    const filteredLocalities = localities.filter(locality => !localityIdsInGroups?.has(locality.id))
    return filteredLocalities
  }

  return localities
}

const AllLocalities: React.FC<{ customers: ICustomer[] | undefined; open: number; setOpen: (value: any) => void }> = ({
  customers,
  open,
  setOpen,
}) => {
  return (
    <Box>
      {customers?.map((customer: ICustomer, index: number) => {
        return <CustomerLocalitySection customer={customer} open={open} setOpen={setOpen} key={index} />
      })}
    </Box>
  )
}

export default AllLocalities

interface ICustomerLocalitySectionProps {
  open: number
  setOpen: (value: any) => void
  customer: ICustomer
}

const CustomerLocalitySection: React.FC<ICustomerLocalitySectionProps> = ({ open, setOpen, customer }) => {
  const [areas, setAreas] = React.useState<string[]>([])
  const [otherItems, setOtherItems] = React.useState<string[]>([])
  const { t } = useTranslation('localities')
  const theme = useTheme()
  const { data, isLoading } = useGetGrouppedLocalities(customer.id)
  const { data: customerActivitySummaryData, isLoading: isLoadingStatus } = useGetCustomersActivityCounts(customer.id)

  const handleDelete = (item: string) => {
    setAreas(prevAreas => prevAreas.filter(area => area !== item))
    setOtherItems(prevItems => prevItems.filter(otherItem => otherItem !== item))
  }

  const renderSelectedChips = () => {
    return (
      (areas?.length > 0 || otherItems?.length > 0) && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, padding: '16px 0px 0px' }}>
          {areas.map(value => (
            <Chip
              key={`area-${value}`}
              label={value}
              onDelete={() => handleDelete(value)}
              sx={{
                backgroundColor: theme.palette.primaryMain[theme.palette.mode],
                color: theme.palette.white[theme.palette.mode],
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.white[theme.palette.mode],
                },
              }}
            />
          ))}
          {otherItems.map(value => (
            <Chip
              key={`other-${value}`}
              label={t(value)}
              onDelete={() => handleDelete(value)}
              sx={{
                backgroundColor: theme.palette.primaryMain[theme.palette.mode],
                color: theme.palette.white[theme.palette.mode],
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.white[theme.palette.mode],
                },
              }}
            />
          ))}
        </Box>
      )
    )
  }

  return (
    <>
      <CustomerHeader
        hasAdminAccess={customer.isAdminCustomer}
        isLandingPage={true}
        {...customer}
        onClick={() => {
          setOpen(value => (value == customer.id ? 0 : customer.id))
        }}
        active={open == customer.id}
        MultipleSelectComponent={
          <MultipleSelectChip
            areas={areas}
            setAreas={setAreas}
            otherItems={otherItems}
            setOtherItems={setOtherItems}
            localityGroups={data?.localityGroups?.map(localityGroup => localityGroup.name)}
          />
        }
        customerActivitySummaryData={customerActivitySummaryData}
        isLoading={isLoadingStatus}
      />
      <Padding.pb12>{open == customer.id && renderSelectedChips()}</Padding.pb12>
      <Box className="overflow-y-auto" style={{ maxHeight: '72vh' }}>
        <Collapse in={open == customer.id}>
          <CustomerContentSection
            areas={areas}
            otherItems={otherItems}
            isLoading={isLoading}
            localities={data?.localityCards || []}
            localityGroups={data?.localityGroups || []}
          ></CustomerContentSection>
        </Collapse>
      </Box>
    </>
  )
}

const CustomerContentSection: React.FC<{
  areas: string[]
  otherItems: string[]
  localityGroups: ILocalityGroup[]
  localities: ILocality[]
  isLoading: boolean
}> = ({ areas, otherItems, localityGroups, localities, isLoading }) => {
  const { t } = useTranslation('localities')

  function filterLocalities(localities: ILocality[]) {
    let filteredLocalities: ILocality[] = localities
    if (otherItems.includes('has_due_documents'))
      filteredLocalities = filteredLocalities.filter(
        item => item.expiredDocumentsCount != null && item.expiredDocumentsCount > 0,
      )

    if (otherItems.includes('has_deviations'))
      filteredLocalities = filteredLocalities.filter(item => item.deviationCount != null && item.deviationCount > 0)

    if (otherItems.includes('has_due_services'))
      filteredLocalities = filteredLocalities.filter(item => item.servicesCount != null && item.servicesCount > 0)

    if (otherItems.includes('has_fish')) filteredLocalities = filteredLocalities.filter(item => item.hasFish == true)

    return filteredLocalities
  }

  if (isLoading) return <CenteredSpinner />

  return (
    <>
      {localityGroups && localityGroups.length > 0 ? (
        <>
          {localityGroups
            .filter(lg => areas.length === 0 || areas.includes(lg.name))
            .map((localityGroup, index) => (
              <LocalityGroupSection
                groupIndex={index}
                key={index}
                localities={filterLocalities(localityGroup.localities)}
                localityGroupName={localityGroup.name}
              />
            ))}
          {areas.length === 0 && (
            <LocalityGroupSection
              groupIndex={0}
              localities={removeDuplicatedLocalities(filterLocalities(localities), localityGroups)}
              localityGroupName={t('not-grouped')}
            />
          )}
        </>
      ) : (
        <LocalityGroupSection
          groupIndex={0}
          localities={removeDuplicatedLocalities(filterLocalities(localities), localityGroups || [])}
        />
      )}
    </>
  )
}

interface ILocalityGroupSectionProps {
  localityGroupName?: string
  localities: ILocality[]
  groupIndex: number
}

const LocalityGroupSection: React.FC<ILocalityGroupSectionProps> = ({ localityGroupName, localities, groupIndex }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(true)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return localities?.length > 0 ? (
    <>
      <Box key={groupIndex} padding={'2px 0px'}>
        {localityGroupName && (
          <ShadowedCard onClick={handleToggle} isLandingPage={true} height="24px">
            <Stack direction={'row'} alignItems="center">
              <Chevron>{isOpen ? <ArrowDropDown /> : <ArrowRight />}</Chevron>
              <Area variant="body2" paddingTop={'2px'}>
                {localityGroupName}
              </Area>
            </Stack>
          </ShadowedCard>
        )}
        <Collapse in={isOpen} sx={{ paddingTop: '12px', paddingBottom: '16px' }}>
          <Grid container spacing={'11px'}>
            {localities?.map((locality, index) => (
              <Grid item md={3} xs={6} key={index}>
                <LocationHeader
                  locality={locality}
                  localityInfo={locality}
                  onClick={() => {
                    navigate(AppRoutesNavigation.Localities.locality(locality.customerId, locality.id))
                  }}
                  active={true}
                />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Box>
    </>
  ) : null
}
