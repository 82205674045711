import logo from '../assets/logo_white_extended.png'

export const LogoHeader = () => {
  return (
    <div className="w-full bg-primary">
      <div className="flex justify-center">
        <div className="w-80">
          <img src={logo} />
        </div>
      </div>
    </div>
  )
}
