import { IMooringService } from '../generated-types/mooring-service'
  import { useDelete, usePost } from './reactQuery'
  
  const baseUrl = (customerId: EntityId, serviceId: EntityId) =>
    `customers/${customerId}/services/${serviceId}/locality-services`
  
  export const localityFrequenciesEndpoints = {
    createLocalityFrequency(customerId: EntityId, serviceId: EntityId) {
      return `${baseUrl(customerId, serviceId)}`
    },
    deleteLocalityFrequency(customerId: EntityId, serviceId: EntityId) {
      return `${baseUrl(customerId, serviceId)}`
    },
  }

  export const useAddMooringToService = (customerId: EntityId, serviceId: EntityId) =>
    usePost<IMooringService, IMooringService>(
      localityFrequenciesEndpoints.createLocalityFrequency(customerId, serviceId),
    )
  
  export const useRemoveLocalityService = (customerId: EntityId, serviceId: EntityId) =>
    useDelete<IMooringService>(localityFrequenciesEndpoints.deleteLocalityFrequency(customerId, serviceId))
