import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MainComponentTypeTable } from '../../main-component-type/MainComponentTypeTable'
import { invalidateMainComponentTypeCache, adminMainComponentTypeEndpoints } from '../../../api/main-component-type'
import { UpdateMainComponentTypeDialog } from './AdminUpdateMainComponentTypeDialog'
import { useDeleteMainComponentTypeAdmin } from '../../../api/main-component-type'
import {
  AdminCreateMainComponentTypeProps,
  CreateMainComponentTypeDialogAdmin,
} from './CreateMainComponentTypeDialogAdmin'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { IPrivilegeLevel } from '../../../enums'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const MainComponentTypesContainer = () => {
  const {showDialog, showConfirmDialog} = useDialogCtx()
  const { t: translate } = useTranslation(['main-component-type', 'common'])

  const onAddMainComponentType = async () => {
    const added = await showDialog(CreateMainComponentTypeDialogAdmin, {
      componentProps: {
        privilegeLevel: IPrivilegeLevel.SuperAdmin,
      } as AdminCreateMainComponentTypeProps,
    }, undefined, true)
    if (!added) return
  }

  const { mutateAsync, isLoading: isDeleting, error } = useDeleteMainComponentTypeAdmin()

  useEffect(() => {
    if (error && error['data'] == 'ComponentsExists') {
      toast(translate('component-exists'), { type: 'error' })
    }
  }, [error])

  const onDelete = (id: EntityId) => {
    showConfirmDialog(translate('deleteMainComponentType'), translate('areYouSureDeleteMainComponentType')).then(
      async e => {
        if (e) {
          await mutateAsync(id).then(() => {
            toast(translate('type-deleted'), { type: 'success' })
            invalidateMainComponentTypeCache.useGetMainComponentTypesAdmin()
          })
        }
      },
    )
  }

  const onUpdate = async id => {
    const added = await showDialog(UpdateMainComponentTypeDialog, {
      componentProps: { id: id as EntityId },
    }, undefined, true)
    if (!added) return
  }

  return (
    <>
      <Box className="p-3 mb-2 bg-white shadow-md" borderRadius={2}>
        <ScreenTitleBar
          title={translate('main-component-types')}
          addNewItemText={translate('add', { ns: 'common' })}
          onAddNewItemClick={onAddMainComponentType}
        />
        <MainComponentTypeTable
        baseQuery={adminMainComponentTypeEndpoints.getComponentTypes()}
        onDelete={onDelete}
        isDeleting={isDeleting}
        onUpdate={onUpdate}
        isAdmin={true}
      />
        </Box>
    </>
  )
}
