import { Stack, Typography, styled, useTheme } from '@mui/material'
import { FC, useEffect, useMemo } from 'react'
import { useGetWeatherData } from '../../../../api/weather'
import { GenericCardMinimalVersion } from '../../../../components/GenericCardMinimalVersion'
import OutlineArrow from '../../../../assets/svg/outline-arrow.svg'
import moment from 'moment'

type WeatherProps = {
  lat: number
  lon: number
}

const LightTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.silent[theme.palette.mode],
}))

const WeatherMinimalCard: FC<WeatherProps> = ({ lat, lon }) => {
  const theme = useTheme()
  const { data, refetch, isLoading } = useGetWeatherData(lat, lon)

  useEffect(() => {
    refetch()
  }, [lat, lon])

  useEffect(() => {
    const intervalId = setInterval(refetch, 5 * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [])

  const weather = useMemo(() => data?.properties?.timeseries[0]?.data, [data])
  const dateTime = useMemo(() => new Date(data?.properties?.timeseries[0]?.time), [data])

  return (
    <GenericCardMinimalVersion
      style={{ backgroundColor: theme.palette.primaryMain[theme.palette.mode] }}
      isLoading={isLoading}
      maxHeight={'180px'}
    >
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} gap={0.5}>
        <img
          src={`/icons/weather/${weather?.next_1_hours?.summary?.symbol_code}.png`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = '/icons/weather/partlycloudy_day.png'
          }}
          width={56}
          height={56}
        />
        <Stack
          sx={{ color: theme.palette.white[theme.palette.mode] }}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'flex-end'}
          gap={1}
        >
          <Typography variant="h3">{weather?.instant?.details?.wind_speed}</Typography>
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignContent={'center'}
            sx={{ color: theme.palette.silent[theme.palette.mode] }}
          >
            <img
              src={OutlineArrow}
              style={{ rotate: `-${weather?.instant?.details?.wind_from_direction}deg` }}
              width={12}
              height={12}
            />
            <LightTypography variant="body1" fontWeight={400}>
              m/s
            </LightTypography>
          </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={1}>
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <LightTypography variant="body2" fontWeight={400}>
              {weather?.instant?.details?.air_temperature}
            </LightTypography>
            <LightTypography variant="body2" fontWeight={400}>
              °C
            </LightTypography>
          </Stack>
          <LightTypography variant="body2" fontWeight={400}>
            {weather?.instant?.details?.air_pressure_at_sea_level}hPa
          </LightTypography>
        </Stack>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <LightTypography variant="body2" fontWeight={400}>
            {moment(dateTime).format('LT')}
          </LightTypography>
          <LightTypography variant="body2" fontWeight={400}>
            {moment(dateTime).format('YYYY-MM -DD')}
          </LightTypography>
        </Stack>
      </Stack>
    </GenericCardMinimalVersion>
  )
}

export default WeatherMinimalCard
