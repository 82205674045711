import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { invalidateServiceCache, useAddService } from '../../../api/service'
import { ShowDialogProps } from '../../../show-dialog'
import { ServiceForm } from './ServiceForm'
import { ServiceTable } from './ServiceTable'
import { toast } from 'react-toastify'
import { invalidateCustomer } from 'src/api/customer'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { AddCircleOutline } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface CreateServiceProps extends ShowDialogProps {
  customerId: EntityId
}

const CreateServiceDialog: React.FC<CreateServiceProps> = ({ pop, customerId }) => {
  const { t } = useTranslation(['supplier', 'common'])

  const { mutateAsync, isLoading } = useAddService(customerId as EntityId)
  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        invalidateServiceCache.getServices(customerId as EntityId)
        invalidateCustomer.getCustomersActivityCounts(customerId as EntityId)
        toast.success(t('serviceAddedSuccessfully', { ns: 'service' }))
        pop()
      },
      onError: _ => {
        toast.error(t('addingError', { ns: 'common' }))
      },
    })
  }

  return <ServiceForm onSubmit={onSubmit} onCancel={() => pop()} title={t('addservice', { ns: 'service' })} />
}

export const ServicesContainer = () => {
  const { t: translate } = useTranslation(['service', 'common'])
  const { id: customerId } = useParams()
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const addService = async () => {
    const added = await showDialog(
      CreateServiceDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <CommonPageLayout
      titleSection={{ title: translate('services', { ns: 'service' }) }}
      topAction={[
        {
          buttonText: translate('service', { ns: 'page-container' }),
          buttonIcon: <AddCircleOutline />,
          onClick: addService,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <ServiceTable customerId={customerId as EntityId} />
      </Box>
    </CommonPageLayout>
  )
}
