import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { MUIThemeColors } from '../../mui-theme'
import { getThemeColor } from 'src/utils'
import { Autocomplete, Box, IconButton, Pagination, Stack, styled, Typography } from '@mui/material'
import { GenericTableProps } from './GenericTable'

export const StyledDataGridPro = styled(DataGridPro)<GenericTableProps>(({ theme, ...props }) => ({
  border: 'none',
  color: getThemeColor(theme, MUIThemeColors.black),
  [`& .${gridClasses.row}`]: {
    border: `1px solid ${getThemeColor(theme, MUIThemeColors.secondaryLight)}`,
    boxSizing: 'border-box',
    maxWidth: 'calc(100% - 2px)',
    height: props.getRowHeight ?? '35px',
    minHeight: '44px !important',
    borderRadius: '0px',
    overflowWrap: 'break-word',
    cursor: props.onRowClick ? 'pointer' : '',
    '& :focus': {
      outline: props.onRowClick ? `solid ${getThemeColor(theme, MUIThemeColors.primaryMain)} 1px` : 'none',
    },
    '&.even': {
      backgroundColor: getThemeColor(theme, MUIThemeColors.white),
    },
    '&.odd': {
      backgroundColor: getThemeColor(theme, MUIThemeColors.backgroundMain),
    },
  },
  [`& .${gridClasses.columnHeaders}`]: {
    minHeight: props.isMinifiedColumnHeader ? '35px !important' : undefined,
    color: getThemeColor(theme, MUIThemeColors.black),
    border: 'none',
    [`& .${gridClasses.columnHeaderTitle}`]: {
      fontSize: '14px',
      fontWeight: 400,
      color: props.headerColor ?? getThemeColor(theme, MUIThemeColors.black),
    },
  },
  [`& .${gridClasses.columnHeadersInner}`]: {
    lineHeight: '35px',
  },
  [`& .${gridClasses['columnSeparator']}`]: {
    visibility: 'hidden',
  },
  [`& .${gridClasses['cell']}`]: {
    border: 'none',
    fontSize: '14px',
    fontWeight: '500',
    color: getThemeColor(theme, MUIThemeColors.black),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    '&:focus': {
      outline: props.isCellEditable ? `solid ${getThemeColor(theme, MUIThemeColors.primaryMain)} 1px` : 'none',
    },
    minHeight: '44px !important',
  },
  [`& .${gridClasses['footerContainer']}`]: {
    border: 'none',
    display: props.hideDefaultFooterPagination ? 'none' : undefined,
  },
  [`& .${gridClasses['virtualScroller']}`]: {
    marginTop: props.isMinifiedColumnHeader ? '35px !important' : undefined,
  },
  [`& .${gridClasses['actionsCell']}`]: {
    border: `2px solid ${getThemeColor(theme, MUIThemeColors.secondaryLight)}`,
    borderRadius: '4px',
    backgroundColor: getThemeColor(theme, MUIThemeColors.white),
    opacity: 1,
    '& .MuiSvgIcon-root': {
      color: getThemeColor(theme, MUIThemeColors.primaryMain),
    },
  }
}))

export const ColumnSelector = styled('div')(({theme})=>({
  position: 'inherit',
  top: '50%',
  left: '87%',
  height: '98%',
  width: '25%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: getThemeColor(theme, MUIThemeColors.white),
  padding: '10px',
  outline:'none'
}))

export const ColorAndTitle = styled(Box)(({ theme }) => ({
  color: getThemeColor(theme, MUIThemeColors.primaryMain),
  fontWeight: 'bold',
}))

export const ColumnOption = styled(Stack)(({theme})=>({
  color: getThemeColor(theme, MUIThemeColors.primaryMain),
  margin: '20px',
}))

export const  OpenTitle = styled(Typography)({
  fontSize: '0.8rem',
})

export const CloseButton = styled(IconButton)({
  color: 'red',
})

export const ColumnOptionInput = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    padding: 0,
  },
}) 


export const PaginationComponent = styled(Pagination)(({theme})=>({
  '& .MuiPaginationItem-root': {
      border: 0,
      backgroundColor: getThemeColor(theme, MUIThemeColors.white),
  },
  '& .selected': {
    backgroundColor: `${getThemeColor(theme, MUIThemeColors.primaryDark)} !important`,
    outline: `1px solid ${getThemeColor(theme, MUIThemeColors.primaryMain)}`,
    borderRadius: '8px',
  }
}))