import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useGetCustomerSuppliers } from '../../api/supplier'
import BasicDatePicker from '../../components/DateTimePicker'
import { AppFormProps, AppForm, FormTextField, FormSelectField, FormFileCoreUpload } from '../../components/FormCore'
import { ICustomerAdminSupplier } from '../../generated-types'
import { ICertificate } from '../../generated-types/Certificate'
import { ICertificateDocumentType } from '../../generated-types/certificate-document-type'
import { useZodForm } from '../../hooks/zod-form'
import { EnumToSelectTypes } from '../../generated-types/select-type'
import { GenericDialogLayout } from '../../components/GenericDialogLayout'
import { MainButton } from '../../components/MainButton'
import { APIResultCount } from '../../constant'
import { SuppliersFormField } from '../../components/SuppliersFormField'
import { getValidUntilDate } from 'src/utils'
import { toast } from 'react-toastify'
import { DatePickerWithDurationDropdown } from 'src/components/common/DatePickerWithDurationDropdown'

interface ICreateCertificateFormProps extends AppFormProps<ICertificate> {
  customerId: EntityId
  title: string
}
export const CreateCertificateForm: React.FC<ICreateCertificateFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  title,
}) => {
  const { t: translate } = useTranslation(['admin-customer', 'common', 'certificate'])

  const schema = z.object({
    certificateNumber: z
      .string()
      .nonempty()
      .regex(/^(?! *$).+/, translate('certificateNumberIsInvalid', { ns: 'certificate' })),
    certificateDate: z.date(),
    validUntil: z.date(),
    certificateDocumentType: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    fileId: z.union([z.string(), z.number()]),
    supplierId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
  })

  const form = useZodForm(schema)

  const certificateDocumentTypes = EnumToSelectTypes(ICertificateDocumentType, 'certificate')

  const { data: suppliers } = useGetCustomerSuppliers(customerId, 0, APIResultCount.Max)

  const [isDropdownOpen, setisDropdownOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuWidth, setMenuWidth] = useState()

  const handleChange = event => {
    if (event.target.value != null) {
      try {
        const ValidUntilDate = getValidUntilDate(form.getValues('certificateDate'), event.target.value)
        form.setValue('validUntil', ValidUntilDate)
        form.clearErrors('validUntil')
      } catch {
        toast.error(translate('error-null-certificate-date', { ns: 'certificate' }))
      }
    }
    setAnchorEl(null)
  }

  const handleOpenDurationDropdown = event => {
    setMenuWidth(event.target.clientWidth)
    setisDropdownOpen(!isDropdownOpen)
    if (event.target.closest('svg') || event.target.closest('.MuiIconButton-root')) {
      return
    }
    !isDropdownOpen ? setAnchorEl(event.currentTarget) : setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      removedCloseButton={false}
      actions={[
        <MainButton
          key="cert-submit"
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
        >
          {translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        <FormTextField
          fullWidth
          name="certificateNumber"
          label={translate('certificateNumber', { ns: 'certificate' })}
        />
        <BasicDatePicker label={translate('certificateDate', { ns: 'certificate' })} name="certificateDate" />

        <DatePickerWithDurationDropdown
          form={form}
          name="validUntil"
          startDate={form.getValues('certificateDate')}
          dropdownTitle={translate('selectDuration', { ns: 'certificate' })}
          formFieldName="validUntil"
        />

        <FormSelectField
          name="certificateDocumentType"
          data={certificateDocumentTypes}
          label={translate('documentType', { ns: 'certificate' })}
          getOptionLabel={option => option.value}
          renderOption={(props: any, option) => (
            <Box {...props} key={option.id}>
              {option.value}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
        />
        <SuppliersFormField
          suppliers={(suppliers?.items as ICustomerAdminSupplier[]) || []}
          name="supplierId"
          label={translate('certifiedBy', { ns: 'certificate' })}
        />
        <FormFileCoreUpload
          label={translate('fileName', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          height="160px"
        />
      </AppForm>
    </GenericDialogLayout>
  )
}
