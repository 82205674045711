import React from 'react'
import { Chip, Box } from '@mui/material'
import { ICertificateExtension } from 'src/generated-types/CertificateExtension'
import { DynamicTextTypography } from '../DynamicTextTypography'
import { useDialogCtx } from 'src/hooks/context-hooks'
import ViewCertificateExtensionDialog from '../main-component/ViewCertificateExtensionDialog'

interface CertificateExtensionViewerProps {
  extensions: ICertificateExtension[]
}

const CertificateExtensionViewer: React.FC<CertificateExtensionViewerProps> = ({ extensions }) => {
  const { showDialog } = useDialogCtx()

  const handleChipClick = async extension => {
    await showDialog(
      ViewCertificateExtensionDialog,
      {
        componentProps: { extension: extension },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {extensions.map((extension, index) => (
        <Chip
          sx={{ cursor: 'pointer', borderRadius: '12px' }}
          key={extension.id || index}
          label={<DynamicTextTypography text={extension.extensionNumber ?? ''} maxLength={3} variant="caption" />}
          variant="filled"
          color="primary"
          onClick={() => handleChipClick(extension)}
        />
      ))}
    </Box>
  )
}

export default CertificateExtensionViewer
