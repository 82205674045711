import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useZodForm } from '../../../hooks/zod-form'
import { z } from 'zod'
import {
  AppForm,
  AppFormProps,
  FormCheckbox,
  FormSelectField,
  FormSubmitButton,
  FormTextField,
} from '../../../components/FormCore'
import { ISupplier, IUnit } from '../../../generated-types'
import { useGetUnits } from '../../../api/unit'
import { useGetAdminSuppliers } from '../../../api/supplier'
import { useGetMaterialFactors } from '../../../api/material-factor'
import { IMaterialFactor } from '../../../generated-types/material-factor'
import { APIResultCount } from '../../../constant'
import { CancelButton } from '../../../components/CancelButton'
import { useGetProductCategoriesForDropDown } from '../../../api/product-category'
import { IProductCategory } from '../../../generated-types/product-category'

let supplierId = 0
let unitId = 0

export const ProductForm: React.FC<AppFormProps> = ({ onSubmit, onCancel, initialValues }) => {
  const productCategories = useGetProductCategoriesForDropDown()
  const { t: translate } = useTranslation(['product', 'common'])

  const schema = z.object({
    number: z.string().nonempty(translate('numberIsRequired')),
    productCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    description: z.string().nonempty(translate('descriptionIsRequired')),
    supplierId: z.number(),
    unitId: z.union([z.number(), z.string().nonempty(translate('unitIsRequired'))]),
    active: z.boolean().default(false),
    expectedLifetime: z
      .string()
      .or(z.number())
      .refine(value => value !== null && value !== '', {
        message: translate('expectedLifeTimeIsRequired'),
      })
      .transform(v => Number(v))
      .refine(value => Number(value) > 0, {
        message: translate('expectedLifeTimeShouldBeGreaterThanZero'),
      }),
    minimumBreakingLoad: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(v => Number(v)),
    materialFactorId: z.string().or(z.number()).or(z.null()).optional(),
    buoyancyKG: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(v => Number(v)),
    weightKG: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(v => Number(v)),
    plasticPerUnitKG: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(v => Number(v)),
    positionDrawingId: z
      .number()
      .or(z.string())
      .nullable()
      .transform(x => (typeof x == 'string' && x == '' ? null : x)),
    positionDrawingNumber: z.string().or(z.string()).nullable(),
    metalPerUnitKG: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(v => Number(v)),
  })

  const form = useZodForm(schema, {
    defaultValues: {
      ...initialValues,
      productCategoryId: productCategories?.data?.find(x => x.id === initialValues.productCategoryId),
    },
  })

  useEffect(() => {
    form.reset({
      ...initialValues,
      productCategoryId: productCategories?.data?.find(x => x.id === initialValues.productCategoryId),
    })
  }, [form, initialValues, productCategories?.data])
  const [selectedUnitId, setselectedUnitId] = useState<number>(initialValues?.unitId)

  const [selectedSupplierId, setselectedSupplierId] = useState<number>(initialValues?.supplierId)

  const handleSelectChange = (event, name, onAction) => {
    const id = event.target.value

    form.setValue(name, id)
    form.clearErrors(name)
    onAction(id)
  }

  const [selectedMaterialFactorId, setselectedMaterialFactorId] = useState<number>(initialValues?.materialFactorId)

  const handleChangeMaterialFactor = event => {
    setselectedMaterialFactorId(event.target.value)
  }

  useEffect(() => {
    supplierId = selectedSupplierId
    unitId = selectedUnitId
  }, [selectedMaterialFactorId, selectedSupplierId, selectedUnitId])

  const isUpdateForm = initialValues?.id

  const { data: units } = useGetUnits()
  const { data: suppliers } = useGetAdminSuppliers(0, APIResultCount.Max)
  const { data: materialFactors } = useGetMaterialFactors()

  const handleSubmit = async form => {
    form.unitId = unitId
    form.supplierId = supplierId

    onSubmit(form)
  }

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={isUpdateForm && initialValues}>
      {isUpdateForm && <FormTextField name="id" type="hidden" />}
      <FormTextField
        name="positionDrawingId"
        value={initialValues.positionDrawingId}
        type="hidden"
        sx={{ display: 'none' }}
      />
      <FormTextField
        name="positionDrawingNumber"
        value={initialValues.positionDrawingNumber}
        type="hidden"
        sx={{ display: 'none' }}
      />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormTextField name="number" label={translate('number', { ns: 'product' })} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSelectField
            name="productCategoryId"
            data={productCategories?.data as IProductCategory[]}
            label={translate('category', { ns: 'common' })}
            getOptionLabel={option => option.name}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {option.name}
              </Box>
            )}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) => {
              form.setValue('productCategoryId', value as any)
            }}
          />
        </Grid>
      </Grid>
      <FormTextField
        name="description"
        label={translate('Description', { ns: 'product' })}
        fullWidth
        multiline
        rows={2}
      />
      <Box py={1}  px={1}>
        <FormControl variant="filled" fullWidth error={!!form.formState.errors.supplierId}>
          <InputLabel htmlFor="name" id="supplierId">
            {translate('supplier', { ns: 'product' })}
          </InputLabel>
          <Select
            labelId="supplierId"
            id="supplierId"
            value={selectedSupplierId}
            onChange={event => handleSelectChange(event, 'supplierId', setselectedSupplierId)}
          >
            {suppliers?.items.map((supplier: ISupplier) => (
              <MenuItem key={supplier.id} value={supplier.id}>
                {supplier.name}
              </MenuItem>
            ))}
          </Select>
          {form.formState.errors.supplierId && (
            <FormHelperText>
              {translate('supplierIs', { ns: 'product' })}
              {form.formState.errors.supplierId.message}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box py={1} px={1}>
        <FormControl variant="filled" fullWidth error={!!form.formState.errors.unitId}>
          <InputLabel id="unitId">{translate('unit', { ns: 'product' })}</InputLabel>
          <Select
            labelId="unitId"
            id="unitId"
            value={selectedUnitId}
            onChange={event => handleSelectChange(event, 'unitId', setselectedUnitId)}
          >
            {units?.items.map((unit: IUnit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {translate(unit.name)} ({unit.abbreviation})
              </MenuItem>
            ))}
          </Select>
          {form.formState.errors.unitId && (
            <FormHelperText>
              {translate('unitIs', { ns: 'product' })}
              {form.formState.errors.unitId.message}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <FormCheckbox name="active" label={translate('isActive', { ns: 'common' })} />
      <FormTextField
        inputProps={{ type: 'number' }}
        name="expectedLifetime"
        label={translate('expectedLifetime', { ns: 'product' })}
      />
      <FormTextField
        inputProps={{ type: 'number' }}
        name="minimumBreakingLoad"
        label={translate('minimumBreakingLoad', { ns: 'product' })}
      />
      <Box py={1} px={1}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="materialFactorId">Material factor</InputLabel>
          <Select
            labelId="materialFactorId"
            id="materialFactorId"
            value={selectedMaterialFactorId}
            onChange={handleChangeMaterialFactor}
          >
            {materialFactors?.items.map((materialFactor: IMaterialFactor) => (
              <MenuItem key={materialFactor.id} value={materialFactor.id}>
                {materialFactor.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <FormTextField
        inputProps={{ type: 'number' }}
        name="buoyancyKG"
        label={translate('buoyancyKG', { ns: 'product' })}
      />
      <FormTextField inputProps={{ type: 'number' }} name="weightKG" label={translate('weightKG', { ns: 'product' })} />
      <FormTextField
        inputProps={{ type: 'number' }}
        name="plasticPerUnitKG"
        label={translate('plasticPerUnitKG', { ns: 'product' })}
      />
      <FormTextField
        inputProps={{ type: 'number' }}
        name="metalPerUnitKG"
        label={translate('metalPerUnitKG', { ns: 'product' })}
      />
      <Box className="flex justify-end gap-4 pt-4">
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}
