import { Box, styled } from '@mui/material'
import React from 'react'
import { FormField, FormLayout } from '../../generated-types/form-field'
import FieldSelectorViewer from './FieldSelectorViewer'
import { StyledContainer, StyledGrid } from './LayoutBox'

interface ILayoutProps {
  layout: FormLayout
  size: number
  values: object
}

const LayoutBoxViewer: React.FC<ILayoutProps> = ({ layout, size, values }) => {
  const { fields } = layout
  return (
    <StyledGrid md={size} marginTop={1} padding={0.1}>
      <Box display={'flex'} flexDirection={'row'} height={'100%'} borderRadius={1}>
        <EnhancedStyledComponent>
          {fields.map((field: FormField, index: number) => {
            return (
              <Box key={index}>
                <FieldSelectorViewer field={field} value={values && values[field.name]} />
              </Box>
            )
          })}
        </EnhancedStyledComponent>
      </Box>
    </StyledGrid>
  )
}

const EnhancedStyledComponent = styled(StyledContainer)({
  height: '100%',
  minHeight: 50,
  minWidth: '50%'
})

export default LayoutBoxViewer
