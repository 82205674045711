import { CustomerCtx } from 'src/context/CustomerContext'
import { authorization } from '../../../../components/common/Authorization'
import { CustomerLocationCtx } from '../../../../context/FacilityContext'
import { MainComponentContextProvider } from '../../../../context/MainComponentContext'
import { CustomerServiceStationCtx } from '../../../../context/ServiceStationContext'
import { AuthorizationType } from '../../../../enums/auth'
import { permissionValue } from '../../../../enums/permissionValue'
import { IMainComponentContainerProps, MainComponentContainer } from '../MainComponentContainer'
import { useLocationCtx } from 'src/hooks/context-hooks'

export const SelectedMainComponent: React.FC<IMainComponentContainerProps> = () => {
  const { permission } = useLocationCtx()

  return (
    <MainComponentContextProvider>
      <MainComponentContainer context={CustomerLocationCtx} permission={permission} />
    </MainComponentContextProvider>
  )
}

export const SelectedAdminMainComponent: React.FC<IMainComponentContainerProps> = () => {
  return (
    <MainComponentContextProvider>
      <MainComponentContainer
        context={CustomerCtx}
      />
    </MainComponentContextProvider>
  )
}

const SelectedServiceStationMainComponent: React.FC<IMainComponentContainerProps> = () => {
  return (
    <MainComponentContextProvider>
      <MainComponentContainer
        context={CustomerServiceStationCtx}
      />
    </MainComponentContextProvider>
  )
}

export default authorization(
  SelectedServiceStationMainComponent,
  [
    permissionValue.ServiceStation.Read,
    permissionValue.ServiceStation.Update,
    permissionValue.ServiceStation.Write,
    permissionValue.ServiceStation.Delete,
    permissionValue.ClientAdminPermission
  ],
  AuthorizationType.Page,
  CustomerServiceStationCtx,
  false,
)

export const SelectedMainComponentInCustomerAdmin = authorization(
  SelectedAdminMainComponent,
  [
    permissionValue.Locality.MainComponent.Read,
    permissionValue.ClientAdminPermission
  ],
  AuthorizationType.Page,
  CustomerCtx,
  false,
)
