import { useTranslation } from 'react-i18next'
import {
  invalidateProductCertificationByProductIdCache,
  useGetProductCertification,
  useUpdateProductCertification,
} from 'src/api/product-certification'
import { ShowDialogProps } from 'src/show-dialog'
import { ProductCertificationForm } from './ProductCertificationForm'
import { CenteredSpinner } from 'src/components/Spinner'

interface UpdateProductCertificateDialogProps extends ShowDialogProps {
  productId: EntityId
  certificationId: EntityId
}
export const UpdateProductCertificateDialog: React.FC<UpdateProductCertificateDialogProps> = ({
  pop,
  productId,
  certificationId,
}) => {
  const { t } = useTranslation(['main-component', 'common', 'certificate'])
  const updateMutate = useUpdateProductCertification(certificationId)

  const { data, isLoading } = useGetProductCertification(certificationId as EntityId)
  if (isLoading) return <CenteredSpinner />

  const onSubmit = async form => {
    form.productId = productId
    await updateMutate.mutateAsync(form, {
      onSuccess: () => {
        invalidateProductCertificationByProductIdCache.getProductCertificationByProductId(productId)
        pop()
      },
    })
  }

  return (
    <ProductCertificationForm
      title={t('update-product-certificate', { ns: 'product'})}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={data}
    />
  )
}
