import { Card, SvgIconTypeMap, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GetRGBA } from 'src/utils'

interface IToggleGroupButtonsProps {
  actions: IToggleGroupButtonActions[]
  defaultValue: any
  value: any
  setViewMode: (value: any) => void
  height?: string
  style?: React.CSSProperties | undefined
}

interface IToggleGroupButtonActions {
  title: string
  Icon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>> | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  value: any
}

const ToggleGroupButtons: React.FC<IToggleGroupButtonsProps> = ({
  actions,
  defaultValue,
  value,
  setViewMode,
  height,
  style,
}) => {
  const { t } = useTranslation('common')
  return (
    <Card elevation={2}>
      <StyledToggleButtonGroup exclusive value={defaultValue}>
        {actions.map(action => (
          <Tooltip title={action.title} key={action.value}>
            <ToggleButtonAction
              value={action.value}
              onClick={() => setViewMode(action.value)}
              enable={value == action.value}
              withIcon={action.Icon != null}
              height={height}
              style={style}
            >
              {action.Icon ? (
                <action.Icon />
              ) : (
                <Typography variant="subtitle1" textTransform={'none'} fontWeight={600}>
                  {action.title}
                </Typography>
              )}
            </ToggleButtonAction>
          </Tooltip>
        ))}
      </StyledToggleButtonGroup>
    </Card>
  )
}

export default ToggleGroupButtons

//styles
const ToggleButtonAction = styled(ToggleButton)<{ enable: boolean; withIcon: boolean; height: string | undefined }>`
  height: ${props => (props.height ? props.height : '36px')};
  width: ${props => (props.withIcon ? '60px' : '100%')};
  background-color: ${props =>
    props.enable
      ? props.theme.palette.primaryMain[props.theme.palette.mode]
      : props.theme.palette.white[props.theme.palette.mode]};
  color: ${props =>
    props.enable
      ? props.theme.palette.white[props.theme.palette.mode]
      : props.theme.palette.primaryMain[props.theme.palette.mode]};
  :hover {
    background-color: ${props =>
      props.enable
        ? props.theme.palette.primaryDark[props.theme.palette.mode]
        : props.theme.palette.white[props.theme.palette.mode]};
    border-color: ${props => props.theme.palette.primaryMain[props.theme.palette.mode]};
  }
  border-radius: 4px;
  border-color: ${props =>
    props.enable
      ? props.theme.palette.primaryMain[props.theme.palette.mode]
      : props.theme.palette.secondaryLight[props.theme.palette.mode]};
`

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '.MuiToggleButtonGroup-grouped:not(:first-of-type) ': { borderLeft: 'none !important' },
  boxShadow: `0px 3px 6px ${GetRGBA(theme.palette.black[theme.palette.mode], 0.29)}`,
}))
