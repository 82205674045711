import { MainButton } from '@lovoldsystem/ui'
import { WarningAmberRounded } from '@mui/icons-material'
import { Box, Typography, styled, useTheme } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowDialogProps } from 'src/show-dialog'
import { Flex } from 'src/styles/flexComponent'

interface WarningDialogProps extends ShowDialogProps {
  title: string
  description?: string
  onConfirm: (pop) => void
  acceptText?: string
}

const StyledFlexColumn = styled(Flex.Column)({
  width: '524px',
  height: '440px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingBottom: '56px',
  paddingTop: '56px',
})

const StyledBox = styled(Box)({
  width: '80px',
  height: '80px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '20px',
})

export const WarningDialog: FC<WarningDialogProps> = ({ title, description, onConfirm, acceptText, pop }) => {
  const theme = useTheme()
  const { t } = useTranslation(['common'])
  return (
    <StyledFlexColumn spacing={'16px'} paddingX={'36px'}>
      <StyledBox bgcolor={theme.palette.errorLight[theme.palette.mode]}>
        <WarningAmberRounded
          htmlColor={theme.palette.errorDark[theme.palette.mode]}
          sx={{ height: '48px', width: '48px' }}
        />
      </StyledBox>
      <Typography variant="h5" fontWeight={600} color={theme.palette.primaryMain[theme.palette.mode]}>
        {title}
      </Typography>
      <Box width={'386px'}>
        <Typography
          variant="subtitle1"
          fontWeight={400}
          color={theme.palette.black[theme.palette.mode]}
          textAlign={'center'}
        >
          {description}
        </Typography>
      </Box>
      <Flex.Row gap={1}>
        <MainButton
          variant="outlined"
          sx={{
            width: '215px',
            height: '64px',
            color: theme.palette.black[theme.palette.mode],
            borderColor: theme.palette.black[theme.palette.mode],
            ':hover': { borderColor: theme.palette.black[theme.palette.mode] },
          }}
          onClick={pop}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            Cancel
          </Typography>
        </MainButton>
        <MainButton
          variant="contained"
          sx={{
            width: '215px',
            height: '64px',
            bgcolor: theme.palette.errorMain[theme.palette.mode],
            ':hover': { bgcolor: theme.palette.errorLight[theme.palette.mode] },
          }}
          onClick={() => {
            onConfirm(pop)
          }}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            {acceptText ?? t('delete')}
          </Typography>
        </MainButton>
      </Flex.Row>
    </StyledFlexColumn>
  )
}
