import { DeleteOutline } from '@mui/icons-material'
import { Box, CardMedia, styled } from '@mui/material'
import React from 'react'
import { Flex } from 'src/styles/flexComponent'
import { GridActionButton } from './common/GridActionButton'

const ROVContainer = () => {
    return (
        <Flex.Row flexWrap='wrap' className='p-12-core' >
            <ROV />
            <ROV />
            <ROV />
            <ROV />
            <ROV />
            <ROV />
            <ROV />
            <ROV />
            <ROV />
        </Flex.Row>
    )
}

const ROV = () => {
    return <Box padding={'5px'} width={'25%'}>
        <Flex.Row justifyContent={'end'} className='pr-6-core' height={0} >
            <ActionButton>
                <GridActionButton height='16px' width='16px' buttonIcon={<DeleteOutline sx={{ fontSize: '14px' }} />} />
            </ActionButton>
        </Flex.Row>
        <Flex.Row >
            <CardMedia component={'video'} controls src='/aqua.mp4' />
        </Flex.Row>
    </Box>
}

const ActionButton = styled(Flex.Row)`
margin-top: 6px;
`


export default ROVContainer