import { invalidateCustomerCache, useGetCustomer, useUpdateCustomer } from '../../api/customer-admin'
import { CenteredSpinner } from '../../components/Spinner'
import { CustomerForm } from '../../forms/CustomerForm'
import { ShowDialogProps } from '../../show-dialog'
import { invalidateCustomer } from 'src/api/customer'

interface UpdateCustomerProps extends ShowDialogProps {
  id: EntityId
}

const UpdateCustomerDialog: React.FC<UpdateCustomerProps> = ({ pop, id }) => {
  const mutation = useUpdateCustomer(id as EntityId)

  const onSubmit = async form => {
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateCustomerCache.getCustomers()
        invalidateCustomer.getUniqueAdminCustomers()
      },
    })
    pop(true)
  }

  const { data, isLoading } = useGetCustomer(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <CustomerForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data} />
  )
}

export default UpdateCustomerDialog
