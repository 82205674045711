import { IResource } from '../generated-types/resource'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const mainComponentBaseURL = (customerId: EntityId) => `customers/${customerId}/resource`

export const resourceEndpoints = {
  baseURL(customerId) {
    return `${mainComponentBaseURL(customerId)}`
  },
  singleResources(customerId, id) {
    return `${mainComponentBaseURL(customerId)}/${id}`
  },
  unAssignedResources(customerId) {
    return `${mainComponentBaseURL(customerId)}/unAssignedResources`
  }
}

export const invalidateTaskResourceCache = {
  getAllResources(customerId) {
    queryClient.invalidateQueries(resourceEndpoints.baseURL(customerId))
  }
}

export const useGetAllResources = (customerId: number, page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IResource>>(resourceEndpoints.baseURL(customerId), { page, pageSize })

export const useGetAllUnAssignedResources = (customerId: number, page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IResource>>(resourceEndpoints.unAssignedResources(customerId), { page, pageSize })

export const useAddResource = (customerId: number) => usePost(resourceEndpoints.baseURL(customerId))

export const useUpdateResource = (customerId: number, id: number) => usePut(resourceEndpoints.singleResources(customerId, id))

export const useDeleteResource = (customerId: number) => useDelete(resourceEndpoints.baseURL(customerId))
