import { Box, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import jsPDF from 'jspdf'
import React from 'react'
import LayoutBoxViewer from '../formGenerator/LayoutBoxViewer'
import { useTranslation } from 'react-i18next'
import { FormFieldType } from '../../enums'
import { getFormattedDate } from '../../utils'
import { UserDetail, getUserDetail } from '../../utils/getUserDetails'

const ReportViewer = ({ layouts, schema, report, pop, disabled = false, createdAt, createdByUser }) => {
    const { t } = useTranslation(['common', 'overall-reports'])
    return (<><DialogContent className='scrollbar'>
        <Box flex={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button variant='contained' onClick={() => exportSchemaReportPDF(report, layouts, t)} disabled={disabled}>{t('export')}</Button>
        </Box>
        <Box id={'pdf-viewer'} padding={1}  >
            <Box display={'flex'} flexDirection={'row'} >
                <Box flex={2}>
                    <Typography variant='h5'>{schema.name}</Typography>
                    <Typography variant='caption'>{schema.description}</Typography>
                </Box>
                <Box flex={1} display={'flex'} flexDirection={'column'} marginTop={3}>
                    <Typography variant='caption'>
                        {t('date')} : {getFormattedDate(createdAt)}
                    </Typography>
                    <Typography variant='caption'>
                        {t('createdBy')}: {getUserDetail(createdByUser, UserDetail.FullName)}
                    </Typography>
                </Box>
            </Box>
            {
                layouts.map((row, index) => {
                    return <Grid key={index} container width={'100%'}>
                        {
                            row.columns.map((layout, indexCol) => {
                                return <LayoutBoxViewer
                                    values={JSON.parse(report.values)}
                                    size={12 / row.columns.length}
                                    key={indexCol}
                                    layout={layout}
                                />
                            })
                        }
                    </Grid>
                })
            }
        </Box>
    </DialogContent>
        <DialogActions>
            <Button onClick={pop}>
                {t('cancel')}
            </Button>
        </DialogActions>
    </>
    )
}

export const exportSchemaReportPDF = (report, layouts, t) => {
    const doc = new jsPDF()
    const elementHTML = document.getElementById('pdf-viewer')
    doc.html(elementHTML || '', {
        callback: function (doc) {
            const values = JSON.parse(report.values)
            layouts.forEach(row => {
                row.columns.forEach(columnData => {
                    columnData?.fields.forEach(field => {
                        if (field.type == FormFieldType.fileUpload) {
                            const dimensions = doc.getTextDimensions(field.name)
                            if (dimensions != null) {
                                doc.link(dimensions.h, dimensions.w + 7, 50, 5, { url: values[field.name] })
                            }
                        }
                    })
                })
            })
            doc.save((new Date(Date.now())).toLocaleString() + `-${t('overall-reports', { ns: 'overall-reports' })}.pdf`)
        },
        margin: 10,
        width: 190,
        windowWidth: 650
    })
}

export default ReportViewer