import { useTranslation } from 'react-i18next'
import { CertificateTable } from '../../Certificates/Components/CertificateTable'
import { permissionValue } from '../../../../enums/permissionValue'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { Box, useTheme } from '@mui/material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { IMainComponentType } from 'src/generated-types/main-component-type'

interface CertificateTabProps {
  onAddCertificateForComponent: () => void
  mainComponentId: EntityId
  mainComponentType?: IMainComponentType
  positionId: EntityId
  customerId: EntityId
  Context?: React.Context<any> | null
}

export default function CertificateTab({
  onAddCertificateForComponent,
  mainComponentId,
  mainComponentType,
  positionId,
  customerId,
  Context = null,
}: CertificateTabProps) {
  const { t } = useTranslation(['certificate', 'common'])
  const theme = useTheme()
  return (
    <CommonPageLayout
      titleSection={{ subtitle: t('Certificates', { ns: 'certificate' }) }}
      topAction={[
        {
          buttonText: t('add-certificate', { ns: 'certificate' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddCertificateForComponent,
          permissionBinaryValues: [
            permissionValue.Locality.MainComponent.Certificate.Write,
            permissionValue.ClientAdminPermission,
            permissionValue.ServiceStation.Write,
          ],
          variant: 'outlined',
          shadowWeight: null,
          context: Context,
        },
      ]}
      titleContainerProps={{ paddingBottom: '12px' }}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <CertificateTable
          mainComponentId={mainComponentId!}
          mainComponentType={mainComponentType}
          positionId={positionId}
          customerId={customerId}
          certificatesParams={{}}
          height={window.innerHeight / 3}
        />
      </Box>
    </CommonPageLayout>
  )
}
