import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react'
import { IDeviationType } from '../../../generated-types/deviation-type'
import { useFormContext } from 'react-hook-form'
import { ReactComponent as Mooring } from 'src/assets/icons/mooring.svg'
import { ReactComponent as Equipment } from 'src/assets/icons/equipments.svg'
import { ReactComponent as Component } from 'src/assets/icons/components.svg'
import { Flex } from 'src/styles/flexComponent'
import { Card } from 'src/styles/Index'
import { Typography } from '@mui/material'
import { Padding } from 'src/styles/paddingComponent'
import { IDeviationStepperFormStep } from 'src/generated-types/form-wizard'

const SelectTypeStep: FC<{
  setDisabled: Dispatch<SetStateAction<boolean>>
  setActiveStep: (step: IDeviationStepperFormStep) => void
}> = ({ setDisabled, setActiveStep }) => {
  const { t: translate } = useTranslation(['common', 'deviation'])
  const { getValues, reset } = useFormContext()

  const deviationTypes = [
    {
      title: translate('mooring'),
      Icon: Mooring,
      value: IDeviationType.Mooring,
      onClick: e => handleChange(e, IDeviationType.Position, IDeviationStepperFormStep.Step_3),
    },
    {
      title: translate('additional-equipment', { ns: 'deviation' }),
      Icon: Equipment,
      value: IDeviationType.Equipment,
      onClick: e => handleChange(e, IDeviationType.Equipment, IDeviationStepperFormStep.Step_4),
    },
    {
      title: translate('net-or-ring', { ns: 'deviation' }),
      Icon: Component,
      value: IDeviationType.MainComponent,
      onClick: e => handleChange(e, IDeviationType.MainComponent, IDeviationStepperFormStep.Step_4),
    },
  ]

  useEffect(() => {
    setDisabled(false)
  }, [setDisabled])

  useEffect(() => {
    if (!getValues('type')) {
      reset({
        ...getValues(),
        type: IDeviationType.Mooring,
      })
    }
  }, [getValues('type')])

  const handleChange = useCallback((e, v, step) => {
    if (getValues('type') !== v) {
      reset({
        ...getValues(),
        type: v,
        mooring: undefined,
        position: undefined,
        component: undefined,
        mainComponent: undefined,
      })
    } else {
      reset({
        ...getValues(),
        type: v,
      })
    }
    setActiveStep(step)
  }, [])

  return (
    <Flex.Row>
      {deviationTypes.map(type => (
        <Padding.p12 key={type.title}>
          <Card
            sx={{
              height: '222px',
              width: '222px',
              cursor: 'pointer',
              textAlign: 'center',
              padding: '48px 57px 0px 57px',
            }}
            onClick={type.onClick}
          >
            <Flex.Column
              justifyContent={'flex-end'}
              className="p-8-core"
              width={'100%'}
              height={'100%'}
              alignItems={'center'}
            >
              <Flex.Column justifyContent={'center'} className="pt-16-core pb-2-core pr-16-core pl-16-core">
                <type.Icon />
              </Flex.Column>
              <Padding.p14>
                <Typography variant="subtitle1" fontWeight={'medium'}>
                  {translate(type.title, { ns: 'deviation' })}
                </Typography>
              </Padding.p14>
            </Flex.Column>
          </Card>
        </Padding.p12>
      ))}
    </Flex.Row>
  )
}

export default SelectTypeStep
