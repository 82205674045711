import { Collapse } from '@mui/material'
import { Box } from '@mui/material'
import classNames from 'classnames'
import React, { useState } from 'react'
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'
import { useQuery, useQueryClient } from 'react-query'
import { invalidateCustomer, useGetCustomerAdministrators } from '../../api/customer'
import { useGetLocalityGroups } from '../../api/locality-groups'
import { MainButton } from '../../components/MainButton'
import { ScreenTitle } from '../../components/ScreenTitle'
import { Spinner } from '../../components/Spinner'
import { CustomerUserInviteDialog } from '../../dialogs/CustomerUserInviteDialog'
import { LoadingGuard } from '../../guards/LoadingGuard'
import { useCustomerCtx, useDialogCtx } from '../../hooks/context-hooks'
import { getLocalityGroupDetails } from '../../http'
import { Queries } from '../../queries'

export const CustomerAccessControlScreen = () => {
  const { customer } = useCustomerCtx()
  const { showDialog } = useDialogCtx()
  const queryClient = useQueryClient()

  const onAddUser = async () => {
    const added = await showDialog(CustomerUserInviteDialog, { componentProps: { customer } })
    if (!added) return

    queryClient.invalidateQueries(Queries.LocalityGroupDetailsAll(customer.id))
    invalidateCustomer.getCustomerAdministrators(customer.id)
    queryClient.invalidateQueries(Queries.UserInvites(customer.id))
  }

  return (
    <>
      <Box className="px-4 py-2 mt-2 mb-2 bg-white shadow-md" borderRadius={2}>
        <Box className="flex justify-between mb-4">
          <ScreenTitle title="Access Control" />
          <MainButton onClick={onAddUser}>Add user</MainButton>
        </Box>
        <Box>
          <Box className="text-xl font-semibold text-primary">Administrators</Box>
          <Box pt={2}>
            <Administrators customerId={customer.id} />
          </Box>
        </Box>
        <Box pt={4}>
          <Box className="text-xl font-semibold text-primary">Locality Groups</Box>
          <Box pt={2}>
            <LocalityGroups customerId={customer.id} />
          </Box>
        </Box>
        <Box pt={4}>
          <Box className="text-xl font-semibold text-primary">Localities</Box>
        </Box>
      </Box>
    </>
  )
}

const Administrators = ({ customerId }) => {
  const { data, isLoading } = useGetCustomerAdministrators(customerId)

  if (isLoading) return <Spinner />

  return (
    <>
      {data?.map(user => (
        <Box py={0.25} key={user.id}>
          <AdministratorRow user={user} />
        </Box>
      ))}
    </>
  )
}

const AdministratorRow = ({ user }) => {
  const [open, setOpen] = useState(false)

  return <ListRow title={user.email} open={open} onToggle={() => setOpen(!open)}></ListRow>
}

const LocalityGroups = ({ customerId }) => {
  const { data, isLoading } = useGetLocalityGroups(customerId)

  if (isLoading) return <Spinner />

  return (
    <Box>
      {data?.items.map(lg => (
        <Box py={0.25} key={lg.id}>
          <LocalityGroupRow group={lg} customerId={customerId} />
        </Box>
      ))}
    </Box>
  )
}
const LocalityGroupRow: React.FC<{ group: any; customerId: EntityId }> = ({ group, customerId }) => {
  const [open, setOpen] = useState(false)
  const { data, isLoading } = useQuery(
    Queries.LocalityGroupDetails(customerId, group.id),
    () => getLocalityGroupDetails(customerId, group.id),
    { enabled: open },
  )

  return (
    <ListRow
      title={
        <Box className="flex items-center gap-4">
          <Box className="font-semibold text-l">{group.name}</Box>
          {group.isStaticGroup && <Box className="text-sm text-gray-600">(System group)</Box>}
        </Box>
      }
      onToggle={() => setOpen(!open)}
      open={open}
    >
      <Collapse in={open}>
        <Box pt={2}>
          <Box className="flex items-center justify-between">
            <Box className="text-xl font-semibold">Users</Box>
            <MainButton>Add user</MainButton>
          </Box>
          <Box>
            <LoadingGuard loading={isLoading}>
              <Box className="flex justify-between">
                <Box className="basis-10" />
                <Box className="basis-8/12">Name</Box>
                <Box className="flex justify-start basis-3/12">Access Level</Box>
              </Box>
              {data?.users.map(({ user, accessLevel }) => (
                <Box
                  key={user.id}
                  className="flex items-center justify-between py-3 my-2 text-black bg-white rounded-md"
                >
                  <Box className="flex justify-center basis-10">
                    <MdArrowDownward />
                  </Box>
                  <Box className="font-semibold basis-8/12 text-l">{user.email}</Box>
                  <Box className="flex justify-start basis-3/12 ">
                    <Box className="p-1 text-white bg-green-600 rounded-md shrink">{accessLevel}</Box>
                  </Box>
                </Box>
              ))}
            </LoadingGuard>
          </Box>

          {!group.isStaticGroup && (
            <>
              <Box pt={4} pb={1} className="flex items-center justify-between">
                <Box className="text-xl font-semibold">Localities</Box>
                <MainButton>Add locality</MainButton>
              </Box>
              <Box>
                <LoadingGuard loading={isLoading}>
                  {data?.localities.map(l => (
                    <Box key={l.id} py={0.5}>
                      <ListRow title={l.name} white />
                    </Box>
                  ))}
                </LoadingGuard>
              </Box>
            </>
          )}
        </Box>
      </Collapse>
    </ListRow>
  )
}

const ListRow: React.FC<{ title: string | JSX.Element; open?: boolean; onToggle?: any; white?: boolean }> = ({
  children,
  title,
  onToggle,
  white,
  open = false,
}) => {
  return (
    <Box
      className={classNames('p-3 border border-gray rounded-md bg-white', {
        ['bg-gray-400']: open && !white,
        ['text-white']: open && !white,
        ['text-black']: white,
        ['bg-white']: white,
      })}
    >
      <Box onClick={onToggle} className="flex items-center gap-4 cursor-pointer">
        {!open ? <MdArrowDownward /> : <MdArrowUpward />}
        {typeof title === 'string' ? <Box className="font-semibold text-l">{title}</Box> : title}
      </Box>
      {children}
    </Box>
  )
}
