import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useGetCustomerUsers } from '../../../../api/customer'
import { AppForm, FormTextField, FormSelectField, FormSubmitButton } from '../../../../components/FormCore'
import { useZodForm } from '../../../../hooks/zod-form'
import { getUserDetail, UserDetail } from '../../../../utils/getUserDetails'
import { IUser } from '../../../../generated-types'

const ResourceFrom = ({ initialValues, customerId, onSubmit, pop, isUpdateForm = false }) => {
  const { t: translate } = useTranslation(['locality', 'common'])

  const schema = z.object({
    name: z
      .string()
      .min(1, translate('nameIsRequired', { ns: 'common' }))
      .max(200),
    description: z
      .string()
      .min(1, translate('descriptionIsRequired', { ns: 'common' }))
      .max(400),
    userId: z.string().optional(),
  })

  const form = useZodForm(schema, { defaultValues: initialValues })

  const { data: customerUsers } = useGetCustomerUsers(customerId as EntityId)

  const handleSubmit = async form => {
    if (isUpdateForm) {
      form.id = initialValues.id
    }
    onSubmit(form)
    pop()
  }

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={isUpdateForm}>
      <FormTextField name="name" label={translate('name', { ns: 'common' })} defaultValue={initialValues?.Name} />
      <FormTextField name="description" label={translate('description', { ns: 'common' })} multiline rows={4} />
      <FormSelectField
        name="responsibleUser"
        data={(customerUsers! as IUser[]) ?? []}
        label={translate('responsibleUser', { ns: 'locality' })}
        getOptionLabel={option => getUserDetail(option, UserDetail.NameAndEmail)}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            {getUserDetail(option, UserDetail.NameAndEmail)}
          </Box>
        )}
        defaultValue={initialValues?.user}
        clearOnBlur={false}
        clearOnEscape={false}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        onChange={(e, value) => form.setValue('userId', (value as IUser).id)}
      />
      <Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>
        <FormSubmitButton createText={translate('add', { ns: 'common' })} />
        <Button key="locality-form-cancel" onClick={pop}>
          {translate('cancel', { ns: 'common' })}
        </Button>
      </Box>
    </AppForm>
  )
}

export default ResourceFrom
