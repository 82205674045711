import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import React, { useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import {
  customPropertyEndpoints,
  invalidateCustomPropertyCache,
  useDeleteCustomPropertyByAdmin,
  useDeleteCustomPropertyByCustomer,
} from '../../../api/custom-property'
import { ControllerTypes, DataTypes } from '../../../enums'
import { UpdateCustomPropertyDialog } from './UpdateCustomProperty'
import { toast } from 'react-toastify'
import { Grid } from '@mui/material'
import GenericTable from 'src/components/table/GenericTable'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const GenericCustomPropertyTable: React.FC<{
  isSuperAdmin: boolean
  dataQueryUrl: string
  onUpdate: (id: number) => void
  onDeleteCustomProperty: (id: number) => void
  isLoading?: boolean
}> = ({ isSuperAdmin, dataQueryUrl, onUpdate, onDeleteCustomProperty, isLoading }) => {
  const { t: translate } = useTranslation(['custom-property', 'common'])

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'label',
      headerName: translate('label'),
      flex: 1,

      renderCell: row => {
        const labelNames = JSON.parse(row.value)
        return (
          <Grid container>
            {Object.keys(labelNames).map(key => (
              <React.Fragment key={key}>
                <Grid item xs={2}>
                  {key}
                </Grid>
                <Grid item xs={10}>
                  {labelNames[key]}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )
      },
    },
    {
      field: 'dataType',
      headerName: translate('dataType'),
      flex: 1,
      renderCell: row => {
        return <div>{DataTypes[row.value]}</div>
      },
    },
    {
      field: 'controllerType',
      headerName: translate('controllerType'),
      flex: 1,
      renderCell: row => {
        return <div>{translate(ControllerTypes[row.value], { ns: 'custom-property' })}</div>
      },
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: '',
        width: 60,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(row.id)}
            disabled={(isSuperAdmin ? row.customerId != null : row.customerId == null) || isLoading}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteCustomProperty(row.id)}
            disabled={(isSuperAdmin ? row.customerId != null : row.customerId == null) || isLoading}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [isSuperAdmin],
  )

  return (
    <GenericTable
      id="custom-property-table"
      queryUrl={dataQueryUrl}
      columns={columns}
      autoHeight={false}
      height={'81vh'}
      enableServerPagination
    />
  )
}

export const CustomPropertyTable: React.FC<{ customerId: EntityId; isSuperAdmin: boolean }> = ({
  customerId,
  isSuperAdmin,
}) => {
  if (isSuperAdmin) return <CustomPropertyAdminTable />
  return <CustomPropertyCustomerTable customerId={customerId} />
}

const CustomPropertyAdminTable: React.FC = () => {
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { t: translate } = useTranslation(['custom-property', 'common'])
  const { mutateAsync, isLoading: isDeleting } = useDeleteCustomPropertyByAdmin()

  const onDeleteCustomProperty = (id: EntityId) => {
    showConfirmDialog(translate('deleteCustomProperty'), translate('areYouSureDeleteCustomProperty')).then(async e => {
      if (e) {
        await mutateAsync(id, {
          onSuccess: () => {
            invalidateCustomPropertyCache.useGetCustomPropertiesAdmin()
            invalidateCustomPropertyCache.useGetCustomPropertyAdmin(id)
            toast.success(translate('custom-property-deleted-successfully'))
          },
          onError: () => {
            toast.error(translate('deletingError', { ns: 'common' }))
          },
        })
      }
    })
  }

  const onUpdate = async id => {
    const added = await showDialog(
      UpdateCustomPropertyDialog,
      {
        componentProps: { id: id as EntityId, isAdmin: true },
      },
      undefined,
      true,
    )
    if (!added) return
  }
  return (
    <GenericCustomPropertyTable
      isSuperAdmin={true}
      dataQueryUrl={customPropertyEndpoints.customPropertiesByAdmin()}
      onUpdate={onUpdate}
      onDeleteCustomProperty={onDeleteCustomProperty}
      isLoading={isDeleting}
    />
  )
}

const CustomPropertyCustomerTable: React.FC<{ customerId: EntityId }> = ({ customerId }) => {
  const { t: translate } = useTranslation(['custom-property', 'common'])
  const { mutateAsync, isLoading: isDeleting } = useDeleteCustomPropertyByCustomer(customerId)
  const { showDialog, showConfirmDialog } = useDialogCtx()

  const onDeleteCustomProperty = (id: EntityId) => {
    showConfirmDialog(translate('deleteCustomProperty'), translate('areYouSureDeleteCustomProperty')).then(async e => {
      if (e) {
        await mutateAsync(id, {
          onSuccess: () => {
            invalidateCustomPropertyCache.useGetCustomPropertiesCustomer(customerId)
            invalidateCustomPropertyCache.useGetCustomPropertyCustomer(customerId, id)
            toast.success(translate('custom-property-deleted-successfully'))
          },
          onError: () => {
            toast.error(translate('deletingError', { ns: 'common' }))
          },
        })
      }
    })
  }

  const onUpdate = async id => {
    const added = await showDialog(
      UpdateCustomPropertyDialog,
      {
        componentProps: { id: id as EntityId, isAdmin: false, customerId: customerId },
      },
      undefined,
      true,
    )
    if (!added) return
  }
  return (
    <GenericCustomPropertyTable
      isSuperAdmin={false}
      dataQueryUrl={customPropertyEndpoints.customPropertiesByCustomer(customerId)}
      onUpdate={onUpdate}
      onDeleteCustomProperty={onDeleteCustomProperty}
      isLoading={isDeleting}
    />
  )
}
