import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { Spinner } from 'src/components/Spinner'
import { ShowDialogProps } from 'src/show-dialog'
import { Flex } from 'src/styles/flexComponent'

interface Props extends ShowDialogProps {
  fileUrl: string
}

export const ShowFileDialog: React.FC<Props> = ({ pop, fileUrl }) => {
  const [loading, setLoading] = useState(true)
  const handleLoad = () => {
    setLoading(false)
  }
  const { t } = useTranslation(['common'])
  const isWordOrExcelFile =
    fileUrl.endsWith('.doc') || fileUrl.endsWith('.docx') || fileUrl.endsWith('.xls') || fileUrl.endsWith('.xlsx')

  const viewerUrl = isWordOrExcelFile ? `https://docs.google.com/gview?url=${fileUrl}&embedded=true` : fileUrl

  return (
    <GenericDialogLayout title={t('preview-document')} pop={pop} sx={{ height: '80vh' }}>
      {loading && (
        <Flex.Column width={'100%'} height={'99%'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex.Column>
      )}
      <iframe src={viewerUrl} width={'100%'} height={'99%'} style={{ border: 'none' }} onLoad={handleLoad} />
    </GenericDialogLayout>
  )
}
