import { IMaintenance } from '../generated-types/IMaintenance'
import { IMaintenanceTypeCount } from '../generated-types/IMaintenanceTypesCount'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useGet } from './reactQuery'

const baseUrl = (customerId: EntityId, localityId: EntityId) => `customers/${customerId}/localities/${localityId}`

export const maintenanceEndpoints = {
  getMaintenances(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/maintenance`
  },
  getMaintenanceTypesCount(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/maintenance-types-count`
  },
}

export const invalidateMaintenanceCache = {
  getMaintenances(customerId: EntityId, localityId: EntityId) {
    queryClient.invalidateQueries(maintenanceEndpoints.getMaintenances(customerId, localityId))
  },
  getMaintenanceTypesCount(customerId: EntityId, localityId: EntityId) {
    queryClient.invalidateQueries(maintenanceEndpoints.getMaintenanceTypesCount(customerId, localityId))
  },
}

export const useGetMaintenances = (customerId: EntityId, localityId: EntityId, page?: number, pageSize?: number) =>
  useGet<IPaginatedResult<IMaintenance>>(maintenanceEndpoints.getMaintenances(customerId, localityId), { page, pageSize })

export const useGetMaintenanceTypesCount = (
    customerId: EntityId, 
    localityId: EntityId, 
    queryParams?: { maintenanceForWeek: boolean },
    page?: number, pageSize?: number) =>
  useGet<IMaintenanceTypeCount>(maintenanceEndpoints.getMaintenanceTypesCount(customerId, localityId), {...queryParams, page, pageSize })
