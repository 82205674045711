import { css, styled } from '@mui/material'
import { padding } from './paddingCss'

const styles = css`
    .p-1-core{
        ${padding.p1}
    }
    .p-2-core{
        ${padding.p2}
    }
    .p-4-core{
        ${padding.p4}
    }
    .p-6-core{
        ${padding.p6}
    }
    .p-8-core{
        ${padding.p8}
    }
    .p-10-core{
        ${padding.p10}
    }
    .p-12-core{
        ${padding.p12}
    }
    .p-14-core{
        ${padding.p14}
    }
    .p-16-core{
        ${padding.p16}
    }
    .p-20-core{
        ${padding.p20}
    }
    .p-24-core{
        ${padding.p24}
    }
    .pl-1-core{
        ${padding.pl1}
    }
    .pl-2-core{
        ${padding.pl2}
    }
    .pl-4-core{
        ${padding.pl4}
    }
    .pl-6-core{
        ${padding.pl6}
    }
    .pl-8-core{
        ${padding.pl8}
    }
    .pl-10-core{
        ${padding.pl10}
    }
    .pl-12-core{
        ${padding.pl12}
    }
    .pl-14-core{
        ${padding.pl14}
    }
    .pl-16-core{
        ${padding.pl16}
    }
    .pl-22-core{
        ${padding.pl22}
    }
    .pl-24-core{
        ${padding.pl24}
    }
    .pt-1-core{
        ${padding.pt1}
    }
    .pt-2-core{
        ${padding.pt2}
    }
    .pt-4-core{
        ${padding.pt4}
    }
    .pt-6-core{
        ${padding.pt6}
    }
    .pt-8-core{
        ${padding.pt8}
    }
    .pt-10-core{
        ${padding.pt10}
    }
    .pt-12-core{
        ${padding.pt12}
    }
    .pt-14-core{
        ${padding.pt14}
    }
    .pt-16-core{
        ${padding.pt16}
    }
    .pt-24-core{
        ${padding.pt24}
    }
    .pb-1-core{
        ${padding.pb1}
    }
    .pb-2-core{
        ${padding.pb2}
    }
    .pb-4-core{
        ${padding.pb4}
    }
    .pb-6-core{
        ${padding.pb6}
    }
    .pb-8-core{
        ${padding.pb8}
    }
    .pb-10-core{
        ${padding.pb10}
    }
    .pb-12-core{
        ${padding.pb12}
    }
    .pb-14-core{
        ${padding.pb14}
    }
    .pb-16-core{
        ${padding.pb16}
    }
    .pb-24-core {
        ${padding.pb24}
    }
    .pr-1-core{
        ${padding.pr1}
    }
    .pr-2-core{
        ${padding.pr2}
    }
    .pr-4-core{
        ${padding.pr4}
    }
    .pr-6-core{
        ${padding.pr6}
    }
    .pr-8-core{
        ${padding.pr8}
    }
    .pr-10-core{
        ${padding.pr10}
    }
    .pr-12-core{
        ${padding.pr12}
    }
    .pr-14-core{
        ${padding.pr14}
    }
    .pr-16-core{
        ${padding.pr16}
    }
    .ph-6-core{
        ${padding.pr6}
        ${padding.pl6}
    }
    .ph-8-core{
        ${padding.pr8}
        ${padding.pl8}
    }
`

export const GlobalStyled = styled('div')`
    ${styles}
`