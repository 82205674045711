import { Box } from '@mui/material'
import React from 'react'
import { Component } from 'react'

export interface GpsToDmsProps {
  decimalGPS: number
  isLongitude: boolean
}

export function CalculateGpsToDms(decimalGPS, isLongitude) {
  //TODO: need to check more about these if's
  if (isLongitude && !(-180 < decimalGPS && 180 > decimalGPS)) {
    // return 'Invalid coordinate. Longitude has to be in interval <-180;180>'
    return ''
  } else if (!isLongitude && !(-90 < decimalGPS && 90 > decimalGPS)) {
    // return 'Invalid coordinate. Latitude has to be in interval <-90;90>'
    return ''
  } else {
    const dir = !isLongitude ? (decimalGPS > 0 ? ' N' : ' S') : decimalGPS > 0 ? ' Ø' : ' V'
    const degree = 0 | (decimalGPS < 0 ? (decimalGPS = -decimalGPS) : decimalGPS)
    const minute = parseFloat(((Math.abs(decimalGPS) - degree) * 60).toFixed(6))
    return degree + '\u00B0' + minute + dir
  }
}

export class GpsToDms extends Component<{ decimalGPS; isLongitude }> {
  render() {
    const { decimalGPS, isLongitude } = this.props
    return <Box>{CalculateGpsToDms(decimalGPS, isLongitude)}</Box>
  }
}

