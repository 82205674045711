import { AppRoutesNavigation } from '../app-routes'
import { ReactComponent as vesselIcon } from '../assets/icons/vesselIcon.svg'
import { ReactComponent as LocalityIcon } from '../assets/icons/localityIcon.svg'
import { ReactComponent as ServiceStation } from '../assets/icons/serviceStationIcon.svg'

export const mainTopBarItems = [
  { name: 'localities', main: AppRoutesNavigation.Localities.basePath, path: AppRoutesNavigation.Localities.main, Icon: LocalityIcon },
  { name: 'service-stations', main: AppRoutesNavigation.serviceStation.basePath, path: AppRoutesNavigation.serviceStation.main, Icon: ServiceStation },
  { name: 'vessels', path: AppRoutesNavigation.Vessel, Icon: vesselIcon }
]
