import { IParams, IPosition, IBatchNumberDetails, UpdatePositionPositionDrawing, IDeviationCounts, IPositionDropDownSelectOptions, IFile } from '../generated-types'
import { IConfirmOlexPositions } from '../generated-types/confirm-olex'
import { IGenerateComponents, IGenerateComponentsForSelectedPositions } from '../generated-types/generate-components'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePatch, usePost, usePut } from './reactQuery'
import { positionQueryKey } from 'src/query-keys'
import { IPositionRequestParams } from 'src/generated-types/position-request-params'

const baseUrl = (customerId: EntityId, localityId: EntityId, mooringId?: EntityId) =>
  mooringId === undefined
    ? `customers/${customerId}/localities/${localityId}/positions`
    : `customers/${customerId}/localities/${localityId}/moorings/${mooringId}/positions`

export const positionEndpoints = {
  getPositions(customerId: EntityId, localityId: EntityId, mooringId?: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}`
  },
  getPositionsWithMinimalData(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/minimal-data`
  },
  getBatchNumberDetailsByProductId(customerId: EntityId, localityId: EntityId, mooringId?: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/get-batch-number-details-by-productId`
  },
  getPositionsForDropDownByMooringId(customerId: EntityId, localityId: EntityId, mooringId?: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/positions_for_drop_down`
  },
  getCagesForDropDownByMooringId(customerId: EntityId, localityId: EntityId, mooringId?: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/cages_for_drop_down`
  },
  getCagePositionsByLocality(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId, undefined)}/cages-by-locality`
  },
  getCagePositionDeviations(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId, undefined)}/get-cage-deviation-counts`
  },
  getPosition(customerId: EntityId, localityId: EntityId, mooringId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/${id}`
  },
  getCageSelectOptionsByMooring(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/cage-select-options-by-mooring`
  },
  createPosition(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}`
  },
  updatePosition(customerId: EntityId, localityId: EntityId, mooringId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/${id}/update-position`
  },
  updatePositionBulk(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/bulkUpdate`
  },
  uploadImage(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}/upload-image`
  },
  createComponentsForPositions(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/generateComponentsForPositions`
  },
  generateComponentsForPositions(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/generate-components-for-positions`
  },
  updatePositionDrawing(customerId: EntityId, localityId: EntityId, mooringId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}/${id}/update-position-drawing`
  },
  deletePosition(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId, mooringId)}`
  },
  confirmOlexPositions(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/confirm-positions`
  },
}

export const invalidatePositionCache = {
  getPositions(
    customerId: EntityId,
    localityId: EntityId,
    mooringId?: EntityId,
    queryParams?: { isWithInfo?: boolean; isIgnoreQueryFilters?: boolean },
  ) {
    queryClient.invalidateQueries(positionQueryKey.GET_POSITIONS(customerId, localityId, mooringId, queryParams))
  },
  getPositionsWithMinimalData(
    customerId: EntityId,
    localityId: EntityId,
    mooringId?: EntityId,
  ) {
    queryClient.invalidateQueries(positionQueryKey.GET_POSITIONS_MINIMAL_DATA(customerId, localityId, mooringId))
  },
  getPosition(customerId: EntityId, localityId: EntityId, mooringId: EntityId, id?: EntityId, queryParams?: IParams) {
    queryClient.invalidateQueries(positionQueryKey.GET_POSITION(customerId, localityId, mooringId, id, queryParams))
  },
  getBatchNumberDetailsByProductId(customerId: EntityId, localityId: EntityId, mooringId?: EntityId) {
    queryClient.invalidateQueries(positionEndpoints.getBatchNumberDetailsByProductId(customerId, localityId, mooringId))
  },
  getCagePositionDeviations(customerId: EntityId, localityId: EntityId, positionId?: EntityId) {
    queryClient.invalidateQueries(positionQueryKey.GET_Position_DEVIATION_COUNT(customerId, localityId, positionId))
  }
}

export const useGetPositions = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId?: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
  queryParams?: IPositionRequestParams,
) => useGet<IPaginatedResult<IPosition>>(
  positionEndpoints.getPositions(customerId, localityId, mooringId),
  {
    page,
    pageSize,
    ...queryParams,
  },
  { queryKey: positionQueryKey.GET_POSITIONS(customerId, localityId, mooringId, queryParams) },
)

export const useGetPositionsWithMinimalData = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId: EntityId,
  queryParams?: IPositionRequestParams,
) => useGet<Array<IPosition>>(
  positionEndpoints.getPositionsWithMinimalData(customerId, localityId, mooringId),
  {
    ...queryParams,
  },
  { queryKey: positionQueryKey.GET_POSITIONS_MINIMAL_DATA(customerId, localityId, mooringId, queryParams) },
)

export const useGetBatchNumberDetailsByProductId = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId?: EntityId,
  queryParams?: { productId: number },
) =>
  useGet<Array<IBatchNumberDetails>>(
    positionEndpoints.getBatchNumberDetailsByProductId(customerId, localityId, mooringId),
    {
      ...queryParams,
    },
  )

export const useGetCagePositionDeviations = (customerId: EntityId,
  localityId: EntityId,
  queryParams?: { positionId: EntityId | undefined },
) => useGet<IDeviationCounts>(
  positionEndpoints.getCagePositionDeviations(customerId, localityId),
  { ...queryParams },
  { queryKey: positionQueryKey.GET_Position_DEVIATION_COUNT(customerId, localityId, queryParams?.positionId ?? 0), enabled: (queryParams?.positionId != null) },
)

export const useConfirmOlexPositions = (customerId: EntityId, localityId: EntityId) =>
  usePut<any, IConfirmOlexPositions>(positionEndpoints.confirmOlexPositions(customerId, localityId))

export const useUploadModelImage = (customerId: EntityId, localityId: EntityId, positionId: EntityId) => {
  return usePost<IFile, FormData>(positionEndpoints.uploadImage(customerId, localityId, positionId))
}

export const useGetPositionsForDropDownByMooringId = (customerId: EntityId, localityId: EntityId, mooringId: EntityId,) =>
  useGet<IPosition[]>(positionEndpoints.getPositionsForDropDownByMooringId(customerId, localityId, mooringId))

export const useGetCagesForDropDownByMooringId = (customerId: EntityId, localityId: EntityId, mooringId: EntityId,) =>
  useGet<IPosition[]>(positionEndpoints.getCagesForDropDownByMooringId(customerId, localityId, mooringId))

export const useGetCagePositionsByLocality = (customerId: EntityId, localityId: EntityId) =>
  useGet<IPosition[]>(positionEndpoints.getCagePositionsByLocality(customerId, localityId))

export const useGetPosition = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId: EntityId,
  id: EntityId,
  queryParams?: IParams,
) => useGet<IPosition>(positionEndpoints.getPosition(customerId, localityId, mooringId, id), queryParams, {
  queryKey: positionQueryKey.GET_POSITION(customerId, localityId, mooringId, id, queryParams),
  enabled: id != null,
})

export const useGetCageSelectOptionsByMooring = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  useGet<Array<IPositionDropDownSelectOptions>>(
    positionEndpoints.getCageSelectOptionsByMooring(customerId, localityId, mooringId),
    {
      queryKey: positionQueryKey.GET_CAGE_SELECT_OPTION_BY_MOORING(customerId, localityId, mooringId),
    },
  )

export const useAddPosition = (customerId: EntityId, localityId: EntityId, mooringId: EntityId, formData: object) =>
  usePost<IPosition, IPosition>(positionEndpoints.createPosition(customerId, localityId, mooringId), { data: formData })

export const useUpdatePosition = (customerId: EntityId, localityId: EntityId, mooringId: EntityId, id: EntityId) =>
  usePut<IPosition, IPosition>(positionEndpoints.updatePosition(customerId, localityId, mooringId, id))

export const useUpdatePositionBulk = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  usePut<IPosition, IPosition[]>(positionEndpoints.updatePositionBulk(customerId, localityId, mooringId))

export const useCreateComponentsForPositions = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  usePost<IPosition, IGenerateComponents>(
    positionEndpoints.createComponentsForPositions(customerId, localityId, mooringId),
  )

export const useGenerateComponentsForPositions = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  usePost<IPosition, IGenerateComponentsForSelectedPositions>(
    positionEndpoints.generateComponentsForPositions(customerId, localityId, mooringId),
  )

export const useUpdatePositionDrawing = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId: EntityId,
  id: EntityId,
) =>
  usePatch<IPosition, UpdatePositionPositionDrawing>(
    positionEndpoints.updatePositionDrawing(customerId, localityId, mooringId, id),
  )

export const useDeletePosition = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  useDelete<IPosition>(positionEndpoints.deletePosition(customerId, localityId, mooringId))
