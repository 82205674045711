import { GridColumns, GridNativeColTypes, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import GenericTable from '../../../../components/table/GenericTable'
import { IComponent } from '../../../../generated-types'
import { Box, useTheme } from '@mui/material'
import BasicDatePicker from '../../../../components/DateTimePicker'
import {
  invalidateComponentsCache,
  useBulkUpdateComponentInstallationDate,
  useDeleteComponent,
  useDeleteComponents,
  useUpdateComponent,
  useUpdateComponentInlineValues,
} from '../../../../api/components'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import React from 'react'
import { InstallationDateDialog } from '../../workAssignments/component/InstallationDateDialog'
import { GridActionButton } from '../../../../components/common/GridActionButton'
import { ReactComponent as AddDeviation } from '../../../../assets/svg/addDeviation.svg'
import moment from 'moment'
import DesignatedLifetime from './DesignatedLifetime'
import {
  CreatePositionDeviationDialog,
  ICreateDeviationDialogProps,
} from '../../deviations/CreatePositionDeviationDialog'
import { IDeviationType } from '../../../../generated-types/deviation-type'
import { useGetPosition } from '../../../../api/positions'
import { useDialogCtx } from '../../../../hooks/context-hooks'
import { DateRangeOutlined, DeleteOutline, Edit, ReportProblemOutlined } from '@mui/icons-material'
import { BatchNumberCell } from './BatchNumberCell'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { Padding } from 'src/styles/paddingComponent'
import { Flex } from 'src/styles/flexComponent'
import { getLocalDateTime, getUTCDate } from 'src/utils/function'
interface IComponentTable {
  components: IComponent[]
  setComponents: React.Dispatch<React.SetStateAction<IComponent[]>>
  customerId: EntityId
  positionId: EntityId
  localityId?: EntityId
  mooringId?: EntityId
  height?: string | number
  enableToolbar?: boolean
  onEdit?: (id: number) => void
  onSelectDeviation?: (componentId: number) => void
  onRowClick?: (component: IComponent) => void | undefined
}

export const ComponentsTable: React.FC<IComponentTable> = ({
  components,
  customerId,
  positionId,
  localityId,
  mooringId,
  height,
  enableToolbar = true,
  onEdit,
  onSelectDeviation,
  onRowClick,
}) => {
  const { t } = useTranslation(['mooring', 'common', 'position', 'component', 'deviation', 'product'])
  const [selectedRows, setSelectedRows] = useState([] as IComponent[])

  const { mutateAsync } = useUpdateComponentInlineValues(customerId, positionId)
  const { mutateAsync: mutationUpdateInstallationDateBulk, isLoading: isBulkUpdating } =
    useBulkUpdateComponentInstallationDate(customerId, positionId)
  const { mutate } = useDeleteComponent(customerId, positionId)
  const { data: position } = useGetPosition(customerId, localityId as EntityId, mooringId as EntityId, positionId)
  const { mutateAsync: componentBulkDelete } = useDeleteComponents(customerId, positionId)
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const theme = useTheme()

  const { mutateAsync: mutationUpdateComponent } = useUpdateComponent(customerId, positionId)

  const ComponentActions = () => {
    return (
      <Flex.Row
        position={'relative'}
        height={0}
        zIndex={100}
        top={20}
        right={10}
        justifyContent={'right'}
        alignItems={'center'}
        p={1}
        gap={1}
      >
        <GridActionButton
          onClick={() => {
            if (selectedRows.length > 0) {
              handleSetInstallationDate()
            } else {
              toast(t('noSelectedComponents'), { type: 'error' })
            }
          }}
          variant="outlined"
          height="33px"
          width="33px"
          buttonIcon={
            <DateRangeOutlined
              htmlColor={selectedRows.length > 0 ? theme.palette.primaryMain[theme.palette.mode] : 'disable'}
            />
          }
          disabled={selectedRows.length == 0}
        />
        <GridActionButton
          onClick={() => handleComponentsBulkDelete()}
          height="33px"
          width="33px"
          variant="outlined"
          buttonIcon={
            <DeleteOutline
              htmlColor={selectedRows.length > 0 ? theme.palette.primaryMain[theme.palette.mode] : 'disable'}
            />
          }
          disabled={selectedRows.length == 0}
        />
      </Flex.Row>
    )
  }

  const onAddDeviationForComponent = useCallback(
    async (component: IComponent) => {
      const added = await showDialog(
        CreatePositionDeviationDialog,
        {
          componentProps: {
            customerId: customerId as EntityId,
            localityId: localityId as EntityId,
            mooringId: mooringId as EntityId,
            positionId: positionId as EntityId,
            position: position,
            component: component,
            type: IDeviationType.Position,
            disableSteps: true,
          } as ICreateDeviationDialogProps,
        },
        undefined,
        true,
      )
      if (!added) return
    },
    [showDialog, customerId, localityId, mooringId, positionId, position],
  )

  const onDelete = async (id: EntityId) => {
    showConfirmDialog(
      t('deleteProduct', { ns: 'product' }),
      t('areYouSureDeleteProduct', { ns: 'product' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await mutate(id, {
          onSuccess: () => {
            toast.success(t('componentDeleted', { ns: 'component' }))
            invalidateComponentsCache.getComponents(customerId, positionId as EntityId)
            pop()
          },
        })
      },
    )
  }

  const onRowsSelectionHandler = useCallback(
    ids => {
      setSelectedRows(components.filter(x => ids.includes(x.id)))
    },
    [components],
  )

  const handleItemInstallationDateChange = useCallback(
    (row: IComponent, date: Date) => {
      if (row) {
        row.installationDate = getUTCDate(date)
        mutateAsync(row, {
          onSuccess: () => {
            invalidateComponentsCache.getComponents(customerId as EntityId, positionId as EntityId)
          },
        })
      }
    },
    [customerId, positionId],
  )

  const handleSetInstallationDate = useCallback(() => {
    showDialog(
      InstallationDateDialog,
      {
        componentProps: {
          onSubmit: (date: Date, pop) => {
            mutationUpdateInstallationDateBulk(
              {
                componentIds: selectedRows.map(x => x.id),
                installationDate: date,
              },
              {
                onSuccess: () => {
                  invalidateComponentsCache.getComponents(customerId as EntityId, positionId as EntityId)
                  pop()
                },
              },
            )
          },
        },
      },
      undefined,
      true,
    )
  }, [selectedRows])

  const handleComponentsBulkDelete = async () => {
    showConfirmDialog(
      t('deleteProduct', { ns: 'product' }),
      t('areYouSureDeleteProduct', { ns: 'product' }),
      undefined,
      async pop => {
        componentBulkDelete(
          { componentIds: selectedRows?.map(x => x.id) as number[] },
          {
            onSuccess: () => {
              invalidateComponentsCache.getComponents(customerId as EntityId, positionId as EntityId)
              toast.success(t('deletedSuccessfully', { ns: 'common' }))
              pop()
            },
          },
        )
      },
    )
  }

  const updateComponentInline = useCallback(
    (component: IComponent) => {
      mutationUpdateComponent(component, {
        onSuccess: () => {
          invalidateComponentsCache.getComponents(customerId, positionId)
        },
      })
    },
    [customerId, mutationUpdateComponent, positionId],
  )

  const onCellEditCommit = useCallback(
    ({ id, field, value }) => {
      const component = (components || [])?.find(c => c.id === id)
      if (component) {
        if (field === 'quantity') {
          value <= 0 ? toast.error(t('quantityCantBeNegative', { ns: 'component' })) : (component[field] = value)
        } else {
          component[field] = value
        }
        updateComponentInline(component)
      }
    },
    [components],
  )

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'positionDrawingNumber',
        headerName: '#',
        width: 2,
        sortable: false,
      },
      {
        field: 'productNumber',
        headerName: t('productNumber', { ns: 'component' }),
        minWidth: 135,
        renderCell: params => {
          return (
            <Flex.Row justifyContent={'center'}>
              <Padding.pt4>{params.row?.productNumber}</Padding.pt4>
              {onSelectDeviation && (
                <Padding.pl4>
                  {params?.row?.deviationCount > 0 ? (
                    <ReportProblemOutlined
                      className="hover-pointer"
                      sx={{ color: getThemeColor(theme, MUIThemeColors.errorMain) }}
                      onClick={() => onSelectDeviation(params.row?.id)}
                    />
                  ) : (
                    <ReportProblemOutlined
                      className="hover-pointer"
                      onClick={() => onSelectDeviation(params.row?.id)}
                    />
                  )}
                </Padding.pl4>
              )}
            </Flex.Row>
          )
        },
      },
      {
        field: 'productDescription',
        headerName: t('productDescription', { ns: 'component' }),
        flex: 2,
        renderCell: params => {
          const isDeviation = params?.row?.deviationCount > 0
          return (
            <span style={{ color: isDeviation ? `${theme.palette.error[theme.palette.mode]}` : '' }}>
              {params.value}
            </span>
          )
        },
      },
      {
        field: 'quantity',
        headerName: t('quantity', { ns: 'component' }),
        minWidth: 20,
        editable: onEdit ? false : true,
      },
      {
        field: 'installationDate',
        headerName: t('installationDate', { ns: 'component' }),
        minWidth: 150,
        type: 'date',
        editable: true,
        renderCell: ({ value }) => {
          return value ? moment(getLocalDateTime(value)).format('Do MMMM YYYY') : '-'
        },
        renderEditCell: onEdit
          ? undefined
          : params => (
              <InstallationDateCell {...params} handleItemInstallationDateChange={handleItemInstallationDateChange} />
            ),
      },
      {
        field: 'potencialCertificateBNs',
        headerName: t('batchNumbers', { ns: 'component' }),
        minWidth: 200,
        renderCell: params => {
          return (
            <BatchNumberCell
              batchNumbersJson={params?.row?.potencialCertificateBNs}
              customerId={customerId}
              localityId={localityId as EntityId}
              mooringId={mooringId as EntityId}
              productId={params?.row?.productId as EntityId}
              positionId={positionId}
              componentId={params?.row?.id as EntityId}
              productDescription={params?.row?.productDescription}
            />
          )
        },
      },
      {
        field: 'designatedLifeTime',
        headerName: t('designatedLifeTime', { ns: 'component' }),
        minWidth: 250,
        renderCell: params => {
          return <DesignatedLifetime row={params.row} />
        },
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions' as GridNativeColTypes,
        minWidth: 150,
        renderCell: ({ id, row }) => (
          <Flex.Row justifyContent={'center'} alignItems={'center'} gap={1} key={id}>
            <GridActionButton
              key={id}
              buttonIcon={<AddDeviation color={theme.palette.primaryMain[theme.palette.mode]} />}
              onClick={() => {
                onAddDeviationForComponent(row)
              }}
              toolTipTitle={t('addDeviation', { ns: 'deviation' })}
              variant="outlined"
              shadowWeight={0}
              height="27px"
              width="27px"
            />
            <GridActionButton
              key={id}
              buttonIcon={<Edit sx={{ color: theme.palette.primaryMain[theme.palette.mode] }} />}
              hidden={onEdit != undefined}
              onClick={() => {
                if (onEdit) onEdit(row.id as number)
              }}
              shadowWeight={0}
              toolTipTitle={t('edit', { ns: 'common' })}
              variant="outlined"
              height="27px"
              width="27px"
            />
            <GridActionButton
              key={id}
              buttonIcon={<DeleteOutline htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
              onClick={() => onDelete(id)}
              shadowWeight={0}
              toolTipTitle={t('delete', { ns: 'common' })}
              variant="outlined"
              height="27px"
              width="27px"
            />
          </Flex.Row>
        ),
      },
    ],
    [handleItemInstallationDateChange, t],
  )

  return (
    <Flex.Column bgcolor={getThemeColor(theme, MUIThemeColors.white)}>
      <ComponentActions />
      <GenericTable
        id="components"
        data={components as IComponent[]}
        columns={STATIC_COLUMNS}
        onRowClick={onRowClick ? params => onRowClick(params.row) : undefined}
        disableSelectionOnClick={true}
        checkboxSelection={true}
        onSelectionModelChange={ids => onRowsSelectionHandler(ids)}
        enableToolbar={enableToolbar}
        height={height}
        autoHeight={height ? false : true}
        onCellEditCommit={onEdit ? undefined : onCellEditCommit}
        className={'inner-table'}
      />
    </Flex.Column>
  )
}

interface InstallationDateCellProps extends GridRenderEditCellParams {
  handleItemInstallationDateChange: (row: IComponent, date: Date) => void
}
export const InstallationDateCell: React.FC<InstallationDateCellProps> = params => {
  const { id, value, field, handleItemInstallationDateChange } = params
  const apiRef = useGridApiContext()
  return (
    <Box width={'100%'} py={1}>
      <BasicDatePicker
        name="installationDate"
        onChange={date => {
          apiRef.current.setEditCellValue({ id, field, value: date })
          handleItemInstallationDateChange(params.row, date)
        }}
        value={value}
        isWithoutAppForm={true}
        shouldDisableDate={date => date > new Date()}
      />
    </Box>
  )
}
