import { Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useAddDeviationFollowUp, invalidateDeviation, useAddDeviationFollowUpAdmin } from '../../../api/deviation'
import BasicDatePicker from '../../../components/DateTimePicker'
import { AppForm, FormTextField, FormSelectField, FormCheckbox } from '../../../components/FormCore'
import { IDeviationPriority } from '../../../generated-types/deviation-priority'
import { EnumToSelectTypes } from '../../../generated-types/select-type'
import { useZodForm } from '../../../hooks/zod-form'
import { ShowDialogProps } from '../../../show-dialog'
import { UserDetail, getUserDetail } from '../../../utils/getUserDetails'
import { useGetCustomerUsers } from '../../../api/customer'
import { IUser } from '../../../generated-types'
import { getUsers } from '../../../http'
import { useQuery } from 'react-query'
import { Queries } from '../../../queries'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { MainButton } from 'src/components/MainButton'

interface CreateDeviationFollowUpDialogProps extends ShowDialogProps {
  customerId: EntityId
  deviationId: EntityId
  dueDate?: Date
  priority?: IDeviationPriority
  localityId: EntityId
  positionId?: EntityId
}

interface CreateDeviationFollowUpDialogAdminProps extends ShowDialogProps {
  deviationId: EntityId
  dueDate?: Date
  priority?: IDeviationPriority
}

interface CreateDeviationFollowUpFormProps extends ShowDialogProps {
  deviationId: EntityId
  customerUsers: IUser[]
  dueDate?: Date
  priority?: IDeviationPriority
  isLoading: boolean
  onSubmit: (form: any) => void
}

export const CreateDeviationFollowUpForm: React.FC<CreateDeviationFollowUpFormProps> = ({
  pop,
  customerUsers,
  deviationId,
  dueDate,
  priority,
  onSubmit,
}) => {
  const { t } = useTranslation(['deviation', 'common'])

  const deviationPriorities = EnumToSelectTypes(IDeviationPriority, 'deviation')

  const deviationSchema = z.object({
    deviationId: z.string().or(z.number()).optional(),
    priority: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    responsiblePersonId: z
      .object(
        {
          id: z.string().nonempty(t('selectOneOfTheGivenOptions', { ns: 'common' })),
        },
        {
          required_error: t('selectOneOfTheGivenOptions', { ns: 'common' }),
          invalid_type_error: t('selectOneOfTheGivenOptions', { ns: 'common' }),
        },
      )
      .transform(value => value.id),
    dueDate: z
      .date()
      .or(z.string())
      .default(dueDate as Date),
    performedBy: z.string().nonempty(t('provideValidInput', { ns: 'common' })),
    description: z.string().nonempty(),
    status: z.boolean().optional(),
    fileId: z.any().optional(),
  })

  const form = useZodForm(deviationSchema, {
    defaultValues: {
      dueDate: dueDate as Date,
      priority: deviationPriorities[priority as IDeviationPriority],
    },
  })
  return (
    <GenericDialogLayout
      title={t('addNewDeviationFollowUp', { ns: 'deviation' })}
      pop={pop}
      actions={[
        <MainButton
          key="submit"
          data-cy="submitAddFollowUp"
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
        >
          {t('add', { ns: 'common' })}
        </MainButton>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        <FormTextField type="hidden" name="deviationId" value={deviationId} sx={{ display: 'none' }} />
        <FormSelectField
          name="responsiblePersonId"
          data-cy="responsiblePerson"
          data={customerUsers!}
          label={t('responsiblePerson', { ns: 'deviation' })}
          getOptionLabel={option => getUserDetail(option, UserDetail.Any)}
          renderOption={(props: any, option) => (
            <Box {...props} key={option}>
              {getUserDetail(option, UserDetail.Any)}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
        />
        <Grid container>
          <Grid item xs={12} sm={4}>
            <FormTextField name="performedBy" label={t('performedBy', { ns: 'common' })} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BasicDatePicker label={t('dueDate', { ns: 'common' })} name="dueDate" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormSelectField
              name="priority"
              data={deviationPriorities}
              label={t('priority', { ns: 'common' })}
              getOptionLabel={option => option.value}
              renderOption={(props: any, option) => (
                <Box {...props} key={option.id}>
                  {option.value}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              defaultValue={deviationPriorities[priority as IDeviationPriority]}
              data-cy="priority"
            />
          </Grid>
        </Grid>
        <FormTextField name="description" label={t('description', { ns: 'common' })} multiline rows={2} />
        <FormCheckbox name="status" label={t('closeDeviation', { ns: 'deviation' })} />
      </AppForm>
    </GenericDialogLayout>
  )
}

export const CreateDeviationFollowUpDialog: React.FC<CreateDeviationFollowUpDialogProps> = ({
  pop,
  customerId,
  deviationId,
  dueDate,
  priority,
  localityId,
  positionId,
}) => {
  const { data: customerUsers } = useGetCustomerUsers(customerId)
  const { mutateAsync, isLoading } = useAddDeviationFollowUp(customerId, deviationId)
  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess:async() => {
        invalidateDeviation.invalidateAllDeviations(
          customerId as EntityId,
          localityId,
          undefined,
          deviationId,
          positionId,
        )
        pop()
      }
    })
  }
  return (
    <CreateDeviationFollowUpForm
      pop={pop}
      deviationId={deviationId}
      customerUsers={customerUsers as IUser[]}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dueDate={dueDate}
      priority={priority}
    />
  )
}

export const CreateDeviationFollowUpDialogAdmin: React.FC<CreateDeviationFollowUpDialogAdminProps> = ({
  pop,
  deviationId,
  dueDate,
  priority,
}) => {
  const { mutateAsync, isLoading } = useAddDeviationFollowUpAdmin(deviationId)
  const { data: customerUsers } = useQuery(Queries.Users, async () => {
    return await getUsers()
  })
  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: async () => {
        invalidateDeviation.invalidateAllDeviations()
        pop()
      },
    })
  }
  return (
    <CreateDeviationFollowUpForm
      pop={pop}
      deviationId={deviationId}
      customerUsers={customerUsers as unknown as IUser[]}
      isLoading={isLoading}
      onSubmit={onSubmit}
      dueDate={dueDate}
      priority={priority}
    />
  )
}
