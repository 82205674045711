import { Autocomplete, Box } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { AppTextField } from './AppTextField'
import { ICustomerAdminSupplier, ISupplier } from '../generated-types'
import { t } from 'i18next'
import { useFormFieldError } from './FormCore'
import { useController } from 'react-hook-form'

interface ISuppliersFormFieldsProps {
  suppliers: ISupplier[] | ICustomerAdminSupplier[]
  name: string
  label?: string
  disabled?: boolean
}
export const SuppliersFormField: FC<ISuppliersFormFieldsProps> = ({ suppliers, name, label,disabled }) => {
  const { hasError, message } = useFormFieldError(name)
  const { field } = useController({ name: name })

  const supplierList: Array<ISupplier | ICustomerAdminSupplier> = useMemo(() => {
    return suppliers ?? []
  }, [suppliers])

  const [selectedSupplier, setSelectedSupplier] = useState<ISupplier | ICustomerAdminSupplier | null>(null)

  useEffect(() => {
    if (field.value && supplierList?.length > 0) {
      setSelectedSupplier(
        supplierList.find(s => s.id === (typeof field.value == 'number' ? field.value : field.value?.id)) ?? null,
      )
    }

    if (field.value == null) {
      setSelectedSupplier(null)
    }
  }, [field.value, supplierList])

  return (
    <Autocomplete
      key={name}
      disabled={disabled}
      options={supplierList}
      getOptionLabel={option => option.name ?? t('no-data', { ns: 'common' })}
      renderOption={(props: any, option) => (
        <Box {...props} key={option.id}>
          {option.name ?? t('no-data', { ns: 'common' })}
        </Box>
      )}
      value={selectedSupplier}
      onChange={(_, v) => {
        setSelectedSupplier(v)
        field.onChange(v)
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderInput={params => (
        <AppTextField
          {...params}
          helperText={message}
          error={hasError}
          name={name}
          label={label ?? t('supplier', { ns: 'common' })}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}
