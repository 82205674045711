import { Stack, StepConnector, stepConnectorClasses, StepLabel, styled } from '@mui/material'

//styled
export const StepLabelStyled = styled(StepLabel)`
  .MuiStepIcon-root{
    font-size:16px !important;
    height:44px;
    width: 44px;
    color: ${props => props.theme.palette.secondaryMain[props.theme.palette.mode]} !important;
  }
  .MuiStepIcon-root.Mui-active {
    color: ${props => props.theme.palette.primaryMain[props.theme.palette.mode]} !important;
  }
    .MuiStepIcon-root.Mui-completed {
    color: ${props => props.theme.palette.primaryMain[props.theme.palette.mode]} !important;
  }

  .MuiStepLabel-label{
    font-size:16px !important;
    width: 160px;
    padding-left: 10px;
  }
`

export const DetailContainer = styled(Stack)`
    background-color: ${props => props.theme.palette.secondaryLight[props.theme.palette.mode]};
    padding: 5px;
    border-radius: 4px;
    margin-left: 8px;
    margin-right: 8px;
`

export const CustomizedConnector = styled(StepConnector)(({ theme }) => ({ 
  [`&.${stepConnectorClasses.active}`]: { 
      [`& .${stepConnectorClasses.line}`]: { 
      backgroundColor: theme.palette.primaryMain[theme.palette.mode],
      height: '2px',
      }, 
  }, 
  [`&.${stepConnectorClasses.completed}`]: { 
      [`& .${stepConnectorClasses.line}`]: { 
      backgroundColor:theme.palette.primaryMain[theme.palette.mode],
      height: '2px',
      }, 
  }, 
  [`& .${stepConnectorClasses.line}`]: { 
    height: '1px',
    border: '10px',
    backgroundColor: theme.palette.secondaryMain[theme.palette.mode],
      borderRadius: 1, 
  }, 
}))