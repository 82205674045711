import { IFile, IPosition, IPositionType } from './generated-types'
import logger, { levels, setLevel } from 'loglevel'
import { Log } from 'oidc-client'
import PDF from 'src/assets/svg/pdf.svg'
import DOC from 'src/assets/svg/doc.svg'
import EXCEL from 'src/assets/svg/excel.svg'
import IMAGE from 'src/assets/svg/img.svg'
import packageJson from '../package.json'
import moment from 'moment'
import {
  INTERNAL_SERVER_ERROR,
  pageSizes,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from './constant'
import { toast } from 'react-toastify'
import { t } from 'i18next'
import { getLocalDateTime } from './utils/function'
import { Theme } from '@mui/material'
import { MUIThemeColors } from './mui-theme'
import i18n from './i18n'

export function setGlobalLogLevel() {
  Log.logger = logger

  const logLevelEnv = process.env.REACT_APP_LOG_LEVEL?.toLowerCase() || 'info'

  switch (logLevelEnv) {
    case 'info':
      setLevel(levels.INFO)
      break
    case 'debug':
      setLevel(levels.DEBUG)
      break
    case 'error':
      setLevel(levels.ERROR)
      break
    case 'trace':
      setLevel(levels.TRACE)
      break
    case 'warn':
      setLevel(levels.WARN)
      break
  }
}

export const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB']
export function bytesToSize(bytes: number): string {
  if (bytes === 0) return 'n/a'
  const i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`
}

export const imageMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/*']

export function isFileContentTypeImage(fileContentType: string) {
  const mimeType = fileContentType.split('.').pop()
  return imageMimeTypes.includes(mimeType!)
}

export function isFileUrlExtensionImage(fileUrl: string) {
  const regex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i
  return regex.test(fileUrl)
}

export function isFileImage(file: IFile) {
  if (!file) return false

  return imageMimeTypes.some(supportedMime => file.contentType?.includes(supportedMime))
}

export const contentType = {
  'application/pdf': { name: 'PDF', icon: PDF },
  'image/jpeg': { name: 'JPEG', icon: IMAGE },
  'image/png': { name: 'PNG', icon: IMAGE },
  'image/jpg': { name: 'JPG', icon: IMAGE },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { name: 'Excel', icon: EXCEL },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { name: 'Word', icon: DOC },
}

export const caching = () => {
  const version = localStorage.getItem('version')
  if (version != packageJson.version) {
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name)
        })
      })

      window.location.reload()
    }

    localStorage.clear()
    localStorage.setItem('version', packageJson.version)
  }
}

export const getFormattedDate = (date?: Date, format?: string) => {
  return date ? moment(getLocalDateTime(date)).format(format ?? 'YYYY-MM-DD') : '-'
}

export const dateIsPassed = (firstDate?: Date, secondDate?: Date) => {
  return moment(firstDate).isBefore(secondDate)
}

export const shortenFileName = (fileName: string, maxLength: number): { fileName: string; isShorten: boolean } => {
  let isShorten = false
  const parts = fileName.split('.')
  const extension = parts.pop()
  let fileNameWithoutExtension = parts.join('.')

  if (fileNameWithoutExtension.length > maxLength) {
    isShorten = true
    fileNameWithoutExtension = fileNameWithoutExtension.slice(0, maxLength) + '...'
  }

  return { fileName: `${fileNameWithoutExtension}.${extension}`, isShorten: isShorten }
}

export const checkMod11 = (value: string) => {
  value = value.split(/\s|-/g).join('')
  if (value.length >= 9) {
    const numberArray = value.split('').map(Number)
    let controlNumber = 2,
      sumForMod = 0,
      i: number

    for (i = value.length - 2; i >= 0; --i) {
      sumForMod += numberArray[i] * controlNumber
      if (++controlNumber > 7) controlNumber = 2
    }
    const result = 11 - (sumForMod % 11)

    if ((result === 11 ? 0 : result) === parseInt(value.charAt(value.length - 1), 10)) {
      return true
    }
  }
}

export const handleToastMessage = (message?: string) => {
  if (!message) return
  if (message === SUCCESSFULLY_CREATED) {
    toast.success(t('addedSuccessfully', { ns: 'common' }))
  } else if (message === SUCCESSFULLY_UPDATED) {
    toast.success(t('updatedSuccessfully', { ns: 'common' }))
  } else if (message === SUCCESSFULLY_DELETED) {
    toast.success(t('deletedSuccessfully', { ns: 'common' }))
  } else if (message === INTERNAL_SERVER_ERROR) {
    toast.error(t('unExpectedError', { ns: 'common-api-message' }))
  }
}

export const validateCertificateResponse = (message: string) => {
  if (message == 'CannotAddCertificate') {
    toast(t('CannotAddCertificate', { ns: 'certificate' }), { type: 'error' })
  }
  if (message == 'ValidUntilLessThanCertificateDate') {
    toast(t('ValidUntilLessThanCertificateDate', { ns: 'certificate' }), { type: 'warning' })
  }
  if (message == 'CertificateDateGreaterThanValidUntil') {
    toast(t('CertificateDateGreaterThanValidUntil', { ns: 'certificate' }), { type: 'warning' })
  }
  if (message == 'NotAValidCertificate') {
    toast(t('NotAValidCertificate', { ns: 'certificate' }), { type: 'warning' })
  }
}

export const CompareTwoDatesWithoutTime = (date1: Date, date2: Date) => {
  const momentDate1 = moment(date1)
  const momentDate2 = moment(date2)

  // Check if the dates are equal (ignoring time)
  return !momentDate1.isSame(momentDate2, 'day')
}

// Check if the string is non-empty and does not contain only whitespace
export const validateString = (input: string) => {
  return input.trim().length > 0
}

export const getMonthName = (monthNumber: number) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const monthIndex = monthNumber - 1
  return months[monthIndex]
}

interface IFormatDaysToTextByDays {
  days: number
}

interface IFormatDaysToTextByDifference {
  startDate: Date
  endDate: Date
}

export const formatDaysToText = (props: IFormatDaysToTextByDays | IFormatDaysToTextByDifference): string => {
  let days = 0

  if (typeof (props as IFormatDaysToTextByDays).days === 'number') {
    days = (props as IFormatDaysToTextByDays).days
  } else {
    const { startDate, endDate } = props as IFormatDaysToTextByDifference
    if (startDate && endDate) {
      days = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
    }
  }

  const years = Math.floor(days / 365)
  days -= years * 365
  const months = Math.floor(days / 30)
  days -= months * 30

  let result = ''
  if (years > 0) {
    result += `${years} ${t('year', { ns: 'common' })}${years > 1 ? 's' : ''} `
  }
  if (months > 0) {
    result += `${months} ${t('month', { ns: 'common' })}${months > 1 ? 's' : ''} `
  }
  if (days > 0) {
    result += `${days} ${t('day', { ns: 'common' })}${days > 1 ? 's' : ''} `
  }

  if (!result.trim()) {
    return `0 ${t('days', { ns: 'common' })}`
  }

  return result.trim()
}

export const GetRGBA = (color, alpha) => {
  const r = parseInt(color.slice(1, 3), 16)
  const g = parseInt(color.slice(3, 5), 16)
  const b = parseInt(color.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const fileSize = contentLength => {
  let size = contentLength
  if (size < 1024) {
    return `${size} KB`
  }
  size = (size / 1024).toFixed(2)
  if (size < 1024) {
    return `${size} MB`
  }
  size = (size / 1024).toFixed(2)
  if (size < 1024) {
    return `${size} GB`
  }
}

export const getThemeColor = (theme: Theme, color: MUIThemeColors) => {
  return theme.palette[color][theme.palette.mode]
}

export const getPageSize = size => {
  let sizeReturn = 5
  pageSizes.forEach(sizeVal => {
    if (sizeVal > size) {
      sizeReturn = sizeVal
    }
  })
  return sizeReturn
}

export const setToMidnight = date => {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

export function filterAndSortPositions(positions: IPosition[], filter: IPositionType[]) {
  const sortedPositions = positions.sort(p => parseInt(p.name))
  if (filter.length == 0) return sortedPositions
  const filteredPositions = sortedPositions.filter(position => filter.includes(position.type))
  return filteredPositions
}

export const getTranslatedText = (name: string) => {
  return i18n.language == 'en' ? JSON.parse(name as string).en ?? '' : JSON.parse(name as string).no ?? ''
}

export function getMedian(arr) {
  if (!Array.isArray(arr) || arr.length === 0) {
    throw new Error('Input must be a non-empty array')
  }

  // Sort the array in ascending order
  const sorted = arr.slice().sort((a, b) => a - b)

  const mid = Math.floor(sorted.length / 2)

  // If odd, return the middle element; if even, return the average of the two middle elements
  return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2
}

export const getImageColor = async (image: string): Promise<string | null> => {
  try {
    const response = await fetch(image)
    if (!response.ok) {
      return null
    }
    const blob = await response.blob()

    const img = new Image()
    img.src = URL.createObjectURL(blob)

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (ctx) {
          // Set canvas size to match the image
          canvas.width = img.width
          canvas.height = img.height

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0)

          // Get the pixel color at the center
          const x = Math.floor(img.width / 2)
          const y = Math.floor(img.height / 2)
          const pixel = ctx.getImageData(x, y, 1, 1).data

          const color = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${pixel[3] / 255})`

          // Clean up
          URL.revokeObjectURL(img.src)

          resolve(color)
        } else {
          reject('Canvas context is not available')
        }
      }

      img.onerror = error => {
        reject(`Image failed to load: ${error}`)
      }
    })
  } catch (error) {
    console.error('Error downloading or processing the image:', error)
    return null
  }
}

export const getValidUntilDate = (startDate, duration) => {
  const newValidUntilDate = new Date(startDate)
  const newMonth = newValidUntilDate.getMonth() + duration
  if (newMonth > 11) {
    newValidUntilDate.setFullYear(newValidUntilDate.getFullYear() + Math.floor(newMonth / 12))
    newValidUntilDate.setMonth(newMonth % 12)
  } else if (newMonth < 0) {
    newValidUntilDate.setFullYear(newValidUntilDate.getFullYear() + Math.floor(newMonth / 12))
    newValidUntilDate.setMonth(newMonth % 12)
  } else {
    newValidUntilDate.setMonth(newMonth)
  }
  // handle if start date is 29 and new date is not leap year
  if (
    startDate.getMonth() % 2 == 0 &&
    startDate.getDate() > 28 &&
    newValidUntilDate.getMonth() == 2 &&
    !isLeapYear(newValidUntilDate.getFullYear())
  ) {
    newValidUntilDate.setMonth(1, 28)
  }
  // handle if start date if feb-29 on a leap year
  if (isLeapYear(startDate.getFullYear()) && startDate.getMonth() == 1 && startDate.getDate() == 29) {
    newValidUntilDate.setDate(29)
    // reset for full years and new date is not a leap year
    if (!isLeapYear(newValidUntilDate.getFullYear()) && duration % 12 == 0) newValidUntilDate.setMonth(1, 28)
  }
  return newValidUntilDate
}

const isLeapYear = year => {
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
}
