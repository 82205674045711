import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { ShowDialogProps } from '../../../show-dialog'
import { useZodForm } from '../../../hooks/zod-form'
import { invalidateGlobalDocument, useAddGlobalDocumentVersion } from '../../../api/global-documents'
import { AppForm, FormFileUpload, FormSubmitButton, FormTextField } from '../../../components/FormCore'
import { CancelButton } from '../../../components/CancelButton'
import { toast } from 'react-toastify'
import { ALREADY_EXISTS } from '../../../constant'
import { invalidateDocumentCategoryCache } from '../../../api/document-category'
import { invalidateDocument } from '../../../api/document'

export interface GlobalDocumentVersionCreateDialogProps extends ShowDialogProps {
  documentId: EntityId
  documentTitle: string
  customerId?: EntityId
}

export const GlobalDocumentVersionCreateDialog: React.FC<GlobalDocumentVersionCreateDialogProps> = ({
  pop,
  documentId,
  documentTitle,
  customerId,
}) => {
  const { t } = useTranslation(['global-documents', 'common'])

  const schema = z.object({
    description: z.string().optional(),
    version: z.string().nonempty({ message: t('version-is-required', { ns: 'global-documents' }) }),
    fileId: z.union([z.string(), z.number()]),
  })

  const form = useZodForm(schema)
  const { mutateAsync } = useAddGlobalDocumentVersion(documentId as EntityId)

  const onSubmit = async form => {
    await mutateAsync(form).then(
      () => {
        invalidateGlobalDocument.getDocuments()
        invalidateGlobalDocument.getVersions(documentId as EntityId)

        customerId && invalidateDocumentCategoryCache.getDocumentCategoriesWithDocumentCountByKey()
        customerId && invalidateDocument.getDocuments(customerId)
        toast.success(t('addedSuccessfully', { ns: 'common' }))
        pop()
      },
      ({ data }) => {
        if (data === ALREADY_EXISTS) {
          toast.error(t('version-already-exists', { ns: 'global-documents' }))
        }
      },
    )
  }
  return (
    <AppForm form={form} onSubmit={onSubmit}>
      <FormTextField
        name="title"
        disabled={true}
        value={documentTitle}
        label={t('title', { ns: 'common' })}
        fullWidth
      />
      <FormTextField name="version" label={t('version', { ns: 'global-documents' })} fullWidth />
      <FormTextField name="description" label={t('description', { ns: 'common' })} fullWidth multiline rows={4} />
      <Box mb={2}>
        <FormFileUpload label={t('fileName', { ns: 'common' })} name="fileId" mapFileKey="id" />
      </Box>
      <Box className="flex justify-end gap-4 pt-4">
        <CancelButton onClick={pop}>{t('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton createText={t('add', { ns: 'common' })} />
      </Box>
    </AppForm>
  )
}
