import { axios } from './axios'
import { AccessLevelGroupFormType } from './forms/AccessLevelGroupForm'
import { CustomerFormType } from './forms/CustomerForm'
import { InviteUserFormType } from './forms/CustomerInviteUserForm'

import { LocalityGroupFormType } from './forms/LocalityGroupFrom'
import { UserRegistrationFormType } from './forms/UserRegistrationForm'
import { ICustomer, IIdentityUserDto, IUser } from './generated-types'

export async function getFacilities() { }

export async function getCustomerAdmin(customerId: EntityId) {
  const { data } = await axios.get(`admin/customers/${customerId}`)
  return data
}

export async function createCustomerAdmin(form: CustomerFormType) {
  const { data } = await axios.post('admin/customers', form)
  return data
}

export async function updateCustomerAdmin(customerId: EntityId, form: CustomerFormType) {
  const { data } = await axios.put(`admin/customers/${customerId}`, form)
  return data
}

export async function deleteCustomerAdmin(customerId: EntityId) {
  const { data } = await axios.delete(`admin/customers/${customerId}`)
  return data
}

export async function getUsers(searchText?: string) {
  const { data } = await axios.get<IIdentityUserDto[]>('users', { params: { searchText } })
  return data
}

export async function getCustomerUserList(clientId: number, searchText?: string, callBack?) {
  const { data } = await axios.get<IIdentityUserDto[]>(`customers/${clientId}/users`, { params: { searchText } })
  callBack(data)
}

export async function getCustomerUsers(clientId: number, searchText?: string) {
  return await axios.get<IUser[]>(`customers/${clientId}/users`, { params: { searchText } })
}


export async function getUserByEmail(email: string) {
  const { data } = await axios.get<IIdentityUserDto>(`users/get-by-email/${email}`)
  return data
}

export async function getUserInvite(code: string) {
  const { data } = await axios.get(`users/invites/${code}`)
  return data
}

export async function getLocalityAccessLevels() {
  const { data } = await axios.get('access-control/access-levels')
  return data
}

export async function getLocalityAccessLevelsAsList() {
  const { data } = await axios.get('access-control/access-levels/list')
  return data
}

export async function deleteLocalityGroup(customerId: EntityId, localityId: EntityId) {
  const { data } = await axios.delete(`customers/${customerId}/locality-groups/${localityId}`)
  return data
}

export async function createLocalityGroup(customerId: EntityId, form: LocalityGroupFormType) {
  const { data } = await axios.post(`customers/${customerId}/locality-groups`, form)
  return data
}

export async function updateLocalityGroup(customerId: EntityId, groupId: EntityId, form: LocalityGroupFormType) {
  const { data } = await axios.put(`customers/${customerId}/locality-groups/${groupId}`, form)
  return data
}

export async function getLocalityGroupDetails(customerId: EntityId, groupId: EntityId) {
  const { data } = await axios.get(`customers/${customerId}/locality-groups/${groupId}/details`)
  return data
}

export async function getLocalityGroup(customerId: EntityId, groupId: EntityId) {
  const { data } = await axios.get(`customers/${customerId}/locality-groups/${groupId}`)
  return data
}

export async function getAccessLevelGroup(customerId: EntityId, id: EntityId) {
  const { data } = await axios.get(`customers/${customerId}/access-level-groups/${id}`)
  return data
}

export async function createAccessGroup(customerId: EntityId, form: AccessLevelGroupFormType) {
  const { data } = await axios.post(`customers/${customerId}/access-level-groups`, form)
  return data
}

export async function updateAccessGroup(customerId: EntityId, groupId: EntityId, form: AccessLevelGroupFormType) {
  const { data } = await axios.put(`customers/${customerId}/access-level-groups/${groupId}`, form)
  return data
}

export async function deleteAccessGroup(customerId: EntityId, groupId: EntityId) {
  const { data } = await axios.delete(`customers/${customerId}/access-level-groups/${groupId}`)
  return data
}

export async function addUserToCustomer(customerId: EntityId, form: InviteUserFormType) {
  const { data } = await axios.post(`customers/${customerId}/add-user`, form)
  return data
}

export async function getCustomer(customerId: EntityId) {
  const { data } = await axios.get<ICustomer>(`customers/${customerId}`)
  return data
}

export async function registerUser(code: string, form: UserRegistrationFormType) {
  const { data } = await axios.post('users/register', form, { params: { code } })
  return data
}

export async function deleteUserInvite(customerId: EntityId, id: EntityId) {
  const { data } = await axios.delete(`customers/${customerId}/user-invites/${id}`)
  return data
}