import { Box, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material'
import React from 'react'
import { FormFieldType } from '../../../enums'
import DraggableField from './DragableField'
import { CalendarMonth, CheckBox, DateRangeTwoTone, FileUpload, FormatAlignCenter, List, Note, Numbers, RadioButtonChecked, SelectAll, TextFields, Timer } from '@mui/icons-material'
import FormValidation from './FormValidation'
import { showConfirmDialog } from '../../../show-dialog'
import { useTranslation } from 'react-i18next'

const TabPanel = ({ children, value, index, classes = [] }) => {
    return value == index ? (
        <Stack spacing={1} >
            {children}
        </Stack>
    ) : (
        <></>
    )
}

const FormSettings = ({ onDrag, openTab, setOpenTab, setLayouts, selectedSetting, updateField, setSelectedSettingField, onDelete, selectedRowIndex, selectedColumnIndex, selectedFieldIndex }) => {
    const { t } = useTranslation(['form-schema', 'common'])
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setOpenTab(newValue)
    }
    const theme = useTheme()

    return (
        <Box width={200}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={openTab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={
                        <Typography color={openTab == 0 ? `${theme.palette.primaryMain[theme.palette.mode]} !important` : `${theme.palette.secondaryMain[theme.palette.mode]} !important`}>{t('fields')}</Typography>
                    } value={0} />
                    <Tab label={
                        <Typography color={openTab == 1 ? `${theme.palette.primaryMain[theme.palette.mode]} !important` : `${theme.palette.secondaryMain[theme.palette.mode]} !important`}>{t('settings')}</Typography>
                    } value={1} />
                </Tabs>
            </Box>
            <Box height={window.innerHeight - 250} className='scrollbar' style={{ overflowY: 'auto', overflowX: 'hidden' }} >
                <TabPanel value={openTab} index={0} >
                    <DraggableField icon={<FormatAlignCenter />} name='Title' value={FormFieldType.title} onDrag={onDrag} />
                    <DraggableField icon={<TextFields />} name='Text Field' value={FormFieldType.textField} onDrag={onDrag} />
                    <DraggableField icon={<Numbers />} name='Number' value={FormFieldType.numberField} onDrag={onDrag} />
                    <DraggableField icon={<CheckBox />} name='CheckBox' value={FormFieldType.checkBox} onDrag={onDrag} />
                    <DraggableField icon={<CalendarMonth />} name='Date Picker' value={FormFieldType.datePicker} onDrag={onDrag} />
                    <DraggableField icon={<Note />} name='Text Area' value={FormFieldType.textArea} onDrag={onDrag} />
                    <DraggableField icon={<DateRangeTwoTone />} name='Date Time' value={FormFieldType.dateTime} onDrag={onDrag} />
                    <DraggableField icon={<FileUpload />} name='File Upload' value={FormFieldType.fileUpload} onDrag={onDrag} />
                    <DraggableField icon={<Timer />} name='Time Picker' value={FormFieldType.timePicker} onDrag={onDrag} />
                    <DraggableField icon={<RadioButtonChecked />} name='Radio' value={FormFieldType.radio} onDrag={onDrag} />
                    <DraggableField icon={<List />} name='Checkbox List' value={FormFieldType.checkBoxList} onDrag={onDrag} />
                    <DraggableField icon={<SelectAll />} name='Select Field' value={FormFieldType.selectField} onDrag={onDrag} />
                </TabPanel>
                <TabPanel value={openTab} index={1}>
                    <FormValidation selectedSetting={selectedSetting} changeSetting={(fieldValues) => {
                        setSelectedSettingField(fieldValues)
                        updateField(fieldValues, selectedRowIndex, selectedColumnIndex, selectedFieldIndex)
                    }} onDelete={() => {
                        showConfirmDialog(t('delete-field'), t('delete-field-content'), { acceptText: t('delete', { ns: 'common' }) }).then((confirmation) => {
                            if (confirmation) {
                                onDelete()
                            }
                        })
                    }} />
                </TabPanel>
            </Box>
        </Box>
    )
}

export default FormSettings