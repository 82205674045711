import { useTranslation } from 'react-i18next'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { Box, useTheme } from '@mui/material'
import { ServiceStationsTable } from './ServiceStationsTable'
import { FC, useCallback } from 'react'
import { ServiceStationDialog } from './ServiceStationDialog'
import { ServiceStationContainer } from './ServiceStationContainer'
import { serviceStationEndpoints, useDeleteServiceStation } from '../../../api/service-station'
import { IServiceStation } from '../../../generated-types/service-station'
import { toast } from 'react-toastify'
import { AlertDialog } from '../../../show-dialog'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

export const ServiceStationScreen: FC = () => {
  const { customer } = useCustomerCtx()
  const { t: translate } = useTranslation(['service-station', 'common'])
  const { mutateAsync: deleteServiceStation } = useDeleteServiceStation(customer.id)
  const theme = useTheme()

  const { showDialog } = useDialogCtx()

  const handleServiceStationDelete = useCallback(
    async id => {
      showDialog(
        AlertDialog,
        {
          componentProps: {
            title: translate('deleteServiceStation', { ns: 'service-station' }),
            acceptText: translate('delete', { ns: 'common' }),
            text: translate('areYouSureDeleteServiceStation', { ns: 'service-station' }),
            onConfirm: async pop => {
              await deleteServiceStation(id)
                .then(() => {
                  toast.success(translate('service-station-deleted-successfully', { ns: 'service-station' }))
                })
                .catch(error => {
                  if (error?.data) {
                    toast(translate('CannotDeleteServiceStation', { ns: 'service-station' }), { type: 'error' })
                    return
                  }
                  toast.error(translate('service-station-deleted-error', { ns: 'service-station' }))
                })
              pop()
            },
          },
        },
        undefined,
        true,
      )
    },
    [customer.id],
  )

  const handleServiceStation = useCallback(
    (initialValues?: IServiceStation) => {
      showDialog(
        ServiceStationDialog,
        {
          opts: {
            maxWidth: 'sm',
            fullWidth: true,
          },
          componentProps: {
            customerId: customer.id,
            title: initialValues?.id
              ? translate('update-service-station', { ns: 'service-station' })
              : translate('add-service-station', { ns: 'service-station' }),
            initialValues: initialValues,
          },
        },
        undefined,
        true,
      )
    },
    [customer.id, translate],
  )

  return (
    <CommonPageLayout
      titleSection={{ title: translate('service-stations') }}
      topAction={[
        {
          buttonText: translate('service-station', { ns: 'page-container' }),
          buttonIcon: <AddCircleOutline />,
          onClick: handleServiceStation,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <ServiceStationsTable
          queryUrl={serviceStationEndpoints.getAllTypeServiceStations(customer.id)}
          queryParams={{ isIgnoreQueryFilters: true }}
          getDetailPanelContent={({ row }) => {
            return (
              <ServiceStationContainer serviceStationId={row.id} serviceStationName={row.name} groups={row.groups} />
            )
          }}
          handleEdit={handleServiceStation}
          handleRemove={handleServiceStationDelete}
        />
      </Box>
    </CommonPageLayout>
  )
}
