import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../app-routes'
import { LogoHeader } from '../components/LogoHeader'

export const RegistrationCompleteScreen = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate(AppRoutesNavigation.Login)
    }, 3000)
  })

  return (
    <>
      <LogoHeader />
      <Box className="pt-6 flex justify-center">
        <Box className="w-6/12 bg-green-600 p-12 rounded-lg">
          <p className="text-white text-3xl text-center">Registration successfull</p>
          <p className="text-white pt-2 text-center">Redirecting in 3 seconds...</p>
        </Box>
      </Box>
    </>
  )
}
