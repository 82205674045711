import { IDeviationType } from '../../generated-types/deviation-type'
import { IPositionType } from '../../generated-types'
import { IDeviationFormWizardType, IDeviationStepperFormStep } from '../../generated-types/form-wizard'
import DeviationFinalForm from './DeviationFinalForm'
import SelectPositionStep from './step-forms/SelectPositionStep'
import { SelectMainComponentStep } from './step-forms/SelectMainComponentStep'
import SelectComponentStep from './step-forms/SelectComponentStep'
import { SelectEquipmentStep } from './step-forms/SelectEquipmentStep'
import SelectTypeStep from './step-forms/SelectTypeStep'
import { useFormContext } from 'react-hook-form'
import { SelectLocalityMainComponentStep } from './step-forms/SelectLocalityMainComponentStep'

interface IRenderStepContent {
  step: IDeviationStepperFormStep
  customerId: EntityId
  localityId?: EntityId
  setActiveStep: (step: IDeviationStepperFormStep) => void
  formWizard?: any
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  handleNext: () => void
  lgModal?: boolean
}

const RenderStepContent: React.FC<IRenderStepContent> = ({
  step,
  customerId,
  localityId,
  setActiveStep,
  formWizard = IDeviationFormWizardType.Common,
  setDisabled,
  handleNext,
  lgModal,
}) => {
  const { getValues } = useFormContext()
  if (formWizard === IDeviationFormWizardType.Common && step === IDeviationStepperFormStep.Step_1) {
    return <SelectTypeStep setDisabled={setDisabled} setActiveStep={setActiveStep} />
  }

  if (
    (formWizard === IDeviationFormWizardType.Common && step === IDeviationStepperFormStep.Step_3) ||
    (formWizard === IDeviationFormWizardType.Mooring && step === IDeviationStepperFormStep.Step_1)
  ) {
    return (
      <SelectPositionStep
        customerId={customerId}
        localityId={localityId as EntityId}
        setDisabled={setDisabled}
        handleNext={handleNext}
      />
    )
  }

  if (
    (formWizard === IDeviationFormWizardType.Common &&
      step === IDeviationStepperFormStep.Step_4 &&
      getValues('type') != IDeviationType.Equipment &&
      getValues('type') != IDeviationType.MainComponent) ||
    (formWizard === IDeviationFormWizardType.Mooring && step === IDeviationStepperFormStep.Step_2) ||
    (formWizard === IDeviationFormWizardType.Component && step === IDeviationStepperFormStep.Step_4) ||
    (formWizard === IDeviationFormWizardType.Position && step === IDeviationStepperFormStep.Step_1)
  ) {
    return getValues('position')?.type == IPositionType.Cage ? (
      <SelectMainComponentStep customerId={customerId} setDisabled={setDisabled} handleNext={handleNext} />
    ) : (
      <SelectComponentStep
        customerId={customerId}
        setDisabled={setDisabled}
        handleNext={handleNext}
        lgModal={lgModal}
      />
    )
  }
  if (getValues('type') === IDeviationType.Equipment && step === IDeviationStepperFormStep.Step_4) {
    return <SelectEquipmentStep customerId={customerId} setDisabled={setDisabled} handleNext={handleNext} />
  }
  if (getValues('type') === IDeviationType.MainComponent && step === IDeviationStepperFormStep.Step_4) {
    return <SelectLocalityMainComponentStep customerId={customerId} setDisabled={setDisabled} handleNext={handleNext} />
  }
  return <DeviationFinalForm customerId={customerId} localityId={localityId} />
}

export default RenderStepContent
