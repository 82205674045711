import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { invalidateSupplierCache, useAddCustomerSupplier } from '../../../api/supplier'
import { ShowDialogProps } from '../../../show-dialog'
import { SupplierTable } from '../../admin/supplier/SupplierTable'
import { SupplierForm } from '../../admin/supplier/SupplierForm'
import { useParams } from 'react-router-dom'
import { IApiResult } from '../../../generated-types/api-result'
import { ICustomerAdminSupplier } from '../../../generated-types'
import { toast } from 'react-toastify'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface CreateSupplierProps extends ShowDialogProps {
  customerId: EntityId
}

const CreateSupplierDialog: React.FC<CreateSupplierProps> = ({ pop, customerId }) => {
  const { t: translate } = useTranslation(['supplier', 'common'])
  const { mutateAsync } = useAddCustomerSupplier()

  const onSubmit = async form => {
    form.customerId = customerId
    await mutateAsync(form)
      .then(({ data }: { data: IApiResult<ICustomerAdminSupplier> }) => {
        toast.success(translate(data.message, { ns: 'supplier' }))
        invalidateSupplierCache.useGetSuppliers()
        pop(true)
      })
      .catch(({ data }: { data: string }) => {
        toast.error(translate(data, { ns: 'supplier' }))
      })
  }

  return (
    <SupplierForm
      isSuperAdmin={false}
      onSubmit={onSubmit}
      onCancel={pop}
      title={translate('addSupplier', { ns: 'supplier' })}
    />
  )
}

export const SupplierContainer = () => {
  const { t: translate } = useTranslation(['supplier', 'common'])
  const { id: customerId } = useParams()
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const onAddSupplier = async () => {
    const added = await showDialog(
      CreateSupplierDialog,
      {
        componentProps: { customerId: customerId as EntityId },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        }},
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <CommonPageLayout
      titleSection={{ title: translate('suppliers', { ns: 'page-container' }) }}
      topAction={[
        {
          buttonText: translate('supplier'),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddSupplier,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <SupplierTable isSuperAdmin={false} customerId={customerId} height={'81vh'} />
      </Box>
    </CommonPageLayout>
  )
}
