import React from 'react'
import { AuthorizationProvider } from '../../components/common/Authorization'
import { AuthorizationType } from '../../enums/auth'
import { permissionValue } from '../../enums/permissionValue'
import { DocumentContainer } from '../../screens/localities/documents/DocumentContainer'
import { CustomerCtx } from 'src/context/CustomerContext'
import { CustomerServiceStationCtx } from 'src/context/ServiceStationContext'

const DocumentPage = () => {
  return (
    <AuthorizationProvider
      permissions={[
        permissionValue.Locality.Documents.Read,
        permissionValue.Locality.Documents.Write,
        permissionValue.Locality.Documents.Delete,
        permissionValue.Locality.MainComponent.Document.Read,
      ]}
      type={AuthorizationType.Page}
    >
      <DocumentContainer />
    </AuthorizationProvider>
  )
}

export default DocumentPage

export const DocumentAdminPage = () => {
  return (
    <DocumentContainer context={CustomerCtx} />
  )
}

export const DocumentServiceStationPage = () => {
  return (
    <DocumentContainer context={CustomerServiceStationCtx} />
  )
}

