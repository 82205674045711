import { FC } from 'react'
import { GenericTable } from '@lovoldsystem/ui'
import { useGetFacilities } from '../../../api/access-group'
import { FacilityTypes, RoleTypes } from '../../../enums'
import { GridColumns } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { Chip, useTheme } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { UpdateDirectPermissionDialog, UpdateUserRoleDialog, useDeleteAssignUser } from '@lovoldsystem/authorization.client'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { permissionBinding, permissionLevel } from '../../../constant'

interface IFacilityTableProps {
  clientId: EntityId
  userId: string
  facilityType: FacilityTypes
  roleTypes: Array<RoleTypes>
  functionElementParents: number[]
}
export const FacilityTable: FC<IFacilityTableProps> = ({ clientId, userId, facilityType, roleTypes, functionElementParents }) => {
  const { data, refetch } = useGetFacilities({
    clientId,
    facilityType,
    userId,
    isWithUserMappings: true,
    isOnlyForUser: true,
  })

  const { t } = useTranslation(['common'])
  const theme = useTheme()
  const { mutateAsync } = useDeleteAssignUser()
  const { showConfirmDialog, showDialog } = useDialogCtx()

  const handleRoleUpdate = ({ clientUserLinkId, roleId }: { clientUserLinkId: number, roleId: number }) => {
    showDialog(
      UpdateUserRoleDialog,
      {
        title: t('change-role', { ns: 'role' }),
        componentProps: {
          clientId: clientId,
          roleTypes: roleTypes,
          roleId: roleId,
          permissionLevels: permissionLevel,
          permissionBinding: permissionBinding,
          clientUserLinkId: clientUserLinkId,
          functionElementParents: functionElementParents,
          invalidateCallback: () => { refetch() },
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
    )
  }

  const updateDirectPermissions = clientUserLinkId => {
    showDialog(
      UpdateDirectPermissionDialog,
      {
        componentProps: {
          clientUserLinkId,
          isDisabled: false,
          permissionBinding: permissionBinding,
          permissionLevels: permissionLevel,
          isWithLayout: true,
          functionElementParents,
        },
        opts: {
          maxWidth: 'md',
        },
        title: t('access-control:assign-users'),
      },
      undefined,
      true,
    )
  }

  const handleDelete = (clientId: number) => {
    showConfirmDialog(
      t('delete-confirm-role-title', { ns: 'access-control' }),
      t('delete-confirm-role-description', { ns: 'access-control' }),
      { acceptText: t('yes', { ns: 'common' }), cancelText: t('no', { ns: 'common' }) },
      async pop => {
        await mutateAsync(clientId, {
          onSuccess: () => {
            pop()
            refetch()
          },
          onError: () => {
            pop()
            refetch()
          }
        })
      },
    )
  }

  const columns: GridColumns = [
    { field: 'name', headerName: t('name', { ns: 'common' }), flex: 1 },
    {
      field: 'type',
      headerName: t('roles', { ns: 'access-control' }),
      flex: 2,
      renderCell: params => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {params.row.userMappings?.map(clientUserLink => {
              return clientUserLink?.roleId ? (
                <Chip
                  style={{
                    background: theme.palette.primary.main,
                    color: 'white',
                    marginLeft: 2,
                    marginTop: 2,
                    cursor: 'pointer',
                  }}
                  label={t(clientUserLink?.roleName || '', { ns: 'access-control' })}
                  onClick={() => {
                    handleRoleUpdate({
                      clientUserLinkId: clientUserLink?.clientUserLinkId,
                      roleId: clientUserLink?.roleId,
                    })
                  }}
                  onDelete={() => {
                    handleDelete(clientUserLink?.clientUserLinkId)
                  }}
                  deleteIcon={<Delete style={{ color: 'white', height: 19 }} />}
                />
              ) : null
            })}

            {params.row.userMappings
              ?.filter(ul => ul.roleId == null)
              .map(clientUserLink => (
                <Chip
                  key={clientUserLink.clientUserLinkId}
                  onClick={() => {
                    updateDirectPermissions(clientUserLink.clientUserLinkId)
                  }}
                  onDelete={() => {
                    handleDelete(clientUserLink?.clientUserLinkId)
                  }}
                  deleteIcon={<Delete style={{ color: 'white', height: 19 }} />}
                  style={{
                    background: theme.palette.success.main,
                    color: 'white',
                    marginLeft: 2,
                    cursor: 'pointer',
                  }}
                  label={t('access-control:direct-permissions', { ns: 'access-control' })}
                />
              ))}
          </div>
        )
      },
    },
  ]

  return (
    <GenericTable
      id={`facility type ${facilityType} table`}
      data={data || []}
      columns={columns}
      pageSize={10}
      pageOptions={[5, 10, 20]}
      enablePagination
      getRowHeight={() => 50}
    />
  )
}
