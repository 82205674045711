import { GridColumns } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { z } from 'zod'
import { checkMod11 } from '../../../utils'

export const GenericSupplierColumns: GridColumns = [
  {
    field: 'name',
    headerName: t('name', { ns: 'common' }),
    flex: 1,
    cellClassName: 'font-weight-semi-bold',
  },
  {
    field: 'notes',
    headerName: t('description', { ns: 'common' }),
    flex: 1,
  },
  { field: 'organizationNumber', headerName: t('organizationNumber', { ns: 'supplier' }), flex: 1 },
]

export const GenericSupplierFormFields = {
  name: z.string().nonempty(t('nameIsRequired', { ns: 'supplier' })),
  organizationNumber: z
    .string()
    .refine(
      value => {
        if(value.length === 0) return true
        return checkMod11(value)
      },
      {
        message: t('wrongOrganizationNumber', { ns: 'supplier' }),
      },
    )
    .optional(),
  notes: z.string().or(z.null()).optional(),
}
