export enum IDeviationFormWizardType {
  Common,
  Position,
  Component,
  Mooring,
}

export enum IDeviationStepperFormStep {
  Step_1,
  Step_2,
  Step_3,
  Step_4,
  Step_5,
}
