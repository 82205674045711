import { ICustomerAdminSupplier, ISupplier } from '../generated-types'
import { IApiResult } from '../generated-types/api-result'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePatch, usePost, usePut } from './reactQuery'

const adminBaseUrl = () => 'admin/suppliers'
const customerBaseUrl = () => 'suppliers'

export const suppliersEndpoints = {
  getAdminSuppliers() {
    return `${adminBaseUrl()}`
  },
  getAdminSupplier(id: EntityId) {
    return `${adminBaseUrl()}/${id}`
  },
  createAdminSupplier() {
    return `${adminBaseUrl()}`
  },
  updateAdminSupplier(id: EntityId) {
    return `${adminBaseUrl()}/${id}`
  },
  activeOrDeActive() {
    return `${adminBaseUrl()}/activate-or-deactivate`
  },
  deleteAdminSupplier() {
    return `${adminBaseUrl()}`
  },
  getCustomerSuppliers() {
    return `${customerBaseUrl()}`
  },
  getCustomerSupplier(id: EntityId) {
    return `${customerBaseUrl()}/${id}`
  },
  createCustomerSupplier() {
    return `${customerBaseUrl()}`
  },
  updateCustomerSupplier(id: EntityId) {
    return `${customerBaseUrl()}/${id}`
  },
  deleteCustomerSupplier() {
    return `${customerBaseUrl()}`
  },
}

export const invalidateSupplierCache = {
  useGetSuppliers() {
    queryClient.invalidateQueries(suppliersEndpoints.getAdminSuppliers())
    queryClient.invalidateQueries(suppliersEndpoints.getCustomerSuppliers())
  },
  getSupplier(id: EntityId) {
    queryClient.invalidateQueries(suppliersEndpoints.getAdminSupplier(id))
    queryClient.invalidateQueries(suppliersEndpoints.getCustomerSupplier(id))
  },
}

export const useGetAdminSuppliers = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<ISupplier>>(suppliersEndpoints.getAdminSuppliers(), {
    page,
    pageSize,
    isOnlyActiveSuppliers: true,
  })

export const useGetAdminSupplier = (id: EntityId) => useGet<ISupplier>(suppliersEndpoints.getAdminSupplier(id))

export const useAddAdminSupplier = () => usePost<ISupplier, ISupplier>(suppliersEndpoints.createAdminSupplier())

export const useUpdateAdminSupplier = (id: EntityId) =>
  usePut<ISupplier, ISupplier>(suppliersEndpoints.updateAdminSupplier(id))

export const useActivateOrDeActivateAdminSupplier = () =>
  usePatch<IApiResult, { id: number }>(suppliersEndpoints.activeOrDeActive())
export const useDeleteAdminSupplier = () => useDelete<ISupplier>(suppliersEndpoints.deleteAdminSupplier())

export const useGetCustomerSuppliers = (
  customerId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<ICustomerAdminSupplier>>(suppliersEndpoints.getCustomerSuppliers(), {
    customerId,
    page,
    pageSize,
  })

export const useGetCustomerSupplier = (id: EntityId) =>
  useGet<ICustomerAdminSupplier>(suppliersEndpoints.getCustomerSupplier(id))

export const useAddCustomerSupplier = () =>
  usePost<IApiResult<ICustomerAdminSupplier>, ICustomerAdminSupplier>(suppliersEndpoints.createCustomerSupplier())

export const useUpdateCustomerSupplier = (id: EntityId) =>
  usePut<IApiResult<ICustomerAdminSupplier>, ICustomerAdminSupplier>(suppliersEndpoints.updateCustomerSupplier(id))

export const useDeleteCustomerSupplier = () =>
  useDelete<IApiResult<ICustomerAdminSupplier>>(suppliersEndpoints.deleteCustomerSupplier())
