import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { z } from 'zod'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { CancelButton } from '../../../components/CancelButton'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'

interface ServiceFormProps extends AppFormProps {
  title: string
}
export const ServiceForm: React.FC<ServiceFormProps> = ({ onSubmit, onCancel, initialValues, title }) => {
  const { t: translate } = useTranslation(['service', 'common'])

  const schema = z.object({
    name: z
      .string()
      .nonempty(translate('provideValidInput', { ns: 'common' }))
      .refine(value => value !== null && value.length <= 150, {
        message: translate('nameMaxLengthError', { ns: 'service' }),
      }),
    description: z.string().refine(value => value !== null && value.length <= 500, {
      message: translate('descriptionMaxLengthError', { ns: 'service' }),
    }),
  })

  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  const isUpdateForm = initialValues?.id

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
      <GenericDialogLayout title={translate('addservice', { ns: 'service' })} pop={onCancel}>
        {isUpdateForm && <FormTextField name="id" type="hidden" />}
        <FormTextField name="name" label={translate('name', { ns: 'common' })} data-cy="name" />
        <FormTextField
          name="description"
          label={translate('description', { ns: 'common' })}
          multiline
          rows={4}
          data-cy="description"
        />
        <Box className="flex justify-end gap-4 pt-4">
          <FormSubmitButton
            createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
            data-cy="submit"
          />
          <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
        </Box>
      </GenericDialogLayout>
    </AppForm>
  )
}
