import { GridColumns } from '@mui/x-data-grid-pro'
import { Context, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useDeleteDeviationFollowUp,
  invalidateDeviation,
  useDeleteDeviationFollowUpAdmin,
} from '../../../api/deviation'
import { CANNOT_DELETE } from '../../../constant'
import { IDeviation } from '../../../generated-types/deviation'
import { IDeviationPriority } from '../../../generated-types/deviation-priority'
import { AuthorizationProvider } from '../../../components/common/Authorization'
import { permissionValue } from '../../../enums/permissionValue'
import { AuthorizationType } from '../../../enums/auth'
import GenericTable from 'src/components/table/GenericTable'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { DeleteOutline, WarningAmberRounded } from '@mui/icons-material'
import { MUIThemeColors } from 'src/mui-theme'
import { Chip } from 'src/components/common/Chip'
import { getFormattedDate, getThemeColor } from 'src/utils'
import { useTheme } from '@mui/material'
import { getLocalDateTime } from 'src/utils/function'

export const DeviationFollowUpTableComponent: React.FC<{
  deviation: IDeviation
  handleDelete: (id: EntityId) => void
  context?: Context<any> | null
}> = ({ deviation, handleDelete, context }) => {
  const theme = useTheme()

  const { t } = useTranslation(['deviation', 'common'])
  const columns: GridColumns = [
    {
      field: 'description',
      headerName: t('description', { ns: 'common' }),
      flex: 1.5,
      renderCell: ({ row }) => (
        <DynamicTextTypography text={row?.description} variant="body1" maxLength={60} fontWeight={600} />
      ),
    },
    {
      field: 'performedBy',
      headerName: t('performedBy', { ns: 'common' }),
      flex: 1,
    },
    {
      field: 'priority',
      headerName: t('priority', { ns: 'common' }),
      flex: 1,
      renderCell: value =>
        value.value == IDeviationPriority.Critical ? (
          <Chip
            label={t('critical', { ns: 'common' })}
            variant="filled"
            sx={{
              backgroundColor: getThemeColor(theme, MUIThemeColors.errorMain),
              color: 'white !important',
              height: '24px',
              borderRadius: '4px',
            }}
          />
        ) : (
          <Chip
            label={t('normal', { ns: 'common' })}
            variant="outlined"
            sx={{
              borderColor: getThemeColor(theme, MUIThemeColors.secondaryLight),
              height: '24px',
              borderRadius: '4px',
            }}
          />
        ),
    },
    {
      field: 'dueDate',
      headerName: t('dueDate', { ns: 'common' }),
      flex: 1,
      valueGetter: value => {
        return getFormattedDate(getLocalDateTime(new Date(value.value)))
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 70,
      renderCell: params => {
        return (
          <AuthorizationProvider
            permissions={[
              permissionValue.Locality.Deviation.Delete,
              permissionValue.ClientAdminPermission,
              permissionValue.ServiceStation.Write,
              permissionValue.ServiceStation.Update,
              permissionValue.Locality.MainComponent.Deviation.Write,
              permissionValue.Locality.MainComponent.Deviation.Delete,
            ]}
            type={AuthorizationType.Disabled}
            data-cy="deleteFollowUp"
            Context={context}
          >
            <GridActionButton
              onClick={e => {
                e.preventDefault()
                handleDelete(params?.id)
              }}
              height="32px"
              width="32px"
              buttonIcon={<DeleteOutline />}
              shadowWeight={0}
            />
          </AuthorizationProvider>
        )
      },
    },
  ]

  return (
    <GenericTable
      id="deviation-follow-up-table"
      data={deviation.deviationFollowUps}
      columns={columns}
      autoHeight={false}
      height={'calc(100vh * 0.25)'}
    />
  )
}

export const DeviationFollowUpTable: React.FC<{
  deviation: IDeviation
  context?: Context<any> | null
}> = ({ deviation, context }) => {
  const { customerId, localityId, deviationId } = useParams()
  const { t } = useTranslation(['deviation', 'common'])
  const { mutateAsync, error } = useDeleteDeviationFollowUp(customerId as EntityId, deviationId as EntityId)
  const { showConfirmDialog } = useDialogCtx()

  useEffect(() => {
    if (error && error['data'] == CANNOT_DELETE) {
      toast(t('cannot-delete-followUp'), { type: 'error' })
    }
  }, [error])

  const handleDelete = async (id: EntityId) => {
    showConfirmDialog(
      t('deleteDeviationFollowUp'),
      t('areYouSureDeleteFollowUp'),
      undefined,
      async pop => {
        await mutateAsync(id, {
          onSuccess: async () => {
            toast(t('followUp-deleted-successfully'), { type: 'success' })
            invalidateDeviation.invalidateAllDeviations(
              customerId as EntityId,
              localityId as EntityId,
              undefined,
              deviationId,
            )
            pop()
          },
        })
      },
      {
        iconBGColor: '#E96B874D',
        Icon: <WarningAmberRounded sx={{ fontSize: 48, color: '#E96B87' }} />,
        buttonBGColor: '#E96B87',
        buttonTextColor: MUIThemeColors.white,
      },
    )
  }
  return <DeviationFollowUpTableComponent context={context} deviation={deviation} handleDelete={handleDelete} />
}

export const DeviationFollowUpTableAdmin: React.FC<{
  deviation: IDeviation
  context?: Context<any> | null
}> = ({ deviation, context }) => {
  const { t } = useTranslation(['deviation', 'common'])
  const { mutateAsync, error } = useDeleteDeviationFollowUpAdmin(deviation.id as EntityId)
  const { showConfirmDialog } = useDialogCtx()

  useEffect(() => {
    if (error && error['data'] == CANNOT_DELETE) {
      toast(t('cannot-delete-followUp'), { type: 'error' })
    }
  }, [error])

  const handleDelete = async (id: EntityId) => {
    showConfirmDialog(t('deleteDeviationFollowUp'), t('areYouSureDeleteFollowUp'), undefined, async pop => {
      await mutateAsync(id, {
        onSuccess: async () => {
          toast(t('followUp-deleted-successfully'), { type: 'success' })
          invalidateDeviation.invalidateAllDeviations(undefined, undefined, undefined, id)
          pop()
        },
      })
    })
  }
  return <DeviationFollowUpTableComponent context={context} deviation={deviation} handleDelete={handleDelete} />
}
