import { CustomersAdminTable } from './CustomersTable'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CreateCustomerDialog from '../../../dialogs/customer/CreateCustomerDialog'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const AdminCustomersScreen = () => {
  const { showDialog } = useDialogCtx()
  const { t: translate } = useTranslation(['admin-customer', 'common'])

  const onAddCustomer = async () => {
    const added = await showDialog(
      CreateCustomerDialog,
      {
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,true
    )
    if (!added) return
  }

  return (
    <>
      <Box className="p-3 mb-2 bg-white shadow-md" borderRadius={2}>
        <ScreenTitleBar
          title={translate('customers')}
          addNewItemText={translate('add', { ns: 'common' })}
          onAddNewItemClick={onAddCustomer}
        />
        <CustomersAdminTable />
      </Box>
    </>
  )
}
