import React from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import Flags from 'country-flag-icons/react/3x2'
import { useTranslation } from 'react-i18next'
import { languages } from '../utils/languages'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Flex } from 'src/styles/flexComponent'

export interface OptionsProps {
  value: string | number
  icon: React.ElementType<any> | undefined
}

export interface LanguageIconMenuProps<T = any> {
  value?: string | undefined
  options?: OptionsProps[]
  name: string
  onChange?: (e: SelectChangeEvent<string>) => any
}

export default function LanguageIconMenu<T = any>({
  value = '',
  options,
  name,
  onChange,
  ...rest
}: LanguageIconMenuProps<T>) {
  const { i18n } = useTranslation()
  return (
    <Flex.Row width={'52px'} paddingTop={'15px'}
      sx={{
        backgroundColor: 'transparent',
      }}
    >
      <Select
        sx={[
          {
            maxWidth: '30px',
            maxHeight: '31px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '#mui-component-select-language': {
              contentVisibility: 'hidden',
            },
            '#mui-component-select-language:focus': {
              backgroundColor: 'transparent',
            },
            '& .MuiSelect-icon': {
              transform: 'none',
              maxWidth: '40px'
            },
            '& svg': {
              width: '30px'
            }
          },
        ]}
        variant="standard"
        value={value}
        onChange={onChange}
        IconComponent={languages.find(l => l.value == i18n.language)?.icon ?? Flags['GB']}
        name={name}
        disableUnderline
        {...rest}
      >
        {options?.map((option: OptionsProps) => {
          const Icon = option.icon
          return (
            <MenuItem sx={{ display: 'block', padding: '8px' }} key={option.value} value={option.value}>
              {Icon && <Icon />}
            </MenuItem>
          )
        })}
      </Select>
      <KeyboardArrowDown sx={{ marginTop: '7px' }} />
    </Flex.Row>
  )
}
