import { Chip, Divider, Grid, IconButton, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCustomerSuppliers } from '../../../api/supplier'
import BasicDatePicker from '../../../components/DateTimePicker'
import { FormFileCoreUpload, FormTextField } from '../../../components/FormCore'
import { ICustomerAdminSupplier } from '../../../generated-types'
import { Months } from '../../../enums'
import { MainComponentCertificationAdd } from './MainComponentCertificationAdd'
import { SuppliersFormField } from '../../../components/SuppliersFormField'
import { APIResultCount } from '../../../constant'
import { IMainComponentProperty } from 'src/generated-types/main-component-property'
import { Flex } from 'src/styles/flexComponent'
import { Close, Delete, Edit, RestartAlt, Save } from '@mui/icons-material'
import { generateUUID } from 'src/utils/function'

const MainComponentStaticFormFields = ({
  form,
  initialValues,
  file,
  initialCertificateFiles,
  setInitialCertificateFiles,
  customerId,
  isUpdateForm,
  mainComponentTypeDetails,
  setMainComponentTypeDetails,
}) => {
  const properties = ['hs', 'vC5', 'vC15']
  const { t: translate } = useTranslation(['main-component', 'common'])
  const [year, setYear] = useState<Date | null | string>(form.getValues('productionYear'))
  const [month, setMonth] = useState<Date | null | string>(`${Months[form.getValues('productionMonth')]} ${year}`)
  const [mainComponentProperties, setMainComponentProperties] = useState<Array<IMainComponentProperty>>([])
  const [editingPropertyId, setEditingPropertyId] = useState<string | null>(null)

  const { data: suppliers } = useGetCustomerSuppliers(customerId, 0, APIResultCount.Max)

  useEffect(() => {
    if (initialValues['supplierId'])
      form.setValue(
        'supplierId',
        suppliers?.items.find(s => s.id == initialValues['supplierId']) ?? suppliers?.items[0],
      )
  }, [suppliers, initialValues, form])

  useEffect(() => {
    if (initialValues.mainComponentProperties) {
      initialValues.mainComponentProperties.forEach(property => {
        property.uniqueId = generateUUID()
      })
      setMainComponentProperties(initialValues.mainComponentProperties)
    }
  }, [initialValues])

  useEffect(() => {
    form.setValue('mainComponentProperties', mainComponentProperties)
  }, [mainComponentProperties, form])

  const resetProperties = () => {
    properties.forEach(key => {
      form.clearErrors(key)
      form.setValue(key, null)
    })
  }

  const validateProperty = key => {
    const value = form.getValues(key)
    if (!value) {
      form.setError(key, { type: 'required', message: translate('required', { ns: 'common' }) })
      return false
    }
    const numberValue = Number(value)
    if (isNaN(numberValue) || numberValue < 0) {
      form.setError(key, { type: 'invalid', message: translate('mustBeZeroOrPositive', { ns: 'common' }) })
      return false
    }

    form.clearErrors(key)
    return true
  }

  const validateProperties = () => {
    let isValid = true
    properties.forEach(key => {
      if (!validateProperty(key)) {
        isValid = false
      }
    })
    return isValid
  }

  const handleSave = () => {
    const hs = form.getValues('hs')
    const vC5 = form.getValues('vC5')
    const vC15 = form.getValues('vC15')
    if (!validateProperties()) {
      return
    }
    setMainComponentProperties(prev => {
      if (editingPropertyId) {
        return prev.map(p => {
          if (p.uniqueId === editingPropertyId) {
            return {
              hs: hs,
              vC5: vC5,
              vC15: vC15,
              uniqueId: editingPropertyId,
            } as IMainComponentProperty
          }
          return p
        })
      }
      return [
        ...prev,
        {
          hs: hs,
          vC5: vC5,
          vC15: vC15,
          uniqueId: generateUUID(),
        } as IMainComponentProperty,
      ]
    })
    setEditingPropertyId(null)
    resetProperties()
  }

  const showingProperties = useMemo(() => {
    return mainComponentProperties.filter(x => x.uniqueId != editingPropertyId)
  }, [editingPropertyId, mainComponentProperties])

  return (
    <Grid container className="!flex !flex-wrap justify-between">
      <Grid item xs={12} md={6} lg={6}>
        <FormTextField name="id" sx={{ display: 'none' }} />
        <FormTextField name="name" label={translate('name', { ns: 'common' })} />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker label={translate('dateProduced', { ns: 'main-component' })} name="dateProduced" />
      </Grid>
      <Grid item xs={12}>
        <FormTextField name="description" label={translate('description', { ns: 'common' })} multiline rows={3} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField name="serialNumber" label={translate('serialNumber', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField name="orderNumber" label={translate('orderNumber', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField name="reference" label={translate('reference', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField type={'number'} name="lifetime" label={translate('lifetime', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField type={'number'} name="solidity" label={translate('solidity', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          type={'number'}
          name="circumference"
          label={translate('circumference', { ns: 'main-component' })}
        />
      </Grid>
      <Flex.Column px={1} py={1} justifyContent={'center'} alignItems={'flex-start'}>
        <Typography variant="subtitle2">{translate('main-component-property', { ns: 'main-component' })}</Typography>
        <Divider />
        {showingProperties.map((property, index) => (
          <Flex.Row py={1} justifyContent={'center'} alignItems={'center'} key={index} gap={1}>
            {`#${index + 1}`}
            <Chip label={`${translate('hs', { ns: 'main-component-property' })} : ${property.hs}`} />
            <Chip label={`${translate('vc5', { ns: 'main-component-property' })} : ${property.vC5}`} />
            <Chip label={`${translate('vc15', { ns: 'main-component-property' })} : ${property.vC15}`} />
            <Flex.Row gap={1} justifyContent={'center'} alignItems={'center'}>
              <IconButton
                onClick={() => {
                  form.setValue('hs', property.hs)
                  form.setValue('vC5', property.vC5)
                  form.setValue('vC15', property.vC15)
                  setEditingPropertyId(property.uniqueId)
                }}
                sx={{ width: '30px', height: '30px' }}
                disabled={editingPropertyId !== null}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  setMainComponentProperties(prev => prev.filter(p => p.uniqueId !== property.uniqueId))
                }}
                sx={{ width: '30px', height: '30px' }}
                disabled={editingPropertyId !== null}
              >
                <Delete />
              </IconButton>
            </Flex.Row>
          </Flex.Row>
        ))}
        <Flex.Row py={1} justifyContent={'center'} alignItems={'center'}>
          <FormTextField
            type={'number'}
            name="hs"
            label={translate('hs', { ns: 'main-component-property' })}
            focused={editingPropertyId ? true : undefined}
          />
          <FormTextField
            type={'number'}
            name="vC5"
            label={translate('vc5', { ns: 'main-component-property' })}
            focused={editingPropertyId ? true : undefined}
          />
          <FormTextField
            type={'number'}
            name="vC15"
            label={translate('vc15', { ns: 'main-component-property' })}
            focused={editingPropertyId ? true : undefined}
          />
          <Flex.Row gap={1}>
            <IconButton onClick={handleSave}>
              <Save />
            </IconButton>
            {editingPropertyId ? (
              <IconButton
                onClick={() => {
                  setEditingPropertyId(null)
                  resetProperties()
                }}
              >
                <Close />
              </IconButton>
            ) : (
              <IconButton onClick={resetProperties}>
                <RestartAlt />
              </IconButton>
            )}
          </Flex.Row>
        </Flex.Row>
      </Flex.Column>
      <Grid item xs={6}>
        <SuppliersFormField
          suppliers={suppliers?.items as ICustomerAdminSupplier[]}
          name="supplierId"
          label={translate('supplier', { ns: 'common' })}
        />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker
          name="productionYear"
          views={['year']}
          inputFormat={'yyyy'}
          label={translate('productionYear', { ns: 'main-component' })}
          openTo={'year'}
          onChange={newValue => {
            form.setValue('productionYear', new Date(newValue || 0).getFullYear().toString())
            setYear(newValue)
          }}
          value={year}
        />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker
          name="productionMonth"
          views={['year', 'month']}
          inputFormat={'MMMM'}
          label={translate('productionMonth', { ns: 'main-component' })}
          openTo={'month'}
          onChange={newValue => {
            form.setValue('productionMonth', new Date(newValue || 0).getMonth())
            setMonth(newValue)
          }}
          value={month}
          disabled={!year}
        />
      </Grid>
      <Grid item xs={12}>
        <FormFileCoreUpload
          label={translate('image', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          initialFiles={file}
          acceptedFileTypes={['image/png', 'image/jpeg', 'image/gif', 'image/jpg']}
          height="150px"
          userMessage={translate('image-upload-message', { ns: 'common' })}
        />
      </Grid>
      {!isUpdateForm && (
        <Grid item xs={12}>
          <MainComponentCertificationAdd
            suppliers={suppliers}
            initialValues={initialValues}
            accordingSummary={'product-certificate'}
            form={form}
            isProductCertificateAdd={true}
            initialCertificateFiles={initialCertificateFiles}
            setInitialCertificateFiles={setInitialCertificateFiles}
            mainComponentTypeDetails={mainComponentTypeDetails}
            setMainComponentTypeDetails={setMainComponentTypeDetails}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default MainComponentStaticFormFields
