import { GridColumns } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import moment from 'moment'

export const staticGlobalDocumentColumns: GridColumns = [
  {
    field: 'notes',
    headerName: t('description', { ns: 'common' }),
    flex: 2,
    valueGetter: ({ value }) => (value ? value : ' - '),
  },
  {
    field: 'version',
    headerName: t('version', { ns: 'global-documents' }),
    flex: 0.5,
    valueGetter: ({ value }) => (value ? value : ' - '),
  },
  {
    field: 'createdAt',
    headerName: t('registered-date', { ns: 'global-documents' }),
    flex: 1,
    valueGetter: value => (value?.value ? moment(value?.value).format('MM/DD/YYYY') : ' - '),
  },
]

export const staticGlobalDocumentVersionColumns: GridColumns = [
  {
    field: 'description',
    headerName: t('description', { ns: 'common' }),
    flex: 2,
    valueGetter: ({ value }) => (value ? value : ' - '),
  },
  {
    field: 'version',
    headerName: t('version', { ns: 'global-documents' }),
    flex: 0.5,
    valueGetter: ({ value }) => (value ? value : ' - '),
  },
  {
    field: 'createdAt',
    headerName: t('registered-date', { ns: 'global-documents' }),
    flex: 1,
    valueGetter: value => (value?.value ? moment(value?.value).format('MM/DD/YYYY') : ' - '),
  },
]
