import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import log from 'loglevel'
import { setGlobalLogLevel } from './utils'
import './i18n'
import { LicenseInfo } from '@mui/x-data-grid-pro'

LicenseInfo.setLicenseKey(
  '61d9f21454e16845be255dce11065facTz05MjE3MyxFPTE3NDk2MjU4OTAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
)

// @ts-ignore
window.setLogLevel = log.setLevel
// @ts-ignore
window.logLevels = log.levels
// @ts-ignore
window.getLogLevel = log.getLevel

setGlobalLogLevel()
// @ts-ignore
window.Logger = log

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
