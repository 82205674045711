import { useParams } from 'react-router-dom'
import { DeviationsTable } from './DeviationsTable'
import { AdminDeviationTable } from './AdminDeviationTable'
import { permissionValue } from '../../../enums/permissionValue'
import { IDeviationFilters } from 'src/generated-types/deviation'

interface IDeviationsContainer {
  isAdminLayout?: boolean
  filterDeviation?: IDeviationFilters
  height?: number,
  positionId?: EntityId
  enablePagination?: boolean
  enableDelete?: boolean
}

export const DeviationsContainer: React.FC<IDeviationsContainer> = ({ isAdminLayout = false, filterDeviation, height, positionId = undefined, enablePagination, enableDelete }) => {
  const { customerId, localityId } = useParams()

  return (isAdminLayout ? (
    <AdminDeviationTable />
  ) : (
    <DeviationsTable
      customerId={customerId!}
      localityId={localityId!}
      queryParams={{ localityId }}
      updatePermissions={[permissionValue.Locality.Deviation.Update]}
      deletePermissions={[permissionValue.Locality.Deviation.Delete]}
      filterDeviation={filterDeviation}
      height={height}
      autoHeight={false}
      positionId={positionId}
      enablePagination={enablePagination}
      enableDelete={enableDelete}
    />
  )
  )
}
